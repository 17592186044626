<template>
  <app-main />
</template>

<script>
import { AppMain } from './components'

export default {
  name: 'EmptyLayout',
  components: {
    AppMain
  }
}
</script>

<style scoped>

</style>

