var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "q-content" }, [
      _c("div", { staticClass: "num" }, [_vm._v(_vm._s(_vm.postForm.sort))]),
      _vm.postForm.required
        ? _c("div", { staticClass: "req" }, [_vm._v("*")])
        : _vm._e(),
      _c(
        "div",
        {
          domProps: {
            innerHTML: _vm._s(
              _vm.$options.filters.formatRichText(_vm.postForm.content)
            ),
          },
        },
        [_vm._v("题干")]
      ),
    ]),
    _c("div", { staticClass: "answer-list" }, [
      _c("div", { staticClass: "rank-text" }, [
        _c("div", [_vm._v(_vm._s(_vm.postForm.answerList[0].content))]),
        _c("div", [
          _vm._v(
            _vm._s(
              _vm.postForm.answerList[_vm.postForm.answerList.length - 1]
                .content
            )
          ),
        ]),
      ]),
      _c("div", { staticClass: "style-box" }, [
        _vm.postForm.rankStyle === "num2"
          ? _c("div", { staticClass: "rank-mask" })
          : _vm._e(),
        _c(
          "div",
          { staticClass: "rank-num-box" },
          _vm._l(_vm.postForm.answerList, function (item) {
            return _c(
              "div",
              {
                key: item.id,
                staticClass: "rank-num-item",
                class: {
                  "rank-unchecked-bg": _vm.bgStyle(item.score),
                  "rank-checked-color": _vm.checkStyle(item.score),
                  "icon-color": _vm.postForm.rankStyle !== "num2",
                },
                attrs: { title: item.content },
                on: {
                  click: function ($event) {
                    return _vm.checkValue(item)
                  },
                },
              },
              [
                _vm.postForm.rankStyle === "heart"
                  ? _c("svg-icon", { attrs: { "icon-class": "rank-heart" } })
                  : _vm._e(),
                _vm.postForm.rankStyle === "good"
                  ? _c("svg-icon", { attrs: { "icon-class": "rank-good" } })
                  : _vm._e(),
                _vm.postForm.rankStyle === "star"
                  ? _c("svg-icon", { attrs: { "icon-class": "rank-star" } })
                  : _vm._e(),
                _vm.postForm.rankStyle === "circle"
                  ? _c("svg-icon", { attrs: { "icon-class": "rank-circle" } })
                  : _vm._e(),
                _vm.postForm.rankStyle === "num1"
                  ? _c(
                      "div",
                      { staticClass: "circle-num-box" },
                      [
                        _c("svg-icon", {
                          attrs: { "icon-class": "rank-circle" },
                        }),
                        _c("div", { staticClass: "num" }, [
                          _vm._v(_vm._s(item.score)),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.postForm.rankStyle === "num2"
                  ? _c("div", [_vm._v(" " + _vm._s(item.score) + " ")])
                  : _vm._e(),
              ],
              1
            )
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }