<template>

  <div>
    <iframe :src="fullUrl" width="100%" height="800px" />
  </div>

</template>

<script>

export default {
  name: 'PdfReader',
  props: {
    src: String
  },
  data() {
    return {
      viewerUrl: process.env.BASE_URL + 'pdf/web/viewer.html?file=',
      fullUrl: ''
    }
  },
  watch: {
    src: {
      handler(val) {
        this.fullUrl = this.viewerUrl + encodeURIComponent(val)
      }
    }
  },
  created() {
    // 初始化文件
    this.fullUrl = this.viewerUrl + encodeURIComponent(this.src)
  }

}

</script>
