<template>
  <div class="cam-box">
    <div class="cam-3x2">
      <div id="video" ref="video" style="background: #262626; max-width: 100%; height: auto"/>
      <face-tracking
        v-if="tracking && videoLoad"
        ref="tracking"
        :capture="trackCapture"
        video-id="video video"
        @face-out="handleFaceOut"
      />
    </div>

    <el-select v-model="deviceId" size="mini" style="width: 100%;" placeholder="选择摄像头">
      <el-option
        v-for="item in cameras"
        :key="item.deviceId"
        :value="item.deviceId"
        :label="item.label"/>
    </el-select>

  </div>
</template>

<script>
import QNRTC, { QNLogLevel } from 'qnweb-rtc'
import { mapGetters } from 'vuex'
import { roomToken, roomRecord } from '@/api/ability/rtc/qnrtc'
import FaceTracking from '@/components/FaceTracking/index.vue'
import { Loading } from 'element-ui'
// 关闭日志
QNRTC.setLogLevel(QNLogLevel.NONE)

export default {
  name: 'QnrtcCam',
  components: { FaceTracking },
  props: {
    paperId: {
      type: String,
      default: ''
    },
    examId: {
      type: String,
      default: ''
    },
    captureFormat: {
      type: String,
      default: 'image/jpeg'
    },
    // 是否开启面部追踪
    tracking: {
      type: Boolean,
      default: false
    },
    // 是否在追踪到人脸后截图
    trackCapture: {
      type: Boolean,
      default: true
    },
    // 是否展示追踪提示
    showTrackTips: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      client: null,
      // 三个音视频流
      videoTrack: null,
      audioTrack: null,
      checkTimer: null,
      canvas: null,

      // 摄像头相关
      cameraKey: 'cached-camera-id',
      preset: '720p',
      cameraLoaded: false,
      cameras: [],
      deviceId: null,
      videoLoad: false
    }
  },
  computed: {
    ...mapGetters([
      'userId'
    ])
  },
  // 切换摄像头
  watch: {
    deviceId: {
      // 重新开始推送
      handler(val) {
        if (val && this.cameraLoaded) {
          this.switchCamera()
        }
      }
    }
  },
  created() {
    this.regEvent()

    // 获得摄像头列表
    QNRTC.getCameras().then(res => {
      // 使用缓存
      const cachedId = localStorage.getItem(this.cameraKey)
      // 设备ID是否有效的
      let cachedReady = false

      for (let i = 0; i<res.length; i++) {
        const deviceInfo = res[i]

        // OBS虚拟摄像头排除
        if (deviceInfo.kind === 'videoinput' && deviceInfo.label !== 'OBS Virtual Camera') {
          this.cameras.push(deviceInfo)
          if (!cachedReady && cachedId && cachedId === deviceInfo.deviceId) {
            cachedReady = true
          }
        }
      }

      // 从缓存拿取
      if (!this.deviceId && cachedReady) {
        this.deviceId = cachedId
      } else {
        // 获取第一个
        this.deviceId = this.cameras[0].deviceId
      }

      setTimeout(() => {
        this.cameraLoaded = true
      }, 1000)

      this.initClient()
    })
  },

  beforeDestroy() {
    // 离开房间
    this.leaveRoom()
  },
  methods: {

    // 注册事件
    regEvent() {
      this.client = QNRTC.createClient()

      const that = this
      const mu = this.userId + '_' + this.paperId + '_mobile'
      this.client.on('user-joined', function(user) {
        console.log('user joined', user)
        if (user === mu) {
          that.$emit('m-join')
        }
      })

      this.client.on('user-left', function(user) {
        console.log('user left', user)
        if (user === mu) {
          that.$emit('m-left')
        }
      })

      this.client.on('connection-state-changed', function(state) {
        console.log('connection-state-changed', state)
        if (state === 'CONNECTED') {
          that.$emit('connected')
        }
      })

      // 开启流检测
      this.startStreamCheck()
    },

    startStreamCheck() {
      if (this.checkTimer) {
        clearInterval(this.checkTimer)
      }

      // 首次延时开启
      setTimeout(() => {
        this.checkTimer = setInterval(() => {
          this.streamCheck()
        }, 3000)
      }, 10000)
    },

    // 流测试
    streamCheck() {
      if (!this.videoTrack) {
        this.$emit('break')
      } else {
        this.$emit('ready')
      }
    },

    // 推送视频流
    async initClient() {
      const userId = this.userId + '_' + this.paperId + '_camera'
      console.log('+++++视频ID:' + userId)

      const params = { userId: userId, roomName: this.examId }

      roomToken(params).then(async res => {
        this.preset = res.data.preset
        // 进入房间
        await this.client.join(res.data.token)
        // 采集视频
        await this.pushVideo()
        // 开始录制
        setTimeout(() => {
          this.startRecord(params)
        }, 3000)
      })
    },

    // 切换摄像头
    async switchCamera() {
      // 设置缓存
      localStorage.setItem(this.cameraKey, this.deviceId)

      // 打开
      Loading.service({
        text: '正在切换摄像头..',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      setTimeout(() => {
        window.location.reload()
      }, 1500)
    },

    // 推送视频
    async pushVideo() {
      const that = this

      // 音视频
      const tracks = await QNRTC.createMicrophoneAndCameraTracks({
        encoderConfig: 'STANDARD'
      }, {
        encoderConfig: this.preset,
        cameraId: this.deviceId
      })

      // 1音频2视频
      this.videoTrack = tracks[1]
      this.audioTrack = tracks[0]

      // 注册事件
      this.videoTrack.on('ended', function() {
        that.videoTrack = null
        that.$emit('break')
      })

      // 推送
      await this.client.publish(tracks)

      // 获取页面上的一个元素作为播放画面的父元素
      const videoElement = document.getElementById('video')
      await this.videoTrack.play(videoElement, { mirror: true })

      // 就绪
      this.$emit('ready')

      // 视频加载完
      this.videoLoad = true
    },

    // 同步进行录制
    startRecord(params) {
      // 发送录制视频和录屏
      roomRecord(params).then(res => {
        console.log('录制响应', res)
      })
    },

    // 销毁流
    async leaveRoom() {
      if (this.videoTrack) {
        this.videoTrack.destroy()
      }
      if (this.audioTrack) {
        this.audioTrack.destroy()
      }
      // 离开房间
      await this.client.leave()
    },

    // 截图方法，由父控件主动调用
    capture() {
      const video = document.querySelector('video')
      // 直接截图
      const canvas = document.createElement('canvas')
      canvas.height = video.clientHeight * 4
      canvas.width = video.clientWidth * 4
      const ctx = canvas.getContext('2d')
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height)
      const dataImage = canvas.toDataURL(this.captureFormat)
      // 转换成base64去除头
      const base64 = dataImage.replace(/^data:image\/\w+;base64,/, '')
      this.$emit('capture', base64)
      return base64
    },

    // 人脸不在视频区域
    handleFaceOut() {
      this.$emit('face-out')
    },

    // 重新追踪人脸并截图
    reTrack() {
      this.captureLock = false
    }

  }
}
</script>

<style scoped>

.cam-box{
  position: relative;
  width: 100%;
  height: auto;
}
.cam-3x2{
  width: 100%;
  position: relative;
  height: 0;
  padding-bottom: 70%;
  cursor: pointer;
  video,canvas {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 2px;
  }
}

</style>
