var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.breakShow
        ? _c(
            "div",
            {
              staticStyle: { "margin-bottom": "30px", cursor: "pointer" },
              on: { click: _vm.toExam },
            },
            [
              _c("el-alert", {
                attrs: {
                  closable: false,
                  title:
                    "您有正在进行的考试，离线太久考试将被作废哦，点击此处可继续考试！",
                  type: "error",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-tabs",
        {
          attrs: { type: "card" },
          model: {
            value: _vm.activeTab,
            callback: function ($$v) {
              _vm.activeTab = $$v
            },
            expression: "activeTab",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "考试大厅", name: "open" } },
            [_c("open-exam-page", { on: { join: _vm.handleJoin } })],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "我的考试", name: "my" } },
            [_c("my-exam-page", { ref: "my" })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }