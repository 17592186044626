<template>
  <web-table
    :options="options"
    :list-query="listQuery"
  >

    <template #filter-content>

      <el-input
        v-model="listQuery.params.refRemark"
        class="filter-item"
        size="small"
        placeholder="请输入积分标题信息"
        prefix-icon="el-icon-search"
        style="width: 300px"
      />

    </template>

    <template #data-columns>

      <el-table-column
        label="标题"
        prop="refRemark"
        show-overflow-tooltip
      />

      <el-table-column
        label="时间"
        align="center"
        prop="createTime"
        width="180px"
      />

      <el-table-column
        label="积分"
        align="right"
        prop="points"
        width="120px"
      >

        <template v-slot="scope">
          <span v-if="scope.row.points > 0" style="font-weight: 700; color: orange">
            + {{ scope.row.points }}
          </span>
          <span v-else style="font-weight: 700; color: red">
            {{ scope.row.points }}
          </span>
        </template>

      </el-table-column>

    </template>

  </web-table>
</template>

<script>

export default {
  name: 'PointsLog',
  components: { },
  data() {
    return {
      listQuery: {
        current: 1,
        size: 10,
        params: {
        }
      },

      options: {
        // 列表请求URL
        listUrl: '/api/user/points/my-paging'
      }
    }
  }
}
</script>

<style scoped>

  ::v-deep
  .search-area{
    display: none;
  }
</style>
