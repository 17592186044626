// 考试模块
import WebLayout from '@/layout/WebLayout'

const examRouter = {
  path: '/exam',
  component: WebLayout,
  hidden: true,
  redirect: '/pages/exam',
  children: [
    {
      path: '/pages/exam',
      component: () => import('@/views/web/exam/index.vue'),
      name: 'ExamOnline',
      meta: { title: '在线考试', noCache: true }
    },

    {
      path: '/pages/exam/check/:id',
      component: () => import('@/views/web/exam/check'),
      name: 'CheckExam',
      meta: { title: '考试校验', noCache: true, activeMenu: '/pages/exam' }
    },

    {
      path: '/pages/exam/result/:id',
      component: () => import('@/views/web/exam/result'),
      name: 'ShowExam',
      meta: { title: '考试结果', noCache: true, activeMenu: '/pages/exam' }
    },

    {
      path: '/pages/qu/view/:id',
      component: () => import('@/views/web/qu/view'),
      name: 'ViewQu',
      meta: { title: '试题详情', noCache: true, activeMenu: '/pages/paper' }
    },

    {
      path: '/pages/paper/list',
      component: () => import('@/views/web/paper/list'),
      name: 'WebPaperList',
      meta: { title: '考试记录', noCache: true, activeMenu: '/pages/uc' }
    },

    {
      path: '/pages/book/list',
      component: () => import('@/views/web/repo/book'),
      name: 'WrongBook',
      meta: { title: '错题本', noCache: true, activeMenu: '/pages/uc' }
    },

    {
      path: '/pages/repo',
      component: () => import('@/views/web/repo'),
      name: 'MyRepoList',
      meta: { title: '题库练习', noCache: true }
    },

    {
      path: '/pages/repo/view/:repoId',
      component: () => import('@/views/web/repo/view'),
      name: 'MyRepoView',
      meta: { title: '题库详情', noCache: true, activeMenu: '/pages/repo' }
    },
    {
      path: '/pages/train/start/:repoId/:mode',
      component: () => import('@/views/web/repo/training'),
      name: 'TrainStart',
      meta: { title: '开始训练', noCache: true, activeMenu: '/pages/repo' }
    },
    {
      path: '/pages/train/result/:trainId',
      component: () => import('@/views/web/repo/result'),
      name: 'TrainResult',
      meta: { title: '训练结果', noCache: true, activeMenu: '/pages/repo' }
    },

    {
      path: '/pages/train/record',
      component: () => import('@/views/web/repo/train/index.vue'),
      name: 'TrainRecord',
      meta: { title: '训练记录', noCache: true, activeMenu: '/pages/repo' }
    }
  ]
}
export default examRouter
