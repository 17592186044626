import { post } from '@/utils/request'

/**
 * 竞赛分页
 * @param data
 * @returns {Promise}
 */
export function apiBattlePaging(data) {
  return post('/api/battle/battle/admin/paging', data)
}

/**
 * 保存竞赛
 * @param data
 * @returns {Promise}
 */
export function apiBattleSave(data) {
  return post('/api/battle/battle/save', data)
}

/**
 * 竞赛详情
 * @param id
 * @returns {Promise}
 */
export function apiBattleDetail(id) {
  return post('/api/battle/battle/detail', { id: id })
}

/**
 * 个人榜单查询
 * @param battleId
 * @returns {Promise}
 */
export function apiRankingPerson(data) {
  return post('/api/battle/battle/ranking-person', data)
}

/**
 * 部门榜单查询
 * @param battleId
 * @returns {Promise}
 */
export function apiRankingDept(data) {
  return post('/api/battle/battle/ranking-dept', data)
}

