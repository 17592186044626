import { post } from '@/utils/request'

/**
 * 题库详情
 * @param id
 */
export function fetchDetail(id) {
  return post('/api/exam/exam/detail', { id: id })
}

/**
 * 简略详情
 * @param id
 */
export function simpleDetail(id) {
  return post('/api/exam/exam/simple-detail', { id: id })
}

/**
 * 保存题库
 * @param data
 */
export function saveData(data) {
  return post('/api/exam/exam/save', data)
}

/**
 * 题库详情
 * @param data
 */
export function fetchList(data) {
  return post('/api/exam/exam/paging', data)
}

/**
 * 校验考试信息
 * @param id
 * @returns {*}
 */
export function checkInfo(id) {
  return post('/api/exam/exam/check-info', { id: id })
}

/**
 * 发送考试安排
 * @param data
 * @returns {Promise}
 */
export function prepareNotify(data) {
  return post('/api/exam/exam/prepare-notify', data)
}

/**
 * 创建预付费订单
 * @param id
 * @returns {Promise}
 */
export function buy(examId) {
  return post('/api/exam/buy/init', { examId: examId })
}

/**
 * 根据考试ID列表获得考试标题
 * @param data
 * @returns {Promise}
 */
export function listTitles(data) {
  return post('/api/exam/exam/list-titles', data)
}


/**
 * 根据试卷加载大题列表，用于阅卷配置
 * @param tmplId
 * @returns {Promise}
 */
export function listTmplGroup(tmplId) {
  return post('/api/tmpl/group/list-group', { id: tmplId })
}

/**
 * 根据试卷加载需要阅卷的题目数量
 * @param tmplId
 * @returns {Promise}
 */
export function countReviewQu(tmplId) {
  return post('/api/tmpl/group/count-review-qu', { id: tmplId })
}

/**
 * 根据试卷加载试题列表用于阅卷配置
 * @param tmplId
 * @returns {Promise}
 */
export function listTmplQu(tmplId) {
  return post('/api/tmpl/group/list-qu', { id: tmplId })
}


/**
 * 修改状态
 * @param examId
 * @param state
 * @returns {Promise}
 */
export function updateState(examId, state) {
  return post('/api/exam/exam/state', { id: examId, state: state })
}


