var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "line-height": "40px" } },
    [
      _c(
        "el-button",
        {
          staticStyle: { "margin-bottom": "10px" },
          attrs: {
            type: "primary",
            plain: "",
            icon: "el-icon-search",
            size: "small",
          },
          on: { click: _vm.initQuery },
        },
        [_vm._v(" " + _vm._s(_vm.title) + " ")]
      ),
      _c("tool-tip", {
        attrs: {
          msg: "通过样本中某些明细数据或通用数据，来反查确定样本数据范围！",
        },
      }),
      _vm.init
        ? _c(
            "div",
            { staticClass: "query-out" },
            [
              _vm._l(_vm.queryList, function (query, i) {
                return _c("div", { key: i, staticClass: "query-box" }, [
                  _c(
                    "div",
                    { staticStyle: { "flex-grow": "1" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { size: "small", placeholder: "请选择" },
                          on: {
                            change: function ($event) {
                              return _vm.selectChange($event, query)
                            },
                          },
                          model: {
                            value: query.field,
                            callback: function ($$v) {
                              _vm.$set(query, "field", $$v)
                            },
                            expression: "query.field",
                          },
                        },
                        _vm._l(_vm.optionGroups, function (group) {
                          return _c(
                            "el-option-group",
                            { key: group.key, attrs: { label: group.label } },
                            _vm._l(group.options, function (option) {
                              return _c("el-option", {
                                key: option.id,
                                attrs: {
                                  label: option.content,
                                  value: option.id,
                                },
                              })
                            }),
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  query.type === "num" || query.type === "date"
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { size: "small" },
                              model: {
                                value: query.concat,
                                callback: function ($$v) {
                                  _vm.$set(query, "concat", $$v)
                                },
                                expression: "query.concat",
                              },
                            },
                            [
                              _c("el-option", {
                                key: "eq",
                                attrs: { label: "等于", value: "eq" },
                              }),
                              _c("el-option", {
                                key: "gt",
                                attrs: { label: "大于", value: "gt" },
                              }),
                              _c("el-option", {
                                key: "lt",
                                attrs: { label: "小于", value: "lt" },
                              }),
                              _c("el-option", {
                                key: "between",
                                attrs: { label: "位于之间", value: "between" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  query.group === "qu" && query.type === "4"
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { size: "small" },
                              model: {
                                value: query.concat,
                                callback: function ($$v) {
                                  _vm.$set(query, "concat", $$v)
                                },
                                expression: "query.concat",
                              },
                            },
                            [
                              _c("el-option", {
                                key: "eq",
                                attrs: { label: "等于", value: "eq" },
                              }),
                              _c("el-option", {
                                key: "ne",
                                attrs: { label: "不等于", value: "ne" },
                              }),
                              _c("el-option", {
                                key: "ct",
                                attrs: { label: "包含", value: "ct" },
                              }),
                              _c("el-option", {
                                key: "nct",
                                attrs: { label: "不包含", value: "nct" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticStyle: { "flex-grow": "1" } },
                    [
                      query.items && query.items.length > 0
                        ? _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                multiple: "",
                                clearable: "",
                                size: "small",
                              },
                              model: {
                                value: query.values,
                                callback: function ($$v) {
                                  _vm.$set(query, "values", $$v)
                                },
                                expression: "query.values",
                              },
                            },
                            _vm._l(query.items, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.content, value: item.id },
                              })
                            }),
                            1
                          )
                        : query.type === "text" || query.type === "4"
                        ? _c("el-input", {
                            attrs: { size: "small" },
                            model: {
                              value: query.values[0],
                              callback: function ($$v) {
                                _vm.$set(query.values, 0, $$v)
                              },
                              expression: "query.values[0]",
                            },
                          })
                        : _vm._e(),
                      query.type === "num"
                        ? _c("el-input-number", {
                            attrs: { size: "small" },
                            model: {
                              value: query.values[0],
                              callback: function ($$v) {
                                _vm.$set(query.values, 0, $$v)
                              },
                              expression: "query.values[0]",
                            },
                          })
                        : _vm._e(),
                      query.type === "num" && query.concat === "between"
                        ? _c("el-input-number", {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { size: "small" },
                            model: {
                              value: query.values[1],
                              callback: function ($$v) {
                                _vm.$set(query.values, 1, $$v)
                              },
                              expression: "query.values[1]",
                            },
                          })
                        : _vm._e(),
                      query.type === "date"
                        ? _c("el-date-picker", {
                            staticStyle: { width: "160px" },
                            attrs: {
                              "value-format": "yyyy-MM-dd",
                              size: "small",
                            },
                            model: {
                              value: query.values[0],
                              callback: function ($$v) {
                                _vm.$set(query.values, 0, $$v)
                              },
                              expression: "query.values[0]",
                            },
                          })
                        : _vm._e(),
                      query.type === "date" && query.concat === "between"
                        ? _c("el-date-picker", {
                            staticStyle: {
                              width: "160px",
                              "margin-left": "10px",
                            },
                            attrs: {
                              "value-format": "yyyy-MM-dd",
                              size: "small",
                            },
                            model: {
                              value: query.values[1],
                              callback: function ($$v) {
                                _vm.$set(query.values, 1, $$v)
                              },
                              expression: "query.values[1]",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("el-button", {
                        attrs: {
                          type: "danger",
                          circle: "",
                          icon: "el-icon-delete",
                          size: "mini",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.removeQuery(i)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ])
              }),
              _c(
                "div",
                { staticStyle: { width: "60%" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        type: "primary",
                        plain: "",
                        icon: "el-icon-plus",
                        size: "small",
                      },
                      on: { click: _vm.addQuery },
                    },
                    [_vm._v("添加条件")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-search",
                        type: "primary",
                        size: "small",
                      },
                      on: { click: _vm.buildQuery },
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-delete",
                        type: "danger",
                        size: "small",
                      },
                      on: { click: _vm.clearQuery },
                    },
                    [_vm._v("清空")]
                  ),
                ],
                1
              ),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }