<template>

  <data-grid
    ref="dataGrid"
    :options="options"
    :list-query="listQuery"
  >
    <template #filter-content>
      <el-input
        v-if="!repoId"
        v-model="listQuery.params.title"
        class="filter-item"
        size="small"
        placeholder="搜索题库"
        prefix-icon="el-icon-search"
        style="width: 300px"
      />
    </template>

    <template v-slot:data-item="{ data }">

      <div class="flex-bt">
        <div style="flex-grow: 1">
          <div>
            <a class="d-link" @click="toRefDetail(data.repoId, data.trainMode)">{{ data.title }}</a>
          </div>

          <div class="info-item">
            <span class="title">练习模式：</span>
            <span>{{ data.trainMode_dictText }}</span>
          </div>
          <div class="info-item">
            <span class="title">训练进度： </span>
            <span> {{ data.answerCount }}/{{ data.totalCount }}</span>
          </div>
          <div class="info-item">
            <span class="title">正确数量： </span>
            <span>{{ data.rightCount }}题（正确率{{ data.percent }}%）</span>
          </div>

        </div>
        <div style="text-align: right">

          <div>
            <el-progress :width="30" :percentage="data.percent" :stroke-width="3" type="circle" />
          </div>

          <div>
            <el-link v-if="data.state===0" type="primary" icon="el-icon-video-play" @click="startTrain(data.repoId, data.trainMode)">继续训练</el-link>
            <el-link v-else icon="el-icon-video-play" disabled>训练结束</el-link>
          </div>
          <div class="font-small">
            {{ data.updateTime }}
          </div>
        </div>
      </div>


    </template>

  </data-grid>

</template>

<script>

import DataGrid from '@/components/DataGrid/index.vue'

export default {
  name: 'TrainRecordPage',
  components: { DataGrid },
  props: {
    repoId: String
  },
  data() {
    return {
      listQuery: {
        current: 1,
        size: 10,
        params: {

        }
      },

      options: {
        // 列表请求URL
        listUrl: '/api/repo/train/paging'
      }
    }
  },
  watch: {
    repoId: {
      handler(val) {
        if (val) {
          this.listQuery.params.repoId = val
        }
      }
    }
  },

  created() {
    if (this.repoId) {
      this.listQuery.params.repoId = this.repoId
    }
  },
  methods: {

    /**
     * 继续训练
     * @param repoId
     * @param mode
     */
    startTrain(repoId, mode) {
      this.$router.push({ name: 'TrainStart', params: { repoId: repoId, mode: mode }})
    },

    // 去详情页
    toRefDetail(repoId, mode) {
      // 题库错题
      if (mode === 'repo') {
        this.$router.push({ name: 'WrongBook', query: { refType: 'repo', refId: repoId }})
      } else if (mode === 'exam') {
        this.$router.push({ name: 'WrongBook', query: { refType: 'exam', refId: repoId }})
      } else {
        this.$router.push({ name: 'MyRepoView', params: { repoId: repoId }})
      }
    }
  }
}
</script>
<style scoped>
::v-deep .el-progress__text{
  font-size: 12px !important;
  -webkit-transform: scale(0.7);
  line-height: 30px;
  top: 0;
  left: 0;
  width: 30px;
}
</style>
