var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "data-grid",
    {
      ref: "dataGrid",
      attrs: { options: _vm.options, "list-query": _vm.listQuery },
      scopedSlots: _vm._u([
        {
          key: "data-item",
          fn: function (ref) {
            var data = ref.data
            return [
              _c(
                "div",
                [
                  _c("detail-link", {
                    attrs: { id: data.id, title: data.title },
                    on: {
                      click: function ($event) {
                        return _vm.toView(data.id)
                      },
                    },
                  }),
                  _c("div", { staticClass: "flex-bt" }, [
                    _c("div", { staticStyle: { "flex-grow": "1" } }, [
                      _c("div", { staticClass: "info-item" }, [
                        _c("span", { staticClass: "title" }, [
                          _vm._v("问卷时间："),
                        ]),
                        _vm._v(
                          " " +
                            _vm._s(data.startTime) +
                            " ~ " +
                            _vm._s(data.endTime)
                        ),
                      ]),
                      _c("div", { staticClass: "info-item" }, [
                        _c("span", { staticClass: "title" }, [
                          _vm._v("问卷分类："),
                        ]),
                        _vm._v(_vm._s(data.surveyType_dictText)),
                      ]),
                      _c("div", { staticClass: "info-item" }, [
                        _c("span", { staticClass: "title" }, [
                          _vm._v("答卷情况："),
                        ]),
                        _c("span", { staticClass: "warning" }, [
                          _vm._v("已提交" + _vm._s(data.sampleCount) + "份"),
                        ]),
                        _c("span", [_vm._v(" / ")]),
                        data.sampleLimit > 0
                          ? _c("span", { staticClass: "danger" }, [
                              _vm._v("限" + _vm._s(data.sampleLimit) + "份"),
                            ])
                          : _c("span", { staticClass: "success" }, [
                              _vm._v("无限制"),
                            ]),
                      ]),
                    ]),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "font-size": "14px",
                          "font-weight": "700",
                        },
                      },
                      [
                        data.myJoinCount === 0
                          ? _c(
                              "el-link",
                              {
                                attrs: { type: "danger" },
                                on: {
                                  click: function ($event) {
                                    return _vm.toView(data.id)
                                  },
                                },
                              },
                              [_vm._v("待参与")]
                            )
                          : _c(
                              "el-link",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.toJoinRecord(data.id)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " 答卷记录(" + _vm._s(data.myJoinCount) + ") "
                                ),
                              ]
                            ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "template",
        { slot: "filter-content" },
        [
          _c("dic-list-select", {
            staticClass: "filter-item",
            attrs: {
              "dic-code": "survey_type",
              width: "200",
              title: "问卷分类",
            },
            model: {
              value: _vm.listQuery.params.surveyType,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery.params, "surveyType", $$v)
              },
              expression: "listQuery.params.surveyType",
            },
          }),
          _c("dic-list-select", {
            staticClass: "filter-item",
            attrs: {
              "dic-code": "survey_join_state",
              width: "200",
              title: "参与状态",
            },
            model: {
              value: _vm.listQuery.params.joinState,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery.params, "joinState", $$v)
              },
              expression: "listQuery.params.joinState",
            },
          }),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: {
              placeholder: "搜索问卷名称",
              size: "small",
              "prefix-icon": "el-icon-search",
            },
            model: {
              value: _vm.listQuery.params.title,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery.params, "title", $$v)
              },
              expression: "listQuery.params.title",
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }