import { post } from '@/utils/request'

/**
 * 我的参与记录
 * @param battleId
 * @returns {Promise}
 */
export function apiMyPaging(data) {
  return post('/api/battle/paper/my-paging', data)
}

/**
 * 创建试卷
 * @param data
 * @returns {Promise}
 */
export function apiCreate(data) {
  return post('/api/battle/paper/create', data)
}

/**
 * 试卷题目索引
 * @param paperId
 * @returns {Promise}
 */
export function apiQuIndex(paperId) {
  return post('/api/battle/paper/qu-index', { paperId: paperId })
}

/**
 * 试卷题目详情
 * @param paperId
 * @param quId
 * @returns {Promise}
 */
export function apiQuDetail(paperId, quId) {
  return post('/api/battle/paper/qu-detail', { paperId: paperId, quId: quId })
}

/**
 * 填充答案
 * @param data
 * @returns {Promise}
 */
export function apiFillAnswer(data) {
  return post('/api/battle/paper/fill-answer', data)
}

/**
 * 交卷
 * @param id 试卷ID
 * @returns {Promise}
 */
export function apiHandExam(id) {
  return post('/api/battle/paper/hand-exam', { id: id })
}

/**
 * 试卷详情
 * @param id 试卷ID
 * @returns {Promise}
 */
export function apiPaperDetail(id) {
  return post('/api/battle/paper/detail', { id: id })
}

/**
 * 试卷详情
 * @param id 试卷ID
 * @returns {Promise}
 */
export function apiPaperResult(id) {
  return post('/api/battle/paper/result', { id: id })
}

