import { post } from '@/utils/request'

/**
 * 本周用户总览，用于控制台
 * @returns {Promise}
 */
export function dashUserWeek() {
  return post('/api/stat/total/user/week', {})
}

/**
 * 本周用户总览，用于控制台
 * @returns {Promise}
 */
export function dashExamWeek() {
  return post('/api/stat/total/exam/week', {})
}

/**
 * 本周用户总览，用于控制台
 * @returns {Promise}
 */
export function dashLearnWeek() {
  return post('/api/stat/total/learn/week', {})
}
