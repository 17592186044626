<template>

  <div>

    <el-dialog
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :append-to-body="true"
      title="参与报名"
      width="50%"
      @close="handleClose">

      <div v-if="dialogVisible">

        <el-form ref="postForm" :model="postForm" :rules="rules" label-position="left" label-width="150px">
          <el-form-item v-for="field in fieldList" :key="field.fieldName" :label="field.fieldTitle" :prop="field.fieldName">

            <div v-if="field.fieldType==='text'">
              <el-input v-model="postForm[field.fieldName]" :placeholder="field.tips" size="small" maxlength="2000" show-word-limit />
            </div>

            <div v-if="field.fieldType==='textarea'">
              <el-input v-model="postForm[field.fieldName]" :placeholder="field.tips" type="textarea" size="small" maxlength="2000" show-word-limit />
            </div>

            <div v-if="field.fieldType==='radio'">
              <div v-if="field.fieldItems.length >= 3">
                <el-select v-model="postForm[field.fieldName]" :placeholder="field.tips || '请选择'">
                  <el-option
                    v-for="item in field.fieldItems"
                    :key="item.value"
                    :label="item.title"
                    :value="item.value"/>
                </el-select>
              </div>
              <div v-else>
                <el-radio-group v-model="postForm[field.fieldName]">
                  <el-radio v-for="item in field.fieldItems" :key="item.value" :label="item.value">{{ item.title }}</el-radio>
                </el-radio-group>
              </div>
            </div>

            <div v-if="field.fieldType==='checkbox'">
              <div v-if="field.fieldItems.length >= 3">
                <el-select v-model="postForm[field.fieldName]" :placeholder="field.tips || '请选择'" multiple>
                  <el-option
                    v-for="item in field.fieldItems"
                    :key="item.value"
                    :label="item.title"
                    :value="item.value"/>
                </el-select>
              </div>
              <div v-else>
                <el-checkbox-group v-model="postForm[field.fieldName]">
                  <el-checkbox v-for="item in field.fieldItems" :key="item.value" :label="item.value">{{ item.title }}</el-checkbox>
                </el-checkbox-group>
              </div>
            </div>

            <div v-if="field.fieldType==='image'">
              <file-upload
                v-model="postForm[field.fieldName]"
                :limit="1"
                :compress="5000"
                :tips="field.tips"
                list-type="picture-card"
                accept=".jpg,.jpeg,.png"/>
            </div>

            <div v-if="field.fieldType==='audio'" >
              <recorder
                ref="record"
                :tips="field.tips"
                v-model="postForm[field.fieldName]"/>
            </div>

            <div v-if="field.fieldType==='video'">
              <file-upload
                v-model="postForm[field.fieldName]"
                :limit="1"
                :tips="field.tips"
                list-type="file"
                accept=".mp4"/>
            </div>

          </el-form-item>
        </el-form>

      </div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button :loading="loading" type="primary" @click="handleSave">确 定</el-button>
      </div>

    </el-dialog>

    <activity-buy :join-id="joinId" :visible.sync="payVisible"/>

  </div>

</template>

<script>

import { join } from '@/api/activity/activity'

export default {
  name: 'ActivityJoinDialog',
  components: { },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    activity: {
      type: Object
    },
    password: {
      type: String
    }
  },
  data() {
    return {
      loading: false,
      dialogVisible: false,
      rules: {},
      fieldList: [],
      postForm: {

      },
      joinId: null,
      payVisible: false
    }
  },

  watch: {

    // 检测查询变化
    visible: {
      handler() {
        this.dialogVisible = this.visible
      }
    },
    // 检测查询变化
    activity: {
      handler() {
        this.parseActivity()
      },
      deep: true
    }
  },

  created() {
    this.dialogVisible = this.visible
  },

  methods: {

    parseActivity() {
      const list = []
      for (let i = 0; i<this.activity.fieldList.length; i++) {
        // 进行深拷贝，比例引用
        const field = JSON.parse(JSON.stringify(this.activity.fieldList[i]))

        // 初始化参数
        this.$set(this.postForm, field.fieldName, null)

        // 增加非空校验
        if (field.required) {
          this.rules[field.fieldName] = [{ required: true, message: `${field.fieldTitle}不能为空！` }]
        }

        // 转换JSON
        if (field.fieldType === 'checkbox' || field.fieldType === 'radio') {
          try {
            const items = field.fieldItems
            field.fieldItems = JSON.parse(items)
          } catch (err) {
            console.log('++++++++++JSON转换错误:', field)
          }
        }

        if (field.fieldType === 'checkbox') {
          const defaults = []
          if (field.fieldItems && field.fieldItems.length > 0) {
            for (let j = 0; j < field.fieldItems.length; j++) {
              if (field.fieldItems[j].checked) {
                defaults.push(field.fieldItems[j].value)
              }
            }
          }
          this.$set(this.postForm, field.fieldName, defaults)
        }

        if (field.fieldType === 'radio') {
          let defaults = ''
          if (field.fieldItems && field.fieldItems.length > 0) {
            for (let j = 0; j < field.fieldItems.length; j++) {
              if (field.fieldItems[j].checked) {
                defaults = field.fieldItems[j].value
              }
            }
          }

          this.$set(this.postForm, field.fieldName, defaults)
        }

        list.push(field)
      }

      this.fieldList = list
    },

    // 关闭选择框
    handleClose() {
      this.$emit('update:visible', false)
    },

    handleConfirm() {
      // 关闭
      this.handleClose()
    },

    // 弹出支付
    handlePay() {
      this.handleClose()
      this.payVisible = true
    },

    // 提交报名
    handleSave() {
      console.log(this.postForm)

      this.$refs.postForm.validate((valid) => {
        if (!valid) {
          return
        }

        const params = {
          activityId: this.activity.id,
          joinData: this.postForm,
          password: this.password
        }

        // 参与竞赛
        join(params).then(res => {
          const state = res.data.state

          if (state === 1) {
            this.joinId = res.data.joinId
            this.$message.success('报名成功，请尽快支付！')
            this.handlePay()
          } else {
            let msg = ''
            if (state === 0) {
              msg = '报名成功！'
            }
            if (state === 2) {
              msg = '报名成功，请耐心等待管理员审核！'
            }
            this.$message.success(msg)
            setTimeout(() => {
              window.location.reload()
            }, 1500)
          }
        })
      })
    }

  }
}
</script>
