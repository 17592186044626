<template>
  <el-card :class="{'left-box':true, 'left-box-normal': !scrolled, 'left-box-scrolled': scrolled, 'left-box-bottom': bottom}" style="width: 250px">

    <div class="group-card">
      <div class="score-box">
        <div>
          试卷总分：<i class="num">{{ postForm.totalScore }}分</i>
        </div>
        <div>
          及格分数：<i class="num">{{ postForm.qualifyScore }}分</i>
        </div>
        <div>
          用户得分：<i class="num">{{ postForm.userScore }}分</i>
        </div>
        <div>
          考试用时：<i class="num">{{ postForm.userTime }}分钟</i>
        </div>
        <div>
          考试结果：<i class="num">
            <span v-if="postForm.valid" style="color: #03DD6D">
              <span v-if="postForm.passed" style="color: #03DD6D">通过</span>
              <span v-else style="color: #FF4B50">
                <span v-if="postForm.reviewState === 0" style="color: #FFBA00">待阅卷</span>
                <span v-else>未通过</span>
              </span>
            </span>
            <span v-else style="color: #ff0000">无效</span>

          </i>
        </div>
        <div v-if="postForm.reviewer_dictText">
          阅卷老师：<i class="num">
            {{ postForm.reviewer_dictText }}
          </i>
        </div>
      </div>

    </div>

    <div v-for="item in postForm.groupList" :key="item.id">
      <div class="group-card">
        <div slot="header" class="clearfix" style="line-height: 40px;">
          <el-button style="font-size: 14px; font-weight: 700" type="text" @click="goAnchor(item.id)">{{ item.title }}</el-button>
          <el-divider />
        </div>
        <div class="group-card-body">
          <div>
            共 <i class="num">{{ item.quCount }}</i> 题，共 <i class="num">{{ item.totalScore }}</i> 分
          </div>
        </div>

        <div class="card-box">
          <div v-for="card in item.quList" :key="card.id" class="item" @click="goAnchor(card.id)">
            <div :class="{num:true, right: card.isRight, error: !card.isRight, 'current': postForm.state==1 && card.quType=='4'}">{{ card.sort }}</div>
            <div class="flag"><i v-if="card.mark" class="el-icon-s-flag" style="color: #FF4B50" /></div>
          </div>
        </div>

      </div>
    </div>

  </el-card>

</template>

<script>
import { scrollTo } from '@/utils/scroll-to'

export default {
  name: 'ResultLeftNav',
  props: {
    data: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      scrolled: false,
      bottom: false,
      postForm: {}
    }
  },
  mounted() {
    // 监听滚动条
    window.addEventListener('scroll', () => {
      let scrollTop = 0
      if (document.documentElement && document.documentElement.scrollTop) {
        scrollTop = document.documentElement.scrollTop
      } else if (document.body) {
        scrollTop = document.body.scrollTop
      }

      this.scrolled = scrollTop > 85
      const height = document.body.scrollHeight
      this.bottom = (height - scrollTop) < 1000
    })
  },
  created() {
    this.postForm = this.data
  },
  methods: {

    // 到指定位置
    goAnchor(selector) {
      const anchor = document.getElementById(selector)
      const to = anchor.offsetTop + 80
      scrollTo(to, 600, function() {
      })
    }
  }
}
</script>

<style scoped>

.group-card{
  margin-bottom: 15px;
  line-height: 28px;
  width: 100%;
}

.group-card-body {
  line-height: 32px;
  font-size: 14px;
}

.group-card .num{
  color: #1890FF;
}

.left-box{
  height: calc(100vh - 120px);
  position: fixed;
  overflow: auto;
  clear: both;
}

.left-box-normal{
  top: 103px;
  height: calc(100vh - 190px);
}

.left-box-scrolled{
  top: 20px;
  height: calc(100vh - 40px);
}

.left-box-bottom{
  height: calc(100vh - 110px);
}

.result {
  display: flex; align-items: center; font-weight: 700; font-size: 16px;
  width: 100px;
}

.result .right{
  color: #03DD6D; font-size: 22px; margin-right: 10px
}

.result .error{
  color: #FF4B50; font-size: 22px; margin-right: 10px
}

/deep/
.el-divider--horizontal{
  margin: 5px 0;
}

.score-box{
  font-size: 16px;
  font-weight: 700;
  line-height: 32px;
  background: #f5f5f5;
  padding: 10px
}

</style>
