var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "q-content" }, [
      _c("div", { staticClass: "num" }, [_vm._v(_vm._s(_vm.postForm.sort))]),
      _vm.postForm.required
        ? _c("div", { staticClass: "req" }, [_vm._v("*")])
        : _vm._e(),
      _c(
        "div",
        {
          domProps: {
            innerHTML: _vm._s(
              _vm.$options.filters.formatRichText(_vm.postForm.content)
            ),
          },
        },
        [_vm._v("题干")]
      ),
    ]),
    _c(
      "div",
      { staticClass: "answer-list" },
      _vm._l(_vm.postForm.answerList, function (item, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "answer-item",
            class: { "answer-selected": item.selected },
            on: {
              click: function ($event) {
                return _vm.clickItem(index)
              },
            },
          },
          [
            _c("div", { staticStyle: { "padding-right": "10px" } }, [
              _vm.postForm.quType === "2" || _vm.postForm.quType === "28"
                ? _c(
                    "span",
                    {
                      staticClass: "el-checkbox__input ",
                      class: { "is-checked": item.selected },
                    },
                    [_c("span", { staticClass: "el-checkbox__inner" })]
                  )
                : _vm._e(),
              _vm.postForm.quType === "1" || _vm.postForm.quType === "27"
                ? _c(
                    "span",
                    {
                      staticClass: "el-radio__input",
                      class: { "is-checked": item.selected },
                    },
                    [_c("span", { staticClass: "el-radio__inner" })]
                  )
                : _vm._e(),
            ]),
            _c("div", { staticStyle: { "flex-grow": "1" } }, [
              _c(
                "div",
                { staticStyle: { display: "flex", "align-items": "center" } },
                [
                  _c(
                    "div",
                    {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$options.filters.formatRichText(item.content)
                        ),
                      },
                    },
                    [_vm._v("题干")]
                  ),
                ]
              ),
              item.remark
                ? _c("div", { staticClass: "remark" }, [
                    _vm._v(_vm._s(item.remark)),
                  ])
                : _vm._e(),
            ]),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }