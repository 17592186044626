var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        title: "问答详情",
        width: "60%",
        top: "5vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.handleClose,
      },
    },
    [
      _c("div", { staticClass: "chat-box" }, [
        _c("div", [
          _c("div", { staticClass: "flex-bt" }, [
            _c(
              "div",
              [
                _c("yf-avatar", {
                  attrs: { src: _vm.detail.avatar, size: 50 },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "tp-nbox" }, [
              _c("div", { staticClass: "tp-nick" }, [
                _vm._v(_vm._s(_vm.detail.realName)),
              ]),
              _c("div", { staticClass: "tp-time" }, [
                _vm._v(_vm._s(_vm.detail.createTime)),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "bubble" }, [
            _c("div", {
              domProps: {
                innerHTML: _vm._s(
                  _vm.$options.filters.formatRichText(_vm.detail.content)
                ),
              },
            }),
          ]),
        ]),
        _vm.replyList && _vm.replyList.length > 0
          ? _c(
              "div",
              { staticStyle: { "margin-top": "50px" } },
              _vm._l(_vm.replyList, function (reply) {
                return _c("div", { key: reply.id }, [
                  _c("div", [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "flex-end",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "tp-nbox",
                            staticStyle: { "text-align": "right" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "tp-nick",
                                staticStyle: { color: "#FF3649" },
                              },
                              [_vm._v(_vm._s(reply.realName))]
                            ),
                            _c("div", { staticClass: "tp-time" }, [
                              _vm._v(_vm._s(reply.createTime)),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          [
                            _c("yf-avatar", {
                              attrs: { src: reply.avatar, size: 50 },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "bubble bubble-a" }, [
                    _c("div", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$options.filters.formatRichText(reply.content)
                        ),
                      },
                    }),
                  ]),
                ])
              }),
              0
            )
          : _c("div", [
              _c("div", [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "justify-content": "flex-end",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "tp-nbox",
                        staticStyle: { "text-align": "right" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "tp-nick",
                            staticStyle: { color: "#FF3649" },
                          },
                          [_vm._v("云帆考试")]
                        ),
                        _c("div", { staticClass: "tp-time" }, [
                          _vm._v(_vm._s(_vm.detail.createTime)),
                        ]),
                      ]
                    ),
                    _c("div", [_c("yf-avatar", { attrs: { size: 50 } })], 1),
                  ]
                ),
              ]),
              _c("div", { staticClass: "bubble bubble-a" }, [
                _c("div", [_vm._v("此问答还没有回复奥~")]),
              ]),
            ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }