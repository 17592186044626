<template>
  <div @click="docClick">
    <watch-frame :exam-id="examId" :line-count="4" @stat="syncStat" @query-change="queryChange">
      <template v-slot:user-item="{ data }">
        <div class="watch-box-item">
          <div :id="`view_${data.userId}_${data.id}_screen`" class="main-video" @click="videoClick"/>
          <div style="display: flex; justify-content: space-between">
            <div :id="`view_${data.userId}_${data.id}_camera`" class="sub-video" @click="videoClick"/>
            <div :id="`view_${data.userId}_${data.id}_mobile`" class="sub-video sub-mobile" @click="videoClick"/>
          </div>
        </div>
      </template>
    </watch-frame>
  </div>

</template>

<script>
import QNRTC, { QNLogLevel } from 'qnweb-rtc'
import { roomToken } from '@/api/ability/rtc/qnrtc'
import { mapGetters } from 'vuex'
import WatchFrame from '@/views/admin/exam/watch/components/WatchFrame/index.vue'
// 关闭日志
QNRTC.setLogLevel(QNLogLevel.NONE)
const client = QNRTC.createClient()

export default {
  name: 'WatchRtc',
  components: { WatchFrame },
  props: {
    examId: String
  },
  computed: {
    ...mapGetters([
      'userId'
    ])
  },
  created() {
    // 视频监控
    this.$nextTick(() => {
      this.initVideo()
    })
  },
  beforeDestroy() {
    // 尝试离开房间
    this.leave()
  },
  methods: {

    // 刷新统计
    syncStat(data) {
      this.$emit('stat', data)
    },

    // 尝试离开
    async leave() {
      // 尝试离开房间
      if (client) {
        await client.leave()
      }
    },

    // 初始化视频
    async initVideo() {
      // 一个1-1000的随机数，避免进房错误
      const rand = Math.ceil(Math.random() * 1000)
      const userId = this.userId + '_admin_' + rand
      roomToken({ userId: userId, roomName: this.examId, permission: 'admin' }).then(async res => {
        // 订阅
        this.autoSubscribe()
        // 再进房
        await client.join(res.data.token)
      })
    },

    /**
     * 订阅远端用户发布的音视频轨道
     */
    async subscribe(userId, tracks) {
      // 传入远端用户发布的音视频轨道执行订阅操作。
      // 订阅成功后异步返回订阅成功的远端音视频轨道
      const remoteTracks = await client.subscribe(tracks)
      // 遍历返回的远端 Track，调用 play 方法完成在页面上的播放
      for (const remoteTrack of [...remoteTracks.videoTracks]) {
        let prefix = null
        // 有3种形式
        if (remoteTrack.isVideo()) {
          prefix = 'view_'
        }
        if (!prefix) {
          continue
        }
        // 选择页面上的一个元素作为父元素，播放远端的音视频轨
        const remoteElement = document.getElementById(prefix + userId)
        remoteElement.innerHTML = ''
        await remoteTrack.play(remoteElement)
      }
    },

    // 这里的参数 client 是指刚刚初始化的 QNRTCClient 对象, 同上
    autoSubscribe() {
      // 添加事件监听，当房间中出现新的 Track 时就会触发，参数是 trackInfo 列表
      client.on('user-published', (userId, tracks) => {
        console.log('user-published!', userId, tracks)
        this.subscribe(userId, tracks)
          .then(() => console.log('subscribe success!'))
          .catch(e => console.error('subscribe error', e))
      })
    },

    // 视频点击事件
    videoClick(e) {
      const dom = document.getElementById(e.currentTarget.id)
      // 弹窗ID
      this.dialogId = e.currentTarget.id
      // 无则加
      if (!dom.classList.contains('video-dialog')) {
        dom.classList.add('video-dialog')
      }
    },

    // 空白处点击事件
    docClick(e) {
      const node = e.target.nodeName
      if (node !== 'VIDEO' && this.dialogId) {
        document.getElementById(this.dialogId).classList.remove('video-dialog')
      }
    },

    // 数据发生变化时
    async queryChange() {
      // 离开房间
      await this.leave()

      setTimeout(async() => {
        const items = document.querySelectorAll('.watch-box-item')
        console.log('items', items)
        if (items && items.length > 0) {
          await this.initVideo()
        }
      }, 200)
    }
  }
}
</script>

<style scoped>
.main-video{
  height: 200px;
  width: auto;
  background: #262626;
  margin-bottom: 2px;
  cursor: pointer;
}

.sub-video{
  height: 100px;
  background: #262626;
  flex-grow: 1;
  width: 50%;
  cursor: pointer;
}

.sub-mobile{
  margin-left: 2px;
}

.watch-box-item{
  background: #F5F7FA;
  padding: 2px;
}

/*视频放大弹窗*/
.video-dialog{
  position: fixed;
  top: calc(20vh / 2);
  left: calc(40vw / 2);
  width: 60vw;
  height: 80vh;
  padding: 10px;
  background-color: #262626 !important;
  border-radius: 4px;
  border: 1px solid #e6ebf5;
  z-index: 99999
}

/*样式影响放大*/
::v-deep .grid-box-inner:hover{
  transform: none !important;
  background: #fafbfc;
}

</style>

