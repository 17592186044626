var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "card" },
          model: {
            value: _vm.activeTab,
            callback: function ($$v) {
              _vm.activeTab = $$v
            },
            expression: "activeTab",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "积分兑换", name: "my" } },
            [_c("mall-goods-page", { ref: "my" })],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "我的订单", name: "order" } },
            [_c("mall-order-page", { ref: "order" })],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "地址管理", name: "addr" } },
            [
              _c("mall-address", {
                attrs: { "show-radio": false, "show-delete": true },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }