import UserLayout from '@/layout/UserLayout'


const certRouter = {
  path: '/pages/uc-cert',
  component: UserLayout,
  hidden: true,
  redirect: '/pages/uc/cert',
  children: [
    {
      path: '/pages/uc/cert',
      component: () => import('@/views/web/ucenter/cert'),
      name: 'UserCert',
      meta: { title: '我的证书', noCache: true, activeMenu: '/pages/uc' }
    }
  ]
}
export default certRouter
