<template>

  <div>
    <qnrtc-cam
      v-if="paperId"
      ref="cam"
      :paper-id="paperId"
      :exam-id="examId"
      :tracking="true"
      :track-capture="false"
      :show-track-tips="false"
      @ready="handleReady"
      @break="handleBeak"
      @face-out="faceOut"
      @connected="startTimer"
      @capture="saveCapture"
      @m-join="mobileJoin"
      @m-left="mobileLeft"
    />
    <iframe ref="screenFrame" :src="`/pages/exam/screen/${examId}/${paperId}`" style="display: none"/>

    <el-dialog
      :visible.sync="dialogVisible"
      :close-on-press-escape="true"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      :append-to-body="true"
      :show-close="false"
      width="660px">
      <div>
        <div style="display: flex">
          <div style="text-align: center;">
            <vue-qr
              v-if="camUrl"
              :size="180"
              :margin="0"
              :text="camUrl"
            />
            <div style="color: #0A84FF; font-size: 12px">{{ qrText }}</div>
          </div>
          <div class="alert-box">
            <div style="font-size: 14px">确保以下设备正常后方可开始考试</div>
            <div>
              <i v-if="cameraReady" class="el-icon-check" style="color: #1aac1a"/>
              <i v-else class="el-icon-close" style="color: #ff3333"/>
              主摄：确保电脑摄像头设备正常，并允许网页使用。</div>
            <div>
              <i v-if="mobileReady" class="el-icon-check" style="color: #1aac1a"/>
              <i v-else class="el-icon-close" style="color: #ff3333"/>
              副摄：用手机扫描右侧二维码，保持手机持续亮屏状态。
            </div>
            <div>
              <i v-if="screenReady" class="el-icon-check" style="color: #1aac1a"/>
              <i v-else class="el-icon-close" style="color: #ff3333"/>
              录屏：点击[开始考试]弹出录屏选项，选择[整个屏幕]并允许。</div>
            <div style="margin-top: 10px">
              <el-button type="danger" style="width: 100%" @click="startScreen">开始考试</el-button>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

  </div>

</template>

<script>
import { saveCapture } from '@/api/paper/capture'
import VueQr from 'vue-qr'
import { mapGetters } from 'vuex'

export default {
  name: 'ExamCamRtc',
  components: {
    VueQr
  },
  props: {
    examId: String,
    camOn: Boolean,
    camInterval: Number,
    paperId: String
  },
  data() {
    return {
      // 定时截图参数
      timer: null,
      intervalSec: 9999,

      // 扫码提示
      camUrl: '',
      qrText: '请使用手机扫码..',

      // 三路准备状态
      mobileReady: false,
      screenReady: false,
      cameraReady: false
    }
  },

  computed: {
    ...mapGetters([
      'userId'
    ]),

    // 显示窗口
    dialogVisible() {
      return !this.screenReady || !this.cameraReady || !this.mobileReady
    }
  },


  beforeDestroy() {
    if (this.timer != null) {
      clearInterval(this.timer)
    }
  },

  created() {
    // 初始化二维码
    this.camUrl = document.location.protocol + '//' + window.location.host + '/pages/cam?examId=' + this.examId + '&paperId=' + this.paperId + '&userId=' + this.userId
  },

  mounted() {
    // 接收iframe消息
    window.screenMsg = this.screenMsg
  },

  methods: {

    // 开始考试并且
    startScreen() {
      if (!this.cameraReady) {
        this.$message.warning('主摄加载失败，请检查电脑摄像头！')
        return
      }

      if (!this.mobileReady) {
        this.$message.warning('请使用手机扫码接入副摄！')
        return
      }

      if (this.$refs.screenFrame) {
        this.$refs.screenFrame.contentWindow.postMessage('start', '*')
      }
    },

    // 开启定时截屏
    startTimer() {
      // 不启动
      if (!this.camOn || this.camInterval <= 0) {
        return
      }
      // 秒数
      this.intervalSec = this.camInterval * 60000

      if (this.timer) {
        clearInterval(this.timer)
      }

      this.timer = setInterval(() => {
        // 摄像头出错
        this.$refs.cam.capture()
      }, this.intervalSec)
    },

    // 保存截图
    saveCapture(base64) {
      // 保存数据
      const data = { paperId: this.paperId, base64 }
      saveCapture(data).then((res) => {
        const { data } = res
        // 达到上限
        if (data.isBreak) {
          this.$emit('break', `连续${data.failCount}次人脸识别失败，系统强制交卷！`)
          return
        }

        // 还有机会
        if (data.failCount > 0) {
          this.$message.error(`人脸识别已失败${data.failCount}次，连续失败${data.faceChance}次将自动交卷！`)
        }
      })
    },

    faceOut() {
      this.$message.error('请诚信考试，确保人脸在摄像头中央！')
    },

    // 副摄加入
    mobileJoin() {
      this.qrText = '副摄加入成功...'
      setTimeout(() => {
        this.mobileReady = true
      }, 1500)
    },

    // 副摄断线
    mobileLeft() {
      this.qrText = '副摄已断开，请重新扫描...'
      this.mobileReady = false
    },

    // 主摄断线
    handleBeak() {
      this.cameraReady = false
    },

    // 主摄开启
    handleReady() {
      this.cameraReady = true
    },

    // 录屏准备
    screenMsg(msg) {
      this.screenReady = msg === 'ready'
    },

    reloadPage() {
      window.location.reload()
    }
  }
}
</script>

<style scoped>
.alert-box{
  line-height: 33px;
  flex-grow: 1;
  padding-left: 20px;
}
</style>

