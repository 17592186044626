<template>

  <web-table
    :options="options"
    :list-query="listQuery"
  >

    <template #filter-content/>

    <template #data-columns>

      <el-table-column
        label="问卷名称"
        show-overflow-tooltip
      >
        <template v-slot="{ row }">
          <detail-link :query="{surveyId: row.surveyId }" :title="row.title" to="SurveyView" />
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        width="160px"
        prop="startTime"
        label="答卷时间" />

      <el-table-column
        label="提交时间"
        align="center"
        prop="handTime"
        width="180px"
      />

      <el-table-column
        align="center"
        width="100px"
        label="状态">

        <template v-slot="scope">

          <span v-if="scope.row.handState===1" class="success">
            已提交
          </span>
          <span v-else-if="scope.row.handState===0" class="danger">
            待提交
          </span>

        </template>

      </el-table-column>

      <el-table-column
        align="center"
        width="100px"
        label="操作">

        <template v-slot="scope">

          <div v-if="scope.row.handState===1">
            <el-link type="danger" size="mini" @click="toView(scope.row.id)">查看</el-link>
          </div>
          <div v-else-if="scope.row.handState===0">
            <el-link type="primary" size="mini" @click="toAnswer(scope.row.id)">提交</el-link>
          </div>
        </template>
      </el-table-column>
    </template>
  </web-table>

</template>

<script>

export default {
  name: 'SurveyJoinPage',
  props: {
    surveyId: String
  },
  data() {
    return {
      activityId: null,
      payVisible: false,
      joinId: null,
      updateVisible: false,
      viewVisible: false,

      listQuery: {
        current: 1,
        size: 10,
        params: {
          surveyId: null
        }
      },

      options: {
        // 列表请求URL
        listUrl: '/api/survey/paper/join-paging'
      }
    }
  },
  watch: {
    surveyId: {
      handler(val) {
        this.listQuery.params.surveyId = val
      }
    }
  },
  methods: {
    // 继续答题
    toAnswer(paperId) {
      const routeData = this.$router.resolve({ name: 'SurveyAnswer', query: { paperId: paperId }})
      window.open(routeData.href, '_blank')
    },
    // 查看问卷
    toView(paperId) {
      const routeData = this.$router.resolve({ name: 'SurveyAnswer', query: { paperId: paperId, flag: 'view' }})
      window.open(routeData.href, '_blank')
    }
  }
}
</script>
