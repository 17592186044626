var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("data-grid", {
    ref: "dataGrid",
    attrs: { options: _vm.options, "list-query": _vm.listQuery },
    scopedSlots: _vm._u([
      {
        key: "filter-content",
        fn: function () {
          return [
            !_vm.repoId
              ? _c("el-input", {
                  staticClass: "filter-item",
                  staticStyle: { width: "300px" },
                  attrs: {
                    size: "small",
                    placeholder: "搜索题库",
                    "prefix-icon": "el-icon-search",
                  },
                  model: {
                    value: _vm.listQuery.params.title,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery.params, "title", $$v)
                    },
                    expression: "listQuery.params.title",
                  },
                })
              : _vm._e(),
          ]
        },
        proxy: true,
      },
      {
        key: "data-item",
        fn: function (ref) {
          var data = ref.data
          return [
            _c("div", { staticClass: "flex-bt" }, [
              _c("div", { staticStyle: { "flex-grow": "1" } }, [
                _c("div", [
                  _c(
                    "a",
                    {
                      staticClass: "d-link",
                      on: {
                        click: function ($event) {
                          return _vm.toRefDetail(data.repoId, data.trainMode)
                        },
                      },
                    },
                    [_vm._v(_vm._s(data.title))]
                  ),
                ]),
                _c("div", { staticClass: "info-item" }, [
                  _c("span", { staticClass: "title" }, [_vm._v("练习模式：")]),
                  _c("span", [_vm._v(_vm._s(data.trainMode_dictText))]),
                ]),
                _c("div", { staticClass: "info-item" }, [
                  _c("span", { staticClass: "title" }, [_vm._v("训练进度： ")]),
                  _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(data.answerCount) +
                        "/" +
                        _vm._s(data.totalCount)
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "info-item" }, [
                  _c("span", { staticClass: "title" }, [_vm._v("正确数量： ")]),
                  _c("span", [
                    _vm._v(
                      _vm._s(data.rightCount) +
                        "题（正确率" +
                        _vm._s(data.percent) +
                        "%）"
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticStyle: { "text-align": "right" } }, [
                _c(
                  "div",
                  [
                    _c("el-progress", {
                      attrs: {
                        width: 30,
                        percentage: data.percent,
                        "stroke-width": 3,
                        type: "circle",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    data.state === 0
                      ? _c(
                          "el-link",
                          {
                            attrs: {
                              type: "primary",
                              icon: "el-icon-video-play",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.startTrain(
                                  data.repoId,
                                  data.trainMode
                                )
                              },
                            },
                          },
                          [_vm._v("继续训练")]
                        )
                      : _c(
                          "el-link",
                          {
                            attrs: { icon: "el-icon-video-play", disabled: "" },
                          },
                          [_vm._v("训练结束")]
                        ),
                  ],
                  1
                ),
                _c("div", { staticClass: "font-small" }, [
                  _vm._v(" " + _vm._s(data.updateTime) + " "),
                ]),
              ]),
            ]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }