var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.trainData && _vm.trainData.id
        ? _c(
            "div",
            {
              staticStyle: { "margin-bottom": "30px", cursor: "pointer" },
              on: {
                click: function ($event) {
                  return _vm.conTrain(
                    _vm.trainData.repoId,
                    _vm.trainData.trainMode
                  )
                },
              },
            },
            [
              _c("el-alert", {
                attrs: {
                  closable: false,
                  title:
                    "您上次训练的题库为：【" +
                    _vm.trainData.title +
                    "】，进度：" +
                    _vm.trainData.answerCount +
                    "/" +
                    _vm.trainData.totalCount +
                    "，点击此处快速继续训练！",
                  type: "error",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-tabs",
        {
          attrs: { type: "card" },
          model: {
            value: _vm.activeTab,
            callback: function ($$v) {
              _vm.activeTab = $$v
            },
            expression: "activeTab",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "公开题库", name: "open" } },
            [_c("open-repo-page", { on: { join: _vm.refreshMy } })],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "我的题库", name: "my" } },
            [_c("my-repo-page", { ref: "my" })],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "训练记录", name: "train" } },
            [_c("train-record-page")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }