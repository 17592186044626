<template>

  <div>

    <data-table
      ref="pagingTable"
      :auto-scroll="false"
      :options="options"
      :list-query="listQuery"
      @add="handleAdd"
    >

      <template #filter-content>
        <el-input v-model="listQuery.params.realName" class="filter-item" size="small" style="width: 200px;" placeholder="搜索姓名/账号" />
      </template>

      <template #data-columns>

        <el-table-column
          type="selection"
          width="55"
        />

        <el-table-column
          show-overflow-tooltip
          align="center"
          label="账号"
          prop="userName"
        />

        <el-table-column
          show-overflow-tooltip
          align="center"
          label="姓名"
          prop="realName"
        />

        <el-table-column
          show-overflow-tooltip
          align="center"
          label="部门"
          prop="deptName"
        />

        <el-table-column
          show-overflow-tooltip
          align="center"
          label="手机号"
          prop="mobile"
        />

      </template>
    </data-table>

    <user-select-dialog
      :dialog-show.sync="dialogVisible"
      :excludes="excludes"
      @select="userSelected"
    />

  </div>

</template>

<script>
import permission from '@/directive/permission'
import { batchJoin } from '@/api/sys/group/user'
import UserSelectDialog from '@/views/admin/sys/user/components/UserSelectDialog.vue'

export default {
  name: 'GroupUserList',
  directives: { permission },
  components: { UserSelectDialog },
  props: {
    groupId: String
  },
  data() {
    return {
      dialogVisible: false,
      excludes: [],
      listQuery: {
        current: 1,
        size: 10,
        params: {
          groupId: ''
        }
      },

      postForm: {},
      options: {

        delete: {
          enable: true,
          permission: 'sys:group:user:remove',
          url: '/api/sys/group/user/delete'
        },
        add: {
          enable: true,
          permission: 'sys:group:user:join'
        },

        // 列表请求URL
        listUrl: '/api/sys/group/user/paging'
      }
    }
  },

  watch: {
    // 关闭窗口刷新
    groupId: {
      handler(val) {
        this.listQuery.params.groupId = val
        this.listQuery.current = 1
        this.excludes = []
      }
    }
  },

  created() {
    this.listQuery.params.groupId = this.groupId
  },

  methods: {

    handleAdd() {
      this.dialogVisible = true
    },

    // 选定用户
    userSelected(ids) {
      this.postForm.userIds = ids
      this.postForm.groupIds = [this.groupId]
      this.postForm.flag = 1
      console.log(this.postForm)

      // 去重
      for (let i = 0; i<ids.length; i++) {
        this.excludes.push(ids[i])
      }

      batchJoin(this.postForm).then(() => {
        this.$notify({
          title: '成功',
          message: '批量加入成功！',
          type: 'success',
          duration: 2000
        })

        this.$refs.pagingTable.getList()
      })
    }
  }
}
</script>

<style scoped>

</style>
