var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Tinymce", {
        ref: "editor",
        attrs: { height: 150 },
        model: {
          value: _vm.postForm.content,
          callback: function ($$v) {
            _vm.$set(_vm.postForm, "content", $$v)
          },
          expression: "postForm.content",
        },
      }),
      _c(
        "div",
        {
          staticClass: "answer-list",
          attrs: { id: _vm.randId },
          on: { mouseover: _vm.showActions, mouseleave: _vm.hideActions },
        },
        [
          _vm._l(_vm.postForm.answerList, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "answer-item",
                on: {
                  mouseover: function ($event) {
                    _vm.hoverIndex = index
                  },
                  mouseleave: function ($event) {
                    _vm.hoverIndex = "-1"
                  },
                },
              },
              [
                _c("div", { staticClass: "sort-icon" }, [
                  _c("i", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.hoverIndex === index,
                        expression: "hoverIndex === index",
                      },
                    ],
                    staticClass: "el-icon-menu",
                  }),
                ]),
                _c(
                  "div",
                  [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: "设置为默认选项",
                          placement: "top-start",
                        },
                      },
                      [
                        _vm.postForm.quType === "1" ||
                        _vm.postForm.quType === "27"
                          ? _c(
                              "el-radio",
                              {
                                attrs: {
                                  value: item.defaultSelected ? index : "",
                                  label: index,
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.setDefault(index, true)
                                  },
                                },
                              },
                              [_c("span")]
                            )
                          : _vm._e(),
                        _vm.postForm.quType === "2" ||
                        _vm.postForm.quType === "28"
                          ? _c(
                              "el-checkbox",
                              {
                                attrs: { value: item.defaultSelected },
                                on: {
                                  change: function ($event) {
                                    return _vm.setDefault(index, $event)
                                  },
                                },
                              },
                              [_c("span")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("div", { staticStyle: { "flex-grow": "1" } }, [
                  _vm.hoverIndex === index
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { size: "mini" },
                            model: {
                              value: item.content,
                              callback: function ($$v) {
                                _vm.$set(item, "content", $$v)
                              },
                              expression: "item.content",
                            },
                          }),
                          _vm.postForm.quType === "27" ||
                          _vm.postForm.quType === "28"
                            ? _c("el-input-number", {
                                staticStyle: { "margin-left": "10px" },
                                attrs: { size: "mini" },
                                model: {
                                  value: item.score,
                                  callback: function ($$v) {
                                    _vm.$set(item, "score", $$v)
                                  },
                                  expression: "item.score",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                          },
                        },
                        [
                          _c("div", {
                            staticStyle: { "font-size": "14px", color: "#555" },
                            domProps: { innerHTML: _vm._s(item.content) },
                          }),
                          _vm.postForm.quType === "27" ||
                          _vm.postForm.quType === "28"
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    color: "#F58711",
                                    "font-weight": "500",
                                  },
                                },
                                [
                                  _vm._v(
                                    "（" + _vm._s(item.score || 0) + "分）"
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                  item.remark
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "12px",
                            color: "#666",
                            "padding-top": "5px",
                          },
                        },
                        [_vm._v(_vm._s(item.remark))]
                      )
                    : _vm._e(),
                ]),
                _c(
                  "div",
                  { staticClass: "answer-actions" },
                  [
                    _vm.actions && _vm.hoverIndex === index
                      ? _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "在下方插入一个选项",
                              placement: "top-start",
                            },
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                size: "mini",
                                icon: "el-icon-plus",
                                circle: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.insertAnswer(index)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.actions && _vm.hoverIndex === index
                      ? _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "删除当前选项",
                              placement: "top-start",
                            },
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                size: "mini",
                                icon: "el-icon-minus",
                                circle: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.removeAnswer(index)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.actions && _vm.hoverIndex === index
                      ? _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "添加说明",
                              placement: "top-start",
                            },
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                size: "mini",
                                icon: "el-icon-document",
                                circle: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.addRemark(index)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.actions && _vm.hoverIndex === index
                      ? _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "选中设为默认",
                              placement: "top-start",
                            },
                          },
                          [
                            _c(
                              "el-checkbox",
                              {
                                staticStyle: {
                                  "margin-left": "10px",
                                  "line-height": "20px",
                                },
                                attrs: {
                                  value: item.defaultSelected,
                                  size: "mini",
                                  border: "",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.setDefault(index, $event)
                                  },
                                },
                              },
                              [_vm._v("默认")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            )
          }),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "align-items": "center",
                "justify-content": "space-between",
              },
            },
            [
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "opt-item",
                      attrs: {
                        size: "mini",
                        type: "primary",
                        plain: "",
                        icon: "el-icon-plus",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.insertAnswer(-1)
                        },
                      },
                    },
                    [_vm._v("添加选项")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "opt-item",
                      attrs: { size: "mini" },
                      on: { click: _vm.showBatch },
                    },
                    [_vm._v("批量编辑")]
                  ),
                  _vm.postForm.quType === "1"
                    ? _c(
                        "el-button",
                        {
                          staticClass: "opt-item",
                          attrs: { size: "mini" },
                          on: { click: _vm.toMulti },
                        },
                        [_vm._v("转多选")]
                      )
                    : _vm._e(),
                  _vm.postForm.quType === "2"
                    ? _c(
                        "el-button",
                        {
                          staticClass: "opt-item",
                          attrs: { size: "mini" },
                          on: { click: _vm.toRadio },
                        },
                        [_vm._v("转单选")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { display: "flex", "align-items": "center" } },
                [
                  _vm.postForm.quType === "2" || _vm.postForm.quType === "28"
                    ? _c(
                        "el-select",
                        {
                          staticClass: "opt-item",
                          staticStyle: { width: "100px" },
                          attrs: {
                            size: "mini",
                            placeholder: "至少选",
                            clearable: "",
                          },
                          model: {
                            value: _vm.postForm.selectMin,
                            callback: function ($$v) {
                              _vm.$set(_vm.postForm, "selectMin", $$v)
                            },
                            expression: "postForm.selectMin",
                          },
                        },
                        _vm._l(
                          _vm.postForm.answerList.length,
                          function (count) {
                            return _c("el-option", {
                              key: count,
                              attrs: { label: count + "项", value: count },
                            })
                          }
                        ),
                        1
                      )
                    : _vm._e(),
                  _vm.postForm.quType === "2" || _vm.postForm.quType === "28"
                    ? _c(
                        "el-select",
                        {
                          staticClass: "opt-item",
                          staticStyle: { width: "100px" },
                          attrs: {
                            size: "mini",
                            placeholder: "最多选",
                            clearable: "",
                          },
                          model: {
                            value: _vm.postForm.selectMax,
                            callback: function ($$v) {
                              _vm.$set(_vm.postForm, "selectMax", $$v)
                            },
                            expression: "postForm.selectMax",
                          },
                        },
                        _vm._l(
                          _vm.postForm.answerList.length,
                          function (count) {
                            return count >= _vm.postForm.selectMin
                              ? _c("el-option", {
                                  key: count,
                                  attrs: { label: count + "项", value: count },
                                })
                              : _vm._e()
                          }
                        ),
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-select",
                    {
                      staticClass: "opt-item",
                      staticStyle: { width: "120px" },
                      attrs: {
                        size: "mini",
                        placeholder: "选项显示方式",
                        clearable: "",
                      },
                      model: {
                        value: _vm.postForm.itemRand,
                        callback: function ($$v) {
                          _vm.$set(_vm.postForm, "itemRand", $$v)
                        },
                        expression: "postForm.itemRand",
                      },
                    },
                    [
                      _c("el-option", {
                        key: false,
                        attrs: { value: false, label: "选项按序显示" },
                      }),
                      _c("el-option", {
                        key: true,
                        attrs: { value: true, label: "选项随机显示" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-checkbox",
                    {
                      staticClass: "opt-item",
                      model: {
                        value: _vm.postForm.required,
                        callback: function ($$v) {
                          _vm.$set(_vm.postForm, "required", $$v)
                        },
                        expression: "postForm.required",
                      },
                    },
                    [_vm._v("必答")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        2
      ),
      _c("select-quick-fill-dialog", {
        attrs: { visible: _vm.batchVisible },
        on: {
          "update:visible": function ($event) {
            _vm.batchVisible = $event
          },
          confirm: _vm.saveBatch,
        },
        model: {
          value: _vm.batchTemplate,
          callback: function ($$v) {
            _vm.batchTemplate = $$v
          },
          expression: "batchTemplate",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }