<template>

  <div>

    <el-tabs v-model="activeTab" type="card" @tab-click="tabClick">
      <el-tab-pane label="报名中心" name="open">
        <open-activity-page />
      </el-tab-pane>
      <el-tab-pane label="报名记录" name="my">
        <activity-join-page />
      </el-tab-pane>
      <el-tab-pane label="报名订单" name="order" class="module-pay">
        <activity-order-page />
      </el-tab-pane>

    </el-tabs>
  </div>

</template>

<script>
export default {
  data() {
    return {
      activeTab: 'open'
    }
  },
  methods: {
    tabClick() {

    }
  }
}
</script>

<style scoped>

</style>
