<template>
  <div style="border: #dedede 1px solid">
    <div :id="chartId" style="width: 100%; height: 400px"/>
  </div>
</template>

<script>

import * as echarts from 'echarts'

export default {
  name: 'StatBarYChart',
  props: {
    chartKey: {
      type: String,
      default: 'default'
    },
    dataList: Array,
    titleField: {
      type: String,
      default: 'content'
    },
    valueField: {
      type: String,
      default: 'itemCount'
    }
  },
  data() {
    return {
      chart: null
    }
  },
  computed: {
    // ID
    chartId() {
      return 'pie-chart-' + this.chartKey
    }
  },
  watch: {
    dataList: {
      handler() {
        this.initChart()
      }
    }
  },

  created() {
    this.initChart()
  },
  methods: {

    // 初始化表格
    initChart() {
      if (!this.dataList || this.dataList.length === 0) {
        return
      }
      const titleList = []
      const dataList = []
      for (let i = 0; i<this.dataList.length; i++) {
        const item = this.dataList[i]
        titleList.push(item[this.titleField])
        dataList.push(item[this.valueField])
      }

      const option = {

        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01]
        },
        yAxis: {
          type: 'category',
          data: titleList
        },
        series: [
          {
            type: 'bar',
            data: dataList
          }
        ]
      }

      console.log('++++++++++++this.chartId', this.chartId)

      this.$nextTick(() => {
        this.chart = echarts.init(document.getElementById(this.chartId))
        this.chart.setOption(option)
      }, 1000)
    }
  }
}
</script>

<style scoped>
.pass {
  color: #1aac1a
}

.fail {
  color: #ff0000;
}

</style>
