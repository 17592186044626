import { post, download } from '@/utils/request'

/**
 * 查找全部题目
 * @param surveyId
 * @returns {Promise}
 */
export function listAll(surveyId) {
  return post('/api/survey/qu/list-all', { id: surveyId })
}


/**
 * 保存全部题目
 * @param data
 * @returns {Promise}
 */
export function saveAll(data) {
  return post('/api/survey/qu/save-all', data)
}

/**
 * 查找只有标题的题目列表，用于动态列展示
 * @param surveyId
 * @returns {Promise}
 */
export function listTitle(surveyId) {
  return post('/api/survey/qu/list-title', { id: surveyId })
}


/**
 * 导出
 * @param data
 */
export function exportExcel(data) {
  return download('/api/survey/qu/export', data, '导出的问题.xlsx')
}
