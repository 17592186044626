var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { staticClass: "state-flag" }, [
    _vm.flag === "NOT_START"
      ? _c("span", { staticClass: "warning" }, [_vm._v("【未开始】")])
      : _vm._e(),
    _vm.flag === "WAIT_REVIEW"
      ? _c("span", { staticClass: "warning" }, [_vm._v("【待阅卷】")])
      : _vm._e(),
    _vm.flag === "IN_REVIEW"
      ? _c("span", { staticClass: "warning" }, [_vm._v("【阅卷中】")])
      : _vm._e(),
    _vm.flag === "FINISHED"
      ? _c("span", { staticClass: "danger" }, [_vm._v("【已出分】")])
      : _vm._e(),
    _vm.flag === "PROCESSING"
      ? _c("span", { staticClass: "success" }, [_vm._v("【考试中】")])
      : _vm._e(),
    _vm.flag === "OVERDUE"
      ? _c("span", { staticClass: "info" }, [_vm._v("【已过期】")])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }