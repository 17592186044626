var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.quData
    ? _c(
        "div",
        { staticClass: "qu-box", class: { "sub-box": _vm.sub } },
        [
          _c("div", { staticClass: "qu-item" }, [
            _c("div", { class: _vm.sub ? "sub" : "num" }, [
              _vm._v(_vm._s(_vm.quData.sort)),
            ]),
            _c("div", { staticClass: "content" }, [
              _c("div", [
                _vm._v("【" + _vm._s(_vm.quData.quType_dictText) + "】"),
              ]),
              _c("div", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$options.filters.formatRichText(_vm.quData.content)
                  ),
                },
              }),
            ]),
          ]),
          _vm.quData.quType === "1" ||
          _vm.quData.quType === "2" ||
          _vm.quData.quType === "3"
            ? _c(
                "div",
                { staticClass: "qu-answer" },
                _vm._l(_vm.quData.answerList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "item",
                      class: {
                        active:
                          item.checked ||
                          (_vm.mode === "repo_rem" && item.isRight),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleCheck(item)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "tag" }, [
                        _vm._v(_vm._s(item.tag)),
                      ]),
                      _c("div", { staticClass: "content" }, [
                        _c("div", [_vm._v(_vm._s(item.content))]),
                        item.image
                          ? _c("div", [
                              _c("img", { attrs: { src: item.image } }),
                            ])
                          : _vm._e(),
                      ]),
                      item.isRight &&
                      (_vm.cardItem.answered || _vm.mode === "repo_rem")
                        ? _c("div", { staticClass: "right" }, [
                            _c("i", {
                              staticClass: "el-icon-success",
                              staticStyle: { color: "#0d5ea5" },
                            }),
                            _vm._v(" 答案 "),
                          ])
                        : _vm._e(),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
          _vm.quData.quType === "4"
            ? _c(
                "div",
                [
                  _c(
                    "div",
                    { staticStyle: { "font-weight": "700", color: "#0a84ff" } },
                    [_vm._v(" 题目解析： ")]
                  ),
                  _vm.quData.analysis
                    ? _c("div", {
                        staticClass: "as-box",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$options.filters.formatRichText(
                              _vm.quData.analysis
                            )
                          ),
                        },
                      })
                    : _c("div", [_vm._v("暂无试题解析!")]),
                  _vm.mode !== "repo_rem"
                    ? _c("el-alert", {
                        staticStyle: { "margin-top": "20px" },
                        attrs: {
                          closable: false,
                          title:
                            "请您详细阅读解析并点击下方的`确认答案`即可完成训练",
                          type: "error",
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.quData.quType === "5" && _vm.mode !== "repo_rem"
            ? _c(
                "div",
                { staticClass: "qu-answer" },
                _vm._l(_vm.quData.answerList, function (item) {
                  return _c("div", { key: item.id, staticClass: "item" }, [
                    _c(
                      "div",
                      { staticClass: "content" },
                      [
                        _c("el-input", {
                          attrs: { disabled: _vm.cardItem.answered },
                          model: {
                            value: item.answer,
                            callback: function ($$v) {
                              _vm.$set(item, "answer", $$v)
                            },
                            expression: "item.answer",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                }),
                0
              )
            : _vm._e(),
          _vm.quData.quType === "99"
            ? _c(
                "div",
                _vm._l(_vm.quData.subList, function (sub) {
                  return _c(
                    "div",
                    { key: sub.id },
                    [
                      _c("qu-item-train", {
                        attrs: {
                          value: sub,
                          card: _vm.cardItem,
                          sub: true,
                          mode: _vm.mode,
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e(),
          (_vm.cardItem.answered || _vm.mode === "repo_rem") &&
          _vm.quData.quType !== "4"
            ? _c("div", [
                _c(
                  "div",
                  { staticStyle: { "font-weight": "700", color: "#cc0000" } },
                  [_vm._v(_vm._s(_vm.sub ? "小题解析：" : "大题解析："))]
                ),
                _c("div", { staticClass: "as-box" }, [
                  _vm.quData.quType === "5"
                    ? _c(
                        "div",
                        _vm._l(_vm.quData.answerList, function (item) {
                          return _c("div", { key: item.id }, [
                            _vm._v(_vm._s(item.content)),
                          ])
                        }),
                        0
                      )
                    : _c("div", [
                        _vm.quData.analysis
                          ? _c("div", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$options.filters.formatRichText(
                                    _vm.quData.analysis
                                  )
                                ),
                              },
                            })
                          : _c("div", [_vm._v("暂无题目分析内容！")]),
                      ]),
                ]),
              ])
            : _vm._e(),
          _vm.mode !== "repo_rem" &&
          !_vm.cardItem.answered &&
          !_vm.sub &&
          (_vm.quData.quType === "2" ||
            _vm.quData.quType === "4" ||
            _vm.quData.quType === "5" ||
            _vm.quData.quType === "99")
            ? _c(
                "el-button",
                {
                  staticStyle: { "margin-top": "20px" },
                  attrs: { type: "warning", size: "small", round: "" },
                  on: { click: _vm.checkAnswer },
                },
                [_vm._v("确认答案")]
              )
            : _vm._e(),
          _vm.sub ? _c("div", { staticClass: "split" }) : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }