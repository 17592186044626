<template>

  <div>

    <data-grid
      ref="dataGrid"
      :options="options"
      :list-query="listQuery"
    >
      <template #filter-content>
        <el-input
          v-model="listQuery.params.title"
          class="filter-item"
          size="small"
          placeholder="搜索竞赛名称"
          prefix-icon="el-icon-search"
          style="width: 250px"
        />
        <el-date-picker
          v-model="listQuery.params.dateRange"
          class="filter-item"
          size="small"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          style="margin-left: 10px"
        />
      </template>

      <template v-slot:data-item="{ data }">
        <div style="display: flex">
          <div style="width: 200px">
            <image-box :src="data.banner" />
          </div>
          <div style="flex-grow: 1">

            <div style="display: flex; align-items: center; padding-left: 10px">
              <div style="flex-grow: 1">
                <detail-link :id="data.id" :title="data.title" to="WebBattleDetail" />

                <div style="font-size: 14px; color: #333">
                  答题规则：
                  <span>{{ data.cycleType_dictText }}{{ data.cycleCount }}次 </span>
                  <span>每次{{ data.quCount }}题，每题{{ data.quScore }}分</span>
                </div>
                <div style="font-size: 14px; color: #333">
                  竞赛时间： {{ data.startTime }} ~ {{ data.endTime }}
                </div>

              </div>
            </div>


          </div>
        </div>
      </template>

    </data-grid>

  </div>
</template>

<script>

import DataGrid from '@/components/DataGrid/index.vue'
import ImageBox from '@/components/ImageBox/index.vue'

export default {
  components: { ImageBox, DataGrid },
  data() {
    return {

      listQuery: {
        current: 1,
        size: 10,
        params: {

        }
      },

      options: {
        // 列表请求URL
        listUrl: '/api/battle/battle/paging'
      }
    }
  }
}
</script>

<style scoped>

/deep/
.el-card__body{
  padding: 0
}

.cover{
  height: 60px;
  width: 180px
}
</style>
