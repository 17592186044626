<template>

  <span>

    <span v-if="checkPermission([permission]) || permission==null || permission===''">

      <router-link v-if="to && !params && !query" :to="{ name: to, params: { id: id }}">
        <a class="d-link" v-html="title" />
      </router-link>

      <router-link v-else-if="to && params" :to="{ name: to, params: params }">
        <a class="d-link" v-html="title" />
      </router-link>

      <router-link v-else-if="to && query" :to="{ name: to, query: query }">
        <a class="d-link" v-html="title" />
      </router-link>

      <a v-else class="d-link" @click="linkClick" v-html="title" />
    </span>

    <span v-else v-html="title" />

  </span>

</template>

<script>
import checkPermission from '@/utils/permission'
export default {
  name: 'DetailLink',
  props: {
    title: String,
    id: String,
    to: String,
    params: Object,
    query: Object,
    permission: String
  },
  methods: {
    checkPermission,
    linkClick() {
      this.$emit('click', this.id)
    }
  }
}
</script>
