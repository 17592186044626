<template>

  <div>
    <div class="q-content">
      <div class="num">{{ postForm.sort }}</div>
      <div v-if="postForm.required" class="req">*</div>
      <div v-html="$options.filters.formatRichText(postForm.content)">题干</div>
    </div>

    <div class="answer-list">
      <el-input v-model="postForm.answer" type="textarea" @blur="answerChange"/>
    </div>

  </div>

</template>

<script>

import DicListSelect from '@/components/DicListSelect/index.vue'
export default {
  name: 'SurveyAnswerAsk',
  components: { DicListSelect },
  props: {
    value: Object
  },
  data() {
    return {
      current: 0,
      postForm: {
        answerList: []
      }
    }
  },

  watch: {
    value: {
      handler() {
        this.initValue()
      }
    }
  },

  created() {
    // 查找详情
    this.initValue()
  },

  methods: {


    // 选定
    answerChange() {
      this.$emit('change', this.postForm)
    },

    // 构建默认数据
    initValue() {
      this.postForm = this.value

      // 赋值
      this.postForm.answerList.forEach((v) => {
        if (v.selected) {
          this.current = v.score
        }
      })
    }

  }
}
</script>

<style scoped>

.answer-list{
  margin-top: 10px;
}

.q-content{
  display: flex;
}

.q-content > .num{
  padding-right: 10px;
  color: #0A84FF;
  font-weight: 700;
}

.q-content > .req{
  color: #ff3333;
  font-size: 22px;
  line-height: 20px;
  font-weight: 700
}
::v-deep .q-content p{
  margin-block-start: 0;
  margin-block-end: 0;
}
</style>
