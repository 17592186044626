var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticStyle: { display: "flex", "justify-content": "space-between" },
        },
        [
          _c(
            "div",
            { staticStyle: { width: "53%" } },
            [
              _c("mall-address", {
                model: {
                  value: _vm.postForm.addrId,
                  callback: function ($$v) {
                    _vm.$set(_vm.postForm, "addrId", $$v)
                  },
                  expression: "postForm.addrId",
                },
              }),
              !_vm.detail.requireAddr
                ? _c("div", [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          margin: "20px 0",
                          "font-size": "12px",
                          color: "#DD4A68",
                        },
                      },
                      [
                        _vm._v(
                          " 虚拟物品可以不选择地址，其他信息可以通过下方备注提交！ "
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticStyle: { margin: "20px 0" } },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-size": "18px",
                        "font-weight": "700",
                        "margin-bottom": "10px",
                      },
                    },
                    [_vm._v("订单备注")]
                  ),
                  _c("el-input", {
                    attrs: { type: "textarea", rows: "5" },
                    model: {
                      value: _vm.postForm.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.postForm, "remark", $$v)
                      },
                      expression: "postForm.remark",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        loading: _vm.loading,
                        disabled: !_vm.detail.orderVerify,
                        type: "danger",
                      },
                      on: { click: _vm.handleSubmit },
                    },
                    [_vm._v("提交订单")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "right-box" }, [
            _c(
              "div",
              {
                staticStyle: {
                  "font-size": "14px",
                  "line-height": "22px",
                  padding: "10px",
                  background: "#FFF7E6",
                  "margin-bottom": "20px",
                  color: "#666",
                },
              },
              [
                _vm._v(
                  " 使用积分+实付款的商品，提交订单后将立即扣除积分；如订单超过30分钟未完成支付，已扣除的积分系统自动退回到您的账号！ "
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "goods-box" },
              [
                _vm._l(_vm.detail.goodsList, function (item) {
                  return _c("div", { key: item.id }, [
                    !item.buyVerify.verify
                      ? _c("div", { staticClass: "verify-msg" }, [
                          _vm._v(" " + _vm._s(item.buyVerify.msg) + " "),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "list" }, [
                      _c("img", {
                        staticClass: "img",
                        attrs: { src: item.goodsImage },
                      }),
                      _c("div", { staticClass: "desc-box" }, [
                        _c("div", { staticStyle: { display: "flex" } }, [
                          _c("div", { staticClass: "title" }, [
                            _vm._v(_vm._s(item.goodsTitle)),
                          ]),
                          _c("div", { staticClass: "num" }, [_vm._v("*1")]),
                        ]),
                        _c("div", { staticStyle: { display: "flex" } }, [
                          _c("div", { staticClass: "sku-t" }, [
                            _vm._v(_vm._s(item.skuTitle)),
                          ]),
                          _c(
                            "div",
                            { staticClass: "sku-p" },
                            [
                              _c("price-show", {
                                attrs: {
                                  "cost-points": item.costPoints,
                                  "sale-price": item.salePrice,
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]),
                  ])
                }),
                _c("el-divider"),
                _c("div", { staticClass: "settle" }, [
                  _vm.detail.totalPoints > 0
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "font-size": "14px",
                            "font-weight": "700",
                            color: "#333",
                          },
                        },
                        [
                          _c("div", { staticStyle: { "flex-grow": "1" } }, [
                            _vm._v("积分支付"),
                          ]),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "font-style": "italic",
                                color: "#333",
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.detail.totalPoints) + "积分 "
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.detail.totalAmount > 0
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "font-size": "14px",
                            "font-weight": "700",
                            color: "#333",
                          },
                        },
                        [
                          _c("div", { staticStyle: { "flex-grow": "1" } }, [
                            _vm._v("现金支付"),
                          ]),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "font-style": "italic",
                                color: "#333",
                              },
                            },
                            [
                              _vm._v(
                                " ￥" + _vm._s(_vm.detail.totalAmount) + " "
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
              ],
              2
            ),
          ]),
        ]
      ),
      _c("pay-dialog", {
        attrs: { visible: _vm.payVisible, "order-id": _vm.orderId },
        on: {
          "update:visible": function ($event) {
            _vm.payVisible = $event
          },
          close: _vm.payBack,
          success: _vm.payBack,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }