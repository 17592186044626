<template>
  <div class="box">

    <div v-if="step===1">

      <el-image :src="require('@/assets/web/cam-bg.png')" fit="fill" class="cover" />

      <div class="tips-line">
        参考上图所示，手机架设在您斜后方，确保可以同时拍到您的上半身及电脑屏幕。
      </div>

      <div class="tips-line">
        点击[开始摄像]按钮后，如出现授权提示，请选择允许授权。
      </div>

      <div class="tips-line">
        如果[开始摄像]不成功，点击右上角3个点，选择在浏览器中打开。
      </div>

      <div class="tips-line red">
        请设置手机的休眠时间足够长，避免自动息屏。
      </div>

      <div v-if="cameras.length > 0" class="tips-line">
        <el-select v-model="cameraId" style="width: 100%" placeholder="请选择摄像头">
          <el-option
            v-for="item in cameras"
            :key="item.value"
            :label="item.text"
            :value="item.value"/>
        </el-select>
      </div>

      <div class="opt-btn">
        <el-button type="primary" style="width: 100%" @click="startCam">开始摄像</el-button>
      </div>

    </div>

    <div v-show="recording" v-if="step===2" >
      <div id="video"/>
      <div class="tips-line green">正在拍摄中...</div>
      <div class="tips-line red">请勿关闭手机，直至考试结束！</div>
      <div class="opt-btn">
        <el-button type="danger" style="width: 100%" @click="closeCam">结束摄像</el-button>
      </div>
    </div>

    <div v-if="step===3">
      <div class="tips-line">摄像已结束！</div>
      <div class="tips-line">直接关闭此页面即可！</div>
    </div>

  </div>

</template>

<script>

import { Loading } from 'element-ui'
import QNRTC, { QNLogLevel } from 'qnweb-rtc'
QNRTC.setLogLevel(QNLogLevel.ERROR)
import { roomToken, roomRecord } from '@/api/ability/rtc/qnrtc'

const client = QNRTC.createClient()

export default {
  data() {
    return {
      step: 1,
      cameras: [],
      cameraId: '',
      userId: '',
      examId: '',
      paperId: '',
      client: null,
      joined: false,
      videoTrack: null,
      recording: false,
      timer: null,
      count: 0,
      joinLock: false
    }
  },

  mounted() {
    this.userId = this.$route.query.userId
    this.paperId = this.$route.query.paperId
    this.examId = this.$route.query.examId
    // 加载摄像头
    this.loadCameras()
    // 注册事件
    this.regEvent()

    // 监听页面可见性
    window.addEventListener('visibilitychange', this.visibleChange)
  },

  beforeDestroy() {
    this.leaveRoom()
    window.removeEventListener('visibilitychange', this.visibleChange)
  },

  methods: {

    // 加载摄像头
    loadCameras() {
      const that = this
      // 监听摄像头设备变化
      QNRTC.getCameras().then(cameras => {
        console.log('摄像头列表', cameras)
        const list = []
        // 转换为能识别的对象
        for (let i = 0; i < cameras.length; i++) {
          list.push({ text: cameras[i].label || ('摄像头' + (i + 1)), value: cameras[i].deviceId })
        }
        that.cameras = list
        // 默认第一个
        if (cameras.length > 0) {
          that.cameraId = cameras[0].deviceId
        }
      })
    },

    // 注册事件
    regEvent() {
      const that = this
      // 监听事件
      const pc = this.userId + '_pc_' + this.paperId
      // 监听事件
      client.on('user-left', function(user) {
        console.log('user-left', user)
        if (user === pc) {
          that.leftInterval()
        }
      })

      client.on('user-joined', function(user) {
        console.log('user-join', user)
        if (user === pc) {
          that.reInit()
        }
      })
    },

    // 开启摄像头
    startCam() {
      this.step = 2
      this.initCam()
    },

    // 结束摄像头
    closeCam() {
      this.step = 3
      this.leaveRoom()
    },

    // 初始化
    async initCam() {
      const that = this
      const mu = this.userId + '_' + this.paperId + '_mobile'

      const params = { userId: mu, roomName: this.examId }
      const loading = Loading.service({
        text: '开启摄像头...',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      roomToken(params).then(async res => {
        // 进入房间
        await client.join(res.data.token)
        // 采集视频
        if (!that.cameraId) {
          this.videoTrack = await QNRTC.createCameraVideoTrack({
            encoderConfig: res.data.preset
          })
        } else {
          this.videoTrack = await QNRTC.createCameraVideoTrack({
            encoderConfig: res.data.preset,
            cameraId: that.cameraId
          })
        }
        // 将刚刚的 Track 列表发布到房间中
        await client.publish(this.videoTrack)
        // 获取页面上的一个元素作为播放画面的父元素
        const videoElement = document.getElementById('video')
        await this.videoTrack.play(videoElement, { mirror: true })
        // 开始录制
        await this.startRecord(params)
        loading.close()
        this.recording = true
        this.joinLock = false
      })
    },

    // 重新加入房间
    async reInit() {
      this.recording = false
      if (this.joinLock) {
        return
      }
      this.joinLock = true
      // 离开房间
      await this.leaveRoom()
      // 重新初始化
      await this.initCam()
    },

    // PC端交卷或者离线
    leftCheck() {
      this.count += 1
      console.log('check join:' + this.count)
      if (this.count > 3) {
        // 先清理
        this.leaveRoom()
        // 提示并退出
        this.$message.warning('电脑端考试已离线，已断开连接...')
        setTimeout(() => {
          this.step = 3
        }, 2000)
      }
    },

    // 持续监听连接状态
    leftInterval() {
      if (this.timer) {
        clearInterval(this.timer)
      }
      this.timer = setInterval(() => {
        this.leftCheck()
      }, 2000)
    },

    // 开始录制
    async startRecord(params) {
      // 发送录制视频和录屏
      roomRecord(params).then(res => {
        console.log('录制响应', res)
      })
    },
    // 断开流
    async leaveRoom() {
      this.count = 0
      if (this.timer) {
        clearInterval(this.timer)
      }

      if (this.videoTrack) {
        this.videoTrack.destroy()
      }
      await client.leave()
    },

    // 视图发生变化
    visibleChange() {
      const visible = document.visibilityState
      console.log('+++++change', visible)
      if (visible === 'hidden') {
        this.leaveRoom()
      } else if (this.step === 2) {
        this.reInit()
      }
    }
  }
}
</script>

<style scoped>

.box{
  padding: 20px 20px 80px 20px
}
.tips-line{
  line-height: 28px;
  margin-top: 10px;
  font-weight: 400;
}

.red{
  font-weight: 600;
  color: #E84637
}

.green{
  font-weight: 600;
  color: #03DF6E
}

.opt-btn{
  position: fixed;
  bottom: 20px;
  width: calc(100% - 40px)
}
</style>
