var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("data-table", {
        ref: "pagingTable",
        attrs: {
          "auto-scroll": false,
          options: _vm.options,
          "list-query": _vm.listQuery,
        },
        on: { add: _vm.handleAdd },
        scopedSlots: _vm._u([
          {
            key: "filter-content",
            fn: function () {
              return [
                _c("el-input", {
                  staticClass: "filter-item",
                  staticStyle: { width: "200px" },
                  attrs: { size: "small", placeholder: "搜索姓名/账号" },
                  model: {
                    value: _vm.listQuery.params.realName,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery.params, "realName", $$v)
                    },
                    expression: "listQuery.params.realName",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "data-columns",
            fn: function () {
              return [
                _c("el-table-column", {
                  attrs: { type: "selection", width: "55" },
                }),
                _c("el-table-column", {
                  attrs: {
                    "show-overflow-tooltip": "",
                    align: "center",
                    label: "账号",
                    prop: "userName",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    "show-overflow-tooltip": "",
                    align: "center",
                    label: "姓名",
                    prop: "realName",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    "show-overflow-tooltip": "",
                    align: "center",
                    label: "部门",
                    prop: "deptName",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    "show-overflow-tooltip": "",
                    align: "center",
                    label: "手机号",
                    prop: "mobile",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("user-select-dialog", {
        attrs: { "dialog-show": _vm.dialogVisible, excludes: _vm.excludes },
        on: {
          "update:dialogShow": function ($event) {
            _vm.dialogVisible = $event
          },
          "update:dialog-show": function ($event) {
            _vm.dialogVisible = $event
          },
          select: _vm.userSelected,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }