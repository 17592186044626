var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("el-card", [
        _c("h3", [_vm._v("竞赛时间：" + _vm._s(_vm.paperData.createTime))]),
        _c(
          "section",
          { staticClass: "exam-result" },
          _vm._l(_vm.paperData.quList, function (item) {
            return _c(
              "div",
              { key: item.id, staticClass: "ques" },
              [
                _c("div", { staticClass: "ques-title" }, [
                  _c("i", { staticClass: "num" }, [_vm._v(_vm._s(item.sort))]),
                  _c("p", [
                    _c("span", [
                      _vm._v("【" + _vm._s(item.quType_dictText) + "】"),
                    ]),
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$options.filters.formatRichText(item.content)
                        ),
                      },
                    }),
                    _c("i", [_vm._v(" " + _vm._s(item.score) + "分")]),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "option" },
                  _vm._l(item.answerList, function (an) {
                    return _c(
                      "label",
                      {
                        key: an.id,
                        class: _vm.itemClass(an.checked, an.isRight),
                      },
                      [
                        _c("span", [_vm._v(_vm._s(an.abc))]),
                        _c("span", [_vm._v(_vm._s(an.content))]),
                        an.image
                          ? _c("div", [
                              _c("img", {
                                staticStyle: { "max-width": "40vw" },
                                attrs: { src: an.image },
                              }),
                            ])
                          : _vm._e(),
                        an.isRight
                          ? _c("div", [
                              _c("i", {
                                staticClass: "el-icon-success",
                                staticStyle: { color: "#5794F7" },
                              }),
                              _vm._v(" 答案"),
                            ])
                          : _vm._e(),
                      ]
                    )
                  }),
                  0
                ),
                item.quType === "4"
                  ? _c("el-card", { attrs: { header: "我的回答" } }, [
                      _vm._v(" " + _vm._s(item.answer) + " "),
                    ])
                  : _vm._e(),
                item.quType === "5"
                  ? _c(
                      "el-card",
                      { attrs: { header: "我的回答" } },
                      _vm._l(item.answerList, function (an) {
                        return _c("div", { key: an.id }, [
                          _vm._v(" " + _vm._s(an.answer) + " "),
                        ])
                      }),
                      0
                    )
                  : _vm._e(),
                _c("div", { staticStyle: { "padding-top": "10px" } }, [
                  item.quType !== "5"
                    ? _c("div", [
                        _vm._v(
                          "正确答案：" +
                            _vm._s(_vm.rightAnswers(item.answerList))
                        ),
                      ])
                    : _vm._e(),
                  item.quType !== "4" && item.quType !== "5"
                    ? _c("div", [
                        _vm._v(
                          "您的答案：" +
                            _vm._s(_vm.yourAnswers(item.answerList))
                        ),
                      ])
                    : _vm._e(),
                  _c("div", [
                    _vm._v("答题结果："),
                    item.isRight
                      ? _c("span", { staticStyle: { color: "#1CA035" } }, [
                          _vm._v("正确"),
                        ])
                      : _c("span", { staticStyle: { color: "#ff3403" } }, [
                          _vm._v("错误"),
                        ]),
                  ]),
                ]),
                item.analysis
                  ? _c(
                      "el-card",
                      {
                        staticStyle: { "margin-top": "10px" },
                        attrs: { header: "试题解析" },
                      },
                      [
                        _c("span", {
                          domProps: { innerHTML: _vm._s(item.analysis) },
                        }),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            )
          }),
          0
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }