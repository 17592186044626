var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "align-items": "center",
            "margin-bottom": "10px",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "time",
              staticStyle: { "flex-grow": "1", "text-align": "left" },
            },
            [_vm._v("已用时：" + _vm._s(_vm.useTime))]
          ),
          _c(
            "div",
            [
              _c(
                "el-button",
                { attrs: { type: "danger" }, on: { click: _vm.handleHand } },
                [_vm._v("提交答题")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("qu-item-exam", {
        attrs: { mark: false },
        on: { fill: _vm.handleFill },
        model: {
          value: _vm.quData,
          callback: function ($$v) {
            _vm.quData = $$v
          },
          expression: "quData",
        },
      }),
      _c(
        "div",
        { staticClass: "footer" },
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-button",
                {
                  attrs: { disabled: _vm.current === 0, icon: "el-icon-back" },
                  on: { click: _vm.handlePrev },
                },
                [_vm._v("上一题")]
              ),
            ],
            1
          ),
          _vm.current < _vm.indexList.length - 1
            ? _c(
                "el-col",
                { staticStyle: { "text-align": "right" }, attrs: { span: 12 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleNext },
                    },
                    [
                      _vm._v("下一题"),
                      _c("i", { staticClass: "el-icon-right el-icon--right" }),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }