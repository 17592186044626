import { post } from '@/utils/request'

/**
 * 获取id
 * @param id
 * @returns {Promise}
 */
export function fetchDetail(id) {
  return post('/api/activity/activity/detail', { id: id })
}

/**
 * 获取id
 * @param data
 * @returns {Promise}
 */
export function save(data) {
  return post('/api/activity/activity/save', data)
}

/**
 * 参与
 * @param data
 * @returns {Promise}
 */
export function join(data) {
  return post('/api/activity/activity/join', data)
}

/**
 * 查找我的报名信息
 * @param activityId
 * @returns {Promise}
 */
export function checkJoin(activityId) {
  return post('/api/activity/join/check-join', { id: activityId })
}

/**
 * 校验活动密码
 * @param data
 * @returns {Promise}
 */
export function checkPass(data) {
  return post('/api/activity/activity/check-pass', data)
}

/**
 * 获取活动列表
 * @param data
 * @returns {Promise}
 */
export function fetchList(data) {
  return post('/api/activity/activity/admin/paging', data)
}

