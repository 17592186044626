import { post, download } from '@/utils/request'

/**
 * 题库详情
 * @param data
 */
export function fetchDetail(id) {
  return post('/api/qu/qu/detail', { id: id })
}

/**
 * 保存题库
 * @param data
 */
export function saveData(data) {
  return post('/api/qu/qu/save', data)
}

/**
 * 导出
 * @param data
 */
export function exportExcel(data) {
  return download('/api/qu/qu/export', data, '导出的试题.xlsx')
}

/**
 * 批量加入到章节
 * @param data
 */
export function joinChapters(data) {
  return post('/api/qu/qu/join-chapters', data)
}

/**
 * 批量清理章节
 * @param data
 */
export function clearChapters(data) {
  return post('/api/qu/qu/clear-chapters', data)
}

/**
 * 批量设置难度级别
 * @param data
 */
export function batchLevel(data) {
  return post('/api/qu/qu/batch-level', data)
}

/**
 * 批量迁移题库
 * @param data
 */
export function batchRepo(data) {
  return post('/api/qu/qu/batch-repo', data)
}

/**
 * 批量迁移题库
 * @param data
 */
export function listWithChapterGroup(data) {
  return post('/api/qu/qu/list-with-chapter-group', data)
}
