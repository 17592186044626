<template>
  <div>
    <el-form ref="postForm" :model="postForm" :rules="rules" label-position="left" label-width="120px">
      <el-form-item label="问卷类型" prop="surveyType">
        <dic-list-select v-model="postForm.surveyType" dic-code="survey_type"/>
      </el-form-item>

      <el-form-item label="答卷时间" prop="startTime">
        <date-time-picker :start-time.sync="postForm.startTime" :end-time.sync="postForm.endTime" />
      </el-form-item>

      <el-form-item label="问卷名称" prop="title">
        <el-input v-model="postForm.title" size="small" />
      </el-form-item>

      <el-form-item label="问卷描述" prop="content">
        <Tinymce ref="editor" v-model="postForm.content" :height="250" />
      </el-form-item>

    </el-form>


    <div style="text-align: right; margin: 0 -30px; padding: 20px" class="el-dialog__footer">
      <el-button type="primary" @click="handleSave">保存</el-button>
    </div>

  </div>
</template>

<script>
import { detail, save } from '@/api/survey/survey'
import DicListSelect from '@/components/DicListSelect/index.vue'
import DateTimePicker from '@/components/DateTimePicker/index.vue'
export default {
  name: 'SurveyUpdateBase',
  components: {
    DateTimePicker,
    DicListSelect
  },
  props: {
    surveyId: String
  },
  data() {
    return {
      postForm: {
        startTime: null,
        endTime: null
      },
      loading: false,
      rules: {
        surveyType: [
          { required: true, message: '问卷类型不能为空！' }
        ],
        startTime: [
          { required: true, message: '答卷时间不能为空！' }
        ],
        title: [
          { required: true, message: '问卷标题不能为空！' }
        ],
        content: [
          { required: true, message: '问卷描述不能为空！' }
        ]
      }
    }
  },
  watch: {
    surveyId: {
      handler() {
        this.fetchData()
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {

    // 查找详情
    fetchData() {
      if (!this.surveyId) {
        return
      }
      detail(this.surveyId).then(res => {
        this.postForm = res.data
        if (this.$refs.editor) {
          this.$refs.editor.setContent(this.postForm.content)
        }
      })
    },

    // 保存基本数据
    handleSave() {
      this.$refs.postForm.validate((valid) => {
        if (!valid) {
          return
        }

        save(this.postForm).then(res => {
          this.$emit('success', res.data)
        })
      })
    }
  }
}
</script>
