var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            {
              staticClass: "left hidden-sm-and-down",
              attrs: { lg: 16, md: 10 },
            },
            [
              _c("img", {
                staticStyle: { height: "45vh" },
                attrs: { src: require("@/assets/web/images/login2.png") },
              }),
            ]
          ),
          _c("el-col", { staticClass: "right", attrs: { lg: 8, md: 14 } }, [
            _c(
              "div",
              { staticClass: "box" },
              [
                _c(
                  "el-tabs",
                  {
                    model: {
                      value: _vm.activeName,
                      callback: function ($$v) {
                        _vm.activeName = $$v
                      },
                      expression: "activeName",
                    },
                  },
                  [
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "重设密码", name: "account" } },
                      [
                        _c(
                          "el-form",
                          {
                            ref: "postForm",
                            attrs: { model: _vm.postForm, rules: _vm.rules },
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { prop: "mobile" } },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "手机号码",
                                    "prefix-icon": "el-icon-mobile",
                                  },
                                  model: {
                                    value: _vm.postForm.mobile,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.postForm, "mobile", $$v)
                                    },
                                    expression: "postForm.mobile",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { prop: "smsCode" } },
                              [
                                _c("sms-input", {
                                  attrs: { type: 2 },
                                  model: {
                                    value: _vm.postForm,
                                    callback: function ($$v) {
                                      _vm.postForm = $$v
                                    },
                                    expression: "postForm",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { prop: "newPassword" } },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    "show-password": "",
                                    placeholder: "新密码",
                                    type: "password",
                                    "prefix-icon": "el-icon-lock",
                                  },
                                  model: {
                                    value: _vm.postForm.newPassword,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.postForm, "newPassword", $$v)
                                    },
                                    expression: "postForm.newPassword",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      loading: _vm.loading,
                                      type: "primary",
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.handleReset($event)
                                      },
                                    },
                                  },
                                  [_vm._v("重设密码")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      "text-align": "right",
                      "line-height": "10px",
                    },
                  },
                  [
                    _c("el-link", { on: { click: _vm.toReg } }, [
                      _vm._v("立即注册"),
                    ]),
                    _c(
                      "el-link",
                      {
                        staticStyle: { "margin-left": "10px" },
                        on: { click: _vm.toLogin },
                      },
                      [_vm._v("已有账号？")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }