var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sv-page", class: _vm.theme }, [
    _c("div", { staticClass: "sv-body" }, [
      _c("div", { staticClass: "sv-title" }, [
        _vm._v(" " + _vm._s(_vm.survey.title) + " "),
      ]),
      _vm.survey.content
        ? _c(
            "div",
            {
              staticClass: "sv-content",
              domProps: {
                innerHTML: _vm._s(
                  _vm.$options.filters.formatRichText(_vm.survey.content)
                ),
              },
            },
            [_vm._v(" 问卷说明 ")]
          )
        : _vm._e(),
      _c(
        "div",
        { staticStyle: { "margin-top": "20px", "text-align": "right" } },
        [
          _c(
            "el-button",
            {
              staticStyle: { width: "30%" },
              attrs: {
                loading: _vm.loading,
                type: "danger",
                icon: "el-icon-s-promotion",
              },
              on: { click: _vm.startSurvey },
            },
            [_vm._v("开始答题")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }