<template>

  <el-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    title="新建问答"
    width="60%"
    top="5vh"
    @close="handleClose"
  >

    <div style="padding-top: 20px">
      <el-form ref="postForm" :model="postForm" :rules="rules" label-width="120px" label-position="left">

        <el-form-item label="所属课件" prop="fileId">
          <course-ref-select :course-id="courseId" v-model="postForm.fileId" style="width: 100%" />
        </el-form-item>

        <el-form-item label="提问内容" prop="content">
          <Tinymce ref="editor" v-model="postForm.content" :height="200" />
        </el-form-item>
      </el-form>
    </div>

    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="handleSave">确 定</el-button>
    </div>

  </el-dialog>

</template>

<script>
import CourseRefSelect from '@/components/CourseRefSelect/index.vue'
import { saveData } from '@/api/course/qa'

export default {
  name: 'CourseQaPublishDialog',
  components: { CourseRefSelect },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    courseId: String,
    fileId: String
  },
  data() {
    return {

      dialogVisible: false,

      postForm: {
        courseId: null,
        fileId: null
      },
      rules: {
        fileId: [{ required: true, message: '必须选择一个课件！' }],
        content: [{ required: true, message: '内容不能为空！' }]
      }
    }
  },

  watch: {

    // 检测查询变化
    visible: {
      handler() {
        this.dialogVisible = this.visible
      }
    },
    courseId: {
      handler(val) {
        this.postForm.courseId = val
      }
    },
    fileId: {
      handler(val) {
        this.postForm.fileId = val
      }
    }
  },

  created() {
    this.dialogVisible = this.visible
    this.postForm.courseId = this.courseId
    this.postForm.fileId = this.fileId
  },

  methods: {

    // 关闭回调
    handleClose() {
      this.$emit('update:visible', false)
      this.$emit('back')

      this.postForm.content = ''
      this.$refs.editor.setContent('')
    },

    // 提交问题
    handleSave() {
      this.postForm.courseId = this.courseId
      this.$refs.postForm.validate((valid) => {
        if (!valid) {
          return
        }

        saveData(this.postForm).then(() => {
          this.$notify({
            title: '成功',
            message: '问题提交成功！',
            type: 'success',
            duration: 2000
          })

          this.handleClose()
        })
      })
    }

  }
}
</script>

