var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-result",
    {
      attrs: {
        title: "问卷提交成功",
        icon: "success",
        "sub-title": "我们已经收到了您的反馈，非常感谢您的支持!",
      },
    },
    [
      _c(
        "template",
        { slot: "extra" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "medium" },
              on: { click: _vm.toHome },
            },
            [_vm._v("返回首页")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }