<template>

  <div>

    <data-table
      ref="pagingTable"
      :options="options"
      :list-query="listQuery"
      @multi-actions="handleActions"
    >
      <template #filter-content>
        <dic-list-select v-model="listQuery.params.auditState" title="选择审核状态" dic-code="exam_audit_state"/>
      </template>

      <template #data-columns>

        <el-table-column
          label="姓名"
          align="center"
          prop="applyUser_dictText"/>

        <el-table-column
          label="申请时间"
          align="center"
          prop="applyTime"/>

        <el-table-column
          show-overflow-tooltip
          label="申请理由"
          align="center"
          prop="applyMsg"/>

        <el-table-column
          label="审核状态"
          align="center"
          prop="auditState_dictText"
        />

        <el-table-column
          label="审核人"
          align="center"
          prop="auditBy_dictText"
        />

        <el-table-column
          show-overflow-tooltip
          label="审核理由"
          align="center"
          prop="auditMsg"
        />

        <el-table-column
          label="是否考试"
          align="center">
          <template v-slot="scope">
            <span v-if="scope.row.auditUsed" style="color: #ff0000">
              已考试
            </span>
            <span v-else style="color: #1aac1a">
              未考试
            </span>
          </template>

        </el-table-column>

      </template>

    </data-table>

    <el-dialog
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      title="审核申请"
      width="500px"
      @close="dialogVisible = false"
    >

      <el-form ref="postForm" :model="postForm" :rules="rules" label-position="left" label-width="100px">

        <el-form-item label="审核意见" prop="auditState">
          <el-radio-group v-model="postForm.auditState">
            <el-radio :label="1">通过</el-radio>
            <el-radio :label="2">拒绝</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="审核理由" prop="auditMsg">
          <el-input v-model="postForm.auditMsg" type="textarea"/>
        </el-form-item>

      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button :loading="loading" type="primary" @click="handAudit">确 定</el-button>
      </div>

    </el-dialog>

  </div>

</template>

<script>
import { applyAudit } from '@/api/exam/apply'

export default {
  name: 'WatchApply',
  components: { },
  props: {
    examId: String
  },
  data() {
    return {
      listQuery: {
        current: 1,
        size: 10,
        params: {
          handState: 1,
          overwrite: 0
        }
      },

      options: {

        // 可批量操作
        multi: true,
        delete: {
          enable: true,
          permission: '',
          url: '/api/exam/apply/delete'
        },
        // 列表请求URL
        listUrl: '/api/exam/apply/paging',
        // 批量操作列表
        multiActions: [
          {
            value: 'audit',
            label: '审核',
            permission: ''
          }]
      },

      dialogVisible: false,
      loading: false,
      postForm: {},
      rules: {
        auditState: [
          { required: true, message: '审核意见不能为空！' }
        ],
        auditMsg: [
          { required: true, message: '审核理由不能为空！' }
        ]
      }
    }
  },
  created() {
    this.listQuery.params.examId = this.examId
  },
  methods: {

    // 显示异常信息
    showMsg(msg) {
      this.$message.info(msg)
    },

    // 提交审核
    handAudit() {
      this.$refs.postForm.validate((valid) => {
        if (!valid) {
          return
        }

        this.loading = true
        applyAudit(this.postForm).then(() => {
          this.$message.success('操作成功！')
          this.loading = false
          this.dialogVisible = false
          this.$refs.pagingTable.refresh()
        })
      })
    },

    // 批量操作
    handleActions(event) {
      if (event.opt === 'audit') {
        this.dialogVisible = true
        this.postForm = { ids: event.ids }
      }
    }
  }
}
</script>

<style scoped>

.ex-ee{
  color: #ff0000;
}
.ex-normal{
  color: #0FDA6D;
}
</style>
