<template>
  <div>
    <el-form ref="postForm" :model="postForm" :rules="rules" label-position="left" label-width="150px">
      <el-form-item label="服务提供商" prop="provider">
        <el-radio-group v-model="postForm.provider" size="small">
          <el-radio label="qiniu">七牛云</el-radio>
        </el-radio-group>
      </el-form-item>

      <div v-if="postForm.provider==='qiniu'">

        <div style="margin-bottom: 10px; margin-top: -20px">
          <el-alert type="error" title="配置流程较多，请参考配置文档：https://docs.yfhl.net/config/qnrtc.html"/>
        </div>

        <el-form-item label="AppId" prop="data.appId">
          <el-input v-model="postForm.data.appId" size="small" placeholder="SecretId" />
        </el-form-item>

        <el-form-item label="AccessKey" prop="data.accessKey">
          <el-input v-model="postForm.data.accessKey" size="small" placeholder="SecretId" />
        </el-form-item>

        <el-form-item label="SecretKey" prop="data.secretKey">
          <el-input v-model="postForm.data.secretKey" size="small" placeholder="SecretKey" />
        </el-form-item>

        <el-form-item label="存储Bucket" prop="data.bucket">
          <el-input v-model="postForm.data.bucket" size="small" placeholder="回放存储的bucket名称，必须要求为【华东-浙江】区域的" />
        </el-form-item>

        <el-form-item label="访问域名" prop="data.url">
          <el-input v-model="postForm.data.url" size="small" placeholder="以https://或http://开头的本地接口域名或CDN加速域名，以/结尾" />
        </el-form-item>

        <el-form-item label="录制质量" prop="data.preset">
          <dic-list-select v-model="postForm.data.preset" style="width: 100%" dic-code="rtc_video_preset"/>
        </el-form-item>

        <el-form-item label="转码配置" prop="data.transcode">
          <el-radio-group v-model="postForm.data.transcode">
            <el-radio label="m3u8">不转码（默认m3u8）</el-radio>
            <el-radio label="mp4">MP4（转码成MP4）</el-radio>
          </el-radio-group>
          <el-alert v-if="postForm.data.transcode==='m3u8'" style="line-height: normal" description="默认为m3u8格式，适应于在线查看播放，无需额外费用"/>
          <el-alert v-if="postForm.data.transcode==='mp4'" type="error" style="line-height: normal" description="转换为mp4，便于下载和存档，会产生额外的费用，需要配置工作流，请参考配置文档"/>

        </el-form-item>

        <el-form-item label="备注信息">
          <el-input v-model="postForm.remark" size="small" placeholder="备注信息" />
        </el-form-item>
      </div>

    </el-form>

    <el-divider />

    <el-row style="margin-top: 20px">
      <el-button :loading="loading" icon="el-icon-folder" type="primary" size="small" @click="submitForm">保存配置</el-button>
    </el-row>
  </div>
</template>

<script>
import { fetchDetail, saveData, change } from '@/api/sys/config/prop'
import { isUrl } from '@/utils/validate'

export default {
  name: 'RtcConfig',
  components: { },
  data() {
    return {
      postForm: {
        type: 'rtc',
        provider: 'qiniu',
        data: {

        }
      },
      loading: false,
      rules: {
        // 本地存储
        'provider': [{ required: true, message: '服务提供商不能为空！' }],
        'data.appId': [{ required: true, message: 'appId不能为空' }],
        'data.accessKey': [{ required: true, message: 'AccessKey不能为空' }],
        'data.secretKey': [{ required: true, message: 'SecretKey不能为空' }],
        'data.bucket': [{ required: true, message: '回放存储空间不能为空' }],
        'data.preset': [{ required: true, message: '必须选择一个视频录制质量' }],
        'data.transcode': [{ required: true, message: '必须设置一种转码方式' }],
        'data.url': [{ validator: isUrl, trigger: 'blur' }, { required: true, message: '访问域名不能为空！' }]
      }
    }
  },
  watch: {
    'postForm.provider': {
      handler(val) {
        this.fetchData(val)
      }
    },
    'postForm.data.bucket': {
      handler() {
        this.postForm.data['init'] = false
      }
    }
  },
  created() {
    this.fetchData('')
  },
  methods: {

    fetchData(provider) {
      fetchDetail('rtc', provider).then((res) => {
        // 清空表单
        this.postForm = { type: 'rtc', provider, data: {}}

        // 赋值
        if (res.data && res.data.provider) {
          this.postForm = res.data
        }
      })
    },
    submitForm() {
      this.$refs.postForm.validate((valid) => {
        if (!valid) {
          return
        }

        this.loading = true
        saveData(this.postForm).then(() => {
          this.$notify({
            title: '成功',
            message: '监考方案配置成功！',
            type: 'success',
            duration: 2000
          })
        })
        this.loading = false
      })
    },
    changProvider() {
      if (!this.postForm.type || !this.postForm.provider) {
        this.$message.warning('服务商必须选择！')
        return
      }

      // 参数转换
      this.loading = true

      change(this.postForm).then(() => {
        this.$notify({
          title: '成功',
          message: '服务商切换成功！',
          type: 'success',
          duration: 2000
        })

        this.loading = false
      })
        .catch(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped>
::v-deep .el-alert .el-alert__description{
  margin: 0;
}
</style>
