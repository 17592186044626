<template>

  <div class="app-container">

    <el-card>

      <h3>竞赛时间：{{ paperData.createTime }}</h3>

      <section class="exam-result">

        <div v-for="item in paperData.quList" :key="item.id" class="ques">

          <div class="ques-title">
            <i class="num">{{ item.sort }}</i>
            <p>
              <span>【{{ item.quType_dictText }}】</span>
              <span v-html="$options.filters.formatRichText(item.content)" />
              <i> {{ item.score }}分</i>
            </p>
          </div>

          <div class="option">
            <label v-for="an in item.answerList" :key="an.id" :class="itemClass(an.checked, an.isRight)">
              <span>{{ an.abc }}</span>
              <span>{{ an.content }}</span>
              <div v-if="an.image">
                <img :src="an.image" style="max-width: 40vw">
              </div>
              <div v-if="an.isRight"><i class="el-icon-success" style="color: #5794F7" /> 答案</div>
            </label>
          </div>

          <el-card v-if="item.quType==='4'" header="我的回答">
            {{ item.answer }}
          </el-card>

          <el-card v-if="item.quType==='5'" header="我的回答">
            <div v-for="an in item.answerList" :key="an.id">
              {{ an.answer }}
            </div>
          </el-card>

          <div style="padding-top: 10px">
            <div v-if="item.quType!=='5'">正确答案：{{ rightAnswers(item.answerList) }}</div>
            <div v-if="item.quType!=='4' && item.quType!=='5'">您的答案：{{ yourAnswers(item.answerList) }}</div>
            <div>答题结果：<span v-if="item.isRight" style="color: #1CA035">正确</span><span v-else style="color: #ff3403">错误</span></div>
          </div>

          <el-card v-if="item.analysis" style="margin-top: 10px" header="试题解析">
            <span v-html="item.analysis" />
          </el-card>

        </div>

      </section>

    </el-card>

  </div>

</template>

<script>
import { apiPaperResult } from '@/api/battle/paper'

export default {
  components: { },
  data() {
    return {
      paperData: {}
    }
  },

  created() {
    this.paperId = this.$route.params.paperId

    apiPaperResult(this.paperId).then(res => {
      this.paperData = res.data
    })
  },

  methods: {

    rightAnswers(list) {
      const arr = []
      for (let i = 0; i < list.length; i++) {
        const a = list[i]
        if (a.isRight) {
          arr.push(a.abc)
        }
      }
      return arr.join(',')
    },

    yourAnswers(list) {
      const arr = []
      for (let i = 0; i < list.length; i++) {
        const a = list[i]
        if (a.checked) {
          arr.push(a.abc)
        }
      }
      return arr.join(',')
    },

    itemClass(checked, isRight) {
      if (checked && !isRight) {
        return 'wrong'
      }
      if (checked && isRight) {
        return 'selected'
      }
    },

    backToBattle() {
      this.$router.push({ name: 'WebBattleDetail', params: { id: this.paperData.battleId }})
    }
  }
}
</script>

<style scoped>

.ques{
  margin-bottom: 50px;
}
.ques .ques-title {
  display: flex;
  margin-bottom: 10px;
  align-items:flex-start;
}

.ques .ques-title .num {
  display: inline-block;
  background: url(~@/assets/web/images/quesbg.png) no-repeat 100% 100%;
  background-size: contain;
  height: 37px;
  width: 37px;
  line-height: 30px;
  color: #fff;
  font-size: 16px;
  text-align: center;
  margin-right: 15px
}

.ques .ques-title p {
  color: #0c073b;
  font-size: 14px;
  flex: 1;
  text-align: left;
  line-height: 28px;
  margin-block-start: 0;
  margin-block-end: 0;
  flex-grow: 1;
}

.ques .option {
  padding: 20px 0 15px 0
}

.ques .option label {
  position: relative;
  padding: 10px;
  border: 1px solid #f6f6f8;
  color: #4f4e58;
  font-size: 14px;
  margin-bottom: 10px;
  text-align: left;
  display: flex;
  align-items: center;
  line-height: 20px
}

.ques .option label.selected {
  padding: 10px;
  border: 1px solid #5794f7
}

.ques .option label.wrong {
  padding: 10px;
  border: 1px solid #ff0000;
}

.ques .option label span:first-child {
  margin: 0 10px 0 10px
}

.ques .option label span:nth-child(2), .lky-exam .ques .option label .option-img {
  flex: 1;
}

.ques .option label .ques-img {
  flex: 1;
}

.exam-result .ques .ques-title p i {
  color: #f01414;
  font-size: 14px;
  margin-left: 10px
}

.exam-result .ques .option label {
  position: relative
}

</style>

