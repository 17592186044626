var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("pdf-reader", { attrs: { src: _vm.fileUrl } }),
      _vm.file.fileId && _vm.visible
        ? _c("read-socket", {
            attrs: { "file-id": _vm.file.fileId, "course-id": _vm.course.id },
            on: {
              change: _vm.changeLearnMin,
              finish: _vm.handleFinish,
              break: _vm.checkBreak,
            },
          })
        : _vm._e(),
      _vm.file.fileId && _vm.visible && _vm.course.checkOn
        ? _c("read-check", {
            attrs: { "time-min": _vm.course.checkSec },
            on: { break: _vm.checkBreak },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }