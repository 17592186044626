<template>

  <web-table
    :options="options"
    :list-query="listQuery"
  >

    <template #filter-content />

    <template #data-columns>

      <el-table-column
        label="证书名称"
        prop="title"
        show-overflow-tooltip
      />

      <el-table-column
        label="证书编号"
        align="center"
        prop="id"
        show-overflow-tooltip
      />

      <el-table-column
        label="获得时间"
        align="center"
        prop="createTime"
        width="180px"
      />

      <el-table-column
        label="取得方式"
        align="center"
        prop="grantType_dictText"
        width="120px"
      />

      <el-table-column
        label="到期时间"
        align="center"
        prop="expireTime"
        width="180px"
      />
      <el-table-column
        label="颁发机构"
        align="center"
        prop="authority"
        show-overflow-tooltip
      />

      <el-table-column
        label="操作项"
        align="center"
        width="100px"
      >
        <template v-slot="scope">

          <div>
            <el-button
              :disabled="!scope.row.certFilePdf"
              type="primary"
              size="mini"
              icon="el-icon-download"
              @click="download(scope.row.certFilePdf)"
            >下载</el-button>
          </div>

        </template>
      </el-table-column>

    </template>

  </web-table>

</template>

<script>
import { myGrant } from '@/api/cert/grant'

export default {
  data() {
    return {
      dataList: [],

      listQuery: {
        current: 1,
        size: 10,
        params: {
        }
      },

      options: {
        // 列表请求URL
        listUrl: '/api/cert/grant/my-paging'
      }
    }
  },

  created() {
    this.fetchData()
  },
  methods: {

    fetchData() {
      myGrant().then(res => {
        this.dataList = res.data
      })
    },

    download(url) {
      window.open(url)
    }

  }
}
</script>

<style scoped>

  .cert-box{
    line-height: 28px;
  }

  .cert-box .title{

    font-size: 18px;
    font-weight: 700;
    line-height: 50px;
  }

  .cert-box .item{
    color: #555;
  }

  .cert-box .item span{
    font-weight: 700;
  }

</style>

