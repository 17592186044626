var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "card" },
          on: { "tab-click": _vm.tabClick },
          model: {
            value: _vm.activeTab,
            callback: function ($$v) {
              _vm.activeTab = $$v
            },
            expression: "activeTab",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "报名中心", name: "open" } },
            [_c("open-activity-page")],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "报名记录", name: "my" } },
            [_c("activity-join-page")],
            1
          ),
          _c(
            "el-tab-pane",
            {
              staticClass: "module-pay",
              attrs: { label: "报名订单", name: "order" },
            },
            [_c("activity-order-page")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }