<template>

  <div v-if="paperData && paperData.id" class="exam-ps">
    <el-row :gutter="20">
      <el-col :span="4">
        <el-card class="lay-box">
          <div>
            <exam-cam-normal
              v-if="paperData.examType==='1' && paperData.camOn && paperData.camInterval > 0"
              :cam-on="paperData.camOn"
              :cam-interval="paperData.camInterval"
              :paper-id="paperData.id"
              @break="handleBreak"
            />
            <exam-cam-rtc
              v-if="paperData.examType==='2'"
              :cam-on="paperData.camOn"
              :cam-interval="paperData.camInterval"
              :exam-id="paperData.examId"
              :paper-id="paperData.id"
              @break="handleBreak"
            />
            <div v-if="(paperData.examType==='1' && paperData.camOn && paperData.camInterval > 0) || paperData.examType==='2'" class="cam-box">
              <strong>监控中</strong>
              确保您的正脸在摄像头范围内！
            </div>
          </div>


          <div style="font-size: 12px; font-weight: 700; background: #efefef; padding: 10px 5px; color: #0A84FF">{{ paperData.title }}</div>

          <div v-for="type in paperData.groupList" :key="type.id">
            <div class="title">
              {{ type.title }} <span style="float: right; font-size: 12px">总{{ type.quCount }}题/共{{ type.totalScore }}分</span>
            </div>

            <div class="card-box">
              <div v-for="item in type.quList" :key="item.id" :class="{'sub-card':item.subList && item.subList.length > 0}">
                <div v-if="!item.subList || item.subList.length===0" class="item" @click="fetchQuData(item.quId)">
                  <div :class="{num:true, 'right': item.answered && currentId !== item.quId, 'current': currentId === item.quId}">{{ item.sort }}</div>
                  <div class="flag"><i v-if="item.mark" class="el-icon-s-flag" style="color: #ff0000" /></div>
                </div>
                <div v-for="sub in item.subList" v-else :key="sub.id" class="item" @click="fetchQuData(item.quId, sub.quId)">
                  <div :class="{num:true, 'right': sub.answered && currentSub !== sub.quId, 'current': currentSub === sub.quId}">{{ item.sort }}.{{ sub.sort }}</div>
                  <div class="flag"><i v-if="sub.mark" class="el-icon-s-flag" style="color: #ff0000" /></div>
                </div>
              </div>

            </div>
          </div>

        </el-card>

      </el-col>

      <el-col :span="17">
        <el-card id="exam-body" class="lay-box" @scroll="bodyScroll">
          <ExamStepMode
            v-if="paperData.answerMode===1"
            ref="examStepMode"
            :paper="paperData"
            @saved="saveBack"/>

          <ExamFullMode
            v-else
            ref="examFullMode"
            :paper="paperData"
            @focus="handleFocus"
            @saved="saveBack"/>
        </el-card>
      </el-col>

      <el-col :span="3">

        <el-card class="lay-box">

          <el-row :gutter="10" style="text-align: center; line-height: 30px">
            <el-col :span="24">
              剩余时间
            </el-col>
            <el-col :span="24">
              <exam-timer
                ref="examTimer"
                :paper-id="paperData.id"
                @timeout="handWithEx(0)"/>
            </el-col>
            <el-col :span="24">
              <el-divider />
            </el-col>
            <el-col :span="24">
              <el-button type="warning" @click="doHandler(0)">提交试卷</el-button>
            </el-col>

            <el-col v-if="paperData.actionOn && paperData.actionInterval>0" :span="24">
              <el-divider />
              <action-checker
                :action-on="paperData.actionOn"
                :action-interval="paperData.actionInterval"
                :trigger="actionTrigger"
                @break="doHandler(4)"
              />
              秒内无任何操作将自动交卷！
            </el-col>

            <el-col :span="24">
              <el-divider />
            </el-col>

            <el-col :span="24">
              <el-button :disabled="!showPrevious" icon="el-icon-back" @click="handleNext(-1)">上一题</el-button>
            </el-col>
            <el-col :span="24" style="padding-top: 20px">
              <el-button :disabled="!showNext" type="primary" @click="handleNext(1)">下一题<i class="el-icon-right el-icon--right" /></el-button>
            </el-col>
          </el-row>

        </el-card>

      </el-col>

    </el-row>

    <!-- 页面离开检测 -->
    <leave-checker
      v-if="paperData.leaveOn && paperData.leaveCount>0"
      :leave-on="paperData.leaveOn"
      :leave-check="paperData.leaveCheck"
      :leave-count="paperData.leaveCount"
      :paper-id="paperData.id"
      @back="leaveBack"
      @break="handleBreak('因切屏次数超限，系统已强制交卷！')"
    />

    <!-- 在线状态 -->
    <exam-socket :exam-id="paperData.examId" platform="pc" />

    <!-- 动作检测 -->
    <event-control v-if="!isDev" />

  </div>

</template>

<script>

import { handExam, paperDetail } from '@/api/paper/exam'
import watermark from '@/utils/watermark'
import screenfull from 'screenfull'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      // 缓存
      psKey: 'exam-process-cache',
      // 是否开发环境
      isDev: this.$isDev,
      // 上下题
      showPrevious: false,
      showNext: true,
      allQuIds: [],
      // 试卷ID
      paperId: '',
      // 试卷信息
      paperData: {},
      // 动作检测
      actionTrigger: 0,
      currentId: null,
      currentSub: null
    }
  },

  computed: {
    ...mapGetters([
      'realName',
      'name',
      'mobile'
    ])
  },

  watch: {
    currentId: {
      handler(val) {
        // 刷新下题按钮
        this.refreshNext(val)
      }
    }
  },
  created() {
    this.paperId = this.$route.params.id
    this.fetchData(this.paperId)

    // 考试缓存
    setTimeout(() => {
      localStorage.setItem(this.psKey, 'yes')
    }, 2000)
  },
  beforeDestroy() {
    // 清理页面
    this.clearPage()
    this.paperData = {}
  },
  methods: {

    // 试卷详情
    fetchData(id) {
      const params = { id: id }
      paperDetail(params).then(res => {
        // 试卷内容
        this.paperData = res.data

        // 已经考完了，不允许
        if (this.paperData.handState !== 0) {
          this.$router.push({ name: 'ShowExam', params: { id: this.paperData.id }})
          return
        }

        // 全部题目
        for (let i = 0; i < this.paperData.groupList.length; i++) {
          const quList = this.paperData.groupList[i].quList
          for (let j = 0; j < quList.length; j++) {
            this.allQuIds.push(quList[j].quId)
          }
        }

        // 是否异常
        this.emptyCheck()

        // 加载第一题
        this.$nextTick(() => {
          this.fetchQuData(this.allQuIds[0])
        })


        // 水印开关
        if (this.paperData.waterMark) {
          // 出现在题干部分
          const dom = document.querySelector('#exam-body')
          watermark.set(dom, `${this.realName} ${this.mobile || this.name}`)
        }
      })
    },

    // 左侧答题卡显示当前
    handleFocus(quId, subId) {
      this.currentId = quId
      if (subId) {
        this.currentSub = subId
      }
      // 触发变化
      this.actionTrigger = new Date().getTime()
    },

    // 填充答案回调
    saveBack(data) {
      // 触发变化
      this.actionTrigger = new Date().getTime()
      // 填充答案
      for (let i = 0; i<this.paperData.groupList.length; i++) {
        const quList = this.paperData.groupList[i].quList
        // 普通题目
        this.fillCard(quList, data)
        // 组合题目
        const subList = data.subList
        if (subList && subList.length>0) {
          for (let j = 0; j<subList.length; j++) {
            this.fillCard(quList, subList[j])
          }
        }
      }
    },

    /**
     * 填充答题卡
     * @param quList
     * @param res
     */
    fillCard(quList, res) {
      if (!quList || quList.length === 0) {
        return
      }

      for (let i = 0; i<quList.length; i++) {
        const qu = quList[i]
        if (qu.quId === res.quId) {
          qu.answered = res.fill
          qu.mark = res.mark
          return
        }

        // 递归
        const subList = qu.subList
        this.fillCard(subList, res)
      }
    },

    // 上一题下一题
    handleNext(num) {
      let index = 0
      for (let i = 0; i < this.allQuIds.length; i++) {
        if (this.allQuIds[i] === this.currentId) {
          index = i
        }
      }
      index += num
      const nextId = this.allQuIds[index]
      this.fetchQuData(nextId)
    },

    // 刷新上下题按钮
    refreshNext(val) {
      if (val === this.allQuIds[0]) {
        this.showPrevious = false
      } else {
        this.showPrevious = true
      }
      // 最后一个索引
      const last = this.allQuIds.length - 1
      if (val === this.allQuIds[last]) {
        this.showNext = false
      } else {
        this.showNext = true
      }
    },

    // 强制交卷后操作
    handleBreak(msg) {
      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        showClose: false,
        showCancelButton: false,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        type: 'error'
      }).then(() => {
        // 出现异常情况
        this.$router.push({ name: 'ShowExam', params: { id: this.paperData.id }})
      })
    },

    // 切换题目
    fetchQuData(quId, subId) {
      this.currentId = quId
      this.currentSub = subId
      // 触发变化
      this.actionTrigger = new Date().getTime()
      // 加载新的题目
      if (this.paperData.answerMode === 1 && this.$refs.examStepMode) {
        this.$refs.examStepMode.fetchQuData(quId, subId)
      }

      if (this.paperData.answerMode === 2 && this.$refs.examFullMode) {
        const focusId = subId || quId
        this.$refs.examFullMode.focus(focusId)
      }
    },

    /**
     * 统计有多少题没答的
     * @returns {number}
     */
    countNotAnswered() {
      let na = 0
      // 判断全部未答
      this.paperData.groupList.forEach(function(item) {
        item.quList.forEach(function(qu) {
          if (!qu.answered) {
            na += 1
          }
        })
      })
      return na
    },

    // 异常行为交卷
    handWithEx(exState) {
      // 打开
      this.pageLoading = true

      // 交卷
      handExam({ id: this.paperId, exState: exState }).then(() => {
        // 清理页面
        this.clearPage()

        setTimeout(() => {
          this.$router.push({ name: 'ShowExam', params: { id: this.paperId }})
        }, 500)

        this.pageLoading = false
      }).catch(() => {
        this.pageLoading = false
      })
    },

    // 交卷统一入口
    doHandler(exState) {
      console.log('异常信息...')
      if (!exState || exState === 0) {
        this.handDirect()
      } else {
        this.handWithEx(exState)
      }
    },

    // 交卷操作
    handDirect() {
      const that = this

      const na = that.countNotAnswered()
      let msg = '确认要交卷吗？'

      if (na > 0) {
        msg = '您还有' + na + '题未作答，确认要交卷吗?'
      }

      that.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        that.handWithEx(0)
      })
    },

    // 校验试卷是否异常
    emptyCheck() {
      if (this.allQuIds.length === 0) {
        this.$confirm('试卷内容出现问题，无法继续考试！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'error'
        }).then(() => {
          // 出现异常情况
          this.$router.push('/pages/exam?break')
        })
      }
    },

    clearPage() {
      // 移除水印
      watermark.remove()
      // 退出全屏
      if (screenfull.isFullscreen) {
        screenfull.toggle()
      }
    },

    // 离开页面返回后
    leaveBack() {
      // 重新倒计时
      if (this.$refs.examTimer) {
        this.$refs.examTimer.fetchLeft()
      }
    },
    bodyScroll() {
      console.log('滑动11')
    }
  }
}
</script>

