var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "data-grid",
        {
          ref: "dataGrid",
          attrs: { options: _vm.options, "list-query": _vm.listQuery },
          scopedSlots: _vm._u([
            {
              key: "data-item",
              fn: function (ref) {
                var data = ref.data
                return [
                  _c(
                    "div",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.handelView(data.id)
                        },
                      },
                    },
                    [_c("image-box", { attrs: { src: data.cover } })],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("detail-link", {
                        attrs: { id: data.id, title: data.title },
                        on: { click: _vm.handelView },
                      }),
                      data.liveCount > 0
                        ? _c(
                            "el-tag",
                            {
                              staticStyle: { "margin-left": "5px" },
                              attrs: { type: "danger", size: "mini" },
                            },
                            [_vm._v("直播")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { color: "#666", "font-size": "12px" } },
                    [_vm._v(" " + _vm._s(data.contentText) + " ")]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: { display: "flex", "align-items": "center" },
                    },
                    [
                      _c("div", { staticStyle: { "flex-grow": "1" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: { color: "#666", "font-size": "12px" },
                          },
                          [
                            _vm._v(
                              " 课程分类：" + _vm._s(data.catId_dictText) + " "
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: { color: "#666", "font-size": "12px" },
                          },
                          [
                            _vm._v(" 课程售价： "),
                            data.price > 0
                              ? _c(
                                  "el-tag",
                                  { attrs: { type: "danger", size: "mini" } },
                                  [_vm._v("￥" + _vm._s(data.price))]
                                )
                              : _c(
                                  "el-tag",
                                  { attrs: { type: "success", size: "mini" } },
                                  [_vm._v("免费")]
                                ),
                          ],
                          1
                        ),
                      ]),
                      _c("div", [
                        _c(
                          "div",
                          {
                            staticClass: "fav-btn",
                            class: { "fav-btn-off": !data.hasJoin },
                            on: {
                              click: function ($event) {
                                return _vm.handleJoin(data)
                              },
                            },
                          },
                          [_c("i", { staticClass: "el-icon-star-on" })]
                        ),
                      ]),
                    ]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "filter-content" },
            [
              _c("dic-catalog-tree", {
                staticClass: "filter-item",
                attrs: {
                  "dic-code": "course_catalog",
                  placeholder: "课程分类",
                  width: "200",
                },
                model: {
                  value: _vm.listQuery.params.catId,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery.params, "catId", $$v)
                  },
                  expression: "listQuery.params.catId",
                },
              }),
              _c("el-input", {
                staticClass: "filter-item",
                staticStyle: { width: "200px", "margin-left": "10px" },
                attrs: {
                  placeholder: "搜索课程名称",
                  "prefix-icon": "el-icon-search",
                  size: "small",
                },
                model: {
                  value: _vm.listQuery.params.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery.params, "title", $$v)
                  },
                  expression: "listQuery.params.title",
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }