<template>

  <div>

    <el-alert type="error" title="学员可绑定手机号、微信、企业微信、钉钉，管理端解绑后只能由学员端重新绑定！"/>

    <data-table
      ref="pagingTable"
      :options="options"
      :list-query="listQuery">

      <template #data-columns>
        <el-table-column
          type="selection"
          width="55"
        />

        <el-table-column
          show-overflow-tooltip
          align="center"
          label="绑定方式"
          prop="loginType_dictText"
        />

        <el-table-column
          align="center"
          label="三方信息"
          prop="openId"
        />

        <el-table-column
          show-overflow-tooltip
          align="center"
          label="绑定时间"
          prop="createTime"
        />

        <el-table-column
          v-if="checkPermission(['sys:user:update'])"
          align="center"
          label="操作项"
          prop="points"
        >

          <template v-slot="scope">
            <el-link v-permission="['sys:user:update']" type="primary" icon="el-icon-link" @click="handleUnbind(scope.row.id)">解绑</el-link>
          </template>
        </el-table-column>

      </template>
    </data-table>

  </div>

</template>

<script>
import checkPermission from '@/utils/permission'
import { unbindById } from '@/api/sys/user/bind'

export default {
  name: 'UserUpdateBind',
  props: {
    userId: String
  },

  data() {
    return {
      listQuery: {
        current: 1,
        size: 10,
        params: {
          userId: ''
        }
      },
      options: {
        // 列表请求URL
        listUrl: '/api/sys/user/bind/paging'
      }
    }
  },

  watch: {

    userId: {
      handler(val) {
        this.listQuery.params.userId = val
      }
    }
  },

  created() {
    // 初始化赋值
    this.listQuery.params.userId = this.userId

    // 刷新数据
    if (this.$refs.pagingTable) {
      this.$refs.pagingTable.getList()
    }
  },

  methods: {
    checkPermission,

    handleUnbind(id) {
      // 删除
      this.$confirm('解绑后，只能由用户重新绑定，确定要解绑吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        unbindById(id).then(() => {
          this.$message({
            type: 'success',
            message: '解绑成功!'
          })
          this.$refs.pagingTable.getList()
        })
      })
    }
  }
}
</script>
