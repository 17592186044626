var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "append-to-body": true,
        title: "设备调试",
        width: "600px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.handleClose,
      },
    },
    [
      _c("div", [
        _c(
          "div",
          [
            _c("tracking-cam", {
              attrs: { tracking: false },
              on: {
                started: function ($event) {
                  _vm.camSuccess = true
                },
                error: function ($event) {
                  _vm.camSuccess = false
                },
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "tips-lg", on: { click: _vm.handleClose } }, [
          _vm._v("能看到视频图像则表示摄像头正常，点此关闭"),
        ]),
        _c("div", { staticClass: "tips-sm" }, [
          _c("div", [
            _vm._v("1、网页必须基于https下才能调用摄像头和麦克风设备。"),
          ]),
          _c("div", [
            _vm._v("2、推荐使用Chrome谷歌浏览器、Firefox火狐浏览器。"),
          ]),
          _c("div", [
            _vm._v("3、地址栏若弹出询问是否允许摄像头/麦克风时，必须允许。"),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }