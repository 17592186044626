<template>

  <div>

    <data-table
      ref="pagingTable"
      :options="options"
      :list-query="listQuery"
    >

      <template #data-columns>

        <el-table-column
          align="center"
          label="答题时间"
          prop="createTime"
        />

        <el-table-column
          align="center"
          label="答题分数"
          prop="score"
        />

        <el-table-column
          align="center"
          label="答对题数"
          prop="trueCount"
        />

        <el-table-column
          align="center"
          label="答错题数"
          prop="falseCount"
        />

        <el-table-column
          align="center"
          label="操作"
          fixed="right"
        >

          <template v-slot="scope">
            <el-link type="primary" @click="handleDetail(scope.row.id)">答题详情</el-link>
          </template>
        </el-table-column>

      </template>
    </data-table>

  </div>

</template>

<script>

export default {
  name: 'BattlePaper',
  props: {
    battleId: String
  },
  data() {
    return {
      listQuery: {
        current: 1,
        size: 10,
        params: {
          battleId: ''
        }
      },

      options: {
        // 列表请求URL
        listUrl: '/api/battle/paper/my-paging'
      }
    }
  },

  watch: {
    battleId: {
      handler(val) {
        this.listQuery.params.battleId = val
      }
    }
  },

  created() {
    this.listQuery.params.battleId = this.battleId
  },

  methods: {
    handleDetail(id) {
      this.$router.push({ name: 'WebBattleResult', params: { paperId: id }})
    }
  }
}
</script>
