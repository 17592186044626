<template>
  <el-result title="问卷提交成功" icon="success" sub-title="我们已经收到了您的反馈，非常感谢您的支持!">
    <template slot="extra">
      <el-button type="primary" size="medium" @click="toHome">返回首页</el-button>
    </template>
  </el-result>
</template>

<script>
export default {

  methods: {
    toHome() {
      window.location.href = '/'
    }
  }
}
</script>
