var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        {
          staticClass: "filter-container",
          staticStyle: { "margin-bottom": "20px" },
        },
        [_vm._t("filter-content")],
        2
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          ref: "table",
          attrs: {
            data: _vm.dataList.records,
            "row-key": _vm.getRowKeys,
            "row-style": _vm.tableRowStyle,
            "header-cell-style": _vm.tableHeaderColor,
            fit: "",
            "highlight-current-row": "",
          },
          on: {
            "sort-change": _vm.tableSortChange,
            "selection-change": _vm.handleSelection,
          },
          scopedSlots: _vm._u([
            {
              key: "empty",
              fn: function () {
                return [
                  _c("el-empty", {
                    attrs: {
                      "image-size": 100,
                      description: _vm.options.noData,
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm.options.multi
            ? _c("el-table-column", {
                attrs: {
                  "reserve-selection": false,
                  align: "center",
                  type: "selection",
                  width: "55",
                },
              })
            : _vm._e(),
          _vm._t("data-columns"),
        ],
        2
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.dataList.total > 0,
            expression: "dataList.total>0",
          },
        ],
        attrs: {
          total: _vm.dataList.total,
          page: _vm.listQuery.current,
          limit: _vm.listQuery.size,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "current", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "size", $event)
          },
          pagination: _vm.getList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }