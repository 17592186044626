var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("data-grid", {
        ref: "dataGrid",
        attrs: { options: _vm.options, "list-query": _vm.listQuery },
        scopedSlots: _vm._u([
          {
            key: "filter-content",
            fn: function () {
              return [
                _c("dic-list-select", {
                  attrs: { "dic-code": "activity_type", title: "报名类型" },
                  model: {
                    value: _vm.listQuery.params.activityType,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery.params, "activityType", $$v)
                    },
                    expression: "listQuery.params.activityType",
                  },
                }),
                _c("dic-catalog-tree", {
                  staticClass: "filter-item",
                  attrs: { "dic-code": "activity_catalog", title: "搜索分类" },
                  model: {
                    value: _vm.listQuery.params.catId,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery.params, "catId", $$v)
                    },
                    expression: "listQuery.params.catId",
                  },
                }),
                _c("el-input", {
                  staticClass: "filter-item",
                  staticStyle: { width: "200px" },
                  attrs: { size: "small", placeholder: "搜索报名" },
                  model: {
                    value: _vm.listQuery.params.title,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery.params, "title", $$v)
                    },
                    expression: "listQuery.params.title",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "data-item",
            fn: function (ref) {
              var data = ref.data
              return [
                _c("div", { staticStyle: { display: "flex" } }, [
                  _c(
                    "div",
                    { staticStyle: { width: "200px" } },
                    [_c("image-box", { attrs: { src: data.banner } })],
                    1
                  ),
                  _c("div", { staticStyle: { "flex-grow": "1" } }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                          "padding-left": "10px",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticStyle: { "flex-grow": "1" } },
                          [
                            _c("detail-link", {
                              attrs: {
                                id: data.id,
                                title: data.title,
                                to: "WebActivityDetail",
                              },
                            }),
                            data.liveCount > 0
                              ? _c(
                                  "el-tag",
                                  {
                                    staticStyle: { "margin-left": "5px" },
                                    attrs: { type: "danger", size: "mini" },
                                  },
                                  [_vm._v("直播")]
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "font-size": "14px",
                                  color: "#333",
                                },
                              },
                              [
                                _vm._v(
                                  " 报名类型：" +
                                    _vm._s(data.activityType_dictText) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "font-size": "14px",
                                  color: "#333",
                                },
                              },
                              [
                                _vm._v(
                                  " 报名时间： " +
                                    _vm._s(data.startTime) +
                                    " ~ " +
                                    _vm._s(data.endTime) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "font-size": "14px",
                                  color: "#333",
                                },
                              },
                              [
                                _vm._v(" 报名情况： "),
                                _c("span", { staticClass: "warning" }, [
                                  _vm._v("已报名" + _vm._s(data.joinCount)),
                                ]),
                                _c("span", [_vm._v(" / ")]),
                                data.maxJoin > 0
                                  ? _c("span", { staticClass: "danger" }, [
                                      _vm._v(
                                        "限" + _vm._s(data.maxJoin) + "人"
                                      ),
                                    ])
                                  : _c("span", { staticClass: "success" }, [
                                      _vm._v("无限制"),
                                    ]),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "tag-box" },
                              [
                                data.price > 0
                                  ? _c(
                                      "el-tag",
                                      {
                                        attrs: { type: "danger", size: "mini" },
                                      },
                                      [_vm._v("￥" + _vm._s(data.price))]
                                    )
                                  : _c(
                                      "el-tag",
                                      {
                                        attrs: {
                                          type: "success",
                                          size: "mini",
                                        },
                                      },
                                      [_vm._v("免费")]
                                    ),
                                data.audit
                                  ? _c(
                                      "el-tag",
                                      {
                                        attrs: {
                                          type: "warning",
                                          size: "mini",
                                        },
                                      },
                                      [_vm._v("需审核")]
                                    )
                                  : _c(
                                      "el-tag",
                                      {
                                        attrs: {
                                          type: "success",
                                          size: "mini",
                                        },
                                      },
                                      [_vm._v("无需审核")]
                                    ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }