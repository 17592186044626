var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("web-table", {
        attrs: { options: _vm.options, "list-query": _vm.listQuery },
        scopedSlots: _vm._u([
          {
            key: "filter-content",
            fn: function () {
              return undefined
            },
            proxy: true,
          },
          {
            key: "data-columns",
            fn: function () {
              return [
                _c("el-table-column", {
                  attrs: { label: "报名名称", "show-overflow-tooltip": "" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("detail-link", {
                            attrs: {
                              params: { id: scope.row.activityId },
                              title: scope.row.activityId_dictText,
                              to: "WebActivityDetail",
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    width: "160px",
                    prop: "amount",
                    label: "价格",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "下单时间",
                    align: "center",
                    prop: "createTime",
                    width: "180px",
                  },
                }),
                _c("el-table-column", {
                  attrs: { align: "center", width: "160px", label: "订单状态" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.paid
                            ? _c("el-tag", { attrs: { type: "success" } }, [
                                _vm._v("已支付"),
                              ])
                            : _c("el-tag", { attrs: { type: "warning" } }, [
                                _vm._v("待支付"),
                              ]),
                        ]
                      },
                    },
                  ]),
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }