var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { on: { click: _vm.docClick } },
    [
      _c("watch-frame", {
        attrs: { "exam-id": _vm.examId, "line-count": 4 },
        on: { stat: _vm.syncStat, "query-change": _vm.queryChange },
        scopedSlots: _vm._u([
          {
            key: "user-item",
            fn: function (ref) {
              var data = ref.data
              return [
                _c("div", { staticClass: "watch-box-item" }, [
                  _c("div", {
                    staticClass: "main-video",
                    attrs: {
                      id: "view_" + data.userId + "_" + data.id + "_screen",
                    },
                    on: { click: _vm.videoClick },
                  }),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "space-between",
                      },
                    },
                    [
                      _c("div", {
                        staticClass: "sub-video",
                        attrs: {
                          id: "view_" + data.userId + "_" + data.id + "_camera",
                        },
                        on: { click: _vm.videoClick },
                      }),
                      _c("div", {
                        staticClass: "sub-video sub-mobile",
                        attrs: {
                          id: "view_" + data.userId + "_" + data.id + "_mobile",
                        },
                        on: { click: _vm.videoClick },
                      }),
                    ]
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }