<template>

  <div>

    <div v-if="trainData && trainData.id" style="margin-bottom: 30px; cursor: pointer" @click="conTrain(trainData.repoId, trainData.trainMode)">
      <el-alert
        :closable="false"
        :title="`您上次训练的题库为：【${trainData.title}】，进度：${trainData.answerCount}/${trainData.totalCount}，点击此处快速继续训练！`"
        type="error"
      />
    </div>


    <el-tabs v-model="activeTab" type="card">

      <el-tab-pane label="公开题库" name="open">
        <open-repo-page @join="refreshMy"/>
      </el-tab-pane>

      <el-tab-pane label="我的题库" name="my">
        <my-repo-page ref="my" />
      </el-tab-pane>

      <el-tab-pane label="训练记录" name="train">
        <train-record-page />
      </el-tab-pane>
    </el-tabs>

  </div>

</template>

<script>
import { checkProcess } from '@/api/repo/train'
import { mapGetters } from 'vuex'
import DataGrid from '@/components/DataGrid/index.vue'
import TrainRecordPage from '@/views/web/repo/components/TrainRecordPage/index.vue'
import MyRepoPage from '@/views/web/repo/components/MyRepoPage/index.vue'
import OpenRepoPage from '@/views/web/repo/components/OpenRepoPage/index.vue'

export default {
  components: { OpenRepoPage, MyRepoPage, TrainRecordPage, DataGrid },
  data() {
    return {
      activeTab: 'open',
      cacheKey: 'repo-index',
      trainData: {}
    }
  },
  computed: {
    ...mapGetters([
      'siteData'
    ])
  },
  watch: {
    activeTab: {
      handler(val) {
        localStorage.setItem(this.cacheKey, val)
      }
    }
  },
  mounted() {
    // 获取缓存
    const cache = localStorage.getItem(this.cacheKey)
    if (cache) {
      this.activeTab = cache
    }


    checkProcess().then(res => {
      this.trainData = res.data
    })
  },
  methods: {

    /**
     * 继续训练
     * @param repoId
     * @param mode
     */
    conTrain(repoId, mode) {
      this.$router.push({ name: 'TrainStart', params: { repoId: repoId, mode: mode }})
    },

    // 刷新我的
    refreshMy() {
      this.$refs.my.refresh()
    }
  }
}
</script>
