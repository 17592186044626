<template>

  <div>

    <Tinymce ref="editor" v-model="postForm.content" :height="150" />


    <div :id="randId" class="answer-list" @mouseover="showActions" @mouseleave="hideActions">
      <div v-for="(item,index) in postForm.answerList" :key="index" class="answer-item" @mouseover="hoverIndex = index" @mouseleave="hoverIndex = '-1'">
        <div class="sort-icon">
          <i v-show="hoverIndex === index" class="el-icon-menu"/>
        </div>
        <div>
          <el-tooltip class="item" effect="dark" content="设置为默认选项" placement="top-start">
            <el-radio v-if="postForm.quType==='1' || postForm.quType==='27'" :value="item.defaultSelected?index: ''" :label="index" @click.native="setDefault(index, true)">
              <span/>
            </el-radio>

            <el-checkbox v-if="postForm.quType==='2' || postForm.quType==='28'" :value="item.defaultSelected" @change="setDefault(index, $event)">
              <span/>
            </el-checkbox>

          </el-tooltip>


        </div>
        <div style="flex-grow: 1;">
          <div v-if="hoverIndex===index" style="display: flex; align-items: center">
            <el-input v-model="item.content" size="mini" />
            <el-input-number v-if="postForm.quType ==='27' || postForm.quType ==='28' " v-model="item.score" size="mini" style="margin-left: 10px" />
          </div>
          <div v-else style="display: flex; align-items: center">
            <div style="font-size: 14px; color: #555" v-html="item.content"/>
            <div v-if="postForm.quType ==='27' || postForm.quType ==='28' " style="color: #F58711; font-weight: 500">（{{ item.score || 0 }}分）</div>
          </div>

          <div v-if="item.remark" style="font-size: 12px; color: #666; padding-top: 5px">{{ item.remark }}</div>
        </div>
        <div class="answer-actions">
          <el-tooltip v-if="actions && hoverIndex===index" class="item" effect="dark" content="在下方插入一个选项" placement="top-start">
            <el-button size="mini" icon="el-icon-plus" circle @click="insertAnswer(index)"/>
          </el-tooltip>
          <el-tooltip v-if="actions && hoverIndex===index" class="item" effect="dark" content="删除当前选项" placement="top-start">
            <el-button size="mini" icon="el-icon-minus" circle @click="removeAnswer(index)"/>
          </el-tooltip>
          <!--          <el-tooltip v-if="actions && hoverIndex===index" class="item" effect="dark" content="添加图片" placement="top-start">-->
          <!--            <el-button size="mini" icon="el-icon-picture-outline" circle />-->
          <!--          </el-tooltip>-->
          <el-tooltip v-if="actions && hoverIndex===index" class="item" effect="dark" content="添加说明" placement="top-start">
            <el-button size="mini" icon="el-icon-document" circle @click="addRemark(index)" />
          </el-tooltip>
          <el-tooltip v-if="actions && hoverIndex===index" class="item" effect="dark" content="选中设为默认" placement="top-start">
            <el-checkbox :value="item.defaultSelected" style="margin-left: 10px; line-height: 20px" size="mini" border @change="setDefault(index, $event)">默认</el-checkbox>
          </el-tooltip>
        </div>
      </div>
      <div style="display: flex; align-items: center; justify-content: space-between">
        <div>
          <el-button class="opt-item" size="mini" type="primary" plain icon="el-icon-plus" @click="insertAnswer(-1)">添加选项</el-button>
          <el-button class="opt-item" size="mini" @click="showBatch">批量编辑</el-button>
          <el-button v-if="postForm.quType==='1'" class="opt-item" size="mini" @click="toMulti">转多选</el-button>
          <el-button v-if="postForm.quType==='2'" class="opt-item" size="mini" @click="toRadio">转单选</el-button>
        </div>

        <div style="display: flex; align-items: center">


          <el-select v-if="postForm.quType==='2' || postForm.quType==='28'" v-model="postForm.selectMin" class="opt-item" size="mini" placeholder="至少选" clearable style="width: 100px">
            <el-option
              v-for="count in postForm.answerList.length"
              :key="count"
              :label="`${count}项`"
              :value="count"/>
          </el-select>

          <el-select v-if="postForm.quType==='2' || postForm.quType==='28'" v-model="postForm.selectMax" class="opt-item" size="mini" placeholder="最多选" clearable style="width: 100px">
            <el-option
              v-for="count in postForm.answerList.length"
              v-if="count >= postForm.selectMin"
              :key="count"
              :label="`${count}项`"
              :value="count"/>
          </el-select>

          <el-select v-model="postForm.itemRand" class="opt-item" size="mini" placeholder="选项显示方式" clearable style="width: 120px">
            <el-option
              :key="false"
              :value="false"
              label="选项按序显示"/>

            <el-option
              :key="true"
              :value="true"
              label="选项随机显示"/>

          </el-select>

          <el-checkbox v-model="postForm.required" class="opt-item">必答</el-checkbox>

        </div>

      </div>
    </div>

    <select-quick-fill-dialog :visible.sync="batchVisible" v-model="batchTemplate" @confirm="saveBatch" />

  </div>

</template>

<script>

import Sortable from 'sortablejs'
import { uuid } from 'vue-uuid'
import DicListSelect from '@/components/DicListSelect/index.vue'
import SelectQuickFillDialog from '@/views/admin/survey/components/SelectQuickFillDialog/index.vue'

export default {
  name: 'SurveyQuTypeRadio',
  components: { SelectQuickFillDialog, DicListSelect },
  props: {
    surveyId: String,
    value: Object
  },
  data() {
    return {
      sortTable: null,
      actions: false,
      hoverIndex: '',
      postForm: {
        answerList: []
      },
      batchVisible: false,
      batchTemplate: ''
    }
  },
  computed: {
    randId() {
      return 'qu-' + uuid.v4()
    }
  },

  watch: {
    value: {
      handler() {
        this.fillValue()
      }
    },
    'postForm.required': {
      handler(val) {
        if (val && !this.postForm.selectMin) {
          this.postForm.selectMin = 1

          if (this.postForm.selectMax < 1) {
            this.postForm.selectMax = 1
          }
        }
      }
    }
  },

  created() {
    // 查找详情
    this.fillValue()
  },

  methods: {
    uuid() {
      return uuid
    },


    // 填充数据
    fillValue() {
      if (this.value) {
        this.postForm = this.value
      }

      this.$nextTick(() => {
        this.initSort()
      })
    },

    // 初始化表格
    initSort() {
      // 存在即销毁
      if (this.sortTable) {
        this.sortTable.destroy()
      }

      console.log('初始化拖动....')

      // 拖动表格
      const tbody = document.querySelector('#' + this.randId)
      const that = this
      this.sortTable = Sortable.create(tbody, {
        handle: '.sort-icon',
        onEnd({ newIndex, oldIndex }) {
          if (newIndex === oldIndex) {
            return
          }
          that.postForm.answerList.splice(
            newIndex,
            0,
            that.postForm.answerList.splice(oldIndex, 1)[0]
          )
          const newArray = that.postForm.answerList.slice(0)
          that.postForm.answerList = []
          that.$nextTick(function() {
            that.postForm.answerList = newArray
          })
        }
      })
    },

    insertAnswer(index) {
      const len = this.postForm.answerList.length + 1
      const data = { content: '选项' + len, defaultSelected: false }
      // 追加
      if (index === -1) {
        this.postForm.answerList.push(data)
      } else {
        // 插入
        this.postForm.answerList.splice(index + 1, 0, data)
      }

      this.initSort()
    },

    // 移除答案
    removeAnswer(i) {
      this.postForm.answerList.splice(i, 1)
    },

    // 设置为默认
    setDefault(index, e) {
      if (this.postForm.quType === '1' || this.postForm.quType === '27') {
        this.postForm.answerList.forEach((v, i) => {
          v.defaultSelected = i === index && e
        })
      }

      // 多选
      if (this.postForm.quType === '2' || this.postForm.quType === '28') {
        const item = this.postForm.answerList[index]
        item.defaultSelected = !item.defaultSelected
      }

      this.$forceUpdate()
    },

    // 转为单选
    toRadio() {
      this.postForm.quType = '1'
      // 保留一个选定
      let keep = false
      // 取消选定
      this.postForm.answerList.forEach((v) => {
        if (v.defaultSelected && !keep) {
          keep = true
          v.defaultSelected = true
        } else {
          v.defaultSelected = false
        }
      })
    },

    // 转为多选
    toMulti() {
      this.postForm.quType = '2'
    },

    // 添加说明
    addRemark(index) {
      const item = this.postForm.answerList[index]
      const remark = item.remark || ''
      this.$prompt('输入选项说明', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: remark
      }).then(({ value }) => {
        item.remark = value
        this.$forceUpdate()
      })
    },

    // 打开模板
    showBatch() {
      this.batchVisible = true
      let tmpl = ''
      this.postForm.answerList.forEach((v) => {
        tmpl += v.content + '\n'
      })
      this.batchTemplate = tmpl
    },

    // 模板回填
    saveBatch(data) {
      const arr = data.split('\n')
      const len = this.postForm.answerList.length
      arr.forEach((v, i) => {
        if (v) {
          // 覆盖的
          if (len > i) {
            this.postForm.answerList[i].content = v
          } else {
            // 追加的
            this.postForm.answerList.push({ content: v })
          }
        }
      })
      if (arr.length < len) {
        this.postForm.answerList.splice(arr.length)
      }
    },

    showActions() {
      this.actions = true
    },
    hideActions() {
      this.actions = false
    }
  }
}
</script>

<style scoped>

.answer-list{
  margin-top: 10px;
}

.answer-item{
  display: flex;
  border: #d3dce6 1px solid;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  align-items: center;
  height: 50px;
}

.answer-item > div{
  padding-right: 10px;
}


.sort-icon{
  display: flex;
  align-items: center;
  color: #606266;
  cursor: move;
  width: 26px;
}
.answer-actions{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 180px;
}

.opt-item + .opt-item {
  margin-left: 10px;
}


</style>
