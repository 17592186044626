var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.noDevice
        ? _c("div", { staticClass: "cam-box" }, [
            _c(
              "div",
              { staticClass: "cam-3x2" },
              [
                _c("video", {
                  ref: "video",
                  staticStyle: {
                    background: "#262626",
                    "max-width": "100%",
                    height: "auto",
                  },
                  attrs: { id: "video", playsinline: true, autoplay: true },
                }),
                _vm.tracking && _vm.videoLoad
                  ? _c("face-tracking", {
                      ref: "tracking",
                      attrs: { capture: _vm.trackCapture, "video-id": "video" },
                      on: {
                        "face-out": _vm.handleFaceOut,
                        "face-in": _vm.handleFaceIn,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { "padding-top": "10px" } },
              [
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { size: "mini" },
                    model: {
                      value: _vm.deviceId,
                      callback: function ($$v) {
                        _vm.deviceId = $$v
                      },
                      expression: "deviceId",
                    },
                  },
                  _vm._l(_vm.cameras, function (item) {
                    return _c("el-option", {
                      key: item.deviceId,
                      attrs: { value: item.deviceId, label: item.label },
                    })
                  }),
                  1
                ),
                _vm.tracking && _vm.showTrackTips && _vm.trackingMsg
                  ? _c("div", { staticClass: "tips" }, [
                      _vm._v(" " + _vm._s(_vm.trackingMsg) + " "),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _c("el-empty", {
            attrs: {
              description:
                "摄像头载入失败，请确认电脑上有摄像头且已允许网页访问！",
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }