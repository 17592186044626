<template>


  <data-grid
    ref="dataGrid"
    :options="options"
    :list-query="listQuery"
  >

    <template #filter-content>
      <dic-catalog-tree v-model="listQuery.params.catId" dic-code="mall_goods_catalog" title="搜索分类" class="filter-item" width="200"/>
      <el-input v-model="listQuery.params.q" size="small" class="filter-item" style="width: 200px; margin-left: 10px" placeholder="搜索商品"/>
    </template>

    <template v-slot:data-item="{ data }">

      <div>
        <router-link :to="{name: 'GoodsDetail',params:{id: data.id} }">

          <div class="img-box">
            <img :src="data.image" class="img-img" alt="">
          </div>

          <div class="title">
            {{ data.title }}
          </div>

          <div class="summary">
            {{ data.summary }}
          </div>

          <div class="price">
            <price-show :sale-price="data.salePrice" :cost-points="data.costPoints"/>
            <div style="font-size: 12px; color: #666; font-style: italic; font-weight: 500">已售：{{ data.sales }}</div>
          </div>

        </router-link>
      </div>

    </template>

  </data-grid>

</template>

<script>


export default {
  name: 'MallGoodsPage',
  data() {
    return {

      listQuery: {
        current: 1,
        size: 10,
        params: {

        }
      },

      options: {
        lineCount: 5,
        // 列表请求URL
        listUrl: '/api/mall/goods/paging'
      }
    }
  }
}
</script>

<style scoped>

.title{
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  overflow:hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  height: 50px;
  margin: 10px 0;
}

.summary {
  color: #999;
  font-size: 12px;
  overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow:ellipsis;
  margin: 10px 0;
}

.price{
  margin-top: 10px;
  color: #F86A00;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.img-box{
   width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;
}

.img-img{
  width: 100%;
  height: 100%;
  position: absolute;
}
</style>
