import { post, download } from '@/utils/request'


/**
 * 创建问卷
 * @param id
 * @param uuid
 * @returns {Promise}
 */
export function create(id, uuid) {
  return post('/api/survey/paper/create', { id: id, uuid: uuid })
}


/**
 * 问卷详情用于答卷
 * @param id
 * @returns {Promise}
 */
export function detailForAnswer(id) {
  return post('/api/survey/paper/detail-for-answer', { id: id })
}


/**
 * 填充答案
 * @param data
 * @returns {Promise}
 */
export function fillAnswer(data) {
  return post('/api/survey/paper/qu/fill-answer', data)
}

/**
 * 提交问卷
 * @param data
 * @returns {Promise}
 */
export function handPaper(data) {
  return post('/api/survey/paper/hand-paper', data)
}


/**
 * 答卷列表-导出
 * @param data
 * @returns {Promise}
 */
export function exportPaper(data) {
  return download('/api/survey/paper/paging-export', data, '答卷列表-导出.xlsx')
}


/**
 * 查找答卷的全部省份
 * @param id
 * @returns {Promise}
 */
export function listProvince(id) {
  return post('/api/survey/paper/list-province', { id: id })
}

/**
 * 查找答卷的全部城市
 * @param id
 * @returns {Promise}
 */
export function listCity(id) {
  return post('/api/survey/paper/list-city', { id: id })
}
