<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="dialogVisible"
      title="菜单管理"
      width="50%"
      @close="handleClose"
    >
      <el-form ref="postForm" :model="postForm" :rules="rules" label-width="120px" label-position="left">

        <el-form-item label="菜单类型" prop="menuType">
          <el-radio-group v-model="postForm.menuType">
            <el-radio :label="1">菜单</el-radio>
            <el-radio :label="2">功能</el-radio>
          </el-radio-group>

          <div style="font-size: 12px; color: #ff4949">顶级菜单、二级菜单等要在后台左侧展示的，一般选择菜单；按钮、不在左侧菜单展示、增删查改操作一般选择功能</div>
        </el-form-item>

        <el-form-item label="上级菜单" prop="parentId">
          <menu-select-tree ref="tree" v-model="postForm.parentId" />
        </el-form-item>

        <el-form-item label="菜单标题" prop="metaTitle">
          <el-input v-model="postForm.metaTitle" size="small" />
        </el-form-item>

        <el-form-item label="菜单名称" prop="name">
          <el-input v-model="postForm.name" size="small" placeholder="应为英文，用于前端程序编码" @keyup.native="postForm.name=postForm.name.replace(/[^a-zA-Z]/g,'')" />
        </el-form-item>

        <el-form-item label="访问路径" prop="path">
          <el-input v-model="postForm.path" size="small" placeholder="页面菜单路径，如：/admin/user" />
        </el-form-item>

        <el-form-item label="视图组件" prop="component">
          <el-input v-model="postForm.component" :disabled="postForm.parentId==='0'" size="small" placeholder="位于前端view/目录下的相对文件路径" />
        </el-form-item>

        <el-form-item label="权限标识">
          <el-input v-model="postForm.permissionTag" size="small" placeholder="用于后端权限控制，如：sys:user:add" />
        </el-form-item>

        <el-form-item label="菜单图标">
          <div style="display: flex; align-items: center;">
            <svg-icon v-if="postForm.metaIcon" :icon-class="postForm.metaIcon" style="width: 20px; height: 20px; margin-right: 10px" />
            <el-button size="mini" @click="iconVisible = true">选择图标</el-button>
            <el-button type="danger" size="mini" @click="clearIcon">清除</el-button>
          </div>

        </el-form-item>

        <el-form-item label="可视状态">
          <el-switch
            v-model="postForm.hidden"
            active-text="隐藏"
            inactive-text="显示"
          />
        </el-form-item>

      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleSave">确 定</el-button>
      </div>
    </el-dialog>


    <icon-dialog :visible.sync="iconVisible" @select="iconSelect"/>

  </div>

</template>

<script>

import UserUpdateBase from '@/views/admin/sys/user/components/UserUpdateDialog/base.vue'
import UserUpdateBind from '@/views/admin/sys/user/components/UserUpdateDialog/bind.vue'
import { saveData, fetchDetail } from '@/api/sys/menu/menu'

export default {
  name: 'MenuUpdateDialog',
  components: { UserUpdateBind, UserUpdateBase },
  props: {
    parentId: String,
    currentId: String,
    visible: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      dialogVisible: false,
      loading: false,
      postForm: {
        metaTitle: '',
        name: '',
        path: '',
        component: '',
        permissionTag: '',
        metaIcon: '',
        hidden: false,
        parentId: '',
        menuType: 1
      },
      rules: {
        parentId: [
          { required: true, message: '上级菜单不能为空！' }
        ],
        menuType: [
          { required: true, message: '菜单类型不能为空！' }
        ],
        metaTitle: [
          { required: true, message: '菜单标题不能为空！' }
        ],
        name: [
          { required: true, message: '菜单名称不能为空！' }
        ],
        path: [
          { required: true, message: '访问路径不能为空！' }
        ],
        component: [
          { required: true, message: '视图组件不能为空！' }
        ]
      },
      iconVisible: false
    }
  },

  watch: {
    // 检测查询变化
    parentId: {
      handler(val) {
        if (val === '0') {
          this.postForm.component = 'Layout'
        }
        this.clearForm()
        this.postForm.parentId = val
      }
    },

    // 检测查询变化
    currentId: {
      handler() {
        this.fetchData()
      }
    },

    // 检测查询变化
    visible: {
      handler(val) {
        this.dialogVisible = val
        if (this.$refs.tree) {
          this.$refs.tree.refresh()
        }

        if (val) {
          this.fetchData()
        }
      }
    },
    'postForm.menuType': {
      handler(val) {
        if (val === 1) {
          this.rules.path = [{ required: true, message: '访问路径不能为空！' }]
          this.rules.component = [{ required: true, message: '视图组件不能为空！' }]
        } else {
          this.rules.path = []
          this.rules.component = []
        }

        // 重置校验
        if (this.$refs.postForm) {
          this.$refs.postForm.clearValidate()
        }
      }
    },
    'postForm.parentId': {
      handler(val) {
        if (val === '0') {
          this.postForm.component = 'Layout'
        }
      }
    }
  },

  methods: {

    // 获取详情
    fetchData() {
      if (this.currentId) {
        fetchDetail(this.currentId).then(res => {
          this.postForm = res.data
        })
      } else {
        this.clearForm()
      }
    },

    // 关闭选择框
    handleClose() {
      this.$emit('update:visible', false)
    },
    // 图标选择
    iconSelect(icon) {
      this.postForm.metaIcon = icon
    },

    clearIcon() {
      this.postForm.metaIcon = ''
    },

    handleSave() {
      this.$refs.postForm.validate((valid) => {
        if (!valid) {
          return
        }
        saveData(this.postForm).then(() => {
          this.$notify({
            title: '成功',
            message: '菜单保存成功！',
            type: 'success',
            duration: 2000
          })

          this.clearForm()
          this.handleClose()
          this.$emit('success')
        })
      })
    },

    // 清理表单
    clearForm() {
      this.postForm.id = ''
      this.postForm.metaTitle = ''
      this.postForm.name = ''
      this.postForm.path = ''
      this.postForm.component = ''
      this.postForm.permissionTag = ''
      this.postForm.metaIcon = ''
      this.postForm.hidden = false

      this.$nextTick(() => {
        if (this.$refs.postForm) {
          this.$refs.postForm.clearValidate()
        }
      })
    }

  }
}
</script>
