<template>

  <el-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    title="选择考试"
    width="60%"
    top="5vh"
    @close="handleClose"
  >

    <div>
      <data-table
        ref="pagingTable"
        :options="options"
        :list-query="listQuery"
        @select-changed="handleSelected"
      >
        <template #filter-content>
          <el-input v-model="listQuery.params.title" size="small" style="width: 200px" placeholder="搜索考试名称" class="filter-item" />
          <el-button type="primary" class="filter-item" size="small" @click="handleConfirm">{{ selectedLabel }}</el-button>
        </template>

        <template #data-columns>

          <el-table-column
            label="考试名称"
            align="center"
            prop="title"
          />

          <el-table-column
            label="考试类型"
            align="center"
            prop="examType_dictText">

            <template v-slot="scope">
              <span v-if="scope.row.offline" style="color: #ff0000">线下考试</span>
              <span v-else>{{ scope.row.examType_dictText }}</span>
            </template>

          </el-table-column>

          <el-table-column
            label="考试时间"
            align="center"
            prop="startTime"/>

          <el-table-column
            label="总分"
            align="center"
            prop="totalScore"/>

        </template>
      </data-table>
    </div>

  </el-dialog>

</template>

<script>

export default {
  name: 'ExamDialog',
  components: { },
  props: {
    excludes: Array,
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {

      dialogVisible: false,
      selectedLabel: '请勾选考试',

      listQuery: {
        current: 1,
        size: 10,
        params: {
          excludes: []
        }
      },

      // 选定的数据列表
      selectedList: [],

      options: {
        // 可批量操作
        multi: true,
        multiShow: false,
        // 列表请求URL
        listUrl: '/api/exam/exam/paging'
      }
    }
  },

  watch: {

    // 检测查询变化
    visible: {
      handler() {
        this.dialogVisible = this.visible
      },
      deep: true
    },

    excludes: {
      handler() {
        this.listQuery.params.excludes = this.excludes
      },
      deep: true
    }
  },

  created() {
    this.dialogVisible = this.visible
  },

  methods: {

    handleSelected(obj) {
      this.selectedList = obj.objs
      this.selectedLabel = '确认' + this.selectedList.length + '项'
    },

    handleClose() {
      this.$emit('update:visible', false)
      this.$emit('close', this.selectedList)
    },

    handleConfirm() {
      this.$emit('update:visible', false)
      this.$emit('select', this.selectedList)
    }

  }
}
</script>
