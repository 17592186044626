<template>

  <div>

    <web-table
      :options="options"
      :list-query="listQuery"
    >

      <template #filter-content/>

      <template #data-columns>

        <el-table-column
          label="报名名称"
          show-overflow-tooltip
        >
          <template v-slot="scope">
            <detail-link :params="{ id: scope.row.activityId }" :title="scope.row.activityId_dictText" to="WebActivityDetail" />
          </template>
        </el-table-column>

        <el-table-column
          align="center"
          width="160px"
          prop="amount"
          label="价格" />

        <el-table-column
          label="下单时间"
          align="center"
          prop="createTime"
          width="180px"
        />

        <el-table-column
          align="center"
          width="160px"
          label="订单状态">
          <template v-slot="scope">
            <el-tag v-if="scope.row.paid" type="success">已支付</el-tag>
            <el-tag v-else type="warning">待支付</el-tag>
          </template>
        </el-table-column>

      </template>
    </web-table>

  </div>
</template>

<script>

export default {
  name: 'ActivityOrderPage',
  components: { },
  data() {
    return {
      listQuery: {
        current: 1,
        size: 10,
        params: {
        }
      },
      options: {
        // 列表请求URL
        listUrl: '/api/activity/buy/paging'
      }
    }
  },
  methods: {

  }
}
</script>
