var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "margin-top": "10px" } },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: {
            data: _vm.dataList,
            "row-key": "id",
            border: "",
            stripe: "",
            "default-expand-all": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "排名" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(" " + _vm._s(scope.$index + 1) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "姓名", prop: "realName" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "总得分", prop: "totalScore" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "平均用时", prop: "avgTimeStr" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }