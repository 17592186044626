// 课程模块
import WebLayout from '@/layout/WebLayout'

const courseRouter = {
  path: '/course',
  component: WebLayout,
  redirect: '/pages/course',
  hidden: true,
  children: [

    {
      path: '/pages/course',
      component: () => import('@/views/web/course/index'),
      name: 'ClientCourse',
      meta: { title: '课程学习', icon: 'user', noCache: true }
    },
    {
      path: '/pages/course/view/:courseId',
      component: () => import('@/views/web/course/view'),
      name: 'CourseView',
      meta: { title: '课程详情', noCache: true, activeMenu: '/pages/course' }
    },

    {
      path: '/pages/course/view/:courseId/:fileId',
      component: () => import('@/views/web/course/view'),
      name: 'CourseViewWithFile',
      meta: { title: '课程详情', noCache: true, activeMenu: '/pages/course' }
    },

    {
      path: '/pages/course/qa',
      component: () => import('@/views/web/course/qa/index.vue'),
      name: 'CourseQa',
      meta: { title: '课程问答', noCache: true, activeMenu: '/pages/course' }
    }
  ]
}
export default courseRouter
