var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "state-bar" },
        _vm._l(_vm.stateList, function (item) {
          return _c(
            "el-tag",
            {
              key: item.value,
              attrs: {
                type:
                  _vm.listQuery.params.state === item.value
                    ? "danger"
                    : "primary",
                effect: "plain",
              },
              on: {
                click: function ($event) {
                  return _vm.tabClick(item.value)
                },
              },
            },
            [_vm._v(_vm._s(item.title))]
          )
        }),
        1
      ),
      _c(
        "data-grid",
        {
          ref: "dataGrid",
          attrs: { options: _vm.options, "list-query": _vm.listQuery },
          scopedSlots: _vm._u([
            {
              key: "data-item",
              fn: function (ref) {
                var data = ref.data
                return [
                  _c("div", { staticStyle: { display: "flex" } }, [
                    _c("div", { staticStyle: { width: "200px" } }, [
                      _c(
                        "div",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.handelView(data.id)
                            },
                          },
                        },
                        [_c("image-box", { attrs: { src: data.cover } })],
                        1
                      ),
                    ]),
                    _c("div", { staticStyle: { "flex-grow": "1" } }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                            "padding-left": "10px",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { "flex-grow": "1" } },
                            [
                              _c("detail-link", {
                                attrs: { id: data.id, title: data.title },
                                on: { click: _vm.handelView },
                              }),
                              data.liveCount > 0
                                ? _c(
                                    "el-tag",
                                    {
                                      staticStyle: { "margin-left": "5px" },
                                      attrs: { type: "danger", size: "mini" },
                                    },
                                    [_vm._v("直播")]
                                  )
                                : _vm._e(),
                              _c("div", { staticClass: "info-item" }, [
                                _c("span", { staticClass: "title" }, [
                                  _vm._v("学习时间："),
                                ]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(data.startTime) +
                                      " ~ " +
                                      _vm._s(data.endTime)
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "info-item" }, [
                                _c("span", { staticClass: "title" }, [
                                  _vm._v("课程来源："),
                                ]),
                                data.openType === 3
                                  ? _c("span", [_vm._v("指定人员")])
                                  : _c("span", [_vm._v("课程中心")]),
                              ]),
                              _c("div", { staticClass: "info-item" }, [
                                _c("span", { staticClass: "title" }, [
                                  _vm._v("总体进度："),
                                ]),
                                _c("span", [
                                  _vm._v(
                                    "已学" +
                                      _vm._s(data.learnFile) +
                                      "/总" +
                                      _vm._s(data.totalFile) +
                                      "课件"
                                  ),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                                "flex-direction": "column",
                              },
                            },
                            [
                              _c(
                                "el-link",
                                {
                                  attrs: {
                                    type: "primary",
                                    icon: "el-icon-chat-dot-round",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.toQaList(data.id)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "课程问答(" + _vm._s(data.qaCount) + ")"
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  data.latestFile
                    ? _c(
                        "div",
                        {
                          staticClass: "record-box",
                          staticStyle: { "margin-top": "10px" },
                        },
                        [
                          _c("div", [
                            _c("span", { staticClass: "title" }, [
                              _vm._v("最近学习："),
                            ]),
                            _c("span", { staticClass: "info" }, [
                              _vm._v(" " + _vm._s(data.latestFile.title) + " "),
                            ]),
                          ]),
                          _c("div", [
                            _c("span", { staticClass: "title" }, [
                              _vm._v("学习时间："),
                            ]),
                            _c("span", { staticClass: "info" }, [
                              _vm._v(
                                " " + _vm._s(data.latestFile.learnTime) + " "
                              ),
                            ]),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "filter-content" },
            [
              _c("dic-catalog-tree", {
                staticClass: "filter-item",
                attrs: {
                  "dic-code": "course_catalog",
                  placeholder: "课程分类",
                  width: "200",
                },
                model: {
                  value: _vm.listQuery.params.catId,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery.params, "catId", $$v)
                  },
                  expression: "listQuery.params.catId",
                },
              }),
              _c("el-input", {
                staticClass: "filter-item",
                staticStyle: { width: "200px", "margin-left": "10px" },
                attrs: {
                  placeholder: "搜索课程名称",
                  "prefix-icon": "el-icon-search",
                  size: "small",
                },
                model: {
                  value: _vm.listQuery.params.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery.params, "title", $$v)
                  },
                  expression: "listQuery.params.title",
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }