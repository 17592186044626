var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "destroy-on-close": true,
        "show-close": false,
        title: "快速填充",
        width: "50%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticStyle: { display: "flex" } }, [
        _c(
          "div",
          { staticStyle: { "flex-grow": "1" } },
          [
            _c(
              "el-input",
              {
                attrs: { rows: 15, type: "textarea" },
                model: {
                  value: _vm.textData,
                  callback: function ($$v) {
                    _vm.textData = $$v
                  },
                  expression: "textData",
                },
              },
              [_vm._v(_vm._s(_vm.textData))]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "tmpl-box" },
          [
            _c(
              "el-button",
              {
                staticStyle: { "margin-left": "10px" },
                attrs: { type: "primary", round: "", plain: "", size: "mini" },
                on: {
                  click: function ($event) {
                    return _vm.fillData("sex")
                  },
                },
              },
              [_vm._v("性别")]
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary", round: "", plain: "", size: "mini" },
                on: {
                  click: function ($event) {
                    return _vm.fillData("age")
                  },
                },
              },
              [_vm._v("年龄")]
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary", round: "", plain: "", size: "mini" },
                on: {
                  click: function ($event) {
                    return _vm.fillData("education")
                  },
                },
              },
              [_vm._v("学历")]
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary", round: "", plain: "", size: "mini" },
                on: {
                  click: function ($event) {
                    return _vm.fillData("nation")
                  },
                },
              },
              [_vm._v("民族")]
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary", round: "", plain: "", size: "mini" },
                on: {
                  click: function ($event) {
                    return _vm.fillData("province")
                  },
                },
              },
              [_vm._v("省份")]
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary", round: "", plain: "", size: "mini" },
                on: {
                  click: function ($event) {
                    return _vm.fillData("marriage")
                  },
                },
              },
              [_vm._v("婚姻")]
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary", round: "", plain: "", size: "mini" },
                on: {
                  click: function ($event) {
                    return _vm.fillData("family")
                  },
                },
              },
              [_vm._v("子女个数")]
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary", round: "", plain: "", size: "mini" },
                on: {
                  click: function ($event) {
                    return _vm.fillData("career")
                  },
                },
              },
              [_vm._v("职业")]
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary", round: "", plain: "", size: "mini" },
                on: {
                  click: function ($event) {
                    return _vm.fillData("industry")
                  },
                },
              },
              [_vm._v("行业")]
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary", round: "", plain: "", size: "mini" },
                on: {
                  click: function ($event) {
                    return _vm.fillData("satisfaction")
                  },
                },
              },
              [_vm._v("满意度")]
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary", round: "", plain: "", size: "mini" },
                on: {
                  click: function ($event) {
                    return _vm.fillData("agree")
                  },
                },
              },
              [_vm._v("认同度")]
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary", round: "", plain: "", size: "mini" },
                on: {
                  click: function ($event) {
                    return _vm.fillData("yesno")
                  },
                },
              },
              [_vm._v("是否")]
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary", round: "", plain: "", size: "mini" },
                on: {
                  click: function ($event) {
                    return _vm.fillData("num")
                  },
                },
              },
              [_vm._v("数字")]
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary", round: "", plain: "", size: "mini" },
                on: {
                  click: function ($event) {
                    return _vm.fillData("week")
                  },
                },
              },
              [_vm._v("星期")]
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary", round: "", plain: "", size: "mini" },
                on: {
                  click: function ($event) {
                    return _vm.fillData("month")
                  },
                },
              },
              [_vm._v("月份")]
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary", round: "", plain: "", size: "mini" },
                on: {
                  click: function ($event) {
                    return _vm.fillData("year")
                  },
                },
              },
              [_vm._v("年份")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { type: "danger" }, on: { click: _vm.handleClose } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleSave } },
            [_vm._v("确 认")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }