<template>
  <div>

    <el-form ref="postForm" :model="postForm" :rules="rules" label-position="left" label-width="150px">

      <el-form-item label="服务提供商">
        <el-radio-group v-model="postForm.provider" size="small">
          <el-radio label="qcloud">腾讯云</el-radio>
          <el-radio label="baidu">百度云</el-radio>
          <el-radio label="aliyun">阿里云</el-radio>
        </el-radio-group>
      </el-form-item>

      <div v-if="postForm.provider==='baidu'">

        <el-form-item label="AppId">
          <el-input v-model="postForm.data.appId" size="small" placeholder="appId" />
        </el-form-item>

        <el-form-item label="AppKey">
          <el-input v-model="postForm.data.appKey" size="small" placeholder="appKey" />
        </el-form-item>

        <el-form-item label="SecretKey">
          <el-input v-model="postForm.data.secretKey" size="small" placeholder="secretKey" />
        </el-form-item>

        <el-form-item label="人脸群组">
          <el-input v-model="postForm.data.faceGroup" size="small" placeholder="人脸群组，适用多应用场景" />
        </el-form-item>

        <el-form-item label="活体检测">
          <el-checkbox v-model="postForm.data.liveCheck">开启</el-checkbox>
          <el-alert type="error" style="line-height: normal" title="开启后所有人脸识别都会做前置的活体检测，可以有效防止翻拍或网图！提示：对防作弊要求高时开启，会产生额外的费用！"/>
        </el-form-item>

        <el-form-item label="remark">
          <el-input v-model="postForm.remark" size="small" placeholder="备注信息" />
        </el-form-item>

      </div>

      <div v-if="postForm.provider==='qcloud'">

        <el-form-item label="EndPoint">
          <dic-list-select v-model="postForm.data.region" style="width: 100%" dic-code="qcloud_face_endpoints"/>
        </el-form-item>

        <el-form-item label="SecretId">
          <el-input v-model="postForm.data.secretId" size="small" placeholder="SecretId" />
        </el-form-item>

        <el-form-item label="SecretKey">
          <el-input v-model="postForm.data.secretKey" size="small" placeholder="SecretKey" />
        </el-form-item>

        <el-form-item label="人脸群组">
          <el-input v-model="postForm.data.groupId" size="small" placeholder="人脸群组，适用多应用场景" />
        </el-form-item>

        <el-form-item label="活体检测">
          <el-checkbox v-model="postForm.data.liveCheck">开启</el-checkbox>
          <el-alert type="error" style="line-height: normal" title="开启后所有人脸识别都会做前置的活体检测，可以有效防止翻拍或网图！提示：对防作弊要求高时开启，会产生额外的费用！"/>
        </el-form-item>

        <el-form-item label="remark">
          <el-input v-model="postForm.remark" size="small" placeholder="备注信息" />
        </el-form-item>

      </div>

      <div v-if="postForm.provider==='aliyun'">

        <el-form-item label="AccessKeyId">
          <el-input v-model="postForm.data.accessKeyId" size="small" placeholder="AccessKeyId" />
        </el-form-item>

        <el-form-item label="AccessKeySecret">
          <el-input v-model="postForm.data.accessKeySecret" size="small" placeholder="AccessKeySecret" />
        </el-form-item>

        <el-form-item label="DbName">
          <el-input v-model="postForm.data.dbName" size="small" placeholder="dbName" />
        </el-form-item>

        <el-form-item label="活体检测">
          <el-checkbox v-model="postForm.data.liveCheck">开启</el-checkbox>
          <el-alert type="error" style="line-height: normal" title="开启后所有人脸识别都会做前置的活体检测，可以有效防止翻拍或网图！提示：对防作弊要求高时开启，会产生额外的费用！"/>
        </el-form-item>

      </div>

    </el-form>

    <el-divider />

    <el-row style="margin-top: 20px">
      <el-button :loading="loading" icon="el-icon-refresh" type="warning" size="small" @click="changProvider">使用当前方案</el-button>
      <el-button :loading="loading" icon="el-icon-folder" type="primary" size="small" @click="submitForm">保存配置</el-button>
    </el-row>

  </div>
</template>

<script>
import { fetchDetail, saveData, change } from '@/api/sys/config/prop'

export default {
  name: 'FaceConfig',
  components: { },
  data() {
    return {
      postForm: {
        type: 'face',
        provider: 'baidu',
        data: {

        }
      },
      loading: false,
      rules: {
      }
    }
  },
  watch: {
    'postForm.provider': {
      handler(val) {
        this.fetchData(val)
      }
    }
  },
  created() {
    this.fetchData('')
  },
  methods: {

    fetchData(provider) {
      fetchDetail('face', provider).then(res => {
        // 清空表单
        this.postForm = { type: 'face', provider: provider, data: {}}

        // 赋值
        if (res.data && res.data.provider) {
          this.postForm = res.data
        }
      })
    },
    submitForm() {
      this.$refs.postForm.validate((valid) => {
        if (!valid) {
          return
        }

        this.loading = true
        saveData(this.postForm).then(() => {
          this.$notify({
            title: '成功',
            message: '人脸识别方案保存成功！',
            type: 'success',
            duration: 2000
          })
        })
        this.loading = false
      })
    },
    changProvider() {
      if (!this.postForm.type || !this.postForm.provider) {
        this.$message.warning('服务商必须选择！')
        return
      }

      // 参数转换
      this.loading = true

      change(this.postForm).then(() => {
        this.$notify({
          title: '成功',
          message: '服务商切换成功！',
          type: 'success',
          duration: 2000
        })

        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
