<template>

  <div>

    <el-dialog
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :append-to-body="true"
      title="查看报名"
      width="50%"
      @close="handleClose">

      <div>

        <el-descriptions :column="2" size="small" border>
          <el-descriptions-item v-for="(item,index) in detail.dataList" :key="index" :label="item.fieldTitle" label-style="width: 100px" content-style="width: 200px">
            <join-field-viewer :field-type="item.fieldType" :field-value="item.valueShow"/>
          </el-descriptions-item>
        </el-descriptions>

      </div>

    </el-dialog>

  </div>

</template>

<script>
import { joinDetail } from '@/api/activity/join'
import JoinFieldViewer from '@/views/admin/activity/components/JoinFieldViewer.vue'

export default {
  name: 'JoinViewDialog',
  components: { JoinFieldViewer },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    joinId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      dialogVisible: false,
      detail: {}
    }
  },

  watch: {

    // 检测查询变化
    visible: {
      handler() {
        this.dialogVisible = this.visible
      }
    },
    // 检测查询变化
    joinId: {
      handler() {
        this.fetchDetail()
      }
    }
  },

  created() {
    this.dialogVisible = this.visible
    if (this.joinId) {
      this.fetchDetail()
    }
  },

  methods: {

    // 获得详情
    fetchDetail() {
      joinDetail(this.joinId).then(res => {
        this.detail = res.data
      })
    },

    // 关闭选择框
    handleClose() {
      this.$emit('update:visible', false)
    }

  }
}
</script>
