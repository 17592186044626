var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.courseData != null && _vm.courseData.id != null
    ? _c(
        "div",
        [
          _c(
            "el-row",
            {
              staticStyle: { "line-height": "32px", "text-align": "center" },
              attrs: { gutter: 30 },
            },
            [
              _c(
                "el-col",
                {
                  staticStyle: {
                    "font-size": "22px",
                    "font-weight": "700",
                    "padding-bottom": "20px",
                  },
                  attrs: { span: 24 },
                },
                [_vm._v(" " + _vm._s(_vm.courseData.title) + " ")]
              ),
              _c(
                "el-col",
                {
                  staticStyle: { background: "#eee" },
                  attrs: { span: 24, xs: 24 },
                },
                [
                  _c(
                    "el-row",
                    { staticStyle: { color: "#666", "font-size": "14px" } },
                    [
                      _c("el-col", { attrs: { span: 8, xs: 12 } }, [
                        _c("i", { staticClass: "el-icon-edit" }),
                        _vm._v(
                          "类型：" + _vm._s(_vm.courseData.catId_dictText)
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 8, xs: 12 } }, [
                        _c("i", { staticClass: "el-icon-notebook-1" }),
                        _vm._v(
                          "课时：" + _vm._s(_vm.courseData.periods) + "课时"
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 8, xs: 12 } }, [
                        _c("i", { staticClass: "el-icon-thumb" }),
                        _vm._v(
                          "发布时间： " + _vm._s(_vm.courseData.createTime)
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.courseData.state === 1
                ? _c("el-col", [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          height: "50vh",
                          display: "flex",
                          "justify-content": "center",
                        },
                      },
                      [
                        _c("el-empty", {
                          attrs: { description: "课程已禁用，请联系管理员！" },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm.courseData.state === 2
                ? _c("el-col", [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          height: "50vh",
                          display: "flex",
                          "justify-content": "center",
                        },
                      },
                      [
                        _c("el-empty", {
                          attrs: {
                            description: "课程学习暂未开始，请持续关注！",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm.courseData.state === 3
                ? _c("el-col", [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          height: "50vh",
                          display: "flex",
                          "justify-content": "center",
                        },
                      },
                      [
                        _c("el-empty", {
                          attrs: { description: "来晚了，课程学习已截止！" },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm.courseData.state === 4
                ? _c("el-col", [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          height: "50vh",
                          display: "flex",
                          "justify-content": "center",
                        },
                      },
                      [
                        _c("el-empty", {
                          attrs: {
                            description:
                              "当前不在可学习时间段，可学习时间段为：" +
                              _vm.courseData.dayRule,
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm.courseData.state === 5
                ? _c("el-col", [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          height: "50vh",
                          display: "flex",
                          "justify-content": "center",
                        },
                      },
                      [
                        _c("el-empty", {
                          attrs: {
                            description:
                              "每天限制最多学习" +
                              _vm.courseData.dayLimit +
                              "分钟，明天再来吧！",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm.courseData.state === 6
                ? _c("el-col", [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          height: "50vh",
                          display: "flex",
                          "justify-content": "center",
                        },
                      },
                      [
                        _c("el-empty", {
                          attrs: {
                            description: "您不在可学习成员名单里面，请确认！",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm.courseData.state === 7
                ? _c("el-col", [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          height: "50vh",
                          display: "flex",
                          "justify-content": "center",
                        },
                      },
                      [
                        _c("el-empty", {
                          attrs: {
                            description: "您不在可学习部门名单里面，请确认！",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : !_vm.courseData.isBuy
                ? _c("el-col", [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          height: "50vh",
                          display: "flex",
                          "flex-direction": "column",
                          "justify-content": "center",
                          "line-height": "50px",
                        },
                      },
                      [
                        _c("div", [_vm._v("您需要购买课程才能继续学习!")]),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              color: "#ff0000",
                              "font-size": "28px",
                              "font-weight": "700",
                            },
                          },
                          [_vm._v("￥" + _vm._s(_vm.courseData.price))]
                        ),
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: { click: _vm.handleBuy },
                              },
                              [_vm._v("购买课程")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ])
                : _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "30px" },
                      attrs: { span: 24 },
                    },
                    [
                      _c(
                        "el-tabs",
                        {
                          model: {
                            value: _vm.activeTab,
                            callback: function ($$v) {
                              _vm.activeTab = $$v
                            },
                            expression: "activeTab",
                          },
                        },
                        [
                          _vm.courseData.liveList.length > 0
                            ? _c(
                                "el-tab-pane",
                                { attrs: { label: "直播日程", name: "live" } },
                                [
                                  _c("course-live", {
                                    model: {
                                      value: _vm.courseData.liveList,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.courseData,
                                          "liveList",
                                          $$v
                                        )
                                      },
                                      expression: "courseData.liveList",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.faceChecked
                            ? _c(
                                "el-tab-pane",
                                { attrs: { label: "课程学习", name: "learn" } },
                                [
                                  _vm.activeTab === "learn"
                                    ? _c(
                                        "div",
                                        [
                                          _vm.courseData.fileCount === 0
                                            ? _c("el-empty", {
                                                attrs: {
                                                  description:
                                                    "无可学习的课件，请联系管理员添加！",
                                                },
                                              })
                                            : _c(
                                                "el-row",
                                                {
                                                  staticStyle: {
                                                    "margin-top": "30px",
                                                  },
                                                  attrs: { gutter: 30 },
                                                },
                                                [
                                                  _vm.currentFile &&
                                                  _vm.currentFile.id
                                                    ? _c(
                                                        "el-col",
                                                        { attrs: { span: 17 } },
                                                        [
                                                          _vm.currentFile
                                                            .fileType === "33"
                                                            ? _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "file-player",
                                                                    {
                                                                      attrs: {
                                                                        course:
                                                                          _vm.courseData,
                                                                        file: _vm.currentFile,
                                                                      },
                                                                      on: {
                                                                        change:
                                                                          _vm.changeLearnMin,
                                                                        finish:
                                                                          _vm.handleFinish,
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                          _vm.currentFile
                                                            .fileType ===
                                                            "11" ||
                                                          _vm.currentFile
                                                            .fileType === "22"
                                                            ? _c("pdf-frame", {
                                                                attrs: {
                                                                  course:
                                                                    _vm.courseData,
                                                                  file: _vm.currentFile,
                                                                },
                                                                on: {
                                                                  change:
                                                                    _vm.changeLearnMin,
                                                                  finish:
                                                                    _vm.handleFinish,
                                                                },
                                                              })
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "el-col",
                                                    {
                                                      staticStyle: {
                                                        "overflow-y": "auto",
                                                        height: "80vh",
                                                      },
                                                      attrs: { span: 7 },
                                                    },
                                                    [
                                                      _vm.courseData.finished &&
                                                      _vm.courseData.refExam
                                                        ? _c(
                                                            "el-button",
                                                            {
                                                              staticStyle: {
                                                                width: "100%",
                                                                "margin-bottom":
                                                                  "20px",
                                                              },
                                                              attrs: {
                                                                type: "danger",
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.toExam,
                                                              },
                                                            },
                                                            [_vm._v("进入考试")]
                                                          )
                                                        : _vm._e(),
                                                      _vm.courseData.faceCam
                                                        ? _c("course-cam", {
                                                            attrs: {
                                                              "face-interval":
                                                                _vm.courseData
                                                                  .faceInterval,
                                                              "course-id":
                                                                _vm.courseId,
                                                              "file-id":
                                                                _vm.currentFile
                                                                  .fileId,
                                                            },
                                                            on: {
                                                              break:
                                                                _vm.checkBreak,
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      _c(
                                                        "div",
                                                        [
                                                          _c(
                                                            "el-collapse",
                                                            {
                                                              model: {
                                                                value:
                                                                  _vm.activeName,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.activeName =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "activeName",
                                                              },
                                                            },
                                                            _vm._l(
                                                              _vm.courseData
                                                                .dirList,
                                                              function (dir) {
                                                                return _c(
                                                                  "el-collapse-item",
                                                                  {
                                                                    key: dir.id,
                                                                    attrs: {
                                                                      title:
                                                                        dir.title,
                                                                      name: dir.id,
                                                                    },
                                                                  },
                                                                  _vm._l(
                                                                    dir.fileList,
                                                                    function (
                                                                      file
                                                                    ) {
                                                                      return _c(
                                                                        "div",
                                                                        {
                                                                          key: file.id,
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.changeFile(
                                                                                  file
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "el-row",
                                                                            {
                                                                              class:
                                                                                {
                                                                                  "file-item": true,
                                                                                  "file-item-active":
                                                                                    file.fileId ===
                                                                                    _vm
                                                                                      .currentFile
                                                                                      .fileId,
                                                                                  "file-unlocked":
                                                                                    !file.unlocked,
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "el-col",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      file.title
                                                                                    ) +
                                                                                      " "
                                                                                  ),
                                                                                  file.needLearn >
                                                                                  0
                                                                                    ? _c(
                                                                                        "span",
                                                                                        {
                                                                                          staticStyle:
                                                                                            {
                                                                                              float:
                                                                                                "right",
                                                                                              color:
                                                                                                "#666",
                                                                                              "font-size":
                                                                                                "12px",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "sec-format",
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  value:
                                                                                                    file.needLearn,
                                                                                                },
                                                                                            }
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    : _vm._e(),
                                                                                ]
                                                                              ),
                                                                              file.unlocked
                                                                                ? _c(
                                                                                    "el-col",
                                                                                    [
                                                                                      file.needLearn >
                                                                                      0
                                                                                        ? _c(
                                                                                            "el-progress",
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  percentage:
                                                                                                    _vm.percent(
                                                                                                      file
                                                                                                    ),
                                                                                                },
                                                                                            }
                                                                                          )
                                                                                        : _c(
                                                                                            "el-progress",
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  percentage: 100,
                                                                                                },
                                                                                            }
                                                                                          ),
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                : _c(
                                                                                    "el-col",
                                                                                    {
                                                                                      staticStyle:
                                                                                        {
                                                                                          display:
                                                                                            "flex",
                                                                                          "align-items":
                                                                                            "center",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticStyle:
                                                                                            {
                                                                                              color:
                                                                                                "#ff0000",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "i",
                                                                                            {
                                                                                              staticClass:
                                                                                                "el-icon-lock",
                                                                                            }
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticStyle:
                                                                                            {
                                                                                              color:
                                                                                                "#888",
                                                                                              "padding-left":
                                                                                                "10px",
                                                                                              "font-size":
                                                                                                "12px",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            " 当前课程未解锁 "
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    }
                                                                  ),
                                                                  0
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "el-tab-pane",
                            { attrs: { label: "课程介绍", name: "detail" } },
                            [
                              _c("div", { staticClass: "content" }, [
                                _c("div", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$options.filters.formatRichText(
                                        _vm.courseData.content
                                      )
                                    ),
                                  },
                                }),
                              ]),
                            ]
                          ),
                          _c(
                            "el-tab-pane",
                            {
                              attrs: {
                                label:
                                  "课程问答(" + _vm.courseData.qaCount + ")",
                                name: "qa",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "content" },
                                [
                                  _c("course-qa", {
                                    attrs: {
                                      "course-id": _vm.courseData.id,
                                      "file-id": _vm.currentFile.fileId,
                                      "is-mine": false,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
          _c("course-buy", {
            attrs: { visible: _vm.payVisible, "course-id": _vm.courseId },
            on: {
              "update:visible": function ($event) {
                _vm.payVisible = $event
              },
            },
          }),
          _c("face-check-dialog", {
            attrs: {
              visible: _vm.faceVisible,
              params: {
                fileId: _vm.currentFile.fileId,
                courseId: _vm.courseId,
              },
              url: "/api/course/face/face-check",
            },
            on: {
              "update:visible": function ($event) {
                _vm.faceVisible = $event
              },
              success: _vm.doCheckFace,
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }