<template>
  <canvas
    id="canvas"
    ref="canvas"
  />
</template>

<script>
import 'tracking/build/tracking-min.js'
import 'tracking/build/data/face-min.js'

export default {
  name: 'FaceTracking',
  props: {
    videoId: {
      type: String,
      default: 'video'
    },
    width: {
      type: [Number, String],
      default: '100%'
    },
    height: {
      type: [Number, String],
      default: 'auto'
    }
  },

  data() {
    return {

      // 人脸识别相关
      tracker: null,
      noFace: false,

      // 警告定时器
      faceTimer: null,

      // 画图
      canvas: null,
      context: null,

      // 锁，避免过快
      fastLock: false
    }
  },
  beforeDestroy() {
    this.clear()
  },

  created() {
    this.$nextTick(() => {
      this.start()
    })
  },

  methods: {

    // 开始面部追踪
    start() {
      // 初始化画布用于截图和人脸跟踪
      this.canvas = document.getElementById('canvas')

      // 跟随视频的宽高
      this.canvas.width = document.querySelector(`#${this.videoId}`).clientWidth
      this.canvas.height = document.querySelector(`#${this.videoId}`).clientHeight
      this.context = this.canvas.getContext('2d')

      // eslint-disable-next-line no-undef
      this.tracker = new tracking.ObjectTracker('face')
      this.tracker.setInitialScale(2)
      this.tracker.setStepSize(2)
      this.tracker.setEdgesDensity(0.1)

      // 注册事件
      this.tracker.on('track', this.handleTracked)

      // 开始监听
      tracking.track(document.querySelector(`#${this.videoId}`), this.tracker, { camera: true })

      // 定时任务
      this.faceTimer = setInterval(this.checkFaceOut, 20000)
    },

    // 进行人脸识别
    async handleTracked(event) {
      // // 有人脸避免过快识别
      // if (this.fastLock) {
      //   return
      // }
      //
      // // 锁住0.5秒
      // this.fastLock = true

      // 清除识别框
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height)
      event.data.forEach(this.plot)

      // 未识别到
      if (event.data.length === 0) {
        this.noFace = true
      } else {
        this.noFace = false
        this.remind()
      }

      // // 解锁
      // setTimeout(() => {
      //   this.fastLock = false
      // }, 200)
    },

    // 人脸识别警告
    checkFaceOut() {
      // 人脸不不在，警告
      if (this.noFace) {
        this.$emit('face-out')
      }
    },

    // 清理并退出
    clear() {
      if (this.faceTimer) {
        clearInterval(this.faceTimer)
        this.faceTimer = null
      }
      if (this.tracker) {
        this.tracker.removeListener('track', this.handleTracked)
        this.tracker = null
      }
    },

    // 识别到人脸
    remind() {
      console.log('识别到人脸，续期...')
      this.$emit('face-in')

      if (this.faceTimer) {
        clearInterval(this.faceTimer)
        this.faceTimer = null
      }
      // 定时任务
      this.faceTimer = setInterval(this.checkFaceOut, 20000)
    },


    // 画人脸识别框
    plot(rect) {
      this.context.strokeStyle = '#FF7026'
      this.context.lineWidth = 2
      this.context.font = '12px Helvetica'
      this.context.strokeRect(rect.x, rect.y, rect.width, rect.height)
      this.context.fillStyle = '#111'
    }

  }
}
</script>

<style scoped>
video, img {
  position: absolute;
  left: 0;
  top: 0;
}

.tips{
  margin-top: 10px;
  font-size: 12px;
  font-weight: 700;
}

canvas {
  position: absolute;
  left: 5px;
  top: 5px;
}

</style>
