var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("watch-frame", {
    attrs: { "exam-id": _vm.examId, "line-count": 8 },
    on: { stat: _vm.syncStat },
    scopedSlots: _vm._u([
      {
        key: "user-item",
        fn: function (ref) {
          var data = ref.data
          return [
            _c(
              "div",
              { staticClass: "watch-box-item" },
              [
                _c(
                  "el-image",
                  {
                    staticStyle: { width: "100%", height: "100px" },
                    attrs: { src: data.image, fit: "cover" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "img-err",
                        attrs: { slot: "error" },
                        slot: "error",
                      },
                      [_c("i", { staticClass: "el-icon-user" })]
                    ),
                  ]
                ),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }