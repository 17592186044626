<template>

  <div class="result-box">

    <div class="score">
      考试结束
    </div>

    <div>
      {{ data.thanks }}
    </div>

  </div>

</template>

<script>

export default {
  name: 'ThanksOnly',
  props: {
    data: Object
  },
  data() {
    return {
    }
  },
  created() {

  },
  methods: {

  }
}
</script>

<style scoped>

.result-box{
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 50px;
  min-height: 50vh;
}

.result-box .score{
  font-size: 30px;
  font-weight: 700;
  color: #0a84ff
}

.result-box .score span{
  font-size: 20px
}

/deep/
.main-bg{
  background: transparent !important;
}

</style>
