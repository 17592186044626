var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("web-table", {
        attrs: { options: _vm.options, "list-query": _vm.listQuery },
        scopedSlots: _vm._u([
          {
            key: "filter-content",
            fn: function () {
              return undefined
            },
            proxy: true,
          },
          {
            key: "data-columns",
            fn: function () {
              return [
                _c("el-table-column", {
                  attrs: { label: "报名名称", "show-overflow-tooltip": "" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("detail-link", {
                            attrs: {
                              params: { id: scope.row.activityId },
                              title: scope.row.title,
                              to: "WebActivityDetail",
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { align: "center", width: "160px", label: "报名对象" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-link",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleView(scope.row.id)
                                },
                              },
                            },
                            [_vm._v(_vm._s(scope.row.joinName))]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "报名类型",
                    align: "center",
                    prop: "activityType_dictText",
                    width: "120px",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "报名时间",
                    align: "center",
                    prop: "createTime",
                    width: "180px",
                  },
                }),
                _c("el-table-column", {
                  attrs: { align: "center", width: "160px", label: "报名状态" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                                "justify-content": "center",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticStyle: { "margin-right": "10px" } },
                                [_vm._v(_vm._s(scope.row.state_dictText))]
                              ),
                              scope.row.state === 1
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "danger",
                                            size: "mini",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handlePay(scope.row.id)
                                            },
                                          },
                                        },
                                        [_vm._v("支付")]
                                      ),
                                    ],
                                    1
                                  )
                                : scope.row.state === 3
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            size: "mini",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleUpdate(
                                                scope.row.id
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("修改")]
                                      ),
                                    ],
                                    1
                                  )
                                : scope.row.state === 0
                                ? _c(
                                    "div",
                                    [
                                      scope.row.activityType === "1" &&
                                      scope.row.refCourse
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "primary",
                                                size: "mini",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.toCourse(
                                                    scope.row.activityId,
                                                    scope.row.refCourse
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("去学习")]
                                          )
                                        : _vm._e(),
                                      scope.row.activityType === "2" &&
                                      scope.row.refExam
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "primary",
                                                size: "mini",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.toExam(
                                                    scope.row.activityId,
                                                    scope.row.refExam
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("去考试")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("activity-buy", {
        attrs: { "join-id": _vm.joinId, visible: _vm.payVisible },
        on: {
          "update:visible": function ($event) {
            _vm.payVisible = $event
          },
        },
      }),
      _c("join-update-dialog", {
        attrs: {
          "join-id": _vm.joinId,
          visible: _vm.updateVisible,
          msg: "报名修改成功，请等待审核！",
        },
        on: {
          "update:visible": function ($event) {
            _vm.updateVisible = $event
          },
        },
      }),
      _c("join-view-dialog", {
        attrs: { "join-id": _vm.joinId, visible: _vm.viewVisible },
        on: {
          "update:visible": function ($event) {
            _vm.viewVisible = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }