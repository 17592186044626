var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            "append-to-body": true,
            title: "参与报名",
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.handleClose,
          },
        },
        [
          _vm.dialogVisible
            ? _c(
                "div",
                [
                  _c(
                    "el-form",
                    {
                      ref: "postForm",
                      attrs: {
                        model: _vm.postForm,
                        rules: _vm.rules,
                        "label-position": "left",
                        "label-width": "150px",
                      },
                    },
                    _vm._l(_vm.fieldList, function (field) {
                      return _c(
                        "el-form-item",
                        {
                          key: field.fieldName,
                          attrs: {
                            label: field.fieldTitle,
                            prop: field.fieldName,
                          },
                        },
                        [
                          field.fieldType === "text"
                            ? _c(
                                "div",
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: field.tips,
                                      size: "small",
                                      maxlength: "2000",
                                      "show-word-limit": "",
                                    },
                                    model: {
                                      value: _vm.postForm[field.fieldName],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.postForm,
                                          field.fieldName,
                                          $$v
                                        )
                                      },
                                      expression: "postForm[field.fieldName]",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          field.fieldType === "textarea"
                            ? _c(
                                "div",
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: field.tips,
                                      type: "textarea",
                                      size: "small",
                                      maxlength: "2000",
                                      "show-word-limit": "",
                                    },
                                    model: {
                                      value: _vm.postForm[field.fieldName],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.postForm,
                                          field.fieldName,
                                          $$v
                                        )
                                      },
                                      expression: "postForm[field.fieldName]",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          field.fieldType === "radio"
                            ? _c("div", [
                                field.fieldItems.length >= 3
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              placeholder:
                                                field.tips || "请选择",
                                            },
                                            model: {
                                              value:
                                                _vm.postForm[field.fieldName],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.postForm,
                                                  field.fieldName,
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "postForm[field.fieldName]",
                                            },
                                          },
                                          _vm._l(
                                            field.fieldItems,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.value,
                                                attrs: {
                                                  label: item.title,
                                                  value: item.value,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "div",
                                      [
                                        _c(
                                          "el-radio-group",
                                          {
                                            model: {
                                              value:
                                                _vm.postForm[field.fieldName],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.postForm,
                                                  field.fieldName,
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "postForm[field.fieldName]",
                                            },
                                          },
                                          _vm._l(
                                            field.fieldItems,
                                            function (item) {
                                              return _c(
                                                "el-radio",
                                                {
                                                  key: item.value,
                                                  attrs: { label: item.value },
                                                },
                                                [_vm._v(_vm._s(item.title))]
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                              ])
                            : _vm._e(),
                          field.fieldType === "checkbox"
                            ? _c("div", [
                                field.fieldItems.length >= 3
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              placeholder:
                                                field.tips || "请选择",
                                              multiple: "",
                                            },
                                            model: {
                                              value:
                                                _vm.postForm[field.fieldName],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.postForm,
                                                  field.fieldName,
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "postForm[field.fieldName]",
                                            },
                                          },
                                          _vm._l(
                                            field.fieldItems,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.value,
                                                attrs: {
                                                  label: item.title,
                                                  value: item.value,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "div",
                                      [
                                        _c(
                                          "el-checkbox-group",
                                          {
                                            model: {
                                              value:
                                                _vm.postForm[field.fieldName],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.postForm,
                                                  field.fieldName,
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "postForm[field.fieldName]",
                                            },
                                          },
                                          _vm._l(
                                            field.fieldItems,
                                            function (item) {
                                              return _c(
                                                "el-checkbox",
                                                {
                                                  key: item.value,
                                                  attrs: { label: item.value },
                                                },
                                                [_vm._v(_vm._s(item.title))]
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                              ])
                            : _vm._e(),
                          field.fieldType === "image"
                            ? _c(
                                "div",
                                [
                                  _c("file-upload", {
                                    attrs: {
                                      limit: 1,
                                      compress: 5000,
                                      tips: field.tips,
                                      "list-type": "picture-card",
                                      accept: ".jpg,.jpeg,.png",
                                    },
                                    model: {
                                      value: _vm.postForm[field.fieldName],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.postForm,
                                          field.fieldName,
                                          $$v
                                        )
                                      },
                                      expression: "postForm[field.fieldName]",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          field.fieldType === "audio"
                            ? _c(
                                "div",
                                [
                                  _c("recorder", {
                                    ref: "record",
                                    refInFor: true,
                                    attrs: { tips: field.tips },
                                    model: {
                                      value: _vm.postForm[field.fieldName],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.postForm,
                                          field.fieldName,
                                          $$v
                                        )
                                      },
                                      expression: "postForm[field.fieldName]",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          field.fieldType === "video"
                            ? _c(
                                "div",
                                [
                                  _c("file-upload", {
                                    attrs: {
                                      limit: 1,
                                      tips: field.tips,
                                      "list-type": "file",
                                      accept: ".mp4",
                                    },
                                    model: {
                                      value: _vm.postForm[field.fieldName],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.postForm,
                                          field.fieldName,
                                          $$v
                                        )
                                      },
                                      expression: "postForm[field.fieldName]",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.loading, type: "primary" },
                  on: { click: _vm.handleSave },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("activity-buy", {
        attrs: { "join-id": _vm.joinId, visible: _vm.payVisible },
        on: {
          "update:visible": function ($event) {
            _vm.payVisible = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }