import { post } from '@/utils/request'

/**
 * 创建预付费订单
 * @param joinId
 * @returns {Promise}
 */
export function buy(joinId) {
  return post('/api/activity/buy/init', { id: joinId })
}

/**
 * 标记为线下支付
 * @param ids
 * @returns {Promise}
 */
export function markAsPaid(ids) {
  return post('/api/activity/buy/mark-paid', { ids: ids })
}
