<template>

  <div>

    <div style="background: #f5f5f5">

      <el-image v-if="battleData.banner" :src="battleData.banner" fit="fill" style="width: 100%" />
      <div style="display: flex; flex-direction: row; padding: 10px; align-items: center">
        <h2 style="flex-grow: 1">{{ battleData.title }} </h2>
        <div>
          <el-button type="danger" @click="handleCreate">参与答题</el-button>
        </div>

      </div>

    </div>

    <el-tabs v-model="activeTab" class="uc-tab">
      <el-tab-pane label="竞赛信息" name="first">
        <h3>竞赛概况</h3>
        <div>
          <p>参与周期：{{ battleData.cycleType_dictText }}{{ battleData.cycleCount }}次</p>
          <p>答题内容：每次{{ battleData.quCount }}，每题{{ battleData.quScore }}分</p>
          <p>答题时间：{{ battleData.startTime }} - {{ battleData.endTime }}</p>
        </div>

        <el-divider />

        <h3>竞赛须知</h3>
        <p class="intro" v-html="$options.filters.formatRichText(battleData.notice)" />
      </el-tab-pane>

      <el-tab-pane label="个人榜单" name="second">

        <div style="margin-bottom: 20px">
          <el-radio-group v-model="typeStr" size="medium" @change="typeChange">
            <el-radio-button label="总榜" />
            <el-radio-button label="日榜" />
            <el-radio-button label="周榜" />
            <el-radio-button label="月榜" />

          </el-radio-group>
        </div>

        <personal-ranking :battle-id="battleId" :time-type="timeType" />

      </el-tab-pane>

      <el-tab-pane label="部门榜单" name="third">
        <div style="margin-bottom: 20px">
          <el-radio-group v-model="typeStr" size="medium" @change="typeChange">
            <el-radio-button label="总榜" />
            <el-radio-button label="日榜" />
            <el-radio-button label="周榜" />
            <el-radio-button label="月榜" />

          </el-radio-group>
        </div>
        <company-ranking :battle-id="battleId" :time-type="timeType" />
      </el-tab-pane>

      <el-tab-pane label="我的答题" name="four">
        <battle-paper :battle-id="battleId" />
      </el-tab-pane>

    </el-tabs>

  </div>

</template>

<script>

import { apiBattleDetail } from '@/api/battle/battle'
import { apiCreate } from '@/api/battle/paper'
import PersonalRanking from '@/views/admin/battle/components/PersonalRanking'
import CompanyRanking from '@/views/admin/battle/components/CompanyRanking'
import BattlePaper from '@/views/web/battle/components/BattlePaper'

export default {
  components: { BattlePaper, CompanyRanking, PersonalRanking },
  data() {
    return {
      activeTab: 'first',
      typeStr: '总榜',
      timeType: 4,
      battleId: '',
      battleData: {}
    }
  },

  created() {
    this.battleId = this.$route.params.id

    apiBattleDetail(this.battleId).then(res => {
      this.battleData = res.data
    })
  },
  methods: {

    typeChange(label) {
      if (label === '日榜') {
        this.timeType = 1
      }

      if (label === '周榜') {
        this.timeType = 2
      }

      if (label === '月榜') {
        this.timeType = 3
      }

      if (label === '总榜') {
        this.timeType = 4
      }
    },

    handleCreate() {
      if (this.battleData.openType === 9) {
        this.$prompt('请输入参赛密码', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }).then(({ value }) => {
          this.handleJoin(value)
        })
      } else {
        this.handleJoin('')
      }
    },

    // 参与比赛
    handleJoin(pass) {
      const params = { battleId: this.battleId, password: pass }
      apiCreate(params).then(res => {
        if (res.code !== 0) {
          this.$notify.error({
            title: '提示信息',
            message: res.msg
          })
        } else {
          this.$router.push({ name: 'WebBattleExam', params: { paperId: res.data.id }})
        }
      })
    }

  }
}
</script>

<style scoped>

/deep/
.el-tabs__item{
  font-size: 16px;
  height: 60px;
  line-height: 60px;
  font-weight: 700;
}

/deep/
.el-tab-pane{
  padding-top: 20px;
}

</style>
