var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sv-page", class: _vm.theme }, [
    _c(
      "div",
      { staticClass: "sv-body", class: { "view-mask": _vm.viewMode } },
      [
        _c("div", { staticClass: "sv-title" }, [
          _vm._v(" " + _vm._s(_vm.paper.title) + " "),
        ]),
        _vm.paper.content
          ? _c(
              "div",
              {
                staticClass: "sv-content",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$options.filters.formatRichText(_vm.paper.content)
                  ),
                },
              },
              [_vm._v(" 问卷说明 ")]
            )
          : _vm._e(),
        _vm._l(_vm.paper.quList, function (item, index) {
          return _c(
            "div",
            {
              key: item.id,
              staticClass: "q-box",
              class: { "error-box": _vm.errorId === item.id },
              attrs: { id: "qu-" + item.id },
            },
            [
              item.quType === "1" ||
              item.quType === "2" ||
              item.quType === "27" ||
              item.quType === "28"
                ? _c("survey-answer-select", {
                    attrs: { value: item },
                    on: {
                      change: function ($event) {
                        return _vm.saveAnswer($event, index)
                      },
                    },
                  })
                : _vm._e(),
              item.quType === "26"
                ? _c("survey-answer-rank", {
                    attrs: { value: item },
                    on: {
                      change: function ($event) {
                        return _vm.saveAnswer($event, index)
                      },
                    },
                  })
                : _vm._e(),
              item.quType === "4"
                ? _c("survey-answer-ask", {
                    attrs: { value: item },
                    on: {
                      change: function ($event) {
                        return _vm.saveAnswer($event, index)
                      },
                    },
                  })
                : _vm._e(),
              _vm.errorId === item.id
                ? _c("div", { staticClass: "error-text" }, [
                    _vm._v("!" + _vm._s(_vm.errorText)),
                  ])
                : _vm._e(),
            ],
            1
          )
        }),
        !_vm.viewMode
          ? _c(
              "div",
              { staticStyle: { "text-align": "center", padding: "20px 0" } },
              [
                _c(
                  "el-button",
                  {
                    staticStyle: { width: "50%" },
                    attrs: { type: "danger", icon: "el-icon-s-promotion" },
                    on: { click: _vm.submitPaper },
                  },
                  [_vm._v("提交问卷")]
                ),
              ],
              1
            )
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }