import { post } from '@/utils/request'


/**
 * 查找详情
 * @param id
 * @returns {Promise}
 */
export function detail(id) {
  return post('/api/survey/survey/detail', { id: id })
}

/**
 * 保存修改
 * @param data
 * @returns {Promise}
 */
export function save(data) {
  return post('/api/survey/survey/save', data)
}

/**
 * 发布/取消发布
 * @param data
 * @returns {Promise}
 */
export function publish(data) {
  return post('/api/survey/survey/publish', data)
}

/**
 * 复制问卷
 * @param id
 * @returns {Promise}
 */
export function copy(id) {
  return post('/api/survey/survey/copy', { id: id })
}


/**
 * 问卷预览
 * @param id
 * @returns {Promise}
 */
export function preview(id) {
  return post('/api/survey/survey/preview', { id: id })
}
