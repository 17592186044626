var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.repoId
    ? _c(
        "el-select",
        {
          staticClass: "filter-item",
          attrs: {
            "remote-method": _vm.fetchData,
            filterable: "",
            size: "small",
            remote: "",
            "reserve-keyword": "",
            clearable: "",
            "automatic-dropdown": "",
            placeholder: "选择章节",
          },
          on: {
            change: _vm.handlerChange,
            clear: function ($event) {
              return _vm.fetchData("")
            },
          },
          model: {
            value: _vm.currentValue,
            callback: function ($$v) {
              _vm.currentValue = $$v
            },
            expression: "currentValue",
          },
        },
        _vm._l(_vm.dataList, function (item) {
          return _c("el-option", {
            key: item.id,
            attrs: { label: item.title, value: item.id },
          })
        }),
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }