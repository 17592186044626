<template>

  <el-container class="page-out">

    <el-header class="page-header">
      <web-header />
    </el-header>

    <el-main class="page-main container">
      <slot v-if="$slots.main" name="main"/>
      <app-main v-else/>
    </el-main>

    <el-footer v-if="siteData.copyRight" class="page-footer">
      <div v-html="siteData.copyRight" />
    </el-footer>
  </el-container>

</template>

<script>
import { mapGetters } from 'vuex'
import { AppMain, WebHeader } from './components'

export default {
  name: 'WebLayout',
  components: {
    AppMain,
    WebHeader
  },
  computed: {
    ...mapGetters([
      'siteData'
    ])
  }
}
</script>

<style scoped>

  ::v-deep .el-pagination{
    text-align: center;
  }

  ::v-deep .el-card__header{
    font-size: 14px;
    font-weight: 700;
  }
  ::v-deep .el-card__body{
    font-size: 14px;
    line-height: 24px;
  }

  ::v-deep .el-tabs__item.is-active {
    color: #ECB507
  }

  ::v-deep .el-tabs__active-bar{
    background-color: #ECB507
  }

  ::v-deep a {
    color: #0a84ff;
  }

  ::v-deep a:hover{
    color: #ff0000;
  }

</style>

