// 知识竞赛模块
import WebLayout from '@/layout/WebLayout'

const battleRouter = {
  path: '/battle',
  component: WebLayout,
  redirect: '/pages/battle',
  hidden: true,
  children: [

    {
      path: '/pages/battle',
      component: () => import('@/views/web/battle/index'),
      name: 'WebBattleList',
      meta: { title: '知识竞赛', noCache: true }
    },

    {
      path: '/pages/battle/detail/:id',
      component: () => import('@/views/web/battle/detail'),
      name: 'WebBattleDetail',
      meta: { title: '竞赛详情', noCache: true }
    },

    {
      path: '/pages/battle/exam/:paperId',
      component: () => import('@/views/web/battle/exam'),
      name: 'WebBattleExam',
      meta: { title: '竞赛答题', noCache: true }
    },

    {
      path: '/pages/battle/result/:paperId',
      component: () => import('@/views/web/battle/result'),
      name: 'WebBattleResult',
      meta: { title: '答题结果', noCache: true }
    },

    {
      path: '/pages/battle/paper/:paperId',
      component: () => import('@/views/web/battle/paper'),
      name: 'WebBattlePaper',
      meta: { title: '答题详情', noCache: true }
    }

  ]
}
export default battleRouter
