var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        title: "选择课件",
        width: "80%",
        top: "5vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.handleClose,
      },
    },
    [
      _c("div", { staticStyle: { display: "flex" } }, [
        _c(
          "div",
          { staticStyle: { width: "300px" } },
          [
            _c(
              "el-card",
              [
                _c("dic-tree", {
                  ref: "tree",
                  attrs: { "dic-code": "course_file_catalog" },
                  model: {
                    value: _vm.listQuery.params.catId,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery.params, "catId", $$v)
                    },
                    expression: "listQuery.params.catId",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            staticStyle: {
              "flex-grow": "1",
              "padding-left": "20px",
              "overflow-x": "auto",
            },
          },
          [
            _c("data-table", {
              ref: "pagingTable",
              attrs: { options: _vm.options, "list-query": _vm.listQuery },
              on: { "select-changed": _vm.handleSelected },
              scopedSlots: _vm._u([
                {
                  key: "filter-content",
                  fn: function () {
                    return [
                      _c("el-input", {
                        staticClass: "filter-item",
                        staticStyle: { width: "200px" },
                        attrs: { placeholder: "搜索课件名称", size: "small" },
                        model: {
                          value: _vm.listQuery.params.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery.params, "title", $$v)
                          },
                          expression: "listQuery.params.title",
                        },
                      }),
                      _c("dic-list-select", {
                        attrs: {
                          "dic-code": "course_file_type",
                          title: "课件类型",
                        },
                        model: {
                          value: _vm.listQuery.params.fileType,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery.params, "fileType", $$v)
                          },
                          expression: "listQuery.params.fileType",
                        },
                      }),
                      _c("dic-list-select", {
                        attrs: {
                          "dic-code": "course_state",
                          title: "课件状态",
                        },
                        model: {
                          value: _vm.listQuery.params.state,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery.params, "state", $$v)
                          },
                          expression: "listQuery.params.state",
                        },
                      }),
                      _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          staticStyle: { float: "right" },
                          attrs: {
                            disabled: _vm.selectedList.length === 0,
                            type: "primary",
                            size: "small",
                          },
                          on: { click: _vm.handleConfirm },
                        },
                        [_vm._v(_vm._s(_vm.selectedLabel))]
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "data-columns",
                  fn: function () {
                    return [
                      _c("el-table-column", {
                        attrs: {
                          label: "课件名称",
                          prop: "title",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "课件大小",
                          align: "center",
                          prop: "fileSize",
                          width: "120px",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "课件时长",
                          align: "center",
                          width: "120px",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.duration === 0
                                  ? _c("span", [_vm._v("无")])
                                  : _c(
                                      "span",
                                      [
                                        _c("sec-format", {
                                          attrs: { value: scope.row.duration },
                                        }),
                                      ],
                                      1
                                    ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "课件类型",
                          align: "center",
                          prop: "fileType_dictText",
                          width: "120px",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "状态",
                          align: "center",
                          width: "120px",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.state === 0
                                  ? _c(
                                      "el-tag",
                                      {
                                        attrs: {
                                          size: "mini",
                                          type: "success",
                                        },
                                      },
                                      [_vm._v("正常")]
                                    )
                                  : _vm._e(),
                                scope.row.state === 1
                                  ? _c(
                                      "el-tag",
                                      {
                                        attrs: {
                                          size: "mini",
                                          type: "warning",
                                        },
                                      },
                                      [_vm._v("转码中")]
                                    )
                                  : _vm._e(),
                                scope.row.state === 2
                                  ? _c(
                                      "el-tag",
                                      {
                                        attrs: { size: "mini", type: "danger" },
                                      },
                                      [_vm._v("转码失败")]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }