import { post } from '@/utils/request'

/**
 * 收藏题库
 * @param id
 * @returns {Promise}
 */
export function joinRepo(id) {
  return post('/api/client/repo/join', { id: id })
}
