var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "watch-box" }, [
        _c(
          "div",
          { staticClass: "watch-left" },
          [
            _c(
              "el-card",
              { staticClass: "watch-left-card" },
              [
                _vm._v(" 自动翻页： "),
                _c(
                  "div",
                  [
                    _c("el-switch", {
                      attrs: { "active-text": "开", "inactive-text": "关" },
                      model: {
                        value: _vm.autoPage,
                        callback: function ($$v) {
                          _vm.autoPage = $$v
                        },
                        expression: "autoPage",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", [_vm._v("刷新时间：")]),
                _c(
                  "div",
                  [
                    _c("el-input-number", {
                      attrs: { min: 5, max: 999, size: "mini" },
                      model: {
                        value: _vm.refreshTime,
                        callback: function ($$v) {
                          _vm.refreshTime = $$v
                        },
                        expression: "refreshTime",
                      },
                    }),
                    _vm._v(" 秒 "),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticStyle: { "font-size": "12px", color: "#0a84ff" } },
                  [_vm._v("即将在" + _vm._s(_vm.seconds) + "秒后刷新")]
                ),
                _c("el-divider"),
                _c(
                  "div",
                  { staticStyle: { color: "#555", "line-height": "30px" } },
                  [
                    _c("div", [
                      _vm._v(
                        "应考人数：" + _vm._s(_vm.stat.requireCount) + "人"
                      ),
                    ]),
                    _c("div", [
                      _vm._v(
                        "在考人数：" + _vm._s(_vm.stat.processCount) + "人"
                      ),
                    ]),
                    _c("div", [
                      _vm._v("交卷人数：" + _vm._s(_vm.stat.handCount) + "人"),
                    ]),
                    _c("div", [
                      _vm._v("缺考人数：" + _vm._s(_vm.stat.missCount) + "人"),
                    ]),
                  ]
                ),
                _c("el-divider"),
                _vm.stat.processCount > 0
                  ? _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { type: "primary", size: "small" },
                            on: { click: _vm.batchDialog },
                          },
                          [_vm._v("群发消息")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.stat.processCount > 0
                  ? _c(
                      "div",
                      { staticStyle: { "padding-top": "10px" } },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { type: "danger", size: "small" },
                            on: { click: _vm.showHandAll },
                          },
                          [_vm._v("全部交卷")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "watch-right" },
          [
            _vm.stat.processCount > 0
              ? _c("data-grid", {
                  ref: "dataGrid",
                  attrs: { options: _vm.options, "list-query": _vm.listQuery },
                  on: { "query-change": _vm.queryChange },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "filter-content",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  padding: "0 0 10px 0",
                                  display: "flex",
                                  "align-items": "center",
                                },
                              },
                              [
                                _c("depart-tree-select", {
                                  attrs: { width: "200" },
                                  model: {
                                    value: _vm.listQuery.params.deptCode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.listQuery.params,
                                        "deptCode",
                                        $$v
                                      )
                                    },
                                    expression: "listQuery.params.deptCode",
                                  },
                                }),
                                _c("el-input", {
                                  staticStyle: {
                                    width: "200px",
                                    "margin-left": "10px",
                                  },
                                  attrs: {
                                    placeholder: "搜索姓名",
                                    size: "small",
                                  },
                                  model: {
                                    value: _vm.listQuery.params.realName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.listQuery.params,
                                        "realName",
                                        $$v
                                      )
                                    },
                                    expression: "listQuery.params.realName",
                                  },
                                }),
                                _c("el-checkbox", {
                                  staticStyle: { "margin-left": "10px" },
                                  attrs: { label: "在线" },
                                  model: {
                                    value: _vm.listQuery.params.online,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.listQuery.params,
                                        "online",
                                        $$v
                                      )
                                    },
                                    expression: "listQuery.params.online",
                                  },
                                }),
                                _c("el-checkbox", {
                                  attrs: { label: "离线" },
                                  model: {
                                    value: _vm.listQuery.params.offline,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.listQuery.params,
                                        "offline",
                                        $$v
                                      )
                                    },
                                    expression: "listQuery.params.offline",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "data-item",
                        fn: function (ref) {
                          var data = ref.data
                          return [
                            _c(
                              "div",
                              [
                                _vm._t("user-item", null, { data: data }),
                                _c("div", { staticClass: "watch-line-box" }, [
                                  _c("div", { staticClass: "user-name" }, [
                                    _vm._v(_vm._s(data.realName)),
                                  ]),
                                  _c("div", { staticClass: "user-state" }, [
                                    _c(
                                      "span",
                                      {
                                        class: {
                                          online: data.online,
                                          offline: !data.online,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              data.online ? "在线" : "离线"
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                                _c("div", { staticClass: "watch-ps-box" }, [
                                  _vm._v(
                                    " 答题进度:" +
                                      _vm._s(data.answerCount) +
                                      "/" +
                                      _vm._s(data.quCount) +
                                      " "
                                  ),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "watch-act-box" },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { width: "50%" },
                                        attrs: {
                                          disabled: !data.online,
                                          type: "primary",
                                          size: "mini",
                                          plain: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.singleDialog(data.userId)
                                          },
                                        },
                                      },
                                      [_vm._v("发消息")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { width: "50%" },
                                        attrs: {
                                          type: "danger",
                                          size: "mini",
                                          plain: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handDialog(data.id)
                                          },
                                        },
                                      },
                                      [_vm._v("交卷")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              : _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "align-items": "center",
                      height: "60vh",
                      "justify-content": "center",
                    },
                  },
                  [
                    _c("el-empty", {
                      attrs: { description: "没有正在考试的用户！" },
                    }),
                  ],
                  1
                ),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.handVisible,
            "close-on-click-modal": false,
            title: "全部收卷",
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.handVisible = $event
            },
            close: function ($event) {
              _vm.handVisible = false
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "handForm",
              attrs: {
                model: _vm.handForm,
                rules: _vm.handFormRules,
                "label-position": "left",
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "考卷状态", prop: "exState" } },
                [
                  _c("dic-list-select", {
                    staticStyle: { width: "100%" },
                    attrs: { "dic-code": "paper_ex_state" },
                    model: {
                      value: _vm.handForm.exState,
                      callback: function ($$v) {
                        _vm.$set(_vm.handForm, "exState", $$v)
                      },
                      expression: "handForm.exState",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "收卷理由", prop: "message" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.handForm.message,
                      callback: function ($$v) {
                        _vm.$set(_vm.handForm, "message", $$v)
                      },
                      expression: "handForm.message",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.handVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.handLoading, type: "primary" },
                  on: { click: _vm.doHandAll },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }