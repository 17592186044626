<template>

  <tracking-cam
    ref="cam"
    :tracking="true"
    :track-capture="false"
    :show-track-tips="false"
    @error="handleCamError"
    @face-out="faceOut"
    @started="startTimer"
    @capture="saveCapture"/>

</template>

<script>
import { saveCapture } from '@/api/paper/capture'
import { localToBase64 } from '@/utils/base64'

export default {
  name: 'ExamCamNormal',
  components: {
  },
  props: {
    camOn: Boolean,
    camInterval: Number,
    paperId: String
  },
  data() {
    return {
      // 文件上传参数
      dataObj: {},
      // 定时截图参数
      timer: null,
      intervalSec: 9999,
      camError: false
    }
  },

  destroyed() {
    if (this.timer != null) {
      clearInterval(this.timer)
    }
  },

  methods: {

    // 开启定时截屏
    startTimer() {
      // 不启动
      if (!this.camOn || this.camInterval <= 0) {
        return
      }

      // 秒数
      this.intervalSec = this.camInterval * 60000

      if (this.timer != null) {
        clearInterval(this.timer)
      }

      this.timer = setInterval(() => {
        // 摄像头出错
        if (this.camError) {
          this.fillEmpty()
        } else {
          this.$refs.cam.capture()
        }
      }, this.intervalSec)
    },

    // 保存截图
    saveCapture(base64) {
      // 保存数据
      const data = { paperId: this.paperId, base64: base64 }
      saveCapture(data).then(res => {
        const data = res.data
        // 达到上限
        if (data.isBreak) {
          this.$emit('break', `连续${data.failCount}次人脸识别失败，系统强制交卷！`)
          return
        }

        // 还有机会
        if (data.failCount > 0) {
          this.$message.error(`人脸识别已失败${data.failCount}次，连续失败${data.faceChance}次将自动交卷！`)
        }
      })
    },

    faceOut() {
      this.$message.error(`请诚信考试，确保人脸在摄像头中央！`)
    },

    // 摄像头错误
    handleCamError() {
      this.camError = true
      console.log('摄像头错误了！')
    },

    // 填充空白数据
    fillEmpty() {
      const file = require('@/assets/web/no-cam.png')
      localToBase64(file).then(res => {
        this.saveCapture(res)
      })
    }
  }
}
</script>

