var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "postForm",
          attrs: {
            model: _vm.postForm,
            rules: _vm.rules,
            "label-position": "left",
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "问卷类型", prop: "surveyType" } },
            [
              _c("dic-list-select", {
                attrs: { "dic-code": "survey_type" },
                model: {
                  value: _vm.postForm.surveyType,
                  callback: function ($$v) {
                    _vm.$set(_vm.postForm, "surveyType", $$v)
                  },
                  expression: "postForm.surveyType",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "答卷时间", prop: "startTime" } },
            [
              _c("date-time-picker", {
                attrs: {
                  "start-time": _vm.postForm.startTime,
                  "end-time": _vm.postForm.endTime,
                },
                on: {
                  "update:startTime": function ($event) {
                    return _vm.$set(_vm.postForm, "startTime", $event)
                  },
                  "update:start-time": function ($event) {
                    return _vm.$set(_vm.postForm, "startTime", $event)
                  },
                  "update:endTime": function ($event) {
                    return _vm.$set(_vm.postForm, "endTime", $event)
                  },
                  "update:end-time": function ($event) {
                    return _vm.$set(_vm.postForm, "endTime", $event)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "问卷名称", prop: "title" } },
            [
              _c("el-input", {
                attrs: { size: "small" },
                model: {
                  value: _vm.postForm.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.postForm, "title", $$v)
                  },
                  expression: "postForm.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "问卷描述", prop: "content" } },
            [
              _c("Tinymce", {
                ref: "editor",
                attrs: { height: 250 },
                model: {
                  value: _vm.postForm.content,
                  callback: function ($$v) {
                    _vm.$set(_vm.postForm, "content", $$v)
                  },
                  expression: "postForm.content",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "el-dialog__footer",
          staticStyle: {
            "text-align": "right",
            margin: "0 -30px",
            padding: "20px",
          },
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleSave } },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }