<template>
  <div class="image-3x2">
    <el-image :src="imageUrl">
      <div slot="error">
        <el-image :src="defaultCover" />
      </div>
    </el-image>
  </div>
</template>

<script>

export default {
  name: 'ImageBox',
  props: {
    src: String,
    style: {
      type: String,
      default: 'image-3x2'
    }
  },
  data() {
    return {
      imageUrl: '',
      defaultCover: require('@/assets/web/images/course_default.jpg')
    }
  },
  watch: {
    src: {
      handler(val) {
        this.imageUrl = val
      }
    }
  },
  created() {
    this.imageUrl = this.src
  },
  methods: {

  }
}
</script>

<style scoped>
.image-3x2{
  width: 100%;
  position: relative;
  height: 0;
  padding-bottom: 56%;
  cursor: pointer;
  .el-image{
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 2px;
  }
}
</style>
