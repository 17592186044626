<template>

  <data-table
    ref="pagingTable"
    :options="options"
    :list-query="listQuery"
  >
    <template #filter-content>
      <el-input v-model="listQuery.params.realName" placeholder="搜索人员" class="filter-item" style="width: 200px;" size="small" />
      <dic-list-select v-model="listQuery.params.hasEx" title="是否异常" dic-code="base_yes_no"/>
    </template>

    <template #data-columns>

      <el-table-column
        label="姓名"
        align="center"
        prop="realName"/>

      <el-table-column
        label="交卷时间"
        align="center"
        prop="handTime"/>

      <el-table-column
        label="考试用时/分钟"
        align="center"
        prop="userTime"/>

      <el-table-column
        label="考试状态"
        align="center"
      >
        <template v-slot="scope">

          <el-popover
            :content="scope.row.exMsg || scope.row.exState_dictText || '无异常信息'"
            placement="top-start"
            title="异常信息"
            width="200"
            trigger="hover">
            <span slot="reference" :class="{'ex-ee': scope.row.exState!==0, 'ex-normal': scope.row.exState===0}">{{ scope.row.exState_dictText }}</span>
          </el-popover>

        </template>
      </el-table-column>

      <el-table-column
        label="切屏次数"
        align="center"
        prop="leaveTimes"
      />

    </template>

  </data-table>

</template>

<script>

export default {
  name: 'WatchHand',
  components: { },
  props: {
    examId: String
  },
  data() {
    return {
      listQuery: {
        current: 1,
        size: 10,
        params: {
          handState: 1,
          overwrite: 0
        }
      },

      options: {

        // 可批量操作
        multi: false,
        // 列表请求URL
        listUrl: '/api/exam/watch/hand-paging'
      }
    }
  },
  created() {
    this.listQuery.params.examId = this.examId
  },
  methods: {

    // 显示异常信息
    showMsg(msg) {
      if (msg) {
        this.$message.info(msg)
      }
    },

    handleReview(id) {
      this.$router.push({ name: 'ReviewPaperDetail', params: { paperId: id }})
    },

    handleView(id) {
      this.$router.push({ name: 'ShowExam', params: { id: id }})
    }
  }
}
</script>

<style scoped>

.ex-ee{
  color: #ff0000;
}
.ex-normal{
  color: #0FDA6D;
}
</style>
