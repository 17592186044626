import { post } from '@/utils/request'

/**
 * 审核申请
 * @param data
 */
export function applyAudit(data) {
  return post('/api/exam/apply/audit', data)
}

/**
 * 提交审核申请
 * @param data
 */
export function saveApply(data) {
  return post('/api/exam/apply/save', data)
}
