<template>

  <data-grid
    ref="dataGrid"
    :options="options"
    :list-query="listQuery"
  >
    <template #filter-content>
      <dic-catalog-tree
        v-model="listQuery.params.catId"
        dic-code="repo_catalog"
        width="200"
        class="filter-item"
      />

      <el-input
        v-model="listQuery.params.title"
        class="filter-item"
        size="small"
        placeholder="搜索题库名称"
        prefix-icon="el-icon-search"
        style="width: 200px"
      />
    </template>

    <template v-slot:data-item="{ data }">


      <detail-link :params="{repoId: data.id}" :title="data.title" to="MyRepoView" />
      <span class="tag-box">
        <el-tag v-if="data.price > 0" type="danger" size="mini">￥{{ data.price }}</el-tag>
        <el-tag v-else type="success" size="mini">免费</el-tag>
      </span>

      <div class="flex-bt">
        <div>
          <div class="info-item">
            <span class="title">题库分类：</span>
            <span>{{ data.catId_dictText }}</span>
          </div>
          <div class="info-item">
            <span class="title">试题数量： </span>
            <span>{{ data.quCount }}</span>
          </div>
        </div>
        <div>
          <div :class="{'fav-btn-off': !data.hasJoin}" class="fav-btn" @click="handleJoin(data)">
            <i class="el-icon-star-on"/>
          </div>
        </div>
      </div>


    </template>

  </data-grid>

</template>

<script>
import DataGrid from '@/components/DataGrid/index.vue'
import { joinRepo } from '@/api/client/repo/repo'

export default {
  name: 'OpenRepoPage',
  components: { DataGrid },
  data() {
    return {
      listQuery: {
        current: 1,
        size: 10,
        params: {
          forTrain: true
        }
      },

      options: {
        // 列表请求URL
        listUrl: '/api/client/repo/open-paging',
        lineCount: 2
      }
    }
  },
  methods: {
    handleJoin(data) {
      joinRepo(data.id).then(() => {
        this.$emit('join')
        data.hasJoin = !data.hasJoin
      })
    }
  }
}
</script>

