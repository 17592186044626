var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticStyle: { background: "#f5f5f5" } },
        [
          _vm.battleData.banner
            ? _c("el-image", {
                staticStyle: { width: "100%" },
                attrs: { src: _vm.battleData.banner, fit: "fill" },
              })
            : _vm._e(),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "flex-direction": "row",
                padding: "10px",
                "align-items": "center",
              },
            },
            [
              _c("h2", { staticStyle: { "flex-grow": "1" } }, [
                _vm._v(_vm._s(_vm.battleData.title) + " "),
              ]),
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger" },
                      on: { click: _vm.handleCreate },
                    },
                    [_vm._v("参与答题")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "el-tabs",
        {
          staticClass: "uc-tab",
          model: {
            value: _vm.activeTab,
            callback: function ($$v) {
              _vm.activeTab = $$v
            },
            expression: "activeTab",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "竞赛信息", name: "first" } },
            [
              _c("h3", [_vm._v("竞赛概况")]),
              _c("div", [
                _c("p", [
                  _vm._v(
                    "参与周期：" +
                      _vm._s(_vm.battleData.cycleType_dictText) +
                      _vm._s(_vm.battleData.cycleCount) +
                      "次"
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "答题内容：每次" +
                      _vm._s(_vm.battleData.quCount) +
                      "，每题" +
                      _vm._s(_vm.battleData.quScore) +
                      "分"
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "答题时间：" +
                      _vm._s(_vm.battleData.startTime) +
                      " - " +
                      _vm._s(_vm.battleData.endTime)
                  ),
                ]),
              ]),
              _c("el-divider"),
              _c("h3", [_vm._v("竞赛须知")]),
              _c("p", {
                staticClass: "intro",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$options.filters.formatRichText(_vm.battleData.notice)
                  ),
                },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "个人榜单", name: "second" } },
            [
              _c(
                "div",
                { staticStyle: { "margin-bottom": "20px" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { size: "medium" },
                      on: { change: _vm.typeChange },
                      model: {
                        value: _vm.typeStr,
                        callback: function ($$v) {
                          _vm.typeStr = $$v
                        },
                        expression: "typeStr",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "总榜" } }),
                      _c("el-radio-button", { attrs: { label: "日榜" } }),
                      _c("el-radio-button", { attrs: { label: "周榜" } }),
                      _c("el-radio-button", { attrs: { label: "月榜" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("personal-ranking", {
                attrs: { "battle-id": _vm.battleId, "time-type": _vm.timeType },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "部门榜单", name: "third" } },
            [
              _c(
                "div",
                { staticStyle: { "margin-bottom": "20px" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { size: "medium" },
                      on: { change: _vm.typeChange },
                      model: {
                        value: _vm.typeStr,
                        callback: function ($$v) {
                          _vm.typeStr = $$v
                        },
                        expression: "typeStr",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "总榜" } }),
                      _c("el-radio-button", { attrs: { label: "日榜" } }),
                      _c("el-radio-button", { attrs: { label: "周榜" } }),
                      _c("el-radio-button", { attrs: { label: "月榜" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("company-ranking", {
                attrs: { "battle-id": _vm.battleId, "time-type": _vm.timeType },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "我的答题", name: "four" } },
            [_c("battle-paper", { attrs: { "battle-id": _vm.battleId } })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }