<template>

  <div>

    <div v-if="breakShow" style="margin-bottom: 30px; cursor: pointer" @click="toExam">
      <el-alert
        :closable="false"
        title="您有正在进行的考试，离线太久考试将被作废哦，点击此处可继续考试！"
        type="error"
      />
    </div>

    <el-tabs v-model="activeTab" type="card">

      <el-tab-pane label="考试大厅" name="open">
        <open-exam-page @join="handleJoin"/>
      </el-tab-pane>

      <el-tab-pane label="我的考试" name="my">
        <my-exam-page ref="my" />
      </el-tab-pane>


    </el-tabs>

  </div>

</template>

<script>


import MyExamPage from '@/views/web/exam/components/MyExamPage/index.vue'
import { checkProcess } from '@/api/paper/exam'
import OpenExamPage from '@/views/web/exam/components/OpenExamPage/index.vue'

export default {
  components: { OpenExamPage, MyExamPage },
  data() {
    return {
      cacheKey: 'exam-index',
      activeTab: 'open',
      breakShow: false,
      breakId: ''
    }
  },

  watch: {
    activeTab: {
      handler(val) {
        localStorage.setItem(this.cacheKey, val)
      }
    }
  },

  created() {
    // 获取缓存
    const cache = localStorage.getItem(this.cacheKey)
    if (cache) {
      this.activeTab = cache
    }

    checkProcess().then(res => {
      if (res.data && res.data.id) {
        this.breakShow = true
        this.breakId = res.data.id
      }
    })
  },
  methods: {
    toExam() {
      this.$router.push({ name: 'StartExam', params: { id: this.breakId }})
    },

    handleJoin() {
      this.$refs.my.reload()
    }
  }
}
</script>
