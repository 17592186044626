var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("web-table", {
    attrs: { options: _vm.options, "list-query": _vm.listQuery },
    scopedSlots: _vm._u([
      {
        key: "filter-content",
        fn: function () {
          return undefined
        },
        proxy: true,
      },
      {
        key: "data-columns",
        fn: function () {
          return [
            _c("el-table-column", {
              attrs: { label: "问卷名称", "show-overflow-tooltip": "" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var row = ref.row
                    return [
                      _c("detail-link", {
                        attrs: {
                          query: { surveyId: row.surveyId },
                          title: row.title,
                          to: "SurveyView",
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                width: "160px",
                prop: "startTime",
                label: "答卷时间",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: "提交时间",
                align: "center",
                prop: "handTime",
                width: "180px",
              },
            }),
            _c("el-table-column", {
              attrs: { align: "center", width: "100px", label: "状态" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.handState === 1
                        ? _c("span", { staticClass: "success" }, [
                            _vm._v(" 已提交 "),
                          ])
                        : scope.row.handState === 0
                        ? _c("span", { staticClass: "danger" }, [
                            _vm._v(" 待提交 "),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { align: "center", width: "100px", label: "操作" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.handState === 1
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-link",
                                {
                                  attrs: { type: "danger", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.toView(scope.row.id)
                                    },
                                  },
                                },
                                [_vm._v("查看")]
                              ),
                            ],
                            1
                          )
                        : scope.row.handState === 0
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-link",
                                {
                                  attrs: { type: "primary", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.toAnswer(scope.row.id)
                                    },
                                  },
                                },
                                [_vm._v("提交")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }