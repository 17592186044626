var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "destroy-on-close": true,
        "show-close": true,
        title: "分享问卷",
        width: "30%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.handleClose,
      },
    },
    [
      _c("div", { staticClass: "share-box" }, [
        _c(
          "div",
          { staticClass: "code-box" },
          [
            _c("vue-qr", {
              attrs: { text: _vm.codeText, size: 100, margin: 10 },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "link-box" }, [
          _c(
            "div",
            [
              _c("el-input", {
                attrs: {
                  value: _vm.codeText,
                  "prefix-icon": "el-icon-paperclip",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.copyLink } },
                [_vm._v("复制链接")]
              ),
              _c("el-button", { on: { click: _vm.openLink } }, [
                _vm._v("打开链接"),
              ]),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }