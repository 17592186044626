var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.repoData && _vm.repoData.id
    ? _c(
        "div",
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 16 } },
                [
                  _c(
                    "el-card",
                    [
                      _c("el-page-header", {
                        attrs: { content: "题库详情" },
                        on: { back: _vm.goBack },
                      }),
                      _c("h3", [_vm._v("题库简介")]),
                      _c("p", { staticClass: "intro" }, [
                        _vm._v(" " + _vm._s(_vm.repoData.title)),
                      ]),
                      _c("el-divider"),
                      _vm.repoData.isBuy
                        ? _c(
                            "div",
                            [
                              _c("h3", [_vm._v("自由练习")]),
                              _c("div", { staticClass: "practice-pattern" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "pattern",
                                    on: {
                                      click: function ($event) {
                                        return _vm.startTrain("repo_sort")
                                      },
                                    },
                                  },
                                  [
                                    _c("i", { staticClass: "repo_sort" }),
                                    _vm._v("顺序练习 "),
                                  ]
                                ),
                                _c(
                                  "a",
                                  {
                                    staticClass: "pattern",
                                    on: {
                                      click: function ($event) {
                                        return _vm.startTrain("repo_rand")
                                      },
                                    },
                                  },
                                  [
                                    _c("i", { staticClass: "repo_rand" }),
                                    _vm._v("随机练习 "),
                                  ]
                                ),
                                _c(
                                  "a",
                                  {
                                    staticClass: "pattern",
                                    on: {
                                      click: function ($event) {
                                        return _vm.startTrain("repo_rem")
                                      },
                                    },
                                  },
                                  [
                                    _c("i", { staticClass: "repo_rem" }),
                                    _vm._v("背题模式 "),
                                  ]
                                ),
                              ]),
                              _c("el-divider"),
                              _c("h3", [_vm._v("题型练习")]),
                              _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "practice-pattern" },
                                  _vm._l(
                                    _vm.repoData.typeList,
                                    function (item) {
                                      return _c(
                                        "a",
                                        {
                                          key: item.id,
                                          staticClass: "pattern",
                                          on: {
                                            click: function ($event) {
                                              return _vm.startTrain(
                                                "repo_qt_" + item.quType
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            class: "repo_qt_" + item.quType,
                                          }),
                                          _vm._v(
                                            _vm._s(item.quType_dictText) +
                                              "（" +
                                              _vm._s(item.count) +
                                              "题） "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]),
                              _c("el-divider"),
                              _c("h3", [_vm._v("巩固练习")]),
                              _c("div", [
                                _c("div", { staticClass: "practice-pattern" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "pattern",
                                      on: {
                                        click: function ($event) {
                                          return _vm.toBook()
                                        },
                                      },
                                    },
                                    [
                                      _c("i", { staticClass: "repo-book" }),
                                      _vm._v(" 错题训练 "),
                                    ]
                                  ),
                                ]),
                              ]),
                            ],
                            1
                          )
                        : _c("div", { staticClass: "pay-box" }, [
                            _c("div", [
                              _vm._v("您需要购买此题库才能继续训练!"),
                            ]),
                            _c("div", { staticClass: "price" }, [
                              _vm._v("￥" + _vm._s(_vm.repoData.price)),
                            ]),
                            _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: { click: _vm.handleBuy },
                                  },
                                  [_vm._v("购买题库")]
                                ),
                              ],
                              1
                            ),
                          ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-card",
                    [
                      _c(
                        "div",
                        { attrs: { slot: "header" }, slot: "header" },
                        [
                          _c(
                            "span",
                            { staticStyle: { "font-weight": "700" } },
                            [_vm._v("最近训练")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticStyle: { float: "right", padding: "3px 0" },
                              attrs: { type: "text" },
                              on: { click: _vm.moreTrain },
                            },
                            [_vm._v("查看更多")]
                          ),
                        ],
                        1
                      ),
                      _vm.latestList.length === 0
                        ? _c("div", [_vm._v(" 您还没有训练记录！ ")])
                        : _vm._e(),
                      _vm._l(_vm.latestList, function (item) {
                        return _c(
                          "el-row",
                          { key: item.id, staticClass: "his-item" },
                          [
                            _c("el-col", { attrs: { span: 12 } }, [
                              _vm._v(_vm._s(item.trainMode_dictText)),
                            ]),
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                item.state === 0
                                  ? _c(
                                      "el-link",
                                      {
                                        attrs: {
                                          type: "primary",
                                          icon: "el-icon-video-play",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.conTrain(
                                              item.repoId,
                                              item.trainMode
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("继续训练")]
                                    )
                                  : _c(
                                      "el-link",
                                      {
                                        attrs: {
                                          icon: "el-icon-video-play",
                                          disabled: "",
                                        },
                                      },
                                      [_vm._v("训练结束")]
                                    ),
                              ],
                              1
                            ),
                            _c("el-col", { attrs: { span: 12 } }, [
                              _vm._v(
                                "进度：" +
                                  _vm._s(item.answerCount) +
                                  "/" +
                                  _vm._s(item.totalCount)
                              ),
                            ]),
                            _c("el-col", { attrs: { span: 12 } }, [
                              _vm._v("正确率：" + _vm._s(item.percent) + "%"),
                            ]),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("repo-buy", {
            attrs: { visible: _vm.payVisible, "repo-id": _vm.repoId },
            on: {
              "update:visible": function ($event) {
                _vm.payVisible = $event
              },
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }