var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        title: "新建问答",
        width: "60%",
        top: "5vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.handleClose,
      },
    },
    [
      _c(
        "div",
        { staticStyle: { "padding-top": "20px" } },
        [
          _c(
            "el-form",
            {
              ref: "postForm",
              attrs: {
                model: _vm.postForm,
                rules: _vm.rules,
                "label-width": "120px",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "所属课件", prop: "fileId" } },
                [
                  _c("course-ref-select", {
                    staticStyle: { width: "100%" },
                    attrs: { "course-id": _vm.courseId },
                    model: {
                      value: _vm.postForm.fileId,
                      callback: function ($$v) {
                        _vm.$set(_vm.postForm, "fileId", $$v)
                      },
                      expression: "postForm.fileId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "提问内容", prop: "content" } },
                [
                  _c("Tinymce", {
                    ref: "editor",
                    attrs: { height: 200 },
                    model: {
                      value: _vm.postForm.content,
                      callback: function ($$v) {
                        _vm.$set(_vm.postForm, "content", $$v)
                      },
                      expression: "postForm.content",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleSave } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }