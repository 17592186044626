var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "destroy-on-close": true,
        "show-close": false,
        title: "考试申请",
        width: "540px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "postForm",
          attrs: {
            model: _vm.postForm,
            rules: _vm.rules,
            "label-position": "left",
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "申请理由", prop: "applyMsg" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  rows: "5",
                  placeholder: "请详细说明情况，描述越详细越容易通过申请哦！",
                },
                model: {
                  value: _vm.postForm.applyMsg,
                  callback: function ($$v) {
                    _vm.$set(_vm.postForm, "applyMsg", $$v)
                  },
                  expression: "postForm.applyMsg",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            "font-size": "12px",
            color: "#ff0000",
            "line-height": "22px",
          },
        },
        [
          _vm._v(
            " 温馨提示：用户摄像头异常或考试密码丢失的情况，管理端审核通过后，当次考试无需人脸识别和密码即可进入考试。 "
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { type: "danger" }, on: { click: _vm.handleClose } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { loading: _vm.loading, type: "primary" },
              on: { click: _vm.handleApply },
            },
            [_vm._v("确 认")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }