var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("web-table", {
    ref: "pagingTable",
    attrs: { options: _vm.options, "list-query": _vm.listQuery },
    scopedSlots: _vm._u([
      {
        key: "filter-content",
        fn: function () {
          return [
            _c("el-date-picker", {
              staticClass: "filter-item",
              attrs: {
                type: "datetimerange",
                "value-format": "yyyy-MM-dd HH:mm:ss",
                "range-separator": "至",
                "start-placeholder": "开始日期",
                "end-placeholder": "结束日期",
              },
              model: {
                value: _vm.listQuery.params.dateRange,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery.params, "dateRange", $$v)
                },
                expression: "listQuery.params.dateRange",
              },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "data-columns",
        fn: function () {
          return [
            _c("el-table-column", {
              attrs: {
                label: "考试时间",
                prop: "title",
                width: "200px",
                "show-overflow-tooltip": "",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("detail-link", {
                        attrs: {
                          id: scope.row.id,
                          title: scope.row.createTime,
                          to: "ShowExam",
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "考试用时", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [_vm._v(" " + _vm._s(scope.row.userTime) + "分钟 ")]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "主观分", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.resultType === 1 || !scope.row.valid
                        ? _c("div", [_vm._v(" -- ")])
                        : _c("div", [
                            _vm._v(" " + _vm._s(scope.row.userSubjScore) + " "),
                          ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "客观分", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.resultType === 1 || !scope.row.valid
                        ? _c("div", [_vm._v(" -- ")])
                        : _c("div", [
                            _vm._v(" " + _vm._s(scope.row.userObjScore) + " "),
                          ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "总得分", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.resultType === 1 || !scope.row.valid
                        ? _c("div", [_vm._v(" -- ")])
                        : _c("div", [
                            _vm._v(" " + _vm._s(scope.row.userScore) + " "),
                          ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "及格分", prop: "qualifyScore", align: "center" },
            }),
            _c("el-table-column", {
              attrs: {
                label: "阅卷状态",
                align: "center",
                prop: "reviewState_dictText",
              },
            }),
            _c("el-table-column", {
              attrs: { label: "是否通过", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      !scope.row.valid
                        ? _c(
                            "div",
                            [
                              _c("el-tag", { attrs: { type: "info" } }, [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.exMsg || "成绩无效") +
                                    " "
                                ),
                              ]),
                            ],
                            1
                          )
                        : scope.row.resultType === 1
                        ? _c(
                            "div",
                            [
                              _c("el-tag", { attrs: { type: "warning" } }, [
                                _vm._v(" 不显示 "),
                              ]),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            [
                              scope.row.reviewState === 0
                                ? _c("el-tag", [_vm._v(" 待阅卷 ")])
                                : scope.row.passed
                                ? _c("el-tag", { attrs: { type: "success" } }, [
                                    _vm._v(" 通过 "),
                                  ])
                                : _c("el-tag", { attrs: { type: "danger" } }, [
                                    _vm._v(" 未通过 "),
                                  ]),
                            ],
                            1
                          ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "操作", fixed: "right", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.state === 0
                        ? _c(
                            "el-link",
                            {
                              attrs: { size: "mini", type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.continueExam(scope.row.id)
                                },
                              },
                            },
                            [_vm._v("继续考试")]
                          )
                        : _c(
                            "el-link",
                            {
                              attrs: { size: "mini", type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.showExam(scope.row.id)
                                },
                              },
                            },
                            [_vm._v("试卷详情")]
                          ),
                    ]
                  },
                },
              ]),
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }