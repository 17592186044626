<template>

  <div>

    <div style="margin-bottom: 10px">
      <el-alert v-if="postForm && postForm.id && postForm.state===0" type="warning">实名认证审核中，审核通过后才能进行人脸登录！</el-alert>
      <el-alert v-if="postForm && postForm.id && postForm.state===1" type="success">实名认证通过，您可以使用人脸进行登录！</el-alert>
      <el-alert v-if="postForm && postForm.id && postForm.state===2" type="error">实名认证未通过：{{ postForm.auditMsg }}</el-alert>
    </div>

    <el-form ref="postForm" :model="postForm" :rules="rules" label-width="100px" label-position="left">

      <el-form-item label="真实姓名" prop="realName">
        <el-input v-model="postForm.realName" size="small" />
      </el-form-item>

      <el-form-item label="人脸照片" prop="face">
        <file-upload
          v-model="postForm.face"
          :limit="1"
          :compress="3000"
          list-type="picture-card"
          tips="用于人脸登录，需要保证正脸位于图片中央，图片质量会影响识别率"
          accept=".jpg,.png,.jpeg" />
      </el-form-item>

      <el-form-item label="身份证号" prop="idCard">
        <el-input v-model="postForm.idCard" size="small" />
      </el-form-item>

      <el-form-item>
        <div v-if="postForm && postForm.state===1" style="font-size: 12px; color: #888">
          *修改认证需要重新审核，通过之前，旧的人脸依然可以登录！
        </div>
        <el-button :loading="loading" type="primary" @click="handleSubmit">
          {{ postForm && postForm.id?'修改认证':'提交认证' }}
        </el-button>
      </el-form-item>

    </el-form>

  </div>

</template>

<script>
import { detail, submit } from '@/api/sys/user/real'
import { isIdCard } from '@/utils/validate'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      postForm: {
        realName: '',
        face: '',
        idCard: ''
      },
      loading: false,
      rules: {
        realName: [
          { required: true, message: '真实姓名不能为空！' }
        ],
        face: [
          { required: true, message: '人脸照片必须上传！' }
        ],
        idCard: [
          { required: true, validator: isIdCard }
        ]
      }
    }
  },
  computed: {
    ...mapGetters([
      'token',
      'siteData'
    ])
  },

  created() {
    this.fetchDetail()
  },
  methods: {

    // 查找详情
    fetchDetail() {
      detail().then(res => {
        this.postForm = res.data
      })
    },

    // 提交审核
    handleSubmit() {
      this.$refs.postForm.validate().then((valid) => {
        if (!valid) {
          return
        }
        this.confirmSubmit()
      })
    },
    confirmSubmit() {
      // 删除
      this.$confirm('为了提高通过率，请确认姓名、身份证号、人脸照片相匹配，确定提交吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true
        submit(this.postForm).then(() => {
          this.loading = false
          this.$notify({
            title: '成功',
            message: '实名认证提交成功！',
            type: 'success',
            duration: 2000
          })

          this.fetchDetail()
        }).catch(() => {
          this.loading = false
        })
      })
    }

  }
}
</script>

<style scoped>

</style>

