var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        title: "选择考试",
        width: "60%",
        top: "5vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.handleClose,
      },
    },
    [
      _c(
        "div",
        [
          _c("data-table", {
            ref: "pagingTable",
            attrs: { options: _vm.options, "list-query": _vm.listQuery },
            on: { "select-changed": _vm.handleSelected },
            scopedSlots: _vm._u([
              {
                key: "filter-content",
                fn: function () {
                  return [
                    _c("el-input", {
                      staticClass: "filter-item",
                      staticStyle: { width: "200px" },
                      attrs: { size: "small", placeholder: "搜索考试名称" },
                      model: {
                        value: _vm.listQuery.params.title,
                        callback: function ($$v) {
                          _vm.$set(_vm.listQuery.params, "title", $$v)
                        },
                        expression: "listQuery.params.title",
                      },
                    }),
                    _c(
                      "el-button",
                      {
                        staticClass: "filter-item",
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.handleConfirm },
                      },
                      [_vm._v(_vm._s(_vm.selectedLabel))]
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "data-columns",
                fn: function () {
                  return [
                    _c("el-table-column", {
                      attrs: {
                        label: "考试名称",
                        align: "center",
                        prop: "title",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "考试类型",
                        align: "center",
                        prop: "examType_dictText",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.offline
                                ? _c(
                                    "span",
                                    { staticStyle: { color: "#ff0000" } },
                                    [_vm._v("线下考试")]
                                  )
                                : _c("span", [
                                    _vm._v(_vm._s(scope.row.examType_dictText)),
                                  ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "考试时间",
                        align: "center",
                        prop: "startTime",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "总分",
                        align: "center",
                        prop: "totalScore",
                      },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }