import { download, post } from '@/utils/request'

/**
 * 审核活动
 * @param data
 * @returns {Promise}
 */
export function activityAudit(data) {
  return post('/api/activity/join/audit', data)
}

/**
 * 查找报名详情
 * @param id
 * @returns {Promise}
 */
export function joinDetail(id) {
  return post('/api/activity/join/detail', { id: id })
}

/**
 * 查找报名详情
 * @param data
 * @returns {Promise}
 */
export function joinUpdate(data) {
  return post('/api/activity/join/update', data)
}

/**
 * 导出
 * @param data
 */
export function exportExcel(data) {
  return download('/api/activity/join/export', data, '报名记录.xlsx')
}
