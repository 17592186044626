var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "q-content" }, [
      _c("div", { staticClass: "num" }, [_vm._v(_vm._s(_vm.postForm.sort))]),
      _vm.postForm.required
        ? _c("div", { staticClass: "req" }, [_vm._v("*")])
        : _vm._e(),
      _c(
        "div",
        {
          domProps: {
            innerHTML: _vm._s(
              _vm.$options.filters.formatRichText(_vm.postForm.content)
            ),
          },
        },
        [_vm._v("题干")]
      ),
    ]),
    _c(
      "div",
      { staticClass: "answer-list" },
      [
        _c("el-input", {
          attrs: { type: "textarea" },
          on: { blur: _vm.answerChange },
          model: {
            value: _vm.postForm.answer,
            callback: function ($$v) {
              _vm.$set(_vm.postForm, "answer", $$v)
            },
            expression: "postForm.answer",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }