<template>

  <div>

    <el-row :gutter="30">

      <el-col :span="18">
        <live-player v-if="current.state===1 && current.liveType==1" v-model="current.flvUrl" />
        <iframe v-if="current.state===1 && current.liveType==2" :src="current.outUrl" style="width: 100%; height: 680px; border: none" />
        <el-empty v-if="current.state===0" description="直播尚未开始，请开播再来奥~" />
        <el-empty v-if="current.state===2" description="直播已经结束喽~" />
      </el-col>

      <el-col :span="6">

        <el-card v-for="live in value" :key="live.id" :class="{'current': live.id===current.id}" class="live-item">

          <div @click="changeLive(live)">
            <div style="font-size: 16px; font-weight: 700">{{ live.title }}</div>
            <div style="font-size: 12px; color: #666">{{ live.intro }}</div>
            <div>开播时间：{{ live.startTime }}</div>
            <div>直播时长：{{ live.duration }}分钟</div>
          </div>

        </el-card>

      </el-col>

    </el-row>

  </div>

</template>

<script>

export default {
  name: 'CourseLive',
  props: {
    value: Array
  },

  data() {
    return {
      current: {}
    }
  },

  watch: {
    value: {
      handler() {
        this.autoChange()
      }
    }
  },
  created() {
    this.autoChange()
  },
  methods: {

    autoChange() {
      for (let i = 0; i < this.value.length; i++) {
        this.current = this.value[i]
      }

      this.current = this.value[0]
    },

    changeLive(live) {
      this.current = live
    }

  }
}
</script>

<style scoped>
.live-item{
  margin-bottom: 10px;
  text-align: left;
  cursor: pointer
}

.current {
  background: #FFD550;
}
</style>
