import { post } from '@/utils/request'

/**
 * 章节列表
 * @param data
 * @returns {*}
 */
export function fetchList(data) {
  return post('/api/repo/chapter/list', data)
}
