var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.source
    ? _c("iframe", {
        staticStyle: {
          border: "none",
          width: "100%",
          "min-height": "520px",
          overflow: "hidden",
        },
        attrs: { src: _vm.baseUrl + "player/live.html?source=" + _vm.source },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }