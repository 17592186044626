<template>

  <div class="result-box">

    <div class="score">
      {{ paperData.score }}<span>分</span>
    </div>

    <div style="display: flex;">
      <div style="width: 50%;  display: flex; align-items: center">
        <i class="el-icon-success" style="color: #1CA035;font-size: 22px; width:100px">{{ paperData.trueCount }}题</i>
      </div>
      <div style="width: 50%; display: flex; align-items: center">
        <i class="el-icon-error" style="color: #ff0000;font-size: 22px; width:100px">{{ paperData.falseCount }}题</i>
      </div>
    </div>

    <div>
      答题结束，感谢您的参与！
    </div>

    <div style="color: #c26a3e;line-height:20px">今日剩余{{ paperData.chance }}次答题机会</div>

    <div>
      <el-button type="danger" @click="showPaper">答题详情</el-button>
      <el-button type="primary" @click="backToBattle">返回竞赛</el-button>
    </div>

  </div>

</template>

<script>
import { apiPaperDetail } from '@/api/battle/paper'

export default {
  components: { },
  data() {
    return {
      paperId: null,
      paperData: {}
    }
  },

  created() {
    this.paperId = this.$route.params.paperId

    apiPaperDetail(this.paperId).then(res => {
      this.paperData = res.data
    })
  },

  methods: {

    backToBattle() {
      this.$router.push({ name: 'WebBattleDetail', params: { id: this.paperData.battleId }})
    },

    showPaper() {
      this.$router.push({ name: 'WebBattlePaper', params: { paperId: this.paperId }})
    }
  }
}
</script>

<style scoped>

.result-box{
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 80px;
  min-height: 50vh;
}

.result-box .score{
  font-size: 60px;
  font-weight: 700;
  color: #0a84ff
}

.result-box .score span{
  font-size: 20px
}

/deep/
.main-bg{
  background: transparent !important;
}

</style>

