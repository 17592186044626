<template>

  <el-dialog
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    :append-to-body="true"
    width="500px"
    @close="handleClose"
  >
    <el-radio-group v-model="mediaType">
      <el-radio :label="1">图片</el-radio>
      <el-radio :label="2">视频</el-radio>
      <el-radio :label="3">音频</el-radio>
      <el-radio :label="4">录音</el-radio>
      <el-radio :label="5">文件</el-radio>
    </el-radio-group>

    <div v-if="dialogVisible" style="padding-top: 20px">
      <recorder
        v-if="mediaType===4"
        ref="record"
        v-model="recordUrl"
        @success="recordBack"/>

      <file-upload
        v-else
        v-model="fileList"
        :accept="accept"
        :tips="tips"
        :limit="100"
        style="width: 100%"
        list-type="file"/>
    </div>
    <div v-if="mediaType!==4 && fileList.length> 0 " style="margin-top: 10px">
      <el-button type="primary" size="mini" style="width: 100%" @click="handleInsert">插入</el-button>
    </div>
  </el-dialog>
</template>

<script>

export default {
  name: 'EditorAttachDialog',
  props: {
    visible: false
  },
  data() {
    return {
      tips: '支持常规图片格式，如png、jpg、gif',
      accept: '.png, .jpeg, .gif, .jpg',
      mediaType: 1,
      fileList: [],
      dialogVisible: false,
      recordUrl: ''
    }
  },
  watch: {
    visible: {
      handler(val) {
        this.dialogVisible = val
      }
    },
    mediaType: {
      handler(val) {
        if (val === 1) {
          this.tips = '支持常规图片格式，如png、jpg、gif'
          this.accept = '.png, .jpeg, .gif, .jpg'
        }

        if (val === 2) {
          this.tips = '仅支持mp4格式视频！'
          this.accept = '.mp4'
        }

        if (val === 3) {
          this.tips = '支持mp3、wav、ogg格式音频！'
          this.accept = '.mp3, .wav, .ogg'
        }

        if (val === 5) {
          this.tips = '支持rar/zip/psd/pdf及office格式文件'
          this.accept = '.rar, .zip, .psd, .pdf, .xls, .xlsx, .doc, .docx, .ppt, .pptx'
        }

        // 置空
        this.fileList = []
        this.recordUrl = ''
      }
    }
  },
  methods: {

    // 关闭窗口
    handleClose() {
      this.$emit('update:visible', false)
    },
    // 插入内容
    handleInsert() {
      if (this.fileList.length > 0) {
        this.$emit('uploaded', { fileList: this.fileList, mediaType: this.mediaType })
        this.dialogVisible = false
        this.fileList = []
      }
      this.handleClose()
    },

    // 录音回调
    recordBack() {
      const data = { mediaType: this.mediaType, fileList: [{ url: this.recordUrl }] }
      this.$emit('uploaded', data)
      this.dialogVisible = false
      this.recordUrl = ''
      this.$refs.record.clearRecord()
    }
  }
}
</script>

<style scoped>

/deep/
.el-upload-dragger{
  width: 460px;
}
</style>
