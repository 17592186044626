var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Tinymce", {
        ref: "editor",
        attrs: { height: 150 },
        model: {
          value: _vm.postForm.content,
          callback: function ($$v) {
            _vm.$set(_vm.postForm, "content", $$v)
          },
          expression: "postForm.content",
        },
      }),
      _c("div", { staticClass: "answer-list" }, [
        _c("div", { staticClass: "rank-text" }, [
          _c("div", [_vm._v(_vm._s(_vm.postForm.answerList[0].content))]),
          _c("div", [
            _vm._v(
              _vm._s(
                _vm.postForm.answerList[_vm.postForm.answerList.length - 1]
                  .content
              )
            ),
          ]),
        ]),
        _c(
          "div",
          {
            staticClass: "style-box",
            class: { "rank-num2": _vm.postForm.rankStyle === "num2" },
          },
          _vm._l(_vm.postForm.answerList.length, function (count) {
            return _c(
              "div",
              {
                key: count,
                staticStyle: {
                  "font-size": "22px",
                  color: "#0A84FF",
                  "font-weight": "700",
                },
              },
              [
                _vm.postForm.rankStyle === "heart"
                  ? _c("svg-icon", { attrs: { "icon-class": "rank-heart" } })
                  : _vm._e(),
                _vm.postForm.rankStyle === "good"
                  ? _c("svg-icon", { attrs: { "icon-class": "rank-good" } })
                  : _vm._e(),
                _vm.postForm.rankStyle === "star"
                  ? _c("svg-icon", { attrs: { "icon-class": "rank-star" } })
                  : _vm._e(),
                _vm.postForm.rankStyle === "circle"
                  ? _c("svg-icon", { attrs: { "icon-class": "rank-circle" } })
                  : _vm._e(),
                _vm.postForm.rankStyle === "num1"
                  ? _c("div", { staticClass: "rank-num1" }, [
                      _vm._v(" " + _vm._s(count) + " "),
                    ])
                  : _vm._e(),
                _vm.postForm.rankStyle === "num2"
                  ? _c("div", [_vm._v(" " + _vm._s(count) + " ")])
                  : _vm._e(),
              ],
              1
            )
          }),
          0
        ),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "align-items": "center",
              "justify-content": "space-between",
            },
          },
          [
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    staticClass: "opt-item",
                    attrs: {
                      size: "mini",
                      type: "primary",
                      plain: "",
                      icon: "el-icon-plus",
                    },
                    on: { click: _vm.showDialog },
                  },
                  [_vm._v("设置选项")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { display: "flex", "align-items": "center" } },
              [
                _c(
                  "el-select",
                  {
                    staticClass: "opt-item",
                    staticStyle: { width: "100px" },
                    attrs: {
                      size: "mini",
                      placeholder: "量表级别",
                      clearable: "",
                    },
                    on: { change: _vm.typeChange },
                    model: {
                      value: _vm.postForm.rankLevel,
                      callback: function ($$v) {
                        _vm.$set(_vm.postForm, "rankLevel", $$v)
                      },
                      expression: "postForm.rankLevel",
                    },
                  },
                  [
                    _vm._l(10, function (count) {
                      return count > 1
                        ? _c("el-option", {
                            key: count,
                            attrs: { label: count + "级量表", value: count },
                          })
                        : _vm._e()
                    }),
                    _c("el-option", {
                      key: 11,
                      attrs: { value: 11, label: "NPS量表" },
                    }),
                  ],
                  2
                ),
                _c(
                  "div",
                  {
                    staticClass: "opt-item",
                    staticStyle: {
                      "font-size": "18px",
                      display: "flex",
                      "align-items": "center",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          color: "#606266",
                          "font-size": "14px",
                          "font-weight": "500",
                        },
                      },
                      [_vm._v("样式：")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "like-item",
                        class: {
                          "like-active": _vm.postForm.rankStyle === "num1",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.likeStyle("num1")
                          },
                        },
                      },
                      [
                        _c("svg-icon", {
                          attrs: { "icon-class": "rank-num1" },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "like-item",
                        class: {
                          "like-active": _vm.postForm.rankStyle === "circle",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.likeStyle("circle")
                          },
                        },
                      },
                      [
                        _c("svg-icon", {
                          attrs: { "icon-class": "rank-circle" },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "like-item",
                        class: {
                          "like-active": _vm.postForm.rankStyle === "num2",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.likeStyle("num2")
                          },
                        },
                      },
                      [
                        _c("svg-icon", {
                          attrs: { "icon-class": "rank-num2" },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "like-item",
                        class: {
                          "like-active": _vm.postForm.rankStyle === "heart",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.likeStyle("heart")
                          },
                        },
                      },
                      [
                        _c("svg-icon", {
                          attrs: { "icon-class": "rank-heart" },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "like-item",
                        class: {
                          "like-active": _vm.postForm.rankStyle === "good",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.likeStyle("good")
                          },
                        },
                      },
                      [
                        _c("svg-icon", {
                          attrs: { "icon-class": "rank-good" },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "like-item",
                        class: {
                          "like-active": _vm.postForm.rankStyle === "star",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.likeStyle("star")
                          },
                        },
                      },
                      [
                        _c("svg-icon", {
                          attrs: { "icon-class": "rank-star" },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "el-checkbox",
                  {
                    staticClass: "opt-item",
                    model: {
                      value: _vm.postForm.required,
                      callback: function ($$v) {
                        _vm.$set(_vm.postForm, "required", $$v)
                      },
                      expression: "postForm.required",
                    },
                  },
                  [_vm._v("必答")]
                ),
              ],
              1
            ),
          ]
        ),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            "destroy-on-close": true,
            "show-close": false,
            title: "选项设置",
            width: "540px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            { attrs: { data: _vm.postForm.answerList } },
            [
              _c("el-table-column", {
                attrs: { label: "选项" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input", {
                          attrs: { size: "mini" },
                          model: {
                            value: scope.row.content,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "content", $$v)
                            },
                            expression: "scope.row.content",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "分值", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input-number", {
                          attrs: { min: 0, max: 999, size: "mini" },
                          model: {
                            value: scope.row.score,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "score", $$v)
                            },
                            expression: "scope.row.score",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { width: "100px", label: "删除" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-button", {
                          attrs: {
                            size: "mini",
                            circle: "",
                            icon: "el-icon-delete",
                            type: "danger",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.removeAnswer(scope.$index)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm.postForm.rankLevel === 11 ||
          _vm.postForm.rankLevel > _vm.postForm.answerList.length
            ? _c(
                "el-button",
                {
                  staticStyle: { width: "100%", "margin-top": "10px" },
                  attrs: {
                    type: "primary",
                    size: "mini",
                    icon: "el-icon-plus",
                  },
                  on: { click: _vm.addAnswer },
                },
                [_vm._v("添加选项")]
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "danger" }, on: { click: _vm.handleClose } },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleApply } },
                [_vm._v("确 认")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }