var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.paperId
    ? _c("qnrtc-screen", {
        ref: "screen",
        attrs: { "paper-id": _vm.paperId, "exam-id": _vm.examId },
        on: { ready: _vm.handleReady, break: _vm.handleBeak },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }