<template>
  <data-grid
    ref="dataGrid"
    :options="options"
    :list-query="listQuery">
    <template slot="filter-content">

      <dic-list-select v-model="listQuery.params.surveyType" dic-code="survey_type" width="200" class="filter-item" title="问卷分类" />
      <dic-list-select v-model="listQuery.params.joinState" dic-code="survey_join_state" width="200" class="filter-item" title="参与状态" />

      <el-input
        v-model="listQuery.params.title"
        class="filter-item"
        placeholder="搜索问卷名称"
        size="small"
        prefix-icon="el-icon-search"
        style="width:200px"
      />

    </template>
    <template v-slot:data-item="{ data }">
      <div>
        <detail-link :id="data.id" :title="data.title" @click="toView(data.id)" />
        <div class="flex-bt">
          <div style="flex-grow: 1">
            <div class="info-item">
              <span class="title">问卷时间：</span>
              {{ data.startTime }} ~ {{ data.endTime }}</div>
            <div class="info-item"><span class="title">问卷分类：</span>{{ data.surveyType_dictText }}</div>
            <div class="info-item">
              <span class="title">答卷情况：</span>
              <span class="warning">已提交{{ data.sampleCount }}份</span>
              <span>&nbsp;/&nbsp;</span>
              <span v-if="data.sampleLimit>0" class="danger">限{{ data.sampleLimit }}份</span>
              <span v-else class="success">无限制</span>
            </div>
          </div>
          <div style="font-size: 14px; font-weight: 700">
            <el-link v-if="data.myJoinCount === 0" type="danger" @click="toView(data.id)">待参与</el-link>
            <el-link v-else type="primary" @click="toJoinRecord(data.id)">
              答卷记录({{ data.myJoinCount }})
            </el-link>
          </div>
        </div>
      </div>
    </template>
  </data-grid>

</template>

<script>

import DicListSelect from '@/components/DicListSelect/index.vue'

export default {
  name: 'MySurveyPage',
  components: { DicListSelect },
  data() {
    return {

      listQuery: {
        current: 1,
        size: 10,
        params: {
          surveyType: null,
          joinState: null,
          title: null
        }
      },

      options: {
        // 列表请求URL
        listUrl: '/api/survey/survey/mine-paging'
      }
    }
  },

  methods: {

    // 刷新页面
    reload() {
      this.$refs.dataGrid.getList()
    },
    toView(id) {
      const routeData = this.$router.resolve({ name: 'SurveyView', query: { surveyId: id }})
      window.open(routeData.href, '_blank')
    },
    toJoinRecord(id) {
      this.$emit('join', id)
    }
  }
}
</script>
