<template>

  <div>


    <data-grid
      ref="dataGrid"
      :options="options"
      :list-query="listQuery"
    >
      <template #filter-content>
        <dic-list-select v-model="listQuery.params.activityType" dic-code="activity_type" title="报名类型" />
        <dic-catalog-tree v-model="listQuery.params.catId" dic-code="activity_catalog" title="搜索分类" class="filter-item"/>
        <el-input v-model="listQuery.params.title" size="small" class="filter-item" style="width: 200px;" placeholder="搜索报名"/>

      </template>

      <template v-slot:data-item="{ data }">
        <div style="display: flex">
          <div style="width: 200px">
            <image-box :src="data.banner" />
          </div>
          <div style="flex-grow: 1">

            <div style="display: flex; align-items: center; padding-left: 10px">
              <div style="flex-grow: 1">
                <detail-link :id="data.id" :title="data.title" to="WebActivityDetail" />
                <el-tag v-if="data.liveCount > 0" style="margin-left: 5px" type="danger" size="mini">直播</el-tag>

                <div style="font-size: 14px; color: #333">
                  报名类型：{{ data.activityType_dictText }}
                </div>
                <div style="font-size: 14px; color: #333">
                  报名时间： {{ data.startTime }} ~ {{ data.endTime }}
                </div>
                <div style="font-size: 14px; color: #333">
                  报名情况：
                  <span class="warning">已报名{{ data.joinCount }}</span>
                  <span>&nbsp;/&nbsp;</span>
                  <span v-if="data.maxJoin>0" class="danger">限{{ data.maxJoin }}人</span>
                  <span v-else class="success">无限制</span>
                </div>

                <div class="tag-box">
                  <el-tag v-if="data.price>0" type="danger" size="mini">￥{{ data.price }}</el-tag>
                  <el-tag v-else type="success" size="mini">免费</el-tag>
                  <el-tag v-if="data.audit" type="warning" size="mini">需审核</el-tag>
                  <el-tag v-else type="success" size="mini">无需审核</el-tag>
                </div>

              </div>
            </div>


          </div>
        </div>
      </template>

    </data-grid>
  </div>

</template>

<script>

import DataGrid from '@/components/DataGrid/index.vue'
import ImageBox from '@/components/ImageBox/index.vue'

export default {
  name: 'OpenActivityPage',
  components: { ImageBox, DataGrid },
  data() {
    return {
      activeTab: '',
      listQuery: {
        current: 1,
        size: 10,
        params: {

        }
      },

      options: {
        lineCount: 2,
        // 列表请求URL
        listUrl: '/api/activity/activity/paging'
      }
    }
  },
  methods: {
    tabClick() {

    }
  }
}
</script>

<style scoped>

.tag-box > .el-tag{
  margin-right: 5px;
}
</style>
