// import parseTime, formatTime and set to filter
export { parseTime, formatTime } from '@/utils'

/**
 * Show plural label if time is plural number
 * @param {number} time
 * @param {string} label
 * @return {string}
 */
function pluralize(time, label) {
  if (time === 1) {
    return time + label
  }
  return time + label + 's'
}

/**
 * @param {number} time
 */
export function timeAgo(time) {
  const between = Date.now() / 1000 - Number(time)
  if (between < 3600) {
    return pluralize(~~(between / 60), ' minute')
  } else if (between < 86400) {
    return pluralize(~~(between / 3600), ' hour')
  } else {
    return pluralize(~~(between / 86400), ' day')
  }
}

/**
 * Number formatting
 * like 10000 => 10k
 * @param {number} num
 * @param {number} digits
 */
export function numberFormatter(num, digits) {
  const si = [
    { value: 1E18, symbol: 'E' },
    { value: 1E15, symbol: 'P' },
    { value: 1E12, symbol: 'T' },
    { value: 1E9, symbol: 'G' },
    { value: 1E6, symbol: 'M' },
    { value: 1E3, symbol: 'k' }
  ]
  for (let i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (num / si[i].value).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol
    }
  }
  return num.toString()
}

/**
 * 10000 => "10,000"
 * @param {number} num
 */
export function toThousandFilter(num) {
  return (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
}

/**
 * Upper case first char
 * @param {String} string
 */
export function uppercaseFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function filedTypeFilter(value) {
  const map = {
    'text': '文本框',
    'textarea': '文本域',
    'radio': '单选框',
    'checkbox': '复选框',
    'image': '图片上传',
    'video': '视频上传',
    'audio': '录音上传'
  }
  return map[value]
}

export function showAsJson(value) {
  return JSON.stringify(value)
}

/**
 * 通用状态过滤器
 * @param value
 * @returns {*}
 */
export function stateFilter(value) {
  const map = {
    '0': '正常',
    '1': '禁用'
  }
  return map[value]
}

/**
 * 通用状态过滤器
 * @param value
 * @returns {*}
 */
export function userStateFilter(value) {
  const map = {
    '0': '正常',
    '1': '禁用',
    '2': '待审核'
  }
  return map[value]
}

/**
 * 是否类型过滤器
 * @param value
 * @returns {*}
 */
export function booleanFilter(value) {
  const map = {
    'true': '是',
    'false': '否',
    '0': '否',
    '1': '是'
  }
  return map[value]
}

/**
 * 百分比过滤器
 * @param value
 * @returns {*}
 */
export function percentFilter(value) {
  return (value * 100) + '%'
}

export function quTypeFilter(value) {
  const map = {
    '1': '单选题',
    '2': '多选题',
    '3': '判断题',
    '4': '简答题',
    '5': '填空题',
    '6': '连线题',
    '26': '量表题',
    '27': '单选评分题',
    '28': '多选评分题',
    '99': '组合题'
  }
  return map[value]
}

export function examResultFilter(value) {
  const map = {
    '1': '合格',
    '0': '不合格'
  }
  return map[value]
}

export function paperStateFilter(value) {
  const map = {
    '0': '考试中',
    '1': '待阅卷',
    '2': '已考完',
    '3': '!已弃考'
  }
  return map[value]
}

export function examOpenType(value) {
  const map = {
    '1': '完全公开',
    '2': '指定部门',
    '3': '指定人员',
    '9': '需要密码'
  }
  return map[value]
}

export function examStateFilter(value) {
  const map = {
    '0': '正常',
    '1': '禁用'
  }
  return map[value]
}

export function quLevelFilter(value) {
  const map = {
    '1': '普通',
    '2': '较难'
  }
  return map[value]
}

export function menuTypeFilter(value) {
  const map = {
    '1': '菜单',
    '2': '功能',
    '3': '路由'
  }
  return map[value]
}

export function cycleTypeFilter(value) {
  if (value === 1) {
    return '每天'
  }
  if (value === 2) {
    return '每周'
  }

  if (value === 3) {
    return '每月'
  }
}

export function clearHtml(value) {
  return value && value.replace(/<(?:.|\n)*?>/gm, '')
    .replace(/(&rdquo;)/g, '\"')
    .replace(/&ldquo;/g, '\"')
    .replace(/&mdash;/g, '-')
    .replace(/&nbsp;/g, '')
    .replace(/&gt;/g, '>')
    .replace(/&lt;/g, '<')
    .replace(/<[\w\s"':=\/]*/, '')
}

/**
 * 处理富文本里的图片宽度自适应
 * 1.去掉img标签里的style、width、height属性
 * 2.img标签添加style属性：max-width:100%;height:auto
 * 3.修改所有style里的width属性为max-width:100%
 * 4.去掉<br/>标签
 * @param html
 * @returns {void|string|*}
 */
export function formatRichText(html) { // 控制小程序中图片大小
  if (!html) {
    return ''
  }

  // 修正显示样式
  return html.replace(/<td[^>]*>/gi, function(match) {
    return match.replace(/style="[^"]+"/gi, 'style="border: 0.5pt solid #000000;"').replace(/width="[^"]+"/gi, '').replace(/height="[^"]+"/gi, '')
  }).replace(/<col[^>]*>/gi, function(match) {
    return match.replace(/style="[^"]+"/gi, '').replace(/width="[^"]+"/gi, '')
  }).replace(/<img[^>]*>/gi, function(match) {
    return match.replace(/style="[^"]+"/gi, 'style="max-width:100%;height:auto;display:inline-block;margin:10rpx auto;"').replace(/width="[^"]+"/gi, '').replace(/height="[^"]+"/gi, '')
  })
}
