<template>
  <div>

    <el-form ref="postForm" :model="postForm" :rules="rules" label-position="left" label-width="150px">


      <h3>存储配置</h3>
      <div style="margin-bottom: 10px; margin-top: -10px">
        <el-alert type="error" title="请务必提前确定好服务商，投入使用后切换服务商无法自动迁移文件，并且可能造成部分服务异常，请谨慎操作！"/>
      </div>
      <el-form-item label="方案类型" prop="provider">
        <el-radio-group v-model="postForm.provider" size="small">
          <el-radio label="local">本地存储</el-radio>
          <el-radio label="aliyun">阿里云OSS</el-radio>
          <el-radio label="qcloud">腾讯云</el-radio>
          <el-radio label="qiniu">七牛云</el-radio>
        </el-radio-group>
      </el-form-item>

      <div v-if="postForm.provider==='local'" >
        <el-form-item label="本地目录" prop="data.localDir">
          <el-input v-model="postForm.data.localDir" size="small" placeholder="本地目录地址，如：/data/upload/ 或 D:/data/file/" />
        </el-form-item>

        <el-form-item label="访问URL" prop="data.url">
          <el-input v-model="postForm.data.url" size="small" placeholder="以https://或http://开头的本地接口域名或CDN加速域名，以/结尾">
            <template slot="append">upload/file/</template>
          </el-input>
        </el-form-item>
      </div>


      <div v-if="postForm.provider==='aliyun'">

        <div style="margin-bottom: 10px; margin-top: -20px">
          <el-alert type="error" title="必须使用RAM账号，账号拥有AliyunSTSAssumeRoleAccess、AliyunMTSFullAccess、AliyunIMMFullAccess权限，文档：https://ram.console.aliyun.com/roles"/>
        </div>

        <el-form-item label="EndPoint" prop="data.endpoint">
          <dic-list-select v-model="postForm.data.endpoint" style="width: 100%" dic-code="aliyun_upload_endpoins"/>
        </el-form-item>

        <el-form-item label="Bucket" prop="data.bucket">
          <el-input v-model="postForm.data.bucket" size="small" placeholder="阿里云存储容器" />
        </el-form-item>

        <el-form-item label="AccessKeyId" prop="data.accessKeyId">
          <el-input v-model="postForm.data.accessKeyId" size="small" placeholder="授权ID" />
        </el-form-item>

        <el-form-item label="AccessKeySecret" prop="data.accessKeySecret">
          <el-input v-model="postForm.data.accessKeySecret" size="small" placeholder="授权秘钥" />
        </el-form-item>

        <el-form-item label="角色ARN" prop="data.arn">
          <el-input v-model="postForm.data.arn" size="small" placeholder="角色ARN，此角色应拥有写入新文件的权限：AliyunOSSFullAccess或oss:PutObject" />
        </el-form-item>

        <el-form-item label="访问URL" prop="data.url">
          <el-input v-model="postForm.data.url" placeholder="OSS访问域名或CDN加速域名，必须以/结尾，如：https://xxxx.oss-cn-beijing.aliyuncs.com/" />
        </el-form-item>


      </div>

      <div v-if="postForm.provider==='qiniu'">
        <el-form-item label="存储空间位置" prop="data.endpoint">
          <dic-list-select v-model="postForm.data.endpoint" dic-code="qiniu_upload_areas" style="width: 100%"/>
        </el-form-item>

        <el-form-item label="存储空间名称" prop="data.bucket">
          <el-input v-model="postForm.data.bucket" size="small" placeholder="阿里云存储容器" />
        </el-form-item>

        <el-form-item label="AccessKeyId" prop="data.accessKeyId">
          <el-input v-model="postForm.data.accessKeyId" size="small" placeholder="AccessKeyId" />
        </el-form-item>

        <el-form-item label="AccessKeySecret" prop="data.accessKeySecret">
          <el-input v-model="postForm.data.accessKeySecret" size="small" placeholder="AccessKeySecret" />
        </el-form-item>

        <el-form-item label="访问URL" prop="data.url">
          <el-input v-model="postForm.data.url" size="small" placeholder="外链域名，必须以/结尾，如：http://qozlc75sb.hn-bkt.clouddn.com/" />
        </el-form-item>

      </div>

      <div v-if="postForm.provider==='qcloud'">
        <el-form-item label="所属地域" prop="data.region">
          <dic-list-select v-model="postForm.data.region" dic-code="qcloud_upload_endpoints" style="width: 100%"/>
        </el-form-item>

        <el-form-item label="存储桶名称" prop="data.bucket">
          <el-input v-model="postForm.data.bucket" size="small" placeholder="腾讯云存储桶" />
        </el-form-item>

        <el-form-item label="SecretId" prop="data.secretId">
          <el-input v-model="postForm.data.secretId" size="small" placeholder="secretId" />
        </el-form-item>

        <el-form-item label="SecretKey" prop="data.secretKey">
          <el-input v-model="postForm.data.secretKey" size="small" placeholder="secretKey" />
        </el-form-item>

        <el-form-item label="访问URL" prop="data.url">
          <el-input v-model="postForm.data.url" size="small" placeholder="COS访问域名或CDN加速域名，必须以/结尾，如：https://yfhl-1252868612.cos.ap-beijing.myqcloud.com/" />
        </el-form-item>


      </div>


      <div class="module-course">
        <el-divider />
        <h3>视频转码</h3>
        <div v-if="postForm.provider==='local'" >
          <el-form-item label="转码方式">
            <el-radio-group v-model="postForm.data.encodeType" size="small">
              <el-radio label="none">不转码</el-radio>
              <el-radio label="ffmpeg">FFmpeg本地转码</el-radio>
            </el-radio-group>
            <el-alert v-if="postForm.data.encodeType==='none'" type="error" title="支持的视频格式视浏览器而定，一般通用的都支持：mp4、webm 和 ogg，建议上传H264编码的mp4文件；"/>
          </el-form-item>

          <div v-if="postForm.data.encodeType==='ffmpeg'" >
            <el-form-item label="FFmpeg路径">
              <el-input v-model="postForm.data.ffmpegHome" size="small" placeholder="FFmpeg安装目录，如：/opt/homebrew/bin/ffmpeg" />
              <div><el-link type="primary" href="https://ffmpeg.org/download.html" target="_blank">必须在服务器安装ffmpeg才能使用转码,下载地址为：https://ffmpeg.org/download.html</el-link></div>
            </el-form-item>
            <el-form-item label="转码方式">
              <el-radio-group v-model="postForm.data.videoEncode" size="small">
                <el-radio label="mp4">MP4</el-radio>
                <el-radio label="m3u8">HLS(M3U8)</el-radio>
                <el-radio label="m3u8_encode">HLS(M3U8)加密</el-radio>
              </el-radio-group>
              <el-alert v-if="postForm.data.videoEncode==='mp4'" type="error" title="转换为H264编码的mp4格式，兼容性最好，网页小程序都能流畅播放；"/>
              <el-alert v-if="postForm.data.videoEncode==='m3u8'" type="error" title="对文件进行切片，转换为m3u8格式，可以一定程度防止下载；"/>
              <el-alert v-if="postForm.data.videoEncode==='m3u8_encode'" type="error" title="对文件进行切片，并转换为AES-128加密的m3u8格式，安全性最高；注意：小程序和IOS设备无法播放，要兼容请勿选择此方案"/>
            </el-form-item>

            <div v-if="postForm.data.videoEncode==='m3u8_encode'">
              <el-form-item label="视频解密地址">
                <el-input v-model="postForm.data.decodeUrl"/>
                <el-alert type="info" title="应为：/api/course/file/decrypt 或 https://您的域名/api/course/file/decrypt"/>
              </el-form-item>
              <el-form-item label="视频加密文件">
                <el-input v-model="postForm.data.encodeKey"/>
                <el-alert type="info" title="使用命令【openssl rand 16 > enc.key】命令生成一个文件，上传到服务器，并填写此地址，如：/upload/enc.key"/>
              </el-form-item>
              <el-form-item label="视频加密秘钥">
                <el-input v-model="postForm.data.encodeIv"/>
                <el-alert type="info" title="加密随机秘钥，使用命令【openssl rand -hex 16】生成"/>
              </el-form-item>
              <el-form-item label="播放解密AES-KEY">
                <el-input v-model="postForm.data.aesKey" placeholder="16位解密KEY，前端播放器使用"/>
                <el-alert type="info" title="16位AES解密KEY，前端播放器使用"/>
              </el-form-item>
              <el-form-item label="播放解密AES-IV">
                <el-input v-model="postForm.data.aesIv" />
                <el-alert type="info" title="16位AES-解密IV，前端播放器使用"/>
              </el-form-item>
            </div>
            <el-form-item label="转码偏好">
              <el-radio-group v-model="postForm.data.videoQuality" size="small">
                <el-radio label="original">与原视频一致</el-radio>
                <el-radio label="480p">标清(854x480)</el-radio>
                <el-radio label="720p">高清(1280x720)</el-radio>
                <el-radio label="1080p">全高清(1920x1080)</el-radio>
                <el-radio label="2k">2K(2048x1152)</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
        </div>

        <div v-if="postForm.provider==='aliyun' || postForm.provider==='qcloud' || postForm.provider==='qiniu'">
          <el-form-item label="转码方式">
            <el-radio-group v-model="postForm.data.encodeType" size="small">
              <el-radio label="none">不转码</el-radio>
              <el-radio label="online">云转码</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="postForm.data.encodeType==='online'" label="转码方式">
            <el-radio-group v-model="postForm.data.videoEncode" size="small">
              <el-radio label="mp4">MP4</el-radio>
              <el-radio label="m3u8">HLS(M3U8)</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>

        <div v-if="postForm.provider==='aliyun'">
          <el-form-item v-if="postForm.data.encodeType==='online'" label="转码样式">
            <el-radio-group v-model="postForm.data.videoQuality" size="small">
              <el-radio label="original">与原视频一致</el-radio>
              <el-radio label="480p">标清(854x480)</el-radio>
              <el-radio label="720p">高清(1280x720)</el-radio>
              <el-radio label="1080p">全高清(1920x1080)</el-radio>
              <el-radio label="2k">2K(2048x1152)</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>

        <div v-if="postForm.provider==='qcloud'">
          <el-form-item label="媒体处理列队ID">
            <el-input v-model="postForm.data.mediaQueue" size="small" placeholder="用于视频转换的媒体处理列队ID" />
          </el-form-item>

          <el-form-item v-if="postForm.data.encodeType==='online'" label="转码样式">
            <el-radio-group v-model="postForm.data.videoQuality" size="small">
              <el-radio label="480p">标清(720*等比例高)</el-radio>
              <el-radio label="720p">高清(1280*等比例高)</el-radio>
              <el-radio label="1080p">全高清(1920*等比例高)</el-radio>
              <el-radio label="2k">2K(2048*等比例高)</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>

        <div v-if="postForm.provider==='qiniu'">
          <el-form-item v-if="postForm.data.encodeType==='online'" label="转码样式">
            <el-radio-group v-model="postForm.data.videoQuality" size="small">
              <el-radio label="original">与原视频一致</el-radio>
              <el-radio label="480p">标清(854x480)</el-radio>
              <el-radio label="720p">高清(1280x720)</el-radio>
              <el-radio label="1080p">全高清(1920x1080)</el-radio>
              <el-radio label="2k">2K(2048x1152)</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>


      </div>


      <el-divider />

      <el-row style="margin-top: 20px">
        <el-button :loading="loading" icon="el-icon-refresh" type="danger" size="small" @click="changProvider">使用此服务商</el-button>
        <el-button :loading="loading" icon="el-icon-folder" type="primary" size="small" @click="submitForm">保存配置</el-button>
      </el-row>

    </el-form>

  </div>
</template>

<script>
import { fetchDetail, saveData, change } from '@/api/sys/config/prop'
import { isUrl, isDirectory } from '@/utils/validate'

export default {
  name: 'UploadConfig',
  components: { },
  data() {
    return {
      postForm: {
        type: 'upload',
        provider: 'local',
        data: {
          encodeType: 'none',
          encodeUrl: '/api/course/file/desc'
        },
        remark: ''
      },
      loading: false,

      rules: {
        // 本地存储
        'provider': [{ required: true, message: '服务商必须选择' }],
        'data.localDir': [{ validator: isDirectory, trigger: 'blur' }],
        'data.url': [{ validator: isUrl, trigger: 'blur' }],
        'data.endpoint': [{ required: true, message: '存储节点不能为空' }],
        'data.bucket': [{ required: true, message: '存储空间不能为空' }],
        'data.arn': [{ required: true, message: '角色ARN必须填写' }],
        'data.accessKeyId': [{ required: true, message: 'AccessKeyId不能为空' }],
        'data.accessKeySecret': [{ required: true, message: 'AccessKeySecret不能为空' }],
        'data.secretId': [{ required: true, message: 'SecretId不能为空' }],
        'data.secretKey': [{ required: true, message: 'SecretKey不能为空' }],
        'data.region': [{ required: true, message: '存储节点不能为空' }]
      }
    }
  },

  watch: {
    'postForm.provider': {
      handler(val) {
        this.fetchData(val)
      }
    }
  },
  created() {
    this.fetchData('')
  },
  methods: {

    fetchData(provider) {
      fetchDetail('upload', provider).then(res => {
        // 清空数据
        this.postForm = { type: 'upload', provider: provider, data: {}}

        // 赋值
        if (res.data && res.data.provider) {
          this.postForm = res.data
        }

        // 进行校验一下
        if (this.$refs.postForm) {
          this.$nextTick(() => {
            this.$refs.postForm.validate()
          })
        }
      })
    },
    submitForm() {
      this.$refs.postForm.validate((valid) => {
        if (!valid) {
          return
        }

        // 参数转换
        this.loading = true

        saveData(this.postForm).then(() => {
          this.$notify({
            title: '成功',
            message: '上传配置保存成功！',
            type: 'success',
            duration: 2000
          })
        })
        this.loading = false
      })
    },

    changProvider() {
      if (!this.postForm.type || !this.postForm.provider) {
        this.$message.warning('服务商必须选择！')
        return
      }

      // 参数转换
      this.loading = true

      change(this.postForm).then(() => {
        this.$notify({
          title: '成功',
          message: '服务商切换成功！',
          type: 'success',
          duration: 2000
        })

        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>
::v-deep .el-alert{
  padding: 5px;
  margin-top: 10px;
  line-height: 18px;
  font-size: 12px;
}


::v-deep .el-alert__title{
  font-size: 12px;
}
</style>
