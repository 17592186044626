<template>

  <div :id="quData.id" :class="{'sub-box': sub}" class="qu-box">

    <div class="qu-item">
      <div v-if="showNum" :class="sub?'sub':'num'">{{ quData.sort || (no+1) || index }}</div>
      <div class="content" style="display: flex; flex-wrap: wrap">
        <span v-if="showType" style="color: #0A84FF; line-height:24px">【{{ quData.quType_dictText }}】</span>
        <span v-html="$options.filters.formatRichText(quData.content)" />
        <span v-if="showScore" style="color: #FF9800">【{{ quData.score }}分】</span>
      </div>
    </div>

    <!-- 单选多选判断 -->
    <div v-if="quData.quType==='1' || quData.quType==='2' || quData.quType==='3' || quData.quType==='5'" class="qu-answer">
      <div
        v-for="item in quData.answerList"
        :key="item.id"
        class="item">
        <div class="tag">{{ item.abc || item.tag }}</div>
        <div v-if="showAnswer || quData.quType!=='5'" class="content">
          <div>{{ item.content }}</div>
          <div v-if="item.image">
            <img :src="item.image" style="max-width: 40vw" alt="" >
          </div>
        </div>
        <div v-if="item.isRight && showAnswer" class="right">
          <i class="el-icon-success" style="color: #1aac1a" />
          答案
        </div>
      </div>
    </div>

    <div v-if="quData.quType==='4' && showAnswer" >

      <div v-if="quData.answerList && quData.answerList.length > 0" class="qu-answer">
        <div class="show-t">给分关键词：</div>
        <div
          v-for="item in quData.answerList"
          :key="item.id"
          class="item">
          <div class="content">
            <div>{{ item.content }}</div>
          </div>
          <div class="right" style="width: 120px">
            给分比例：{{ item.scoreRate }}%
          </div>
        </div>
      </div>

      <div style="line-height: 30px">
        <span class="show-t">人工批阅：</span>
        <span v-if="quData.manual" style="color: #ff3333">是，交卷后需阅卷老师手动批改</span>
        <span v-else style="color: #1aac1a">否，交卷后自动根据关键字判分并累加成绩</span>
      </div>
    </div>

    <div v-if="quData.quType==='5' && quData.answerOrder" >

      <div style="line-height: 30px; color: #ff3333">
        此题需严格按顺序作答才可得分！
      </div>
    </div>

    <!-- 得分选项，组卷模式无需展示 -->
    <div v-if="showAnalysis && ((mode!==3 && quData.quType!=='99') || quData.analysis)" class="as-box">
      <div v-if="mode!==3 && quData.quType!=='99'">
        <!-- 简答题/组合题无对错显示 -->
        <div v-if="quData.quType!=='4' && quData.quType!=='99'">
          <div class="right-line">
            <i :class="quData.isRight?'right el-icon-success':'error el-icon-error'" />
            <span v-if="quData.isRight">答对了</span>
            <span v-else>答错了</span>
          </div>
        </div>

        <div>
          学员得分：
          <span v-if="quData.quType==='4' && reviewState===0">
            待阅卷后显示得分
          </span>
          <span v-else>
            {{ quData.actualScore }} <span v-if="!quData.isRight && quData.actualScore>0 && quData.actualScore!==quData.score">（部分得分）</span>
          </span>
        </div>

        <!-- 单选多选判断题 -->
        <div v-if="quData.quType==='1' || quData.quType==='2' || quData.quType==='3'">
          学员答案：
          <span>{{ userAnswer(quData.answerList) }}</span>
        </div>

        <!-- 简答题 -->
        <div v-if="quData.quType==='4'">
          学员答案：<span class="asp" v-html="highlight(quData.answer, quData.answerList)" />
        </div>

        <div v-if="quData.media!=null && quData.media!==''">
          <audio :src="quData.media" controls />
        </div>

        <!-- 填空题 -->
        <div v-if="quData.quType==='5'">
          学员答案：
          <template v-for="a in quData.answerList">
            <div v-if="a.answer" :key="a.id">{{ a.answer }}</div>
          </template>
        </div>
      </div>

      <div v-if="quData.analysis">
        <div v-if="sub">
          题目解析：
        </div>
        <div v-else>
          大题解析：
        </div>
        <div class="asp" v-html="$options.filters.formatRichText(quData.analysis)" />
      </div>

    </div>

  </div>
</template>

<script>

export default {
  name: 'QuItemShow',
  components: { },
  props: {
    value: Object,
    index: Number,
    reviewState: Number,
    // 视图模式，1学员视图，2批阅视图，3试卷视图
    mode: Number,
    showAnalysis: {
      type: Boolean,
      default: true
    },
    showAnswer: {
      type: Boolean,
      default: true
    },
    showType: {
      type: Boolean,
      default: false
    },
    showNum: {
      type: Boolean,
      default: true
    },
    showScore: {
      type: Boolean,
      default: false
    },
    sub: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      quData: {},
      no: 1
    }
  },

  watch: {

    value: {
      handler(val) {
        this.quData = val
      },
      deep: true
    },
    index: {
      handler(val) {
        this.no = val
      }
    }
  },

  created() {
    this.quData = this.value
    this.no = this.index
  },

  methods: {

    // 判断是否回答
    userAnswer(answerList) {
      let answer = ''

      for (let i = 0; i < answerList.length; i++) {
        if (answerList[i].checked) {
          answer += answerList[i].abc
        }
      }

      if (answer === '') {
        answer = '未作答'
      }

      return answer
    },

    // 对答对的关键字进行高亮展示
    highlight(answer, list) {
      if (!answer) {
        return ''
      }
      let result = this.$options.filters.formatRichText(answer)
      if (!list || list.length === 0) {
        return result
      }
      for (let i = 0; i<list.length; i++) {
        const content = list[i].content
        // 替换代码实现高亮
        result = this.highlightText(result, content)
      }

      return result
    },

    /**
     * 对HTML片段进行高亮处理
     * @param htmlFragment
     * @param targetText
     * @returns {*}
     */
    highlightText(htmlFragment, targetText) {
      // 创建一个DOMParser实例
      const parser = new DOMParser()
      // 将HTML片段解析为DOM Document
      const doc = parser.parseFromString('<div>' + htmlFragment + '</div>', 'text/html')

      // 创建一个TreeWalker来遍历DOM树
      const walker = document.createTreeWalker(
        doc.body.firstChild, // 开始遍历的节点（假设为div）
        NodeFilter.SHOW_TEXT, // 只显示文本节点
        { acceptNode: function(node) { return NodeFilter.FILTER_ACCEPT } }, // 过滤函数
        false // 不展开实体引用
      )

      let currentNode
      // eslint-disable-next-line no-cond-assign
      while (currentNode = walker.nextNode()) {
        // 查找并替换文本中的目标文本
        const text = currentNode.nodeValue
        const newText = text.replace(new RegExp(targetText, 'g'), `<span class="highlight">${targetText}</span>`)

        const parent = currentNode.parentNode
        const fragment = document.createDocumentFragment()
        const tempDiv = document.createElement('div')
        tempDiv.innerHTML = newText

        // 将临时div中的节点移动到fragment中
        while (tempDiv.firstChild) {
          fragment.appendChild(tempDiv.firstChild)
        }
        // 清除父节点中的旧文本节点
        parent.replaceChild(fragment, currentNode)
      }
      // 获取修改后的HTML片段（不包括我们添加的额外div）
      return doc.body.firstChild.innerHTML
    }
  }
}
</script>

<style scoped>

/deep/
.asp p{
  margin-block-start: 0;
  margin-block-end: 0.5em;
  word-wrap:break-word
}

.right-line{
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 10px;
}

.right-line .right{
  color: #03DD6D; font-size: 22px; margin-right: 10px
}

.right-line .error{
  color: #FF4B50; font-size: 22px; margin-right: 10px
}


::v-deep .highlight{
  background:#04D86A;
  color:#333
}

.show-t{
  color: #1890ff;
  font-weight: 700;
  font-size: 14px
}

</style>
