<template>
  <course-qa
    :course-id="courseId"
    :file-id="fileId" />
</template>

<script>

import CourseQa from '@/views/web/course/components/CourseQa/index.vue'

export default {
  components: { CourseQa },
  data() {
    return {
      courseId: null,
      fileId: null
    }
  },
  created() {
    this.courseId = this.$route.query.courseId
  }
}
</script>
