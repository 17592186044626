var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Tinymce", {
        ref: "editor",
        attrs: { height: 200 },
        model: {
          value: _vm.postForm.content,
          callback: function ($$v) {
            _vm.$set(_vm.postForm, "content", $$v)
          },
          expression: "postForm.content",
        },
      }),
      _c("div", { staticClass: "answer-list" }, [
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "align-items": "center",
              "justify-content": "flex-end",
            },
          },
          [
            _c(
              "el-checkbox",
              {
                staticClass: "opt-item",
                model: {
                  value: _vm.postForm.required,
                  callback: function ($$v) {
                    _vm.$set(_vm.postForm, "required", $$v)
                  },
                  expression: "postForm.required",
                },
              },
              [_vm._v("必答")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }