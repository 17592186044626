<template>
  <div>
    <div :id="chartId" style="width: 100%; height: 400px"/>
  </div>
</template>

<script>

import * as echarts from 'echarts'

export default {
  name: 'StatPieChart',
  props: {
    chartKey: {
      type: String,
      default: 'default'
    },
    dataList: Array,
    titleField: {
      type: String,
      default: 'content'
    },
    valueField: {
      type: String,
      default: 'percent'
    }
  },
  data() {
    return {
      chart: null
    }
  },
  computed: {
    // ID
    chartId() {
      return 'pie-chart-' + this.chartKey
    }
  },
  watch: {
    dataList: {
      handler() {
        this.initChart()
      }
    }
  },

  created() {
    this.initChart()
  },
  methods: {

    // 初始化表格
    initChart() {
      if (!this.dataList || this.dataList.length === 0) {
        return
      }
      const chatData = []
      for (let i = 0; i<this.dataList.length; i++) {
        const item = this.dataList[i]
        const data = { name: item[this.titleField], value: item[this.valueField] }
        chatData.push(data)
      }

      const option = {
        tooltip: {
          trigger: 'item',
          // 模板变量有 {a}、{b}、{c}、{d}，分别表示系列名，数据名，数据值，百分比。
          formatter: '{b}({c}%)'
        },
        legend: {
          orient: 'vertical',
          left: 'right'
        },
        series: [
          {
            type: 'pie', // type设置为饼图
            data: chatData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            label: {
              normal: {
                show: true,
                formatter: '{b}: {c}%'
              }
            }
          }

        ]
      }

      console.log('++++++++++++data', chatData)
      console.log('++++++++++++this.chartId', this.chartId)

      this.$nextTick(() => {
        this.chart = echarts.init(document.getElementById(this.chartId))
        this.chart.setOption(option)
      }, 1000)
    }
  }
}
</script>

<style scoped>
.pass {
  color: #1aac1a
}

.fail {
  color: #ff0000;
}

</style>
