<template>

  <div>

    <data-grid
      ref="dataGrid"
      :options="options"
      :list-query="listQuery">
      <template slot="filter-content">
        <el-input
          v-model="listQuery.params.title"
          class="filter-item"
          placeholder="搜索考试名称"
          size="small"
          prefix-icon="el-icon-search"
          style="width:200px"
        />

        <el-date-picker
          v-model="listQuery.params.dateRange"
          class="filter-item"
          size="small"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        />
      </template>
      <template v-slot:data-item="{ data }">
        <div>
          <detail-link :id="data.id" :title="data.title" to="CheckExam" />


          <div style="display: flex">
            <div style="flex-grow: 1">
              <div class="info-item">
                <span class="title">考试时间：</span>{{ data.startTime }} ~ {{ data.endTime }}
              </div>
              <div class="info-item">
                <span class="title">考试机会：</span>{{ data.chance === 0?'不限': data.chance+'次' }}
              </div>
              <div class="info-item">
                <span class="title">考试状态：</span>
                <span v-if="data.stateFlag==='PROCESSING'" class="success">进行中</span>
                <span v-if="data.stateFlag==='NOT_START'" class="warning" disabled>未开始</span>
                <span v-if="data.stateFlag==='OVERDUE'" disabled>已过期</span>
              </div>
            </div>
            <div>
              <div>
                <el-tag v-if="data.price > 0" type="danger" size="mini">￥{{ data.price }}</el-tag>
                <el-tag v-else type="success" size="mini">免费</el-tag>

              </div>

              <div :class="{'fav-btn-off': !data.hasJoin}" class="fav-btn" @click="handleJoin(data)">
                <i class="el-icon-star-on"/>
              </div>

            </div>

          </div>


          <div class="record-box">
            <div>
              <span class="title">时长：</span>
              <span class="info">{{ data.totalTime }} 分钟</span>
            </div>
            <div>
              <span class="title">总分：</span>
              <span class="info">{{ data.totalScore }} 分</span>
            </div>
            <div>
              <span class="title">及格分：</span>
              <span class="info">{{ data.qualifyScore }} 分</span>
            </div>
          </div>
        </div>
      </template>
    </data-grid>
  </div>

</template>

<script>

import { joinExam } from '@/api/client/exam/exam'

export default {
  name: 'OpenExamPage',
  components: { },
  data() {
    return {
      detailData: {},
      listQuery: {
        current: 1,
        size: 10,
        params: {

        }
      },

      options: {
        // 列表请求URL
        listUrl: '/api/client/exam/open-paging',
        lineCount: 2
      }
    }
  },

  created() {

  },
  methods: {
    // 收藏考试
    handleJoin(data) {
      joinExam(data.id).then(() => {
        this.$emit('join')
        data.hasJoin = !data.hasJoin
      })
    }
  }
}
</script>
