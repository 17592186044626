var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "card" },
          on: { "tab-click": _vm.tabClick },
          model: {
            value: _vm.activeTab,
            callback: function ($$v) {
              _vm.activeTab = $$v
            },
            expression: "activeTab",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "问卷中心", name: "my" } },
            [_c("my-survey-page", { on: { join: _vm.toJoin } })],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "答卷记录", name: "join" } },
            [
              _c("survey-join-page", {
                ref: "join",
                attrs: { "survey-id": _vm.surveyId },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }