<template>
  <div :class="theme" class="sv-page">
    <div class="sv-body">
      <div class="sv-title">
        {{ survey.title }}
      </div>
      <div
        v-if="survey.content"
        class="sv-content"
        v-html="$options.filters.formatRichText(survey.content)">
        问卷说明
      </div>
      <div style="margin-top: 20px; text-align: right">
        <el-button :loading="loading" style="width: 30%" type="danger" icon="el-icon-s-promotion" @click="startSurvey">开始答题</el-button>
      </div>
    </div>

  </div>


</template>

<script>

import { detail } from '@/api/survey/survey'
import { create } from '@/api/survey/paper'
import { uuid } from 'vue-uuid'

export default {
  data() {
    return {
      key: 'survey-temporary-id',
      surveyId: '',
      tempId: '',
      survey: {},
      loading: false
    }
  },
  computed: {
    theme() {
      if (!this.survey.id) {
        return ''
      }
      if (!this.survey.theme) {
        return 'sv-theme-default'
      }
      return 'sv-theme-' + this.survey.theme
    }
  },

  created() {
    // 答卷ID
    this.surveyId = this.$route.query.surveyId
    const auto = this.$route.query.auto

    // 客户临时标识
    const tempId = localStorage.getItem(this.key)

    if (!tempId) {
      this.tempId = uuid.v4()
      localStorage.setItem(this.key, this.tempId)
    } else {
      this.tempId = tempId
    }

    if (auto) {
      this.startSurvey()
    } else {
      this.fetchDetail()
    }
  },
  methods: {


    // 获取详情
    fetchDetail() {
      detail(this.surveyId).then(res => {
        this.survey = res.data
      })
    },

    // 开始问卷
    startSurvey() {
      this.loading = true
      create(this.surveyId, this.tempId).then(res => {
        this.$router.push({ name: 'SurveyAnswer', query: { paperId: res.data }})
        this.loading = false
      }).catch(err => {
        if (err.message === '10010002') {
          localStorage.setItem('login-redirect', window.location.href)
        }
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>

.sv-page {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0
}

</style>
