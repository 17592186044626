<template>

  <el-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    :show-close="true"
    title="分享问卷"
    width="30%"
    @close="handleClose"
  >

    <div class="share-box">
      <div class="code-box"><vue-qr :text="codeText" :size="100" :margin="10" /></div>
      <div class="link-box">
        <div>
          <el-input :value="codeText" prefix-icon="el-icon-paperclip"/>
        </div>
        <div>
          <el-button type="primary" @click="copyLink">复制链接</el-button>
          <el-button @click="openLink">打开链接</el-button>
        </div>
      </div>
    </div>


  </el-dialog>

</template>

<script>

import vueQr from 'vue-qr'
import clip from '@/utils/clipboard'

export default {
  name: 'SurveyShareDialog',
  components: { vueQr },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    surveyId: String
  },
  data() {
    return {
      dialogVisible: false,
      codeText: ''
    }
  },

  watch: {
    visible: {
      handler() {
        this.dialogVisible = this.visible
      }
    },
    surveyId: {
      handler() {
        this.buildUrl()
      }
    }
  },

  created() {
    this.buildUrl()
  },

  methods: {

    // 构建URL
    buildUrl() {
      this.codeText = document.location.protocol + '//' + window.location.host + '/pages/survey/view?surveyId=' + this.surveyId
    },

    handleClose() {
      this.$emit('update:visible', false)
    },

    copyLink(e) {
      clip(this.codeText, e)
    },

    openLink() {
      window.open(this.codeText)
    }
  }
}
</script>


<style scoped>
.share-box{
  display: flex;
  align-items: flex-start;
}

.code-box{
  border: #c5c8cf 1px solid;
  height: 100px;
  overflow: hidden;
}
.link-box{
  flex-grow: 1;
  padding-left: 10px;
  line-height: 50px;
  margin-top: -5px
}
</style>
