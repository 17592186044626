<template>

  <el-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    :show-close="true"
    title="创建问卷"
    width="50%"
    @close="handleClose"
  >
    <survey-update-base
      next="design"
      @success="handleSuccess"
      @cancel="handleClose"/>
  </el-dialog>

</template>

<script>

import SurveyUpdateBase from '@/views/admin/survey/components/SurveyUpdateBase/index.vue'

export default {
  name: 'SurveyCreateDialog',
  components: { SurveyUpdateBase },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogVisible: false
    }
  },

  watch: {
    visible: {
      handler() {
        this.dialogVisible = this.visible
      }
    }
  },

  methods: {

    // 创建问卷
    handleSuccess(id) {
      this.$notify({
        title: '提示信息',
        message: '创建成功，即将跳转到问卷设计页面..',
        type: 'success',
        duration: 1500
      })
      setTimeout(() => {
        this.toDesign(id)
      }, 1500)
    },
    handleClose() {
      this.$emit('update:visible', false)
    },
    toDesign(id) {
      this.handleClose()
      this.$router.push({ name: 'SurveyUpdate', query: { id: id, tab: 'design' }})
    }
  }
}
</script>
