var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.paperId
        ? _c("qnrtc-cam", {
            ref: "cam",
            attrs: {
              "paper-id": _vm.paperId,
              "exam-id": _vm.examId,
              tracking: true,
              "track-capture": false,
              "show-track-tips": false,
            },
            on: {
              ready: _vm.handleReady,
              break: _vm.handleBeak,
              "face-out": _vm.faceOut,
              connected: _vm.startTimer,
              capture: _vm.saveCapture,
              "m-join": _vm.mobileJoin,
              "m-left": _vm.mobileLeft,
            },
          })
        : _vm._e(),
      _c("iframe", {
        ref: "screenFrame",
        staticStyle: { display: "none" },
        attrs: { src: "/pages/exam/screen/" + _vm.examId + "/" + _vm.paperId },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            "close-on-press-escape": true,
            "close-on-click-modal": false,
            "destroy-on-close": true,
            "append-to-body": true,
            "show-close": false,
            width: "660px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", [
            _c("div", { staticStyle: { display: "flex" } }, [
              _c(
                "div",
                { staticStyle: { "text-align": "center" } },
                [
                  _vm.camUrl
                    ? _c("vue-qr", {
                        attrs: { size: 180, margin: 0, text: _vm.camUrl },
                      })
                    : _vm._e(),
                  _c(
                    "div",
                    { staticStyle: { color: "#0A84FF", "font-size": "12px" } },
                    [_vm._v(_vm._s(_vm.qrText))]
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "alert-box" }, [
                _c("div", { staticStyle: { "font-size": "14px" } }, [
                  _vm._v("确保以下设备正常后方可开始考试"),
                ]),
                _c("div", [
                  _vm.cameraReady
                    ? _c("i", {
                        staticClass: "el-icon-check",
                        staticStyle: { color: "#1aac1a" },
                      })
                    : _c("i", {
                        staticClass: "el-icon-close",
                        staticStyle: { color: "#ff3333" },
                      }),
                  _vm._v(" 主摄：确保电脑摄像头设备正常，并允许网页使用。"),
                ]),
                _c("div", [
                  _vm.mobileReady
                    ? _c("i", {
                        staticClass: "el-icon-check",
                        staticStyle: { color: "#1aac1a" },
                      })
                    : _c("i", {
                        staticClass: "el-icon-close",
                        staticStyle: { color: "#ff3333" },
                      }),
                  _vm._v(
                    " 副摄：用手机扫描右侧二维码，保持手机持续亮屏状态。 "
                  ),
                ]),
                _c("div", [
                  _vm.screenReady
                    ? _c("i", {
                        staticClass: "el-icon-check",
                        staticStyle: { color: "#1aac1a" },
                      })
                    : _c("i", {
                        staticClass: "el-icon-close",
                        staticStyle: { color: "#ff3333" },
                      }),
                  _vm._v(
                    " 录屏：点击[开始考试]弹出录屏选项，选择[整个屏幕]并允许。"
                  ),
                ]),
                _c(
                  "div",
                  { staticStyle: { "margin-top": "10px" } },
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { type: "danger" },
                        on: { click: _vm.startScreen },
                      },
                      [_vm._v("开始考试")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }