<template>

  <div>
    <div style="margin-bottom: 10px; display: flex; align-items: center">
      <el-input
        v-model="filterText"
        style="flex-grow: 1"
        size="small"
        placeholder="输入关键字进行过滤"/>
      <el-button
        style="margin-left: 10px"
        icon="el-icon-refresh-left"
        size="mini"
        circle
        @click="clearSelection"/>
    </div>

    <el-tree
      ref="tree"
      :data="treeData"
      :close-on-click-modal="false"
      :filter-node-method="filterNode"
      :props="defaultProps"
      default-expand-all
      node-key="id"
      highlight-current
      @node-click="departSelected"
    />
  </div>

</template>

<script>
import { fetchTree } from '@/api/sys/depart/depart'

export default {
  name: 'DepartTreeShow',
  data() {
    return {
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'deptName'
      },
      filterText: ''
    }
  },

  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    }
  },

  created() {
    // 获取部门树结构
    fetchTree().then(res => {
      this.treeData = res.data
    })
  },

  methods: {

    filterNode(value, data) {
      if (!value) return true
      return data.deptName.indexOf(value) !== -1
    },

    // 清理
    clearSelection() {
      this.filterText = ''
      // 移除高亮效果
      const dom = document.querySelector('.is-current')
      if (dom) {
        dom.className = 'el-tree-node is-expanded is-focusable'
      }
      this.$emit('clear')
    },

    // 选定
    departSelected(data) {
      this.$emit('select', { deptCode: data.deptCode, id: data.id })
    }
  }
}
</script>
