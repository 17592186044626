<template>

  <div>

    <div style="display: flex; align-items: center; margin-bottom: 10px">
      <div class="time" style="flex-grow: 1; text-align: left">已用时：{{ useTime }}</div>
      <div>
        <el-button type="danger" @click="handleHand">提交答题</el-button>
      </div>
    </div>

    <qu-item-exam v-model="quData" :mark="false" @fill="handleFill" />

    <div class="footer">

      <el-col :span="12">
        <el-button :disabled="current===0" icon="el-icon-back" @click="handlePrev">上一题</el-button>
      </el-col>
      <el-col v-if="current < indexList.length-1" :span="12" style="text-align: right">
        <el-button type="primary" @click="handleNext">下一题<i class="el-icon-right el-icon--right" /></el-button>
      </el-col>

    </div>

  </div>

</template>

<script>

import { apiQuIndex, apiQuDetail, apiFillAnswer, apiHandExam, apiPaperDetail } from '@/api/battle/paper'

export default {
  components: { },
  data() {
    return {
      useTime: '00:00',
      loading: false,
      paperId: '',
      current: 0,
      indexList: [],
      quData: {},
      paperData: {}
    }
  },

  created() {
    this.paperId = this.$route.params.paperId

    // 查找索引
    apiQuIndex(this.paperId).then(res => {
      this.indexList = res.data
      this.fetchQuDetail()
    })

    apiPaperDetail(this.paperId).then(res => {
      this.paperData = res.data
      // 计算时间
      setInterval(this.calcUseTime, 1000)
    })
  },

  methods: {

    // 转换时间
    parseTime(date) {
      date = date.substring(0, 19)
      date = date.replace(/-/g, '/')
      return new Date(date).getTime()
    },

    calcUseTime() {
      const now = new Date().getTime()
      const that = this.parseTime(this.paperData.createTime)
      const diff = (now - that) / 1000
      const minutes = parseInt(diff % 3600 / 60)
      const seconds = parseInt(diff % 60)
      this.useTime = minutes + '分' + seconds + '秒'
    },

    handleNext() {
      if (this.current > this.indexList.length) {
        return
      }

      this.current += 1
      this.fetchQuDetail()
    },

    handlePrev() {
      if (this.current === 0) {
        return
      }

      this.current -= 1
      this.fetchQuDetail()
    },

    handleFill(data) {
      this.loading = true
      apiFillAnswer(data).then(() => {
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },

    handleHand() {
      this.$confirm('确实要提交答题吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true
        apiHandExam(this.paperId).then(() => {
          this.$router.push({ name: 'WebBattleResult', params: { paperId: this.paperId }})
          this.loading = false
        })
      }).catch(() => {
        this.loading = false
      })
    },

    fetchQuDetail() {
      const quId = this.indexList[this.current]
      apiQuDetail(this.paperId, quId).then(res => {
        this.quData = res.data
      })
    }
  }
}
</script>

<style scoped>
/deep/
.mark{
  display: none;
}

.time{
  color: #ff0000;
  font-size: 16px;
  text-align: right;
  padding: 10px 0;
  font-weight: 700;
}

</style>
