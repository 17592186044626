import { post } from '@/utils/request'

/**
 * 加入我的课程
 * @param id
 * @returns {Promise}
 */
export function joinCourse(id) {
  return post('/api/client/course/join', { id: id })
}

/**
 * 课程的课件列表
 * @param courseId
 * @param title
 * @returns {Promise}
 */
export function refFileList(courseId, title) {
  return post('/api/client/course/ref-file-list', { courseId, title })
}
