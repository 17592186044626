import { post } from '@/utils/request'

/**
 * 获得进房许可
 * @param data
 * @returns {*}
 */
export function roomToken(data) {
  return post('/api/common/qnrtc/token', data)
}

/**
 * 开始录制视频
 * @param data
 * @returns {*}
 */
export function roomRecord(data) {
  return post('/api/common/qnrtc/record', data)
}
