<template>

  <div style="display: flex; justify-content: space-between;">

    <div ref="content" class="left-content">
      <div v-if="quList.length === 0">
        <el-empty description="该问卷还没有添加题目，请点击右侧的题型开始添加吧！"/>
      </div>
      <div v-for="(item,index) in quList" :key="index">
        <div :id="`qu-${index}`" class="survey-qu-box">
          <div class="survey-qu-title">第{{ index+1 }}题：{{ item.quType | quTypeFilter }}</div>
          <survey-qu-type-select v-if="item.quType==='1' || item.quType==='2' || item.quType==='27' || item.quType==='28' " :survey-id="surveyId" :value.sync="item"/>
          <survey-qu-type-ask v-if="item.quType==='4'" :survey-id="surveyId" :value.sync="item"/>
          <survey-qu-type-rank v-if="item.quType==='26'" :survey-id="surveyId" :value.sync="item"/>
          <div v-if="!item.visible" class="hide-mask"/>
        </div>
      </div>
    </div>


    <div class="right-preview">

      <div style="display: flex; justify-content: space-between">

        <el-dropdown @command="addCommand">
          <el-button type="primary" size="small" style="width:150px">
            添加新题目<i class="el-icon-arrow-down el-icon--right"/>
          </el-button>
          <el-dropdown-menu>
            <el-dropdown-item command="radio">
              <svg-icon icon-class="qu-radio" /> 单选题
            </el-dropdown-item>
            <el-dropdown-item command="multi">
              <svg-icon icon-class="qu-multi" /> 多选题
            </el-dropdown-item>
            <el-dropdown-item command="ask">
              <svg-icon icon-class="qu-ask" /> 简答题
            </el-dropdown-item>
            <el-dropdown-item command="rank">
              <svg-icon icon-class="qu-rank" /> 量表题
            </el-dropdown-item>
            <el-dropdown-item command="radio-score">
              <svg-icon icon-class="qu-radio-score" /> 单选评分
            </el-dropdown-item>
            <el-dropdown-item command="multi-score">
              <svg-icon icon-class="qu-multi-score" /> 多选评分
            </el-dropdown-item>

          </el-dropdown-menu>
        </el-dropdown>

        <el-button style="width: 150px" type="danger" icon="el-icon-upload2" size="small" @click="showImport">批量导入</el-button>
      </div>

      <div class="overview-list">


        <div v-for="(item,index) in quList" :key="`qu-${index}`" class="over-item">

          <div style="display: flex; align-items: center">
            <div style="flex-grow: 1">
              <el-link @click="goAnchor(index)">{{ index +1 }} - {{ item.quType | quTypeFilter }}</el-link>
            </div>
            <div>
              <el-button size="mini" circle @click="markAsHidden(index)">
                <svg-icon :icon-class="!item.visible?'icon-hide':'icon-show'" />
              </el-button>
              <el-button size="mini" type="danger" icon="el-icon-delete" circle @click="removeQu(index)" />
              <el-tooltip effect="dark" content="按住鼠标调整到合适的位置后释放" placement="top-start">
                <el-button class="sort-btn" size="mini" icon="el-icon-rank" circle />
              </el-tooltip>
            </div>
          </div>
          <div class="over-content" v-html="$options.filters.clearHtml(item.content)" />
        </div>

      </div>

    </div>

    <survey-qu-import-dialog :survey-id="surveyId" :visible.sync="importVisible" @success="fetchQuList" />

  </div>

</template>

<script>

import Sortable from 'sortablejs'
import DicListSelect from '@/components/DicListSelect/index.vue'
import SurveyQuTypeSelect from '@/views/admin/survey/components/SurveyQuTypeSelect/index.vue'
import SurveyQuTypeAsk from '@/views/admin/survey/components/SurveyQuTypeAsk/index.vue'
import { detail } from '@/api/survey/survey'
import { listAll, saveAll } from '@/api/survey/qu'
import SurveyQuTypeRank from '@/views/admin/survey/components/SurveyQuTypeRank/index.vue'
import SurveyQuImportDialog from '@/views/admin/survey/components/SurveyQuImportDialog/index.vue'

export default {
  name: 'SurveyDesign',
  components: { SurveyQuImportDialog, SurveyQuTypeRank, SurveyQuTypeAsk, SurveyQuTypeSelect, DicListSelect },
  props: {
    surveyId: String
  },
  data() {
    return {
      detail: {},
      quList: [],
      sortTable: null,
      // 导入
      importVisible: false
    }
  },

  created() {
    this.fetchQuList()
    this.showSurvey()
  },

  methods: {

    // 查找信息
    showSurvey() {
      detail(this.surveyId).then(res => {
        this.detail = res.data
      })
    },

    // 查找全部题目
    fetchQuList() {
      if (!this.surveyId) {
        return
      }

      listAll(this.surveyId).then(res => {
        const list = res.data
        if (list && list.length>0) {
          this.quList = list
        }
        // 初始化排序
        this.initSort()
      })
    },

    // 保存全部题目
    saveQuList() {
      saveAll({ surveyId: this.surveyId, quList: this.quList }).then(() => {
        // 重新查找覆盖
        this.fetchQuList()
      })
    },

    // 初始化表格
    initSort() {
      // 存在即销毁
      if (this.sortTable) {
        this.sortTable.destroy()
      }

      // 拖动表格
      const tbody = document.querySelector('.overview-list')
      const that = this
      this.sortTable = Sortable.create(tbody, {
        handle: '.sort-btn',
        onEnd({ newIndex, oldIndex }) {
          if (newIndex === oldIndex) {
            return
          }
          that.quList.splice(
            newIndex,
            0,
            that.quList.splice(oldIndex, 1)[0]
          )
          const newArray = that.quList.slice(0)
          that.quList = []
          that.$nextTick(() => {
            that.quList = newArray
          })
        }
      })
    },

    // 添加单选题
    addRadio() {
      this.quList.push({ visible: true, quType: '1', answerList: [{ content: '选项1' }, { content: '选项2' }] })
      // 到最后一题
      this.goLast()
    },

    // 添加多选题
    addMulti() {
      this.quList.push({ visible: true, quType: '2', answerList: [{ content: '选项1' }, { content: '选项2' }, { content: '选项3' }] })
      this.goLast()
    },

    // 添加问答题
    addAsk() {
      this.quList.push({ visible: true, quType: '4' })
      // 到最后一题
      this.goLast()
    },

    // 添加量表体
    addRank() {
      this.quList.push({ visible: true, quType: '26' })
      // 到最后一题
      this.goLast()
    },

    // 添加单评分
    addRadioScore() {
      this.quList.push({ visible: true, quType: '27', answerList: [{ content: '选项1' }, { content: '选项2' }] })
      // 到最后一题
      this.goLast()
    },

    // 添加多评分
    addMultiScore() {
      this.quList.push({ visible: true, quType: '28', answerList: [{ content: '选项1' }, { content: '选项2' }, { content: '选项3' }] })
      // 到最后一题
      this.goLast()
    },

    // 标记为隐藏
    markAsHidden(index) {
      const qu = this.quList[index]
      if (qu.visible) {
        this.$confirm('隐藏该题目后，问卷参与者将无法查看到此题目，此题对应的数据为空或默认值', '提示', {
          confirmButtonText: '确定隐藏',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          qu.visible = false
          this.$forceUpdate()
        })
      } else {
        qu.visible = true
        this.$forceUpdate()
      }
    },


    // 到指定位置
    goAnchor(selector) {
      const anchor = document.getElementById('qu-' + selector)
      const to = anchor.offsetTop - 10
      this.$refs.content.scrollTo({
        top: to,
        behavior: 'smooth'
      })
      this.anchor = selector
    },

    // 定位到最后
    goLast() {
      this.$nextTick(() => {
        this.goAnchor(this.quList.length - 1)
      })
    },


    // 移除题目
    removeQu(index) {
      // 删除
      this.$confirm('确实要删除题目吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const newList = []
        for (let i = 0; i<this.quList.length; i++) {
          if (i !== index) {
            newList.push(this.quList[i])
          }
        }

        this.quList = []
        this.$nextTick(() => {
          this.quList = newList
        })
      })
    },

    addCommand(cmd) {
      if (cmd === 'radio') {
        this.addRadio()
      }
      if (cmd === 'multi') {
        this.addMulti()
      }
      if (cmd === 'ask') {
        this.addAsk()
      }
      if (cmd === 'rank') {
        this.addRank()
      }
      if (cmd === 'radio-score') {
        this.addRadioScore()
      }
      if (cmd === 'multi-score') {
        this.addMultiScore()
      }
    },

    showImport() {
      this.importVisible = true
    }
  }
}
</script>

<style scoped>
.right-preview {
  width: 360px;
  border: #e0dfd5 1px solid;
  height: calc(100vh - 160px);
  border-radius: 5px;
  overflow-y: hidden;
  padding: 20px;
  margin-left: 20px;
}

.left-content{
  flex-grow: 1;
  flex-shrink: 1;
  border-radius: 5px;
  border: #e0dfd5 1px solid;
  width: calc(100% - 380px);
  padding: 10px;
  height: calc(100vh - 160px);
  overflow-y: auto;
}

.survey-qu-title{
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 10px;
  color: #0A84FF
}

.survey-qu-box{
  padding: 10px 10px 30px 10px;
  margin-bottom: 20px;
  width: 100%;
  border-bottom: #ccc 3px dotted;
  position: relative;
}
.hide-mask{
  z-index: 2000;
  cursor: not-allowed;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.5;
}

.overview-list{
  margin-top: 20px;
  height: calc(100vh - 240px);
  overflow-y: auto;
}

.over-item{
  border-top: #e0dfd5 1px solid;
  padding: 10px 5px;
  color: #333;
  font-size: 14px;
}

.over-item:last-child{
  border-bottom: #e0dfd5 1px solid;
}

.over-item:hover{
  background: #f6f6f6;
}


.over-content{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-all;
  color: #888;
  font-size: 12px;
  padding-top: 5px;
}

</style>
