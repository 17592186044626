<template>
  <qnrtc-screen
    v-if="paperId"
    ref="screen"
    :paper-id="paperId"
    :exam-id="examId"
    @ready="handleReady"
    @break="handleBeak"/>
</template>

<script>
import QnrtcScreen from '@/components/QnrtcScreen/index.vue'

export default {
  components: { QnrtcScreen },
  data() {
    return {
      paperId: '',
      examId: ''
    }
  },
  created() {
    this.examId = this.$route.params.examId
    this.paperId = this.$route.params.paperId
  },

  mounted() {
    const that = this

    // 自动启动
    try {
      this.$refs.screen.startScreen()
    } catch (err) {
      console.log('自动启动失败，使用消息启动..')
    }

    // 监听消息
    window.addEventListener('message', function() {
      that.$refs.screen.startScreen()
    })
  },

  methods: {
    handleReady() {
      parent.screenMsg('ready')
    },
    handleBeak() {
      parent.screenMsg('break')
    }
  }
}
</script>

<style scoped>

</style>
