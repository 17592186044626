<template>

  <div>

    <el-dialog
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :append-to-body="true"
      title="修改报名"
      width="50%"
      @close="handleClose">

      <div v-if="dialogVisible">

        <el-alert
          v-if="auditMsg"
          :title="auditMsg"
          style="margin-bottom: 20px"
          type="error"/>

        <el-form ref="postForm" :model="postForm" :rules="rules" label-position="left" label-width="100px">
          <el-form-item v-for="field in fieldList" :key="field.fieldName" :label="field.fieldTitle" :prop="field.fieldName">

            <div v-if="field.fieldType==='text'">
              <el-input v-model="postForm[field.fieldName]" size="small" maxlength="2000" show-word-limit />
            </div>

            <div v-if="field.fieldType==='textarea'">
              <el-input v-model="postForm[field.fieldName]" type="textarea" size="small" maxlength="2000" show-word-limit />
            </div>

            <div v-if="field.fieldType==='radio'">
              <div v-if="field.fieldItems.length >= 3">
                <el-select v-model="postForm[field.fieldName]" :placeholder="field.tips || '请选择'">
                  <el-option
                    v-for="item in field.fieldItems"
                    :key="item.value"
                    :label="item.title"
                    :value="item.value"/>
                </el-select>
              </div>
              <div v-else>
                <el-radio-group v-model="postForm[field.fieldName]">
                  <el-radio v-for="item in field.fieldItems" :key="item.value" :label="item.value">{{ item.title }}</el-radio>
                </el-radio-group>
              </div>
            </div>

            <div v-if="field.fieldType==='checkbox'">
              <div v-if="field.fieldItems.length >= 3">
                <el-select v-model="postForm[field.fieldName]" :placeholder="field.tips || '请选择'" multiple>
                  <el-option
                    v-for="item in field.fieldItems"
                    :key="item.value"
                    :label="item.title"
                    :value="item.value"/>
                </el-select>
              </div>
              <div v-else>
                <el-checkbox-group v-model="postForm[field.fieldName]">
                  <el-checkbox v-for="item in field.fieldItems" :key="item.value" :label="item.value">{{ item.title }}</el-checkbox>
                </el-checkbox-group>
              </div>
            </div>

            <div v-if="field.fieldType==='image'">

              <file-upload
                v-model="postForm[field.fieldName]"
                :limit="1"
                :compress="500"
                :tips="field.tips"
                list-type="picture-card"
                accept=".jpg,.jpeg,.png"/>
            </div>

            <div v-if="field.fieldType==='audio'">
              <recorder
                ref="record"
                :tips="field.tips"
                v-model="postForm[field.fieldName]"/>
            </div>

            <div v-if="field.fieldType==='video'">
              <file-upload
                v-model="postForm[field.fieldName]"
                :limit="1"
                :tips="field.tips"
                list-type="file"
                accept=".mp4"/>
            </div>

          </el-form-item>
        </el-form>

      </div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button :loading="loading" type="primary" @click="handleSave">确 定</el-button>
      </div>

    </el-dialog>

  </div>

</template>

<script>
import { joinDetail, joinUpdate } from '@/api/activity/join'

export default {
  name: 'JoinUpdateDialog',
  components: { },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    joinId: {
      type: String,
      default: null
    },
    msg: {
      type: String,
      default: '报名修改成功！'
    }
  },
  data() {
    return {
      loading: false,
      dialogVisible: false,
      rules: {},
      fieldList: [],
      auditMsg: '',
      postForm: {

      },
      payVisible: false
    }
  },

  watch: {

    // 检测查询变化
    visible: {
      handler() {
        this.dialogVisible = this.visible
      },
      deep: true
    },
    // 检测查询变化
    joinId: {
      handler() {
        this.fetchDetail()
      },
      deep: true
    }
  },

  created() {
    this.dialogVisible = this.visible
    if (this.joinId) {
      this.fetchDetail()
    }
  },

  methods: {

    // 获得详情
    fetchDetail() {
      joinDetail(this.joinId).then(res => {
        const data = res.data
        this.parseActivity(data.dataList)
        if (data.state === 3) {
          this.auditMsg = data.auditMsg
        }
      })
    },

    // 构建表单
    parseActivity(fieldList) {
      const list = []
      for (let i = 0; i<fieldList.length; i++) {
        // 进行深拷贝，断开引用
        const field = JSON.parse(JSON.stringify(fieldList[i]))

        // 初始化参数
        this.$set(this.postForm, field.fieldName, field.fieldValue)

        // 增加非空校验
        if (field.required) {
          this.rules[field.fieldName] = [{ required: true, message: `${field.fieldTitle}不能为空！` }]
        }

        if (field.fieldType === 'checkbox') {
          const defaults = JSON.parse(field.fieldValue)
          this.$set(this.postForm, field.fieldName, defaults)
        }

        if (field.fieldType === 'radio') {
          const defaults = parseInt(field.fieldValue)
          this.$set(this.postForm, field.fieldName, defaults)
        }

        list.push(field)
      }

      this.fieldList = list
    },

    // 关闭选择框
    handleClose() {
      this.$emit('update:visible', false)
    },

    // 提交报名
    handleSave() {
      console.log(this.postForm)

      this.$refs.postForm.validate((valid) => {
        if (!valid) {
          return
        }

        const params = {
          joinId: this.joinId,
          joinData: this.postForm
        }

        // 参与竞赛
        joinUpdate(params).then(() => {
          this.$message.success(this.msg)
          setTimeout(() => {
            window.location.reload()
          }, 1500)
        })
      })
    }

  }
}
</script>
