<template>

  <div>

    <el-tabs v-model="listQuery.params.state">
      <el-tab-pane label="所有订单" name="null"/>
      <el-tab-pane label="待付款" name="0"/>
      <el-tab-pane label="待发货" name="1"/>
      <el-tab-pane label="待收货" name="2"/>
      <el-tab-pane label="已完成" name="4"/>
      <el-tab-pane label="已取消" name="100"/>
    </el-tabs>

    <data-grid
      ref="dataGrid"
      :options="options"
      :list-query="listQuery"
    >

      <template #filter-content>
        <el-input v-model="listQuery.params.title" size="small" class="filter-item" style="width: 300px;" placeholder="搜索商品名称"/>
      </template>

      <template v-slot:data-item="{ data }">

        <div class="order-box">
          <div class="top">
            <div style="flex-grow: 1;">{{ data.createTime }}
              <el-link type="primary" @click="showDetail(data.id)">订单号：{{ data.id }}</el-link>
            </div>
            <div style="justify-content: flex-end">
              <el-link v-if="data.state === 0" :disabled="!data.thirdNumber" type="primary" @click="toPay(data.thirdNumber)">继续支付</el-link>
            </div>
          </div>
          <div class="goods">
            <div class="item">
              <div v-for="item in data.goodsList" :key="item.id" style="display: flex">
                <img :src="item.goodsImage" style="width: 80px; height: 80px" >
                <div style="flex-grow: 1; display: flex">
                  <div style="flex-grow: 1; padding-left: 10px; line-height: 22px;">
                    <div style="font-weight: 700">{{ item.goodsTitle }}</div>
                    <div style="color: #888;">{{ item.skuTitle }}</div>
                    <div style="color: #e67e23; font-weight: 700">
                      <price-show :cost-points="item.totalPoints" :sale-price="item.totalAmount"/>
                    </div>
                  </div>

                  <div style="width: 100px; text-align: center;">
                    *{{ item.totalCount }}
                  </div>
                </div>
              </div>
            </div>
            <div class="price">
              <price-show :cost-points="data.totalPoints" :sale-price="data.totalAmount"/>
            </div>
            <div class="states">
              <div style="text-align: right">
                <div>{{ data.state_dictText }}</div>
                <div v-if="data.state===2">
                  <el-link type="primary" @click="showExpress(data.id)">发货信息</el-link>
                </div>
                <div v-if="data.state===2">
                  <el-link type="primary" @click="handleConfirm(data.id)">确认收货</el-link>
                </div>
                <div v-if="data.state===0">
                  <el-link type="danger" @click="handleCancel(data.id)">取消订单</el-link>
                </div>
                <div v-if="data.state===100">
                  <el-link type="danger" @click="handleDelete(data.id)">删除订单</el-link>
                </div>

              </div>

            </div>
          </div>
        </div>

      </template>

    </data-grid>

    <!-- 支付弹窗 -->
    <pay-dialog
      v-if="payVisible"
      :visible.sync="payVisible"
      :order-id="payId"
      @close="payVisible = false"
      @success="payBack" />

    <!-- 快递查询 -->
    <express-dialog
      v-if="expressVisible"
      :visible.sync="expressVisible"
      :order-id="orderId"/>

    <!-- 订单详情 -->
    <mall-order-detail-dialog
      v-if="detailVisible"
      :visible.sync="detailVisible"
      :order-id="orderId"/>


  </div>

</template>

<script>

import ExpressDialog from '@/views/admin/mall/order/components/ExpressDialog.vue'
import { confirmOrder, cancelOrder, deleteOrder } from '@/api/mall/order'
import MallOrderDetailDialog from '@/views/web/ucenter/components/MallOrderDetailDialog/index.vue'

export default {
  name: 'MallOrderPage',
  components: { MallOrderDetailDialog, ExpressDialog },
  props: {
    state: {
      type: String,
      default: null
    }
  },
  data() {
    return {

      listQuery: {
        current: 1,
        size: 10,
        params: {
          state: ''
        }
      },

      options: {
        lineCount: 1,
        // 列表请求URL
        listUrl: '/api/mall/order/paging'
      },

      // 继续支付
      payVisible: false,
      orderId: '',
      payId: '',

      // 快递查找
      expressVisible: false,
      detailVisible: false
    }
  },
  watch: {
    'listQuery.params.state': {
      handler(val) {
        if (val) {
          // 保存状态
          localStorage.setItem('uc:order:active', val)
        }
      },
      deep: true
    },
    '$route.query.state': {
      handler(val) {
        this.autoState(val)
      },
      deep: true
    }
  },
  created() {
    const state = this.$route.query.state

    // 优先使用url参数
    if (this.autoState(state)) {
      return
    }
    const cache = localStorage.getItem('uc:order:active')
    if (cache && cache !== 'undefined') {
      this.listQuery.params.state = cache
    }
  },
  methods: {

    // 切换状态
    autoState(state) {
      if (state) {
        this.listQuery.params.state = state
        return true
      }
      return false
    },

    toPay(id) {
      this.payId = id
      this.payVisible = true
    },

    showExpress(id) {
      this.orderId = id
      this.expressVisible = true
    },

    handleConfirm(id) {
      this.$confirm('确认已经收到货了吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        confirmOrder(id).then(() => {
          this.$message.success('操作成功！')
          this.$refs.dataGrid.getList()
        })
      })
    },

    handleCancel(id) {
      this.$confirm('确认要取消订单吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        cancelOrder(id).then(() => {
          this.$message.success('操作成功！')
          this.$refs.dataGrid.getList()
        })
      })
    },

    handleDelete(id) {
      this.$confirm('是否要彻底删除订单？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteOrder(id).then(() => {
          this.$message.success('操作成功！')
          this.$refs.dataGrid.getList()
        })
      })
    },

    payBack() {
      // 切换到已完成
      this.listQuery.params.state = '1'
      this.payVisible = false
    },

    showDetail(id) {
      this.orderId = id
      this.detailVisible = true
    }
  }
}
</script>

<style scoped>

.order-box{
  font-size: 14px;
}

.order-box .top{
  display: flex;
  width: 100%;
  background: #efefef;
  line-height: 26px;
  padding: 10px;
}

.order-box .goods{
  background: #fff;
  padding: 10px 0;
  display: flex;
}

.order-box .goods .item{
  flex-grow: 1;
  padding-left: 10px
}

.order-box .price{
  width: 200px;
  display: flex;
  justify-content: center
}

.order-box .states{
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
  line-height: 28px;
  font-size: 14px
}
</style>
