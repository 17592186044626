var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "data-grid",
        {
          ref: "dataGrid",
          attrs: { options: _vm.options, "list-query": _vm.listQuery },
          scopedSlots: _vm._u([
            {
              key: "data-item",
              fn: function (ref) {
                var data = ref.data
                return [
                  _c("div", { staticClass: "flex" }, [
                    _c("div", { staticStyle: { "flex-grow": "1" } }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                          },
                        },
                        [
                          data.replied
                            ? _c(
                                "el-tag",
                                { attrs: { type: "success", size: "small" } },
                                [_vm._v("已回复")]
                              )
                            : _c(
                                "el-tag",
                                { attrs: { type: "warning", size: "small" } },
                                [_vm._v("待回复")]
                              ),
                          _c(
                            "div",
                            { staticStyle: { "padding-left": "5px" } },
                            [
                              _c("detail-link", {
                                attrs: { id: data.id, title: data.title },
                                on: {
                                  click: function ($event) {
                                    return _vm.handelView(data.id)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            color: "#6d737b",
                            "font-size": "12px",
                            "font-style": "italic",
                          },
                        },
                        [_vm._v("发表于：" + _vm._s(data.createTime))]
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "font-size": "12px",
                          "font-weight": "700",
                          "line-height": "15px",
                          color: "#0a84ff",
                        },
                      },
                      [
                        _c("yf-avatar", { attrs: { src: data.avatar } }),
                        _c("div", [_vm._v(_vm._s(data.realName))]),
                      ],
                      1
                    ),
                  ]),
                ]
              },
            },
          ]),
        },
        [
          _c("template", { slot: "filter-content" }, [
            _c("div", { staticClass: "flex-bt" }, [
              _c(
                "div",
                [
                  _c("course-ref-select", {
                    staticStyle: { width: "300px" },
                    attrs: { "course-id": _vm.listQuery.params.courseId },
                    model: {
                      value: _vm.listQuery.params.fileId,
                      callback: function ($$v) {
                        _vm.$set(_vm.listQuery.params, "fileId", $$v)
                      },
                      expression: "listQuery.params.fileId",
                    },
                  }),
                  _c("el-input", {
                    staticClass: "filter-item",
                    staticStyle: { width: "200px", "margin-left": "10px" },
                    attrs: {
                      placeholder: "搜索问答",
                      "prefix-icon": "el-icon-search",
                      size: "small",
                    },
                    model: {
                      value: _vm.listQuery.params.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.listQuery.params, "title", $$v)
                      },
                      expression: "listQuery.params.title",
                    },
                  }),
                  _c(
                    "el-checkbox",
                    {
                      staticClass: "filter-item",
                      model: {
                        value: _vm.listQuery.params.onlyReplied,
                        callback: function ($$v) {
                          _vm.$set(_vm.listQuery.params, "onlyReplied", $$v)
                        },
                        expression: "listQuery.params.onlyReplied",
                      },
                    },
                    [_vm._v("只看已回复")]
                  ),
                  _c(
                    "el-checkbox",
                    {
                      staticClass: "filter-item",
                      model: {
                        value: _vm.listQuery.params.onlyMyself,
                        callback: function ($$v) {
                          _vm.$set(_vm.listQuery.params, "onlyMyself", $$v)
                        },
                        expression: "listQuery.params.onlyMyself",
                      },
                    },
                    [_vm._v("仅我发表的")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "el-icon-plus",
                        round: "",
                      },
                      on: { click: _vm.handleNew },
                    },
                    [_vm._v("新建提问")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        2
      ),
      _c("course-qa-publish-dialog", {
        attrs: {
          "course-id": _vm.listQuery.params.courseId,
          "file-id": _vm.listQuery.params.fileId,
          visible: _vm.dialogVisible,
        },
        on: {
          "update:visible": function ($event) {
            _vm.dialogVisible = $event
          },
          back: _vm.reloadGrid,
        },
      }),
      _c("course-qa-detail-dialog", {
        attrs: { visible: _vm.detailVisible, "qa-id": _vm.qaId },
        on: {
          "update:visible": function ($event) {
            _vm.detailVisible = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }