var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "header-badge", on: { click: _vm.toMsgList } },
    [
      _c(
        "el-badge",
        { attrs: { value: _vm.unread, max: 99, hidden: _vm.unread === 0 } },
        [
          _c("svg-icon", {
            attrs: { "class-name": "badge-icon", "icon-class": "msg" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }