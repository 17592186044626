<template>

  <div>

    <data-grid
      ref="dataGrid"
      :options="options"
      :list-query="listQuery">
      <template slot="filter-content">

        <div class="flex-bt">
          <div>
            <course-ref-select
              :course-id="listQuery.params.courseId"
              v-model="listQuery.params.fileId"
              style="width: 300px"
            />
            <el-input
              v-model="listQuery.params.title"
              class="filter-item"
              placeholder="搜索问答"
              prefix-icon="el-icon-search"
              size="small"
              style="width: 200px; margin-left: 10px"
            />

            <el-checkbox v-model="listQuery.params.onlyReplied" class="filter-item">只看已回复</el-checkbox>
            <el-checkbox v-model="listQuery.params.onlyMyself" class="filter-item">仅我发表的</el-checkbox>

          </div>
          <div><el-button type="primary" icon="el-icon-plus" round @click="handleNew">新建提问</el-button></div>
        </div>

      </template>
      <template v-slot:data-item="{ data }">
        <div class="flex">
          <div style="flex-grow: 1;">
            <div style="display: flex; align-items: center">
              <el-tag v-if="data.replied" type="success" size="small">已回复</el-tag>
              <el-tag v-else type="warning" size="small">待回复</el-tag>
              <div style="padding-left: 5px">
                <detail-link :id="data.id" :title="data.title" @click="handelView(data.id)" />
              </div>
            </div>
            <div style="color: #6d737b; font-size: 12px; font-style: italic">发表于：{{ data.createTime }}</div>
          </div>

          <div style="text-align: center; font-size: 12px; font-weight: 700; line-height: 15px; color: #0a84ff">
            <yf-avatar :src="data.avatar"/>
            <div>{{ data.realName }}</div>
          </div>
        </div>
      </template>
    </data-grid>

    <course-qa-publish-dialog
      :course-id="listQuery.params.courseId"
      :file-id="listQuery.params.fileId"
      :visible.sync="dialogVisible"
      @back="reloadGrid"/>

    <course-qa-detail-dialog
      :visible.sync="detailVisible"
      :qa-id="qaId" />

  </div>

</template>

<script>
import CourseRefSelect from '@/components/CourseRefSelect/index.vue'
import CourseQaPublishDialog from '@/views/web/course/components/CourseQaPublishDialog/index.vue'
import CourseQaDetailDialog from '@/views/web/course/components/CourseQaDetailDialog/index.vue'
import YfAvatar from '@/components/YfAvatar/index.vue'

export default {
  name: 'CourseQa',
  components: { YfAvatar, CourseQaDetailDialog, CourseQaPublishDialog, CourseRefSelect },
  props: {
    courseId: String,
    fileId: String
  },

  data() {
    return {
      listQuery: {
        current: 1,
        size: 12,
        params: {
          onlyReplied: false,
          onlyMyself: false,
          courseId: this.courseId,
          fileId: this.fileId
        }
      },
      options: {
        // 列表请求URL
        listUrl: '/api/client/course/qa/paging',
        lineCount: 1
      },
      dialogVisible: false,

      // 详情
      detailVisible: false,
      qaId: ''
    }
  },

  watch: {
    courseId: {
      handler(val) {
        this.listQuery.current = 1
        this.listQuery.params.courseId = val
      }
    },
    fileId: {
      handler(val) {
        this.listQuery.current = 1
        this.listQuery.params.fileId = val
      }
    }
  },
  created() {
    this.listQuery.params.courseId = this.courseId
    this.listQuery.params.fileId = this.fileId
  },
  methods: {

    // 问答详情
    handelView(id) {
      this.qaId = id
      this.detailVisible = true
    },

    // 打开窗口
    handleNew() {
      this.dialogVisible = true
    },

    // 刷新表格
    reloadGrid() {
      this.$refs.dataGrid.getList()
    }
  }
}
</script>

<style scoped>

</style>
