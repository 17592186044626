<template>

  <div v-if="courseData!=null && courseData.id!=null">

    <el-row :gutter="30" style="line-height: 32px; text-align: center">

      <el-col :span="24" style="font-size: 22px; font-weight:700; padding-bottom: 20px ">
        {{ courseData.title }}
      </el-col>

      <el-col :span="24" :xs="24" style="background: #eee">

        <el-row style="color: #666; font-size: 14px; ">
          <el-col :span="8" :xs="12"><i class="el-icon-edit" />类型：{{ courseData.catId_dictText }}</el-col>
          <el-col :span="8" :xs="12"><i class="el-icon-notebook-1" />课时：{{ courseData.periods }}课时</el-col>
          <el-col :span="8" :xs="12"><i class="el-icon-thumb" />发布时间： {{ courseData.createTime }}</el-col>
        </el-row>

      </el-col>

      <el-col v-if="courseData.state === 1">
        <div style="height: 50vh; display: flex; justify-content: center;">
          <el-empty description="课程已禁用，请联系管理员！" />
        </div>
      </el-col>

      <el-col v-else-if="courseData.state === 2">
        <div style="height: 50vh; display: flex; justify-content: center;">
          <el-empty description="课程学习暂未开始，请持续关注！" />
        </div>
      </el-col>

      <el-col v-else-if="courseData.state === 3">
        <div style="height: 50vh; display: flex; justify-content: center;">
          <el-empty description="来晚了，课程学习已截止！" />
        </div>
      </el-col>

      <el-col v-else-if="courseData.state === 4">
        <div style="height: 50vh; display: flex; justify-content: center;">
          <el-empty :description="`当前不在可学习时间段，可学习时间段为：${courseData.dayRule}`" />
        </div>
      </el-col>

      <el-col v-else-if="courseData.state === 5">
        <div style="height: 50vh; display: flex; justify-content: center;">
          <el-empty :description="`每天限制最多学习${courseData.dayLimit}分钟，明天再来吧！`" />
        </div>
      </el-col>

      <el-col v-else-if="courseData.state === 6">
        <div style="height: 50vh; display: flex; justify-content: center;">
          <el-empty description="您不在可学习成员名单里面，请确认！" />
        </div>
      </el-col>

      <el-col v-else-if="courseData.state === 7">
        <div style="height: 50vh; display: flex; justify-content: center;">
          <el-empty description="您不在可学习部门名单里面，请确认！" />
        </div>
      </el-col>

      <el-col v-else-if="!courseData.isBuy">

        <div style="height: 50vh; display: flex; flex-direction: column; justify-content: center; line-height: 50px">
          <div>您需要购买课程才能继续学习!</div>
          <div style="color: #ff0000; font-size: 28px; font-weight: 700">￥{{ courseData.price }}</div>
          <div>
            <el-button type="primary" @click="handleBuy">购买课程</el-button>
          </div>
        </div>
      </el-col>

      <el-col v-else :span="24" style="margin-top: 30px">
        <el-tabs v-model="activeTab">

          <el-tab-pane v-if="courseData.liveList.length > 0" label="直播日程" name="live">
            <course-live v-model="courseData.liveList" />
          </el-tab-pane>

          <el-tab-pane v-if="faceChecked" label="课程学习" name="learn">

            <div v-if="activeTab==='learn'">

              <el-empty
                v-if="courseData.fileCount === 0"
                description="无可学习的课件，请联系管理员添加！"
              />

              <el-row v-else :gutter="30" style="margin-top: 30px">

                <el-col v-if="currentFile && currentFile.id" :span="17">

                  <!-- 视频播放 -->
                  <div v-if="currentFile.fileType==='33'">

                    <file-player
                      :course="courseData"
                      :file="currentFile"
                      @change="changeLearnMin"
                      @finish="handleFinish"
                    />

                  </div>

                  <pdf-frame
                    v-if="currentFile.fileType==='11' || currentFile.fileType==='22'"
                    :course="courseData"
                    :file="currentFile"
                    @change="changeLearnMin"
                    @finish="handleFinish"
                  />
                </el-col>

                <el-col :span="7" style="overflow-y: auto; height: 80vh">

                  <el-button
                    v-if="courseData.finished && courseData.refExam"
                    style="width: 100%; margin-bottom: 20px"
                    type="danger"
                    @click="toExam">进入考试</el-button>

                  <course-cam
                    v-if="courseData.faceCam"
                    :face-interval="courseData.faceInterval"
                    :course-id="courseId"
                    :file-id="currentFile.fileId"
                    @break="checkBreak"
                  />

                  <div>
                    <el-collapse v-model="activeName">
                      <el-collapse-item v-for="dir in courseData.dirList" :key="dir.id" :title="dir.title" :name="dir.id">

                        <div v-for="file in dir.fileList" :key="file.id" @click="changeFile(file)">
                          <el-row :class="{'file-item': true, 'file-item-active': file.fileId === currentFile.fileId, 'file-unlocked': !file.unlocked}">
                            <el-col>{{ file.title }} <span v-if="file.needLearn > 0" style="float: right;color: #666; font-size: 12px">
                              <sec-format :value="file.needLearn"/>
                            </span>
                            </el-col>
                            <el-col v-if="file.unlocked">
                              <el-progress v-if="file.needLearn > 0" :percentage="percent(file)" />
                              <el-progress v-else :percentage="100" />
                            </el-col>

                            <el-col v-else style="display: flex; align-items: center ">
                              <div style="color: #ff0000">
                                <i class="el-icon-lock"/>
                              </div>
                              <div style="color: #888; padding-left: 10px; font-size: 12px">
                                当前课程未解锁
                              </div>

                            </el-col>

                          </el-row>
                        </div>

                      </el-collapse-item>
                    </el-collapse>

                  </div>

                </el-col>
              </el-row>

            </div>

          </el-tab-pane>
          <el-tab-pane label="课程介绍" name="detail">
            <div class="content">
              <div v-html="$options.filters.formatRichText(courseData.content)" />
            </div>

          </el-tab-pane>

          <el-tab-pane :label="`课程问答(${courseData.qaCount})`" name="qa">
            <div class="content">
              <course-qa :course-id="courseData.id" :file-id="currentFile.fileId" :is-mine="false" />
            </div>

          </el-tab-pane>

        </el-tabs>
      </el-col>

    </el-row>

    <!-- 支付弹窗 -->
    <course-buy :visible.sync="payVisible" :course-id="courseId" />

    <!-- 人脸识别 -->
    <face-check-dialog
      :visible.sync="faceVisible"
      :params="{fileId: currentFile.fileId, courseId: courseId}"
      url="/api/course/face/face-check"
      @success="doCheckFace"/>

  </div>


</template>

<script>

import { fetchDetail } from '@/api/course/course'
import { validateFace } from '@/api/course/face'
import CourseLive from './live'
import CourseQa from '@/views/web/course/components/CourseQa/index.vue'

export default {
  components: { CourseQa, CourseLive },
  data() {
    return {
      courseId: '',
      activeTab: 'learn',
      activeName: '',
      readKey: '',
      courseData: {},
      lastRead: '',
      fileList: [],
      currentFile: {
        learnMin: 0,
        fileId: null
      },
      payVisible: false,
      payOrder: null,
      faceVisible: false,
      faceChecked: false,
      keyListener: function(e) {
        if (e.code === 'F12' ||
          e.code === 'MetaLeft' ||
          e.code === 'MetaRight' ||
          e.code === 'AltLeft' ||
          e.code === 'AltRight' ||
          e.code === 'ControlLeft' ||
          e.code === 'ControlRight') {
          // 阻止
          e.stopPropagation()
          e.preventDefault()
        }
      }
    }
  },

  created() {
    // 课程ID
    this.courseId = this.$route.params.courseId
    const fileId = this.$route.params.fileId
    // 读取缓存key
    this.readKey = 'file:read:' + this.courseId
    if (fileId) {
      this.lastRead = fileId
    } else {
      // 记录上次读取的文件
      this.lastRead = localStorage.getItem(this.readKey)
    }

    if (this.courseId !== undefined) {
      this.fetchData()
    }
  },
  methods: {

    percent(item) {
      if (!item.learnSec) {
        return 0
      }

      if (item.learnSec >= item.needLearn) {
        return 100
      }

      return parseInt((item.learnSec * 100 / item.needLearn))
    },

    fetchData() {
      fetchDetail(this.courseId).then(res => {
        this.courseData = res.data

        if (this.courseData.liveList.length > 0) {
          this.activeTab = 'live'
        }
        if (this.courseData.fileCount === 0) {
          return
        }
        const dirList = this.courseData.dirList
        let firstFile = null
        let activeDir = null

        for (let i = 0; i < dirList.length; i++) {
          const fileList = dirList[i].fileList

          if (fileList.length > 0) {
            for (let j = 0; j < fileList.length; j++) {
              if (firstFile == null) {
                firstFile = fileList[j]
              }

              if (activeDir == null) {
                activeDir = dirList[i].id
              }

              if (fileList[j].fileId === this.lastRead) {
                this.changeFile(fileList[j])
                this.activeName = dirList[i].id
              }
            }
          }
        }

        if (this.currentFile.id === null || this.currentFile.id === undefined) {
          this.changeFile(firstFile)
          this.activeName = activeDir
        }

        if (!this.courseData.faceStart || this.courseData.fileCount === 0) {
          this.faceChecked = true
        }
      })
    },

    // 进入考试
    toExam() {
      this.$router.push({ name: 'CheckExam', params: { id: this.courseData.refExam }, query: { courseId: this.courseId }})
    },

    changeFile(data) {
      // 未解锁，不允许学习
      if (data.unlocked != undefined && !data.unlocked) {
        this.$message.error('此课件尚未解锁，请按顺序学习！')
        return
      }

      this.currentFile = data
      // 保存状态
      localStorage.setItem(this.readKey, data.fileId)

      // 检测是否刷脸
      this.doCheckFace()
    },

    handleFinish(json) {
      // 当前课程学完
      if (json.current) {
        this.currentFile.learnSec = this.currentFile.needLearn
      }

      // 全部学完
      if (json.all) {
        this.courseData.finished = true
      }
      // 解锁下一个课件
      if (json != null && json.unlockFile) {
        for (let i = 0; i < this.courseData.dirList.length; i++) {
          const fileList = this.courseData.dirList[i].fileList

          if (fileList.length > 0) {
            for (let j = 0; j < fileList.length; j++) {
              const file = fileList[j]
              if (file.fileId === json.unlockFile) {
                file.unlocked = true
              }
            }
          }
        }
      }
    },

    changeLearnMin(data) {
      this.currentFile.learnSec = data.learnSec
      this.$forceUpdate()
    },

    handleBuy() {
      this.payVisible = true
    },

    // 校验超时
    checkBreak() {
      // 跳转到
      this.$router.push('/pages/course?break')
    },

    // 人脸校验
    doCheckFace() {
      // 非正常状态和未开启不需要人脸识别
      if (this.courseData.state !== 0 || !this.courseData.faceStart) {
        this.faceChecked = true
        return
      }

      validateFace({ courseId: this.courseId, fileId: this.currentFile.fileId }).then(res => {
        this.faceVisible = !res.data
        this.faceChecked = res.data
      })
    }
  }
}
</script>

<style scoped>

.file-item{

  border: #ddd 1px solid;
  text-align: left;
  padding: 10px;
  margin-bottom: 15px;
  cursor: pointer;
}

.file-item-active {

  border: #1890ff 1px solid;
  box-sizing: border-box;
  color: #1890ff;
  font-weight: 700;
}

::v-deep
.content{
  text-align: left;
}

::v-deep
.content img{
  text-align: left;
  max-width: 100%;
}

.file-unlocked{
  border: #ddd 1px solid;
  background: #eee;
}

</style>
