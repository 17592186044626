var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("data-grid", {
    ref: "dataGrid",
    attrs: { options: _vm.options, "list-query": _vm.listQuery },
    scopedSlots: _vm._u([
      {
        key: "filter-content",
        fn: function () {
          return [
            _c("dic-catalog-tree", {
              staticClass: "filter-item",
              attrs: {
                "dic-code": "mall_goods_catalog",
                title: "搜索分类",
                width: "200",
              },
              model: {
                value: _vm.listQuery.params.catId,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery.params, "catId", $$v)
                },
                expression: "listQuery.params.catId",
              },
            }),
            _c("el-input", {
              staticClass: "filter-item",
              staticStyle: { width: "200px", "margin-left": "10px" },
              attrs: { size: "small", placeholder: "搜索商品" },
              model: {
                value: _vm.listQuery.params.q,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery.params, "q", $$v)
                },
                expression: "listQuery.params.q",
              },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "data-item",
        fn: function (ref) {
          var data = ref.data
          return [
            _c(
              "div",
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: { name: "GoodsDetail", params: { id: data.id } },
                    },
                  },
                  [
                    _c("div", { staticClass: "img-box" }, [
                      _c("img", {
                        staticClass: "img-img",
                        attrs: { src: data.image, alt: "" },
                      }),
                    ]),
                    _c("div", { staticClass: "title" }, [
                      _vm._v(" " + _vm._s(data.title) + " "),
                    ]),
                    _c("div", { staticClass: "summary" }, [
                      _vm._v(" " + _vm._s(data.summary) + " "),
                    ]),
                    _c(
                      "div",
                      { staticClass: "price" },
                      [
                        _c("price-show", {
                          attrs: {
                            "sale-price": data.salePrice,
                            "cost-points": data.costPoints,
                          },
                        }),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "12px",
                              color: "#666",
                              "font-style": "italic",
                              "font-weight": "500",
                            },
                          },
                          [_vm._v("已售：" + _vm._s(data.sales))]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }