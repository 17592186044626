var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.fieldType === "video"
        ? _c(
            "div",
            [
              _vm.fieldValue
                ? _c(
                    "el-link",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleShow },
                    },
                    [_vm._v("[视频]")]
                  )
                : _c("div", [_vm._v("[未上传]")]),
            ],
            1
          )
        : _vm.fieldType === "audio"
        ? _c(
            "div",
            [
              _vm.fieldValue
                ? _c(
                    "el-link",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleShow },
                    },
                    [_vm._v("[语音]")]
                  )
                : _c("div", [_vm._v("[未上传]")]),
            ],
            1
          )
        : _vm.fieldType === "image"
        ? _c(
            "div",
            [
              _vm.fieldValue
                ? _c("el-image", {
                    staticStyle: { width: "auto", height: "60px" },
                    attrs: {
                      "preview-src-list": [_vm.fieldValue],
                      src: _vm.fieldValue,
                    },
                  })
                : _c("div", [_vm._v("[未上传]")]),
            ],
            1
          )
        : _c("div", { attrs: { type: "primary" } }, [
            _vm._v(_vm._s(_vm.fieldValue)),
          ]),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            visible: _vm.dialogVisible,
            "append-to-body": "",
            title: "信息预览",
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.handleClose,
          },
        },
        [
          _vm.dialogVisible
            ? _c("div", [
                _vm.fieldType === "video"
                  ? _c(
                      "div",
                      [
                        _c("video-player", {
                          model: {
                            value: _vm.fieldValue,
                            callback: function ($$v) {
                              _vm.fieldValue = $$v
                            },
                            expression: "fieldValue",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.fieldType === "audio"
                  ? _c("div", { staticClass: "audio-box" }, [
                      _c("audio", {
                        attrs: {
                          src: _vm.fieldValue,
                          controls: "controls",
                          controlslist: "nodownload",
                          oncontextmenu: "return false",
                        },
                      }),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }