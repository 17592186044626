<template>

  <div>

    <div v-if="fieldType==='video'">
      <el-link v-if="fieldValue" type="primary" @click="handleShow">[视频]</el-link>
      <div v-else>[未上传]</div>
    </div>

    <div v-else-if="fieldType==='audio'">
      <el-link v-if="fieldValue" type="primary" @click="handleShow">[语音]</el-link>
      <div v-else>[未上传]</div>
    </div>
    <div v-else-if="fieldType==='image'">
      <el-image v-if="fieldValue" :preview-src-list="[fieldValue]" :src="fieldValue" style="width: auto; height: 60px" />
      <div v-else>[未上传]</div>
    </div>
    <div v-else type="primary">{{ fieldValue }}</div>

    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      append-to-body
      title="信息预览"
      width="800px"
      @close="handleClose">

      <div v-if="dialogVisible">
        <div v-if="fieldType==='video'">
          <video-player v-model="fieldValue"/>
        </div>
        <div v-if="fieldType==='audio'" class="audio-box">
          <audio :src="fieldValue" controls="controls" controlslist="nodownload" oncontextmenu="return false"/>
        </div>
      </div>
    </el-dialog>

  </div>

</template>

<script>

export default {
  name: 'JoinFieldViewer',
  components: { },
  props: {
    fieldType: String,
    fieldValue: String
  },
  data() {
    return {
      dialogVisible: false
    }
  },
  created() {

  },

  methods: {

    handleShow() {
      this.dialogVisible = true
    },

    // 获得详情
    handleClose() {
      this.dialogVisible = false
    }

  }
}
</script>

<style scoped>
.audio-box{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px
}
</style>
