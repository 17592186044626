<template>

  <div>
    <div style="display: flex; justify-content: space-between">
      <div style="width:40%;">
        <carousel-show :images="detail.images"/>
      </div>

      <div class="goods-box">
        <div class="title">{{ detail.title }}</div>
        <div class="summary">{{ detail.summary }}</div>
        <div class="sku">{{ sku.title }}</div>
        <div class="price">
          <price-show :cost-points="sku.costPoints" :sale-price="sku.salePrice"/>
        </div>
        <div class="sku-group">

          <div v-if="detail.multiSku">
            <div v-for="item in detail.params" :key="item.id" class="sku-group-item">
              <div class="sku-group-name">{{ item.title }}</div>
              <ul class="sku-group-property">
                <li
                  v-for="vv in item.values"
                  :key="vv.id"
                  :class="{'active': isActive(item.id, vv.id) }"
                  class="sku-group-key"
                  @click="skuSelect(item.id, vv.id)">
                  {{ vv.value }}
                </li>
              </ul>
            </div>
          </div>

          <div style="font-weight: 700">兑换数量</div>
          <div class="num-box">
            <el-input-number :min="1" :value="1" :max="sku.leftStock" style="width: 300px" disabled/>
            <div class="stock">库存：{{ sku.leftStock }}</div>
          </div>

          <div style="margin-top: 20px">
            <el-button :disabled="!sku.id || sku.leftStock<=0" style="width: 300px" type="primary" size="big" @click="checkout">立即购买</el-button>
          </div>
        </div>
      </div>

    </div>

    <el-divider/>
    <div class="goods-detail">
      <div v-html="$options.filters.formatRichText(detail.content)" />
    </div>

  </div>

</template>

<script>

import { fetchShowDetail, mapSku } from '@/api/mall/goods'

export default {
  data() {
    return {
      goodsId: '',
      detail: {},
      leftStock: 0,
      skuId: null,
      sku: {},
      skuMap: new Map()
    }
  },

  created() {
    this.goodsId = this.$route.params.id

    fetchShowDetail(this.goodsId).then(res => {
      this.detail = res.data
      this.sku = res.data.defaultSku
    })
  },
  methods: {

    // 标记SKU
    isActive(key, val) {
      return this.skuMap.has(key) && this.skuMap.get(key) === val
    },
    // 选择规格
    skuSelect(key, val) {
      // 移除
      if (this.skuMap.has(key) && this.skuMap.get(key) === val) {
        this.skuMap.delete(key)
      } else {
        this.skuMap.set(key, val)
      }

      // 映射SKU
      this.getSku()

      // 强制刷新UI
      this.$forceUpdate()
    },

    getSku() {
      const items = []

      this.skuMap.forEach(function(value, key) {
        console.log(value, key)
        const item = {
          paramId: key,
          valueId: value
        }
        items.push(item)
      })

      console.log(items)

      const params = {
        goodsId: this.goodsId,
        items: items
      }

      mapSku(params).then(res => {
        console.log(res)
        if (res.data.id) {
          this.sku = res.data
        } else {
          this.sku = {
            leftStock: 0,
            title: '请选择规格'
          }
        }
      })
    },

    // 去结算
    checkout() {
      this.$router.push({ name: 'MallCheckout', params: { sku: this.sku.id }})
    }
  }
}
</script>

<style scoped>

.goods-box{
  width: 58%;
  line-height: 32px
}

.goods-box .price{
  color: #e67e23;
  font-size: 22px;
  font-weight: 700;
  padding: 20px 0;
  font-family: 'Gill Sans', 'Gill Sans MT', 'Calibri', 'sans-serif';
}

.goods-box .title{
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 32px
}

.goods-box .summary{
  color: #666;
  line-height: 32px;
  font-size: 14px
}

.goods-box .sku{
  line-height: 32px;
  font-size: 18px;
  font-weight: 700;

}

.goods-box .num-box{
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.goods-box .num-box .stock{
  font-style: italic;
  padding-left: 10px;
  color: #333;
}

.sku-group .sku-group-item:not(:last-child) {
  margin-bottom: 1rem;
}

.sku-group .sku-group-item .sku-group-name {
  margin-bottom: 0;
  font-weight: 700;
}

.sku-group .sku-group-item .sku-group-property {
  padding: 0;
  margin: 0;
}

.sku-group .sku-group-item .sku-group-property .sku-group-key {
  border: 1px #c5c8cf solid;
  background-color: #fff;
  border-radius: 3px;
  padding: 0.25rem 0.5rem;
  margin: 0.625rem 0.625rem 0 0;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  vertical-align: middle;
  cursor: pointer;
}

.sku-group .sku-group-item .sku-group-property .sku-group-key.active {
  background-color: #C3232D;
  color: #fff;
  border-color: #C3232D;
}

.goods-detail{
  display: flex;
  justify-content: center
}
::v-deep .goods-detail img{
  width: 800px !important;
  height: auto;
}

::v-deep .goods-detail > div > p{
  margin-block-start:-4px !important;
  margin-block-end:-4px !important;
  margin: 0;
}

</style>
