<template>

  <div style="line-height: 40px">

    <el-button type="primary" plain icon="el-icon-search" size="small" style="margin-bottom: 10px" @click="initQuery">
      {{ title }}
    </el-button>

    <tool-tip msg="通过样本中某些明细数据或通用数据，来反查确定样本数据范围！" />

    <div v-if="init" class="query-out">

      <div v-for="(query,i) in queryList" :key="i" class="query-box">
        <div style="flex-grow: 1;">
          <el-select v-model="query.field" style="width: 100%" size="small" placeholder="请选择" @change="selectChange($event, query)">
            <el-option-group
              v-for="group in optionGroups"
              :key="group.key"
              :label="group.label">
              <el-option
                v-for="option in group.options"
                :key="option.id"
                :label="option.content"
                :value="option.id"/>
            </el-option-group>
          </el-select>
        </div>

        <div v-if="query.type === 'num' || query.type === 'date'">
          <el-select v-model="query.concat" size="small">
            <el-option key="eq" label="等于" value="eq"/>
            <el-option key="gt" label="大于" value="gt"/>
            <el-option key="lt" label="小于" value="lt"/>
            <el-option key="between" label="位于之间" value="between"/>
          </el-select>
        </div>

        <div v-if="query.group==='qu' && query.type === '4'">
          <el-select v-model="query.concat" size="small">
            <el-option key="eq" label="等于" value="eq"/>
            <el-option key="ne" label="不等于" value="ne"/>
            <el-option key="ct" label="包含" value="ct"/>
            <el-option key="nct" label="不包含" value="nct"/>
          </el-select>
        </div>

        <div style="flex-grow: 1;">

          <el-select v-if="query.items && query.items.length>0" v-model="query.values" multiple clearable style="width: 100%" size="small">
            <el-option
              v-for="item in query.items"
              :key="item.id"
              :label="item.content"
              :value="item.id"/>
          </el-select>

          <el-input v-else-if="query.type==='text' || query.type==='4'" v-model="query.values[0]" size="small"/>

          <!-- 数字及数字区间 -->
          <el-input-number v-if="query.type==='num'" v-model="query.values[0]" size="small"/>
          <el-input-number v-if="query.type==='num' && query.concat === 'between'" v-model="query.values[1]" style="margin-left: 10px" size="small"/>

          <!-- 日期及日期区间 -->
          <el-date-picker v-if="query.type==='date'" v-model="query.values[0]" value-format="yyyy-MM-dd" style="width: 160px" size="small" />
          <el-date-picker v-if="query.type==='date' && query.concat === 'between'" v-model="query.values[1]" value-format="yyyy-MM-dd" style="width: 160px; margin-left: 10px" size="small"/>
        </div>

        <div>
          <el-button type="danger" circle icon="el-icon-delete" size="mini" @click="removeQuery(i)"/>
        </div>

      </div>

      <div style="width: 60%">
        <el-button type="primary" plain style="width: 100%" icon="el-icon-plus" size="small" @click="addQuery">添加条件</el-button>
      </div>
      <div>
        <el-button icon="el-icon-search" type="primary" size="small" @click="buildQuery">查询</el-button>
        <el-button icon="el-icon-delete" type="danger" size="small" @click="clearQuery">清空</el-button>
      </div>
    </div>
  </div>

</template>

<script>

import { listAll } from '@/api/survey/qu'
import { listProvince, listCity } from '@/api/survey/paper'

export default {
  name: 'SurveyQueryBuilder',
  props: {
    title: {
      type: String,
      default: '数据过滤'
    },
    surveyId: String
  },
  data() {
    return {
      init: false,
      queryList: [],
      optionGroups: [{
        key: 'qu',
        label: '问卷内容',
        options: []
      }, {
        key: 'ext',
        label: '通用信息',
        options: [
        //   {
        //   id: 'join_channel',
        //   content: '来源渠道',
        //   type: 'text'
        // },
          {
            id: 'hand_time',
            content: '答卷提交日期',
            type: 'date'
          },
          {
            id: 'join_province',
            content: '省份(由IP自动获取)',
            type: 'province'
          },
          {
            id: 'join_city',
            content: '城市(由IP自动获取)',
            type: 'city'
          },
          {
            id: 'total_score',
            content: '得分',
            type: 'num'
          },
          {
            id: 'cost_time',
            content: '答卷用时(秒)',
            type: 'num'
          }
        ]
      }]

    }
  },

  watch: {
    surveyId: {
      handler() {
        // 查找详情
        this.fetchQuList()
      }
    }
  },

  methods: {
    initQuery() {
      this.fetchQuList()
      this.init = !this.init
    },

    // 下拉切换搜索类型
    selectChange(e, query) {
      this.optionGroups.forEach((group, key) => {
        const options = group.options
        options.forEach(opt => {
          if (e === opt.id) {
            // 分组题目或通用属性
            query.group = group.key
            // 重新初始化
            query.values = []
            query.items = []

            if (opt.type) {
              query.type = opt.type
              // 加载省份
              if (opt.type === 'province') {
                listProvince(this.surveyId).then(res => {
                  this.fillItems(query, res.data)
                })
                return
              }
              // 城市
              if (opt.type === 'city') {
                listCity(this.surveyId).then(res => {
                  this.fillItems(query, res.data)
                })
                return
              }
            }
            // 题目查询
            if (key === 0) {
              this.fillWithAnswers(query, opt)
            }
          }
        })
      })
    },

    // 查找全部题目
    fetchQuList() {
      if (!this.surveyId) {
        return
      }
      listAll(this.surveyId).then(res => {
        const list = res.data
        if (!list || list.length === 0) {
          return
        }
        const options = []
        // 处理
        for (let i = 0; i<list.length; i++) {
          const item = list[i]
          options.push({ id: item.id, content: item.contentText, quType: item.quType, answerList: item.answerList })
        }
        this.optionGroups[0].options = options
      })
    },

    // 添加查询
    addQuery() {
      this.queryList.push({ field: '', values: [] })
    },
    // 移除条件
    removeQuery(i) {
      this.queryList.splice(i, 1)
    },
    // 重置条件
    clearQuery() {
      this.queryList = []
      this.$emit('reset')
    },

    // 填充下拉列表
    fillItems(query, list) {
      if (!list || list.length === 0) {
        return
      }
      const items = []
      for (let i = 0; i < list.length; i++) {
        items.push({ id: list[i], content: list[i], value: list[i] })
      }
      query.items = items
      query.values = []
      this.$forceUpdate()
    },

    // 填充下拉列表
    fillWithAnswers(query, opt) {
      // 题型传入后台
      query.type = opt.quType

      // 问答题
      if (opt.quType === '4') {
        return
      }

      // 答题列表
      const answerList = opt.answerList
      if (!answerList || answerList.length === 0) {
        return
      }

      const items = []
      for (let i = 0; i < answerList.length; i++) {
        items.push({ id: answerList[i].id, content: answerList[i].content, value: answerList[i].id })
      }
      query.items = items
      query.values = []
      this.$forceUpdate()
    },

    // 构建查询
    buildQuery() {
      const list = JSON.parse(JSON.stringify(this.queryList))
      for (let i = 0; i< list.length; i++) {
        this.$delete(list[i], 'items')
        // 清除null的值
        const values = list[i].values
        if (values.length > 0) {
          for (let j = 0; j<values.length; j++) {
            if (!values[j]) {
              values.splice(j, 1)
            }
          }
        }
      }
      this.$emit('query', list)
    }
  }
}
</script>

<style scoped>

.query-out{
  background: #f2f3f4;
  border: 1px solid #dfe6ec;
  border-radius: 5px;
  padding: 10px;
}

.query-box{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60%;
  border-bottom: #e1e1e1 1px dotted;
  padding-bottom: 5px;
  margin-bottom: 5px;
}

::v-deep .query-box > div{
  line-height: 32px;
  height: 42px;
  padding:5px;
}

</style>
