var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "data-grid",
        {
          ref: "dataGrid",
          attrs: { options: _vm.options, "list-query": _vm.listQuery },
          scopedSlots: _vm._u([
            {
              key: "data-item",
              fn: function (ref) {
                var data = ref.data
                return [
                  _c(
                    "div",
                    [
                      _c("detail-link", {
                        attrs: {
                          id: data.id,
                          title: data.title,
                          to: "CheckExam",
                        },
                      }),
                      _c("div", { staticStyle: { display: "flex" } }, [
                        _c("div", { staticStyle: { "flex-grow": "1" } }, [
                          _c("div", { staticClass: "info-item" }, [
                            _c("span", { staticClass: "title" }, [
                              _vm._v("考试时间："),
                            ]),
                            _vm._v(
                              _vm._s(data.startTime) +
                                " ~ " +
                                _vm._s(data.endTime) +
                                " "
                            ),
                          ]),
                          _c("div", { staticClass: "info-item" }, [
                            _c("span", { staticClass: "title" }, [
                              _vm._v("考试机会："),
                            ]),
                            _vm._v(
                              _vm._s(
                                data.chance === 0 ? "不限" : data.chance + "次"
                              ) + " "
                            ),
                          ]),
                          _c("div", { staticClass: "info-item" }, [
                            _c("span", { staticClass: "title" }, [
                              _vm._v("考试状态："),
                            ]),
                            data.stateFlag === "PROCESSING"
                              ? _c("span", { staticClass: "success" }, [
                                  _vm._v("进行中"),
                                ])
                              : _vm._e(),
                            data.stateFlag === "NOT_START"
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "warning",
                                    attrs: { disabled: "" },
                                  },
                                  [_vm._v("未开始")]
                                )
                              : _vm._e(),
                            data.stateFlag === "OVERDUE"
                              ? _c("span", { attrs: { disabled: "" } }, [
                                  _vm._v("已过期"),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                        _c("div", [
                          _c(
                            "div",
                            [
                              data.price > 0
                                ? _c(
                                    "el-tag",
                                    { attrs: { type: "danger", size: "mini" } },
                                    [_vm._v("￥" + _vm._s(data.price))]
                                  )
                                : _c(
                                    "el-tag",
                                    {
                                      attrs: { type: "success", size: "mini" },
                                    },
                                    [_vm._v("免费")]
                                  ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "fav-btn",
                              class: { "fav-btn-off": !data.hasJoin },
                              on: {
                                click: function ($event) {
                                  return _vm.handleJoin(data)
                                },
                              },
                            },
                            [_c("i", { staticClass: "el-icon-star-on" })]
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "record-box" }, [
                        _c("div", [
                          _c("span", { staticClass: "title" }, [
                            _vm._v("时长："),
                          ]),
                          _c("span", { staticClass: "info" }, [
                            _vm._v(_vm._s(data.totalTime) + " 分钟"),
                          ]),
                        ]),
                        _c("div", [
                          _c("span", { staticClass: "title" }, [
                            _vm._v("总分："),
                          ]),
                          _c("span", { staticClass: "info" }, [
                            _vm._v(_vm._s(data.totalScore) + " 分"),
                          ]),
                        ]),
                        _c("div", [
                          _c("span", { staticClass: "title" }, [
                            _vm._v("及格分："),
                          ]),
                          _c("span", { staticClass: "info" }, [
                            _vm._v(_vm._s(data.qualifyScore) + " 分"),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "filter-content" },
            [
              _c("el-input", {
                staticClass: "filter-item",
                staticStyle: { width: "200px" },
                attrs: {
                  placeholder: "搜索考试名称",
                  size: "small",
                  "prefix-icon": "el-icon-search",
                },
                model: {
                  value: _vm.listQuery.params.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery.params, "title", $$v)
                  },
                  expression: "listQuery.params.title",
                },
              }),
              _c("el-date-picker", {
                staticClass: "filter-item",
                attrs: {
                  size: "small",
                  type: "datetimerange",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                },
                model: {
                  value: _vm.listQuery.params.dateRange,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery.params, "dateRange", $$v)
                  },
                  expression: "listQuery.params.dateRange",
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }