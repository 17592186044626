var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "checkout" },
    [
      _c("div", { staticClass: "checkout-address" }, [
        _c(
          "div",
          {
            staticStyle: {
              "font-size": "18px",
              "font-weight": "700",
              "margin-bottom": "20px",
            },
          },
          [
            _c(
              "el-button",
              {
                staticClass: "float-right",
                attrs: {
                  icon: "el-icon-plus",
                  type: "default",
                  size: "mini",
                  plain: "",
                },
                on: { click: _vm.showAddAddr },
              },
              [_vm._v("添加地址")]
            ),
            _c("span", { staticStyle: { "font-size": "18px" } }, [
              _vm._v("收货地址"),
            ]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "checkout-address-container" },
          [
            !_vm.addrList || _vm.addrList.length === 0
              ? _c("div", { staticClass: "checkout-no-data" }, [
                  _c("h5", { staticClass: "checkout-secondary text-center" }, [
                    _vm._v("请点击上方按钮添加一个地址！"),
                  ]),
                ])
              : _vm._e(),
            _vm._l(_vm.addrList, function (item) {
              return _c(
                "div",
                { key: item.id, staticClass: "checkout-address-item" },
                [
                  _c(
                    "p",
                    [
                      _c("el-button", {
                        staticClass: "float-right",
                        staticStyle: { "font-size": "14px" },
                        attrs: {
                          icon: "el-icon-edit",
                          size: "mini",
                          type: "primary",
                          circle: "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.preUpdate(item.id)
                          },
                        },
                      }),
                      _vm.showDelete
                        ? _c("el-button", {
                            staticClass: "float-right",
                            staticStyle: {
                              "font-size": "14px",
                              "margin-right": "10px",
                            },
                            attrs: {
                              icon: "el-icon-delete",
                              size: "mini",
                              type: "danger",
                              circle: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(item.id)
                              },
                            },
                          })
                        : _vm._e(),
                      _c("label", { staticClass: "addr-line" }, [
                        _vm.showRadio
                          ? _c("input", {
                              staticClass: "checkout-option",
                              attrs: {
                                type: "radio",
                                name: "address-shipping",
                              },
                              domProps: { checked: item.id === _vm.addrId },
                              on: {
                                click: function ($event) {
                                  _vm.addrId = item.id
                                },
                              },
                            })
                          : _vm._e(),
                        item.isDefault
                          ? _c("span", [_vm._v("默认")])
                          : _vm._e(),
                        _vm._v(" " + _vm._s(item.contact) + " "),
                        _c("b", [_vm._v(_vm._s(item.mobile))]),
                      ]),
                    ],
                    1
                  ),
                  _c("p", { staticClass: "addr-line" }, [
                    _vm._v(
                      " " +
                        _vm._s(item.province) +
                        _vm._s(item.city) +
                        _vm._s(item.area) +
                        " " +
                        _vm._s(item.address) +
                        " "
                    ),
                  ]),
                ]
              )
            }),
          ],
          2
        ),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            title: "添加地址",
            width: "40%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "postForm",
              attrs: {
                model: _vm.postForm,
                rules: _vm.rules,
                "label-position": "left",
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "姓名", prop: "contact" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.postForm.contact,
                      callback: function ($$v) {
                        _vm.$set(_vm.postForm, "contact", $$v)
                      },
                      expression: "postForm.contact",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "手机号", prop: "mobile" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.postForm.mobile,
                      callback: function ($$v) {
                        _vm.$set(_vm.postForm, "mobile", $$v)
                      },
                      expression: "postForm.mobile",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "所在区域", prop: "area" } },
                [
                  _c("v-distpicker", {
                    attrs: {
                      province: _vm.postForm.province,
                      city: _vm.postForm.city,
                      area: _vm.postForm.area,
                    },
                    on: { selected: _vm.distSelected },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "详细地址", prop: "address" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.postForm.address,
                      callback: function ($$v) {
                        _vm.$set(_vm.postForm, "address", $$v)
                      },
                      expression: "postForm.address",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c("el-checkbox", {
                    attrs: { label: "设置为默认地址" },
                    model: {
                      value: _vm.postForm.isDefault,
                      callback: function ($$v) {
                        _vm.$set(_vm.postForm, "isDefault", $$v)
                      },
                      expression: "postForm.isDefault",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.loading, type: "primary" },
                  on: { click: _vm.handleSave },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }