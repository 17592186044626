var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cam-box" },
    [
      _c(
        "div",
        { staticClass: "cam-3x2" },
        [
          _c("div", {
            ref: "video",
            staticStyle: {
              background: "#262626",
              "max-width": "100%",
              height: "auto",
            },
            attrs: { id: "video" },
          }),
          _vm.tracking && _vm.videoLoad
            ? _c("face-tracking", {
                ref: "tracking",
                attrs: { capture: _vm.trackCapture, "video-id": "video video" },
                on: { "face-out": _vm.handleFaceOut },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-select",
        {
          staticStyle: { width: "100%" },
          attrs: { size: "mini", placeholder: "选择摄像头" },
          model: {
            value: _vm.deviceId,
            callback: function ($$v) {
              _vm.deviceId = $$v
            },
            expression: "deviceId",
          },
        },
        _vm._l(_vm.cameras, function (item) {
          return _c("el-option", {
            key: item.deviceId,
            attrs: { value: item.deviceId, label: item.label },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }