<template>

  <div class="user-infocon">
    <div class="bg">
      <div style="cursor: pointer; position: relative" @click="uploadDialog = true">
        <el-image :src="userData.avatar" class="user-img">
          <div slot="error">
            <img src="@/assets/web/avatar.png" alt="">
          </div>
        </el-image>
      </div>
      <div class="infor" style="line-height: 15px; margin-top: 0">
        <p class="name">{{ userData.realName }} （ID：{{ userData.id }}）</p>
        <p class="class"><span><i class="el-icon-s-custom" /> 部门：{{ userData.deptCode_dictText }}</span> </p>
        <p class="class"><span><i class="el-icon-s-finance" />  积分：{{ userData.points }}</span> </p>
      </div>
    </div>

  </div>

</template>

<script>

import { mapGetters } from 'vuex'
import { findInfo } from '@/api/sys/user/user'
import YfAvatar from '@/components/YfAvatar/index.vue'

export default {
  name: 'UserBanner',
  components: { YfAvatar },

  data() {
    return {
      uploadDialog: false,
      userData: {},
      postForm: {
        avatar: null
      }
    }
  },
  computed: {
    ...mapGetters([
      'token'
    ])
  },

  created() {
    this.handleUserInfo()
  },
  methods: {
    handleUserInfo() {
      findInfo(this.token).then(res => {
        this.userData = res.data
      })
    }
  }
}
</script>

<style scoped>
  .user-infocon {
    height: 145px;
    background: url(~@/assets/web/images/ubg2.png) no-repeat top center;
    background-size: 100% 100%;
  }

  .user-infocon {
    padding: 0 85px
  }

  .user-infocon .bg {
    display: flex;
    padding-top: 40px;
  }

  .user-infocon .user-img {
    border: 2px solid #eee;
    width: 130px;
    height: 130px;
    overflow: hidden;
    position: relative;
    border-radius: 5px;
  }

  .user-infocon .user-img img {
    width: 130px;
    height: 130px;
    overflow: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
  }

  .user-infocon .infor {
    margin: 10px 0 0 35px;
  }

  .user-infocon .infor .name {
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    margin-top: 0;
  }

  .user-infocon .infor .class {
    font-size: 14px;
    color: #fff;
  }

  .user-infocon .infor .class span:first-child:after {
    content: "|";
    font-size: 14px;
    color: #fff;
    position: absolute;
    right: -15px;
    top: 0
  }

  .edit-con{
    background:#fff;
    display: flex;
    align-items:center;
    justify-content: center;
    text-align:center;
    color:#888;
    right:0;
    z-index: 888;
    width: 20px;
    height: 20px;
    position: absolute;
    font-size: 16px;
  }
</style>
