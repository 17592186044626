var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box" }, [
    _vm.step === 1
      ? _c(
          "div",
          [
            _c("el-image", {
              staticClass: "cover",
              attrs: { src: require("@/assets/web/cam-bg.png"), fit: "fill" },
            }),
            _c("div", { staticClass: "tips-line" }, [
              _vm._v(
                " 参考上图所示，手机架设在您斜后方，确保可以同时拍到您的上半身及电脑屏幕。 "
              ),
            ]),
            _c("div", { staticClass: "tips-line" }, [
              _vm._v(
                " 点击[开始摄像]按钮后，如出现授权提示，请选择允许授权。 "
              ),
            ]),
            _c("div", { staticClass: "tips-line" }, [
              _vm._v(
                " 如果[开始摄像]不成功，点击右上角3个点，选择在浏览器中打开。 "
              ),
            ]),
            _c("div", { staticClass: "tips-line red" }, [
              _vm._v(" 请设置手机的休眠时间足够长，避免自动息屏。 "),
            ]),
            _vm.cameras.length > 0
              ? _c(
                  "div",
                  { staticClass: "tips-line" },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { placeholder: "请选择摄像头" },
                        model: {
                          value: _vm.cameraId,
                          callback: function ($$v) {
                            _vm.cameraId = $$v
                          },
                          expression: "cameraId",
                        },
                      },
                      _vm._l(_vm.cameras, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.text, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "opt-btn" },
              [
                _c(
                  "el-button",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { type: "primary" },
                    on: { click: _vm.startCam },
                  },
                  [_vm._v("开始摄像")]
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _vm.step === 2
      ? _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.recording,
                expression: "recording",
              },
            ],
          },
          [
            _c("div", { attrs: { id: "video" } }),
            _c("div", { staticClass: "tips-line green" }, [
              _vm._v("正在拍摄中..."),
            ]),
            _c("div", { staticClass: "tips-line red" }, [
              _vm._v("请勿关闭手机，直至考试结束！"),
            ]),
            _c(
              "div",
              { staticClass: "opt-btn" },
              [
                _c(
                  "el-button",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { type: "danger" },
                    on: { click: _vm.closeCam },
                  },
                  [_vm._v("结束摄像")]
                ),
              ],
              1
            ),
          ]
        )
      : _vm._e(),
    _vm.step === 3
      ? _c("div", [
          _c("div", { staticClass: "tips-line" }, [_vm._v("摄像已结束！")]),
          _c("div", { staticClass: "tips-line" }, [
            _vm._v("直接关闭此页面即可！"),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }