import { post } from '@/utils/request'

/**
 * 发送单人消息
 * @param data
 */
export function singleMsg(data) {
  return post('/api/socket/watch/single-msg', data)
}

/**
 * 发送批量消息
 * @param data
 */
export function batchMsg(data) {
  return post('/api/socket/watch/batch-msg', data)
}

/**
 * 监考统计信息
 * @param data
 */
export function watchStat(data) {
  return post('/api/exam/watch/watch-stat', data)
}

/**
 * 强制交卷
 * @param data
 */
export function forceHand(data) {
  return post('/api/exam/watch/force-hand', data)
}

/**
 * 全部强制交卷
 * @param data
 */
export function forceHandAll(data) {
  return post('/api/exam/watch/force-hand-all', data)
}
