<template>

  <div>

    <el-tabs v-model="activeTab" type="card" @tab-click="tabClick">
      <el-tab-pane label="问卷中心" name="my">
        <my-survey-page @join="toJoin"/>
      </el-tab-pane>
      <el-tab-pane label="答卷记录" name="join">
        <survey-join-page ref="join" :survey-id="surveyId"/>
      </el-tab-pane>

    </el-tabs>
  </div>

</template>

<script>

import DataGrid from '@/components/DataGrid/index.vue'

export default {
  components: { DataGrid },
  data() {
    return {
      cacheKey: 'survey-index',
      activeTab: 'my',
      surveyId: null
    }
  },
  watch: {
    activeTab: {
      handler(val) {
        localStorage.setItem(this.cacheKey, val)
      }
    }
  },
  created() {
    // 获取缓存
    const cache = localStorage.getItem(this.cacheKey)
    if (cache) {
      this.activeTab = cache
    }
  },
  methods: {
    toJoin(id) {
      this.activeTab = 'join'
      this.surveyId = id
    },
    tabClick(tab) {
      // 手动点击清空
      if (tab.name === 'join') {
        this.surveyId = null
      }
    }
  }
}
</script>

<style scoped>

</style>
