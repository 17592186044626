import router from './router'
import store from './store'
import NProgress from 'nprogress'
import { Message } from 'element-ui'
import 'nprogress/nprogress.css'
import { getToken } from '@/utils/auth'
import getPageTitle from '@/utils/get-page-title'

NProgress.configure({ showSpinner: false })

// 白名单
const whiteList = ['/pages/login/login',
  '/pages/login/register',
  '/pages/login/forgot',
  '/pages/login/sync',
  '401',
  '404',
  '/pages/cam',
  '/pages/survey']

router.beforeEach(async(to, from, next) => {
  // 进度条
  NProgress.start()

  // 获取网站基本信息
  let siteData = store.getters.siteData
  if (!siteData.siteName) {
    siteData = await store.dispatch('settings/getSite')
  }

  // 页面标题
  document.title = getPageTitle(siteData.siteName, to.meta.title)

  // 获取token
  const token = getToken()

  if (token) {
    // 判断角色
    const hasRoles = store.getters.roles != null && store.getters.roles.length > 0

    if (hasRoles) {
      // 从登陆页面直接跳转到首页
      if (to.path === '/pages/login/login' || to.path === '' || to.path === '/') {
        // 管理首页
        const index = store.getters.index
        next({ path: index })
        NProgress.done()
        return
      }

      next()
    } else {
      // 获取用户信息
      store.dispatch('user/getInfo').then(async() => {
        // 加载动态路由
        const routes = await store.dispatch('permission/generateRoutes')
        // 管理员未获取任何菜单权限
        if (routes.length === 0 && store.getters.roleType === 2) {
          Message.error('您无权访问此系统，请联系管理员进行菜单授权！')
          await store.dispatch('user/resetToken')
          next(`/pages/login/login?redirect=${to.path}`)
          return
        }
        router.addRoutes(routes)
        // 确保路由已完成并不改变路由地址
        next({ ...to, replace: true })
      }).catch(() => {
        Message({
          type: 'error',
          message: '网络走丢啦，请稍后刷新页面重试！',
          duration: 5000
        })
      })
    }
  } else {
    // 检测白名单数据
    let white = false
    for (let i = 0; i < whiteList.length; i++) {
      if (to.path.startsWith(whiteList[i])) {
        white = true
      }
    }

    // 无需授权的页面
    if (white) {
      next()
    } else {
      // 跳转到登录
      next('/pages/login/login')
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})
