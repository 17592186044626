// 知识竞赛模块
import WebLayout from '@/layout/WebLayout'

const examRouter = {
  path: '/pages',
  component: WebLayout,
  hidden: true,
  children: [

    {
      path: '/pages/notice',
      component: () => import('@/views/web/notice'),
      name: 'NoticeList',
      meta: { title: '系统公告', noCache: true }
    },
    {
      path: '/pages/notice/detail/:id',
      component: () => import('@/views/web/notice/detail'),
      name: 'NoticeDetail',
      meta: { title: '公告详情', noCache: true, activeMenu: '/pages/notice' }
    }
  ]
}
export default examRouter
