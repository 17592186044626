<template>

  <div>

    <div class="state-bar">
      <el-tag
        v-for="item in stateList"
        :key="item.value"
        :type="listQuery.params.state===item.value?'danger':'primary'" effect="plain"
        @click="tabClick(item.value)">{{ item.title }}</el-tag>
    </div>

    <data-grid
      ref="dataGrid"
      :options="options"
      :list-query="listQuery">
      <template slot="filter-content">
        <dic-catalog-tree v-model="listQuery.params.catId" dic-code="course_catalog" placeholder="课程分类" width="200" class="filter-item" />
        <el-input
          v-model="listQuery.params.title"
          class="filter-item"
          placeholder="搜索课程名称"
          prefix-icon="el-icon-search"
          size="small"
          style="width: 200px; margin-left: 10px"
        />
      </template>
      <template v-slot:data-item="{ data }">

        <div style="display: flex">
          <div style="width: 200px">
            <div @click="handelView(data.id)">
              <image-box :src="data.cover" />
            </div>
          </div>
          <div style="flex-grow: 1">

            <div style="display: flex; align-items: center; padding-left: 10px">
              <div style="flex-grow: 1">
                <detail-link :id="data.id" :title="data.title" @click="handelView" />
                <el-tag v-if="data.liveCount > 0" style="margin-left: 5px" type="danger" size="mini">直播</el-tag>

                <div class="info-item">
                  <span class="title">学习时间：</span>
                  <span>{{ data.startTime }} ~ {{ data.endTime }}</span>
                </div>
                <div class="info-item">
                  <span class="title">课程来源：</span>
                  <span v-if="data.openType===3">指定人员</span>
                  <span v-else>课程中心</span>
                </div>
                <div class="info-item">
                  <span class="title">总体进度：</span>
                  <span>已学{{ data.learnFile }}/总{{ data.totalFile }}课件</span>
                </div>

              </div>
              <div style="display: flex; align-items: center; flex-direction: column">

                <el-link type="primary" icon="el-icon-chat-dot-round" @click="toQaList(data.id)">课程问答({{ data.qaCount }})</el-link>

              </div>
            </div>


          </div>
        </div>

        <div v-if="data.latestFile" class="record-box" style="margin-top: 10px">

          <div>
            <span class="title">最近学习：</span>
            <span class="info">
              {{ data.latestFile.title }}
            </span>
          </div>

          <div>
            <span class="title">学习时间：</span>
            <span class="info">
              {{ data.latestFile.learnTime }}
            </span>
          </div>

        </div>


      </template>

    </data-grid>

  </div>

</template>

<script>

import { mapGetters } from 'vuex'
import ImageBox from '@/components/ImageBox/index.vue'

export default {
  name: 'MyCoursePage',
  components: { ImageBox },
  props: {
    onlyLearn: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      stateList: [
        { value: 0, title: '全部课程' },
        { value: 1, title: '未学习' },
        { value: 2, title: '学习中' },
        { value: 3, title: '已学完' }
      ],

      listQuery: {
        current: 1,
        size: 10,
        params: {
          state: 0
        }
      },

      options: {
        // 列表请求URL
        listUrl: '/api/client/course/mine-paging',
        lineCount: 1
      }
    }
  },

  computed: {
    ...mapGetters([
      'siteData'
    ])
  },

  activated() {
  },
  methods: {

    reload() {
      this.$refs.dataGrid.getList()
    },

    tabClick(state) {
      this.listQuery.params.state = state
    },

    // 课程详情
    handelView(courseId) {
      this.$router.push({ name: 'CourseView', params: { courseId: courseId }})
    },

    // 计算百分比
    percent(data) {
      if (data.learnFile === 0 || data.totalFile === 0) {
        return 0
      }
      return parseInt(data.learnFile * 100 / data.totalFile)
    },

    // 问答列表
    toQaList(courseId) {
      this.$router.push({ name: 'CourseQa', query: { courseId: courseId }})
    }
  }
}
</script>

<style lang="scss" scoped>
.state-bar{
  padding-bottom: 10px;
  border-bottom: #f1f1f1 1px solid;
  margin-bottom: 15px;
}

.state-bar > .el-tag{
  margin-right: 10px;
  cursor: pointer;
}
</style>

