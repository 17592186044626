var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _vm.checkPermission([_vm.permission]) ||
    _vm.permission == null ||
    _vm.permission === ""
      ? _c(
          "span",
          [
            _vm.to && !_vm.params && !_vm.query
              ? _c(
                  "router-link",
                  { attrs: { to: { name: _vm.to, params: { id: _vm.id } } } },
                  [
                    _c("a", {
                      staticClass: "d-link",
                      domProps: { innerHTML: _vm._s(_vm.title) },
                    }),
                  ]
                )
              : _vm.to && _vm.params
              ? _c(
                  "router-link",
                  { attrs: { to: { name: _vm.to, params: _vm.params } } },
                  [
                    _c("a", {
                      staticClass: "d-link",
                      domProps: { innerHTML: _vm._s(_vm.title) },
                    }),
                  ]
                )
              : _vm.to && _vm.query
              ? _c(
                  "router-link",
                  { attrs: { to: { name: _vm.to, query: _vm.query } } },
                  [
                    _c("a", {
                      staticClass: "d-link",
                      domProps: { innerHTML: _vm._s(_vm.title) },
                    }),
                  ]
                )
              : _c("a", {
                  staticClass: "d-link",
                  domProps: { innerHTML: _vm._s(_vm.title) },
                  on: { click: _vm.linkClick },
                }),
          ],
          1
        )
      : _c("span", { domProps: { innerHTML: _vm._s(_vm.title) } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }