// 考试开始

const examRouteScreen = {
  path: '/pages/exam/screen/:examId/:paperId',
  component: () => import('@/views/web/exam/screen'),
  name: 'ExamScreen',
  meta: { title: '屏幕监控', noCache: true, activeMenu: '/pages/exam' },
  hidden: true
}

export default examRouteScreen
