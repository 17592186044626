<template>
  <div class="content">
    <el-upload
      ref="fileUpload"
      :action="uploadUrl"
      :accept="accept"
      :before-upload="beforeUpload"
      :on-change="handleChange"
      :before-remove="beforeRemove"
      :on-remove="handleRemove"
      :on-success="handleSuccess"
      :on-exceed="handleExceed"
      :drag="listType!=='picture-card'"
      :multiple="limit > 1"
      :limit="limit===1?2:limit"
      :file-list="fileList"
      :list-type="listType"
      :headers="header"
    >

      <i v-if="listType==='picture-card'" class="el-icon-plus"/>

      <i v-if="listType!=='picture-card'" class="el-icon-upload" />
      <div v-if="listType!=='picture-card'" class="el-upload__text">
        将文件拖到此处，或
        <em>点击上传</em>
      </div>
      <div v-if="tips" slot="tip" class="el-upload__tip">{{ tips }}</div>
    </el-upload>

  </div>

</template>

<script>

import { getToken } from '@/utils/auth'
import { compressImage } from '@/utils/upload'

export default {
  name: 'FileUploadLocal',
  props: {
    value: {
      type: Array,
      default() {
        return []
      }
    },
    accept: String,
    tips: String,
    listType: String,
    limit: {
      type: Number,
      default: 1
    },
    compress: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      fileList: [],
      uploadUrl: process.env.VUE_APP_BASE_API + '/api/common/file/upload',
      header: {}
    }
  },

  watch: {
    // 检测查询变化
    value: {
      handler(val) {
        this.fileList = val
      }
    }
  },

  created() {
    this.fillValue()
    this.header = { token: getToken() }
  },

  methods: {

    // 填充时间
    fillValue() {
      this.fileList = this.value
    },

    // 文件超出个数限制时的钩子
    handleExceed() {
      this.$message.warning(`每次只能上传 ${this.limit} 个文件`)
    },
    // 删除文件之前的钩子
    beforeRemove() {
      return this.$confirm(`确定移除文件吗？`)
    },

    // 文件列表移除文件时的钩子
    handleRemove(file) {
      // 移除文件
      for (let i = 0; i<this.fileList.length; i++) {
        if (file.uid === this.fileList[i].uid) {
          this.fileList.splice(i, 1)
        }
      }
      this.$emit('input', this.fileList)
    },

    // 文件上传成功时的钩子
    handleSuccess(res, file, fileList) {
      if (res.code !== 0) {
        this.$message.error(res.msg)
        this.$emit('input', [])
        return
      }
      // 只能传一个的进行替换
      if (this.limit === 1 && fileList.length>1) {
        const url = res.data.url
        // 反转并保留一个
        fileList = [{ url: url, name: url }]
      } else {
        // 上传成功替换服务端URL
        for (let i = 0; i< fileList.length; i++) {
          if (file.uid === fileList[i].uid) {
            fileList[i].url = res.data.url
          }
        }
      }

      this.$emit('input', fileList)
    },

    // 文件变化重新上传
    handleChange(file, fileList) {
      if (this.limit === 1 && fileList.length > 1) {
        fileList.splice(0, 1)
      }
    },

    // 文件上传前的校验
    beforeUpload(file) {
      console.log('++++++++++文件名称：', file.name)
      this.$emit('select', file.name)
      // 压缩上传
      return compressImage(file, this.compress)
    }
  }
}
</script>

<style scoped>
::v-deep .el-list-enter-active, .el-list-leave-active {
  transition: none;
}
</style>
