<template>

  <el-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    :show-close="false"
    title="快速填充"
    width="50%"
  >

    <div style="display: flex">
      <div style="flex-grow: 1">
        <el-input v-model="textData" :rows="15" type="textarea">{{ textData }}</el-input>
      </div>
      <div class="tmpl-box">
        <el-button type="primary" round plain size="mini" style="margin-left: 10px" @click="fillData('sex')">性别</el-button>
        <el-button type="primary" round plain size="mini" @click="fillData('age')">年龄</el-button>
        <el-button type="primary" round plain size="mini" @click="fillData('education')">学历</el-button>
        <el-button type="primary" round plain size="mini" @click="fillData('nation')">民族</el-button>
        <el-button type="primary" round plain size="mini" @click="fillData('province')">省份</el-button>
        <el-button type="primary" round plain size="mini" @click="fillData('marriage')">婚姻</el-button>
        <el-button type="primary" round plain size="mini" @click="fillData('family')">子女个数</el-button>
        <el-button type="primary" round plain size="mini" @click="fillData('career')">职业</el-button>
        <el-button type="primary" round plain size="mini" @click="fillData('industry')">行业</el-button>
        <el-button type="primary" round plain size="mini" @click="fillData('satisfaction')">满意度</el-button>
        <el-button type="primary" round plain size="mini" @click="fillData('agree')">认同度</el-button>
        <el-button type="primary" round plain size="mini" @click="fillData('yesno')">是否</el-button>
        <el-button type="primary" round plain size="mini" @click="fillData('num')">数字</el-button>
        <el-button type="primary" round plain size="mini" @click="fillData('week')">星期</el-button>
        <el-button type="primary" round plain size="mini" @click="fillData('month')">月份</el-button>
        <el-button type="primary" round plain size="mini" @click="fillData('year')">年份</el-button>
      </div>
    </div>


    <div slot="footer" class="dialog-footer">
      <el-button type="danger" @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleSave">确 认</el-button>
    </div>

  </el-dialog>

</template>

<script>

import DicListSelect from '@/components/DicListSelect/index.vue'

export default {
  name: 'SelectQuickFillDialog',
  components: { DicListSelect },
  props: {
    value: String,
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tmplData: {
        'sex': '男\n' +
          '女',
        'age': '18岁以下\n' +
          '18~25\n' +
          '26~30\n' +
          '31~40\n' +
          '41~50\n' +
          '51~60\n' +
          '60以上',
        'education': '初中及以下\n' +
          '高中/中专/技校\n' +
          '大学专科\n' +
          '大学本科\n' +
          '研究生及以上',
        'nation': '汉族\n' +
          '满族\n' +
          '蒙古族\n' +
          '回族\n' +
          '藏族\n' +
          '维吾尔族\n' +
          '苗族\n' +
          '彝族\n' +
          '壮族\n' +
          '布依族\n' +
          '侗族\n' +
          '瑶族\n' +
          '白族\n' +
          '土家族\n' +
          '哈尼族\n' +
          '哈萨克族\n' +
          '傣族\n' +
          '黎族\n' +
          '傈僳族\n' +
          '佤族\n' +
          '畲族\n' +
          '高山族\n' +
          '拉祜族\n' +
          '水族\n' +
          '东乡族\n' +
          '纳西族\n' +
          '景颇族\n' +
          '柯尔克孜族\n' +
          '土族\n' +
          '达斡（音：握）尔族\n' +
          '仫（音：目）佬族\n' +
          '羌族\n' +
          '布朗族\n' +
          '撒拉族\n' +
          '毛南族\n' +
          '仡佬族\n' +
          '锡伯族\n' +
          '阿昌族\n' +
          '普米族\n' +
          '朝鲜族\n' +
          '塔吉克族\n' +
          '怒族\n' +
          '乌孜别克族\n' +
          '俄罗斯族\n' +
          '鄂温克族\n' +
          '德昂族\n' +
          '保安族\n' +
          '裕固族\n' +
          '京族\n' +
          '塔塔尔族\n' +
          '独龙族\n' +
          '鄂伦春族\n' +
          '赫哲族\n' +
          '门巴族\n' +
          '珞巴族\n' +
          '基诺族',
        'province': '安徽\n' +
          '北京\n' +
          '重庆\n' +
          '福建\n' +
          '甘肃\n' +
          '广东\n' +
          '广西\n' +
          '贵州\n' +
          '海南\n' +
          '河北\n' +
          '黑龙江\n' +
          '河南\n' +
          '香港\n' +
          '湖北\n' +
          '湖南\n' +
          '江苏\n' +
          '江西\n' +
          '吉林\n' +
          '辽宁\n' +
          '澳门\n' +
          '内蒙古\n' +
          '宁夏\n' +
          '青海\n' +
          '山东\n' +
          '上海\n' +
          '山西\n' +
          '陕西\n' +
          '四川\n' +
          '台湾\n' +
          '天津\n' +
          '新疆\n' +
          '西藏\n' +
          '云南\n' +
          '浙江\n' +
          '海外',
        'marriage': '未婚\n' +
          '已婚\n' +
          '离异',
        'family': '没有小孩\n' +
          '一个小孩\n' +
          '两个小孩\n' +
          '三个及以上',
        'career': '市场/销售/商务\n' +
          '采购\n' +
          '行政\n' +
          '人力\n' +
          '产品/运营人员\n' +
          '个体经营者\n' +
          '财务/会计/出纳/审计\n' +
          '企业管理者\n' +
          '律师/法务\n' +
          '设计从业者\n' +
          '服务业人员\n' +
          '技术开发/工程师\n' +
          '农林牧渔劳动者\n' +
          '工人劳动者\n' +
          '全职家庭主妇/夫\n' +
          '自由职业\n' +
          '离休/退休\n' +
          '学生\n' +
          '老师\n' +
          '医护人员\n' +
          '科研人员\n' +
          '党政机关人员',
        'industry': '制造业\n' +
          '建筑业\n' +
          '物流交通\n' +
          '教育/培训\n' +
          '互联网\n' +
          '计算机/软件\n' +
          '批发和零售\n' +
          '住宿餐饮业\n' +
          '金融业\n' +
          '房地产业\n' +
          '出租/租赁\n' +
          '专业服务（如法律/咨询服务）\n' +
          '科学研究\n' +
          '生活服务（如家政/美发类服务）\n' +
          '医疗卫生/社会保障\n' +
          '文化娱乐\n' +
          '政府事业单位\n' +
          '农、林、牧、渔业\n' +
          '水利环境公共设施管理\n' +
          '电力燃气\n' +
          '采矿业\n' +
          '国际组织',
        'satisfaction': '很不满意\n' +
          '不满意\n' +
          '一般\n' +
          '满意\n' +
          '很满意',
        'agree': '很不同意\n' +
          '不同意\n' +
          '一般\n' +
          '同意\n' +
          '很同意',
        'yesno': '是\n' +
          '否',
        'num': '1\n' +
          '2\n' +
          '3\n' +
          '4\n' +
          '5',
        'week': '星期日\n' +
          '星期一\n' +
          '星期二\n' +
          '星期三\n' +
          '星期四\n' +
          '星期五\n' +
          '星期六',
        'month': '1月份\n' +
          '2月份\n' +
          '3月份\n' +
          '4月份\n' +
          '5月份\n' +
          '6月份\n' +
          '7月份\n' +
          '8月份\n' +
          '9月份\n' +
          '10月份\n' +
          '11月份\n' +
          '12月份',
        'year': '1980\n' +
          '1981\n' +
          '1982\n' +
          '1983\n' +
          '1984\n' +
          '1985\n' +
          '1986\n' +
          '1987\n' +
          '1988\n' +
          '1989\n' +
          '1990\n' +
          '1991\n' +
          '1992\n' +
          '1993\n' +
          '1994\n' +
          '1995\n' +
          '1996\n' +
          '1997\n' +
          '1998\n' +
          '1999\n' +
          '2000\n' +
          '2001\n' +
          '2002\n' +
          '2003\n' +
          '2004\n' +
          '2005\n' +
          '2006\n' +
          '2007\n' +
          '2008\n' +
          '2009\n' +
          '2010\n' +
          '2011\n' +
          '2012\n' +
          '2013\n' +
          '2014\n' +
          '2015\n' +
          '2016\n' +
          '2017\n' +
          '2018\n' +
          '2019\n' +
          '2020\n' +
          '2021\n' +
          '2022\n' +
          '2023\n' +
          '2024'

      },
      dialogVisible: false,
      textData: ''
    }
  },

  watch: {
    visible: {
      handler(val) {
        this.dialogVisible = val
      }
    },
    value: {
      handler(val) {
        this.textData = val
      }
    }
  },

  methods: {

    // 填充数据
    fillData(key) {
      this.textData = this.tmplData[key]
    },

    // 保存
    handleSave() {
      if (this.textData) {
        this.$emit('confirm', this.textData)
      }

      this.handleClose()
    },
    handleClose() {
      this.$emit('update:visible', false)
    }
  }
}
</script>


<style scoped>
.tmpl-box{
  width: 200px;
  margin-left: 20px;
  text-align: left;
}

.tmpl-box .el-button{
 width: 40%;
  margin-bottom: 10px;
}
</style>
