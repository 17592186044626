<template>

  <el-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    :show-close="true"
    title="导入问题"
    width="30%"
    @close="handleClose"
  >


    <import-excel
      :data="{ surveyId: surveyId}"
      title="导入问题"
      action="/api/survey/qu/import"
      template="/api/survey/qu/import/template"
      @success="importSuccess"
    />

  </el-dialog>

</template>

<script>


export default {
  name: 'SurveyQuImportDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    surveyId: String
  },
  data() {
    return {
      dialogVisible: false
    }
  },

  watch: {
    visible: {
      handler() {
        this.dialogVisible = this.visible
      }
    }
  },

  methods: {

    // 创建问卷
    importSuccess() {
      this.$notify({
        title: '提示信息',
        message: '题目导入成功！',
        type: 'success',
        duration: 1500
      })
      this.$emit('success')
      this.handleClose()
    },
    handleClose() {
      this.$emit('update:visible', false)
    }
  }
}
</script>
