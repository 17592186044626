<template>

  <div>

    <pdf-reader :src="fileUrl" />

    <!-- SOCKET记录时间 -->
    <read-socket
      v-if="file.fileId && visible"
      :file-id="file.fileId"
      :course-id="course.id"
      @change="changeLearnMin"
      @finish="handleFinish"
      @break="checkBreak"
    />

    <!-- 行为校验 -->
    <read-check
      v-if="file.fileId && visible && course.checkOn"
      :time-min="course.checkSec"
      @break="checkBreak"
    />

  </div>

</template>

<script>

export default {
  name: 'PdfFrame',
  props: {
    course: Object,
    file: Object
  },
  data() {
    return {
      fileUrl: '',
      visible: true
    }
  },
  watch: {

    file: {
      handler(val) {
        this.initFile(val)
      }
    }
  },
  created() {
    // 初始化文件
    this.initFile(this.file)

    // 监听页面状态
    document.addEventListener('visibilitychange', this.stateChange)
  },
  methods: {

    // 初始化预览地址
    initFile(file) {
      if (file) {
        // office查看转换地址
        if (file.fileType === '11') {
          this.fileUrl = file.viewUrl
        }

        // pdf原文件播放
        if (file.fileType === '22') {
          this.fileUrl = file.fileUrl
        }
      }
    },

    // 监听面可见状态
    stateChange() {
      if (document.visibilityState === 'hidden') {
        this.visible = false
        this.$emit('pause')
      } else {
        this.visible = true
        this.$emit('play')
      }
    },

    // 校验超时
    checkBreak() {
      // 跳转到
      this.$router.push('/pages/course?break')
    },

    // 阅读进度变化
    changeLearnMin(data) {
      this.$emit('change', data)
    },

    // 完成课件阅读
    handleFinish(json) {
      this.$emit('finish', json)
    }

  }

}

</script>
