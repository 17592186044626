<template>

  <div>

    <Tinymce ref="editor" v-model="postForm.content" :height="150" />
    <div class="answer-list">
      <div class="rank-text">
        <div>{{ postForm.answerList[0].content }}</div>
        <div>{{ postForm.answerList[postForm.answerList.length-1].content }}</div>
      </div>

      <div :class="{'rank-num2':postForm.rankStyle==='num2'}" class="style-box">
        <div v-for="count in postForm.answerList.length" :key="count" style="font-size: 22px; color: #0A84FF; font-weight: 700">
          <svg-icon v-if="postForm.rankStyle==='heart'" icon-class="rank-heart"/>
          <svg-icon v-if="postForm.rankStyle==='good'" icon-class="rank-good"/>
          <svg-icon v-if="postForm.rankStyle==='star'" icon-class="rank-star"/>
          <svg-icon v-if="postForm.rankStyle==='circle'" icon-class="rank-circle"/>
          <div v-if="postForm.rankStyle==='num1'" class="rank-num1">
            {{ count }}
          </div>
          <div v-if="postForm.rankStyle==='num2'">
            {{ count }}
          </div>
        </div>
      </div>


      <div style="display: flex; align-items: center; justify-content: space-between">
        <div>
          <el-button class="opt-item" size="mini" type="primary" plain icon="el-icon-plus" @click="showDialog">设置选项</el-button>
        </div>

        <div style="display: flex; align-items: center">


          <el-select v-model="postForm.rankLevel" class="opt-item" size="mini" placeholder="量表级别" clearable style="width: 100px" @change="typeChange">
            <el-option
              v-for="count in 10"
              v-if="count > 1"
              :key="count"
              :label="`${count}级量表`"
              :value="count"/>

            <el-option
              :key="11"
              :value="11"
              label="NPS量表"/>
          </el-select>

          <div class="opt-item" style="font-size: 18px; display: flex; align-items: center">
            <div style="color: #606266; font-size: 14px; font-weight: 500">样式：</div>
            <div :class="{'like-active': postForm.rankStyle === 'num1'}" class="like-item" @click="likeStyle('num1')">
              <svg-icon icon-class="rank-num1"/>
            </div>

            <div :class="{'like-active': postForm.rankStyle === 'circle'}" class="like-item" @click="likeStyle('circle')">
              <svg-icon icon-class="rank-circle" />
            </div>

            <div :class="{'like-active': postForm.rankStyle === 'num2'}" class="like-item" @click="likeStyle('num2')">
              <svg-icon icon-class="rank-num2" />
            </div>


            <div :class="{'like-active': postForm.rankStyle === 'heart'}" class="like-item" @click="likeStyle('heart')">
              <svg-icon icon-class="rank-heart" />
            </div>

            <div :class="{'like-active': postForm.rankStyle === 'good'}" class="like-item" @click="likeStyle('good')">
              <svg-icon icon-class="rank-good" />
            </div>

            <div :class="{'like-active': postForm.rankStyle === 'star'}" class="like-item" @click="likeStyle('star')">
              <svg-icon icon-class="rank-star" />
            </div>


          </div>


          <el-checkbox v-model="postForm.required" class="opt-item">必答</el-checkbox>

        </div>

      </div>
    </div>

    <el-dialog
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      :show-close="false"
      title="选项设置"
      width="540px"
    >

      <el-table :data="postForm.answerList">
        <el-table-column
          label="选项">
          <template v-slot="scope">
            <el-input v-model="scope.row.content" size="mini"/>
          </template>
        </el-table-column>

        <el-table-column
          label="分值"
          align="center"
        >
          <template v-slot="scope">
            <el-input-number v-model="scope.row.score" :min="0" :max="999" size="mini"/>
          </template>
        </el-table-column>

        <el-table-column
          width="100px"
          label="删除">
          <template v-slot="scope">
            <el-button size="mini" circle icon="el-icon-delete" type="danger" @click="removeAnswer(scope.$index)"/>
          </template>
        </el-table-column>
      </el-table>

      <el-button v-if="postForm.rankLevel===11 || postForm.rankLevel > postForm.answerList.length" type="primary" size="mini" icon="el-icon-plus" style="width: 100%; margin-top: 10px" @click="addAnswer">添加选项</el-button>


      <div slot="footer" class="dialog-footer">
        <el-button type="danger" @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleApply">确 认</el-button>
      </div>

    </el-dialog>

  </div>

</template>

<script>

import DicListSelect from '@/components/DicListSelect/index.vue'
export default {
  name: 'SurveyQuTypeRank',
  components: { DicListSelect },
  props: {
    surveyId: String,
    value: Object
  },
  data() {
    return {
      sortTable: null,
      dialogVisible: false,
      postForm: {
        rankLevel: 10,
        rankStyle: '',
        answerList: []
      }
    }
  },

  watch: {
    surveyId: {
      handler() {
        // 查找详情
      }
    },
    value: {
      handler() {
        this.initValue()
      }
    }
  },

  created() {
    // 查找详情
    this.initValue()
  },

  methods: {

    // 构建默认数据
    initValue() {
      const qu = this.value
      if (!qu.answerList || qu.answerList.length === 0) {
        const list = []
        for (let i = 1; i <= 5; i++) {
          let content = i + ''
          if (i === 1) {
            content = '很不满意'
          }
          if (i === 5) {
            content = '很满意'
          }
          list.push({ score: i, content: content })
        }
        qu.answerList = list
        qu.rankLevel = 5
        qu.rankStyle = 'num1'
      }

      this.postForm = qu
    },

    // 量表样式
    likeStyle(v) {
      this.postForm.rankStyle = v
      this.$forceUpdate()
    },

    // 量表
    typeChange(v) {
      // NPS默认10级
      const level = v === 11 ? 10 : v
      const list = []
      for (let i = 1; i <= level; i++) {
        let content = i + ''
        if (i === 1) {
          content = '很不满意'
        }
        if (i === level) {
          content = '很满意'
        }
        list.push({ score: i, content: content })
      }
      this.postForm.answerList = list
      this.$forceUpdate()
    },

    handleClose() {
      this.dialogVisible = false
    },

    handleApply() {
      this.dialogVisible = false
    },

    showDialog() {
      this.dialogVisible = true
    },

    removeAnswer(index) {
      this.postForm.answerList.splice(index, 1)
    },
    addAnswer() {
      if (!this.postForm.answerList) {
        this.postForm.answerList = []
      }
      // 处理数据
      const last = this.postForm.answerList[this.postForm.answerList.length - 1]
      // 下一个值+1
      const next = last.score + 1
      // 最后一个标签是描述，则往下递
      const isNum = /^\d+$/.test(last.content)
      this.postForm.answerList.push({ content: isNum ? next : last.content, score: next })
      // 还原上一个标签为值
      last.content = last.score
      this.$forceUpdate()
    }
  }
}
</script>

<style scoped>

.answer-list{
  margin-top: 10px;
}

.opt-item + .opt-item {
  margin-left: 10px;
}

.style-box{
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 10px;
  height: 45px;
}

.rank-text{
  display: flex;
  justify-content: space-between;
  margin: 30px 0 0 10px;
  font-weight: 700;
  color: #555;
  padding-bottom: 10px;
  border-bottom: #dee0e2 1px solid
}

.like-item{
  font-size: 18px;
  color: #c5c8cf;
  cursor: pointer;
}

.like-item:hover, .like-active{
  color: #0A84FF;
}

.like-item + .like-item {
  margin-left: 10px;
}

.rank-num1{
  width: 25px;
  height: 25px;
  border-radius: 50%;
  color: #0A84FF;
  border: 2px solid #0A84FF;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  font-size: 14px;
}

.rank-num2{
  background: linear-gradient(to right, #fff, #FCBD1A);
}

</style>
