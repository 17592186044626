<template>

  <div>
    <div class="q-content">
      <div class="num">{{ postForm.sort }}</div>
      <div v-if="postForm.required" class="req">*</div>
      <div v-html="$options.filters.formatRichText(postForm.content)">题干</div>
    </div>
    <div class="answer-list">
      <div v-for="(item,index) in postForm.answerList" :key="index" :class="{'answer-selected': item.selected}" class="answer-item" @click="clickItem(index)">
        <div style="padding-right: 10px">
          <span v-if="postForm.quType==='2' || postForm.quType==='28'" :class="{'is-checked':item.selected}" class="el-checkbox__input ">
            <span class="el-checkbox__inner"/>
          </span>
          <span v-if="postForm.quType==='1' || postForm.quType==='27'" :class="{'is-checked':item.selected}" class="el-radio__input">
            <span class="el-radio__inner"/>
          </span>
        </div>
        <div style="flex-grow: 1;">
          <div style="display: flex; align-items: center">
            <div v-html="$options.filters.formatRichText(item.content)">题干</div>
          </div>
          <div v-if="item.remark" class="remark">{{ item.remark }}</div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import DicListSelect from '@/components/DicListSelect/index.vue'

export default {
  name: 'SurveyAnswerSelect',
  components: { DicListSelect },
  props: {
    surveyId: String,
    value: Object
  },
  data() {
    return {
      postForm: {
        answerList: []
      }
    }
  },

  watch: {
    value: {
      handler() {
        this.fillValue()
      }
    }
  },

  created() {
    // 查找详情
    this.fillValue()
  },

  methods: {

    // 填充数据
    fillValue() {
      if (this.value) {
        this.postForm = this.value
        this.makeDefaultCheck()
      }
    },

    // 设置为默认
    clickItem(index) {
      if (this.postForm.quType === '1' || this.postForm.quType === '27') {
        this.postForm.answerList.forEach((v, i) => {
          v.selected = i === index
        })
      }
      // 多选
      if (this.postForm.quType === '2' || this.postForm.quType === '28') {
        const item = this.postForm.answerList[index]
        item.selected = !item.selected
      }
      this.$forceUpdate()
      this.$emit('change', this.postForm)
    },

    // 设置为默认
    makeDefaultCheck() {
      if (!this.postForm.answered) {
        this.postForm.answerList.forEach((v) => {
          if (v.defaultSelected && !v.selected) {
            v.selected = true
          }
        })
        this.$forceUpdate()
        this.$emit('change', this.postForm)
      }
    }
  }
}
</script>

<style scoped>

.answer-list{
  margin-top: 10px;
}

.answer-item{
  display: flex;
  border: #d3dce6 1px solid;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  align-items: center;
  cursor: pointer;
}

.answer-selected{
  border: #0A84FF 1px solid;
}

.answer-item .remark{
  font-size: 12px;
  color: #666;
  padding-top: 5px
}

.q-content{
  display: flex;
}

.q-content > .num{
  padding-right: 10px;
  color: #0A84FF;
  font-weight: 700;
}

.q-content > .req{
  color: #ff3333;
  font-size: 22px;
  line-height: 20px;
  font-weight: 700
}

::v-deep .q-content p{
  margin-block-start: 0;
  margin-block-end: 0;
}


</style>
