<template>

  <div style="margin-top: 10px">
    <el-table
      v-loading="loading"
      :data="dataList"
      row-key="id"
      stripe
      border
      default-expand-all
    >

      <el-table-column
        align="center"
        label="排名"
      >
        <template v-slot="scope">
          {{ scope.$index+1 }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="部门名称"
        prop="deptName"
      />

      <el-table-column
        align="center"
        label="总得分"
        prop="totalScore"
      />

    </el-table>

  </div>

</template>

<script>
import { apiRankingDept } from '@/api/battle/battle'

export default {
  name: 'CompanyRanking',
  props: {
    battleId: String,
    timeType: Number
  },
  data() {
    return {
      loading: false,
      dataList: []
    }
  },

  watch: {

    // 检测查询变化
    timeType: {
      handler() {
        this.fetchData()
      },
      deep: true
    },

    battleId: {
      handler() {
        this.fetchData()
      },
      deep: true
    }
  },

  created() {
    this.fetchData()
  },

  methods: {

    fetchData() {
      this.loading = true
      apiRankingDept({ id: this.battleId, timeType: this.timeType }).then(res => {
        this.dataList = res.data.list
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>

  ::v-deep
  .el-dialog__body{
    padding: 0;
  }
</style>
