var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            "append-to-body": true,
            title: "查看报名",
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.handleClose,
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-descriptions",
                { attrs: { column: 2, size: "small", border: "" } },
                _vm._l(_vm.detail.dataList, function (item, index) {
                  return _c(
                    "el-descriptions-item",
                    {
                      key: index,
                      attrs: {
                        label: item.fieldTitle,
                        "label-style": "width: 100px",
                        "content-style": "width: 200px",
                      },
                    },
                    [
                      _c("join-field-viewer", {
                        attrs: {
                          "field-type": item.fieldType,
                          "field-value": item.valueShow,
                        },
                      }),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }