var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("data-grid", {
        ref: "dataGrid",
        attrs: { options: _vm.options, "list-query": _vm.listQuery },
        scopedSlots: _vm._u([
          {
            key: "filter-content",
            fn: function () {
              return [
                _c("el-input", {
                  staticClass: "filter-item",
                  staticStyle: { width: "250px" },
                  attrs: {
                    size: "small",
                    placeholder: "搜索竞赛名称",
                    "prefix-icon": "el-icon-search",
                  },
                  model: {
                    value: _vm.listQuery.params.title,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery.params, "title", $$v)
                    },
                    expression: "listQuery.params.title",
                  },
                }),
                _c("el-date-picker", {
                  staticClass: "filter-item",
                  staticStyle: { "margin-left": "10px" },
                  attrs: {
                    size: "small",
                    type: "datetimerange",
                    "value-format": "yyyy-MM-dd HH:mm:ss",
                    "range-separator": "至",
                    "start-placeholder": "开始日期",
                    "end-placeholder": "结束日期",
                  },
                  model: {
                    value: _vm.listQuery.params.dateRange,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery.params, "dateRange", $$v)
                    },
                    expression: "listQuery.params.dateRange",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "data-item",
            fn: function (ref) {
              var data = ref.data
              return [
                _c("div", { staticStyle: { display: "flex" } }, [
                  _c(
                    "div",
                    { staticStyle: { width: "200px" } },
                    [_c("image-box", { attrs: { src: data.banner } })],
                    1
                  ),
                  _c("div", { staticStyle: { "flex-grow": "1" } }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                          "padding-left": "10px",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticStyle: { "flex-grow": "1" } },
                          [
                            _c("detail-link", {
                              attrs: {
                                id: data.id,
                                title: data.title,
                                to: "WebBattleDetail",
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "font-size": "14px",
                                  color: "#333",
                                },
                              },
                              [
                                _vm._v(" 答题规则： "),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(data.cycleType_dictText) +
                                      _vm._s(data.cycleCount) +
                                      "次 "
                                  ),
                                ]),
                                _c("span", [
                                  _vm._v(
                                    "每次" +
                                      _vm._s(data.quCount) +
                                      "题，每题" +
                                      _vm._s(data.quScore) +
                                      "分"
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "font-size": "14px",
                                  color: "#333",
                                },
                              },
                              [
                                _vm._v(
                                  " 竞赛时间： " +
                                    _vm._s(data.startTime) +
                                    " ~ " +
                                    _vm._s(data.endTime) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }