// 课程模块
import EmptyLayout from '@/layout/EmptyLayout'

const surveyAnswerRouter = {
  path: '/survey/a',
  component: EmptyLayout,
  hidden: true,
  children: [

    {
      path: '/pages/survey/view',
      component: () => import('@/views/web/survey/view'),
      name: 'SurveyView',
      meta: { title: '问卷详情', noCache: true, activeMenu: '/pages/survey' }
    },

    {
      path: '/pages/survey/answer',
      component: () => import('@/views/web/survey/answer'),
      name: 'SurveyAnswer',
      meta: { title: '问卷答题', noCache: true, activeMenu: '/pages/survey' }
    }
  ]
}
export default surveyAnswerRouter
