var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    {
      staticClass: "filter-item",
      staticStyle: { width: "100%" },
      attrs: {
        disabled: _vm.disabled,
        "remote-method": _vm.fetchData,
        size: "small",
        filterable: "",
        remote: "",
        multiple: "",
        "reserve-keyword": "",
        clearable: "",
        "automatic-dropdown": "",
        placeholder: "请选择角色",
      },
      on: {
        change: _vm.handlerChange,
        clear: function ($event) {
          return _vm.fetchData("")
        },
      },
      model: {
        value: _vm.roleIds,
        callback: function ($$v) {
          _vm.roleIds = $$v
        },
        expression: "roleIds",
      },
    },
    _vm._l(_vm.list, function (item) {
      return _c("el-option", {
        key: item.id,
        attrs: {
          label: item.roleName + "(" + item.roleLevel + ")",
          value: item.id,
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }