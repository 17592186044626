<template>

  <div>
    <div class="q-content">
      <div class="num">{{ postForm.sort }}</div>
      <div v-if="postForm.required" class="req">*</div>
      <div v-html="$options.filters.formatRichText(postForm.content)">题干</div>
    </div>
    <div class="answer-list">
      <div class="rank-text">
        <div>{{ postForm.answerList[0].content }}</div>
        <div>{{ postForm.answerList[postForm.answerList.length-1].content }}</div>
      </div>


      <div class="style-box">

        <div v-if="postForm.rankStyle==='num2'" class="rank-mask"/>
        <div class="rank-num-box">
          <div
            v-for="item in postForm.answerList"
            :key="item.id"
            :title="item.content"
            :class="{'rank-unchecked-bg': bgStyle(item.score),
                     'rank-checked-color': checkStyle(item.score),
                     'icon-color': postForm.rankStyle!=='num2'}"
            class="rank-num-item"
            @click="checkValue(item)">
            <svg-icon v-if="postForm.rankStyle==='heart'" icon-class="rank-heart"/>
            <svg-icon v-if="postForm.rankStyle==='good'" icon-class="rank-good"/>
            <svg-icon v-if="postForm.rankStyle==='star'" icon-class="rank-star"/>
            <svg-icon v-if="postForm.rankStyle==='circle'" icon-class="rank-circle" />
            <div v-if="postForm.rankStyle==='num1'" class="circle-num-box">
              <svg-icon icon-class="rank-circle"/>
              <div class="num">{{ item.score }}</div>
            </div>
            <div v-if="postForm.rankStyle==='num2'">
              {{ item.score }}
            </div>
          </div>
        </div>

      </div>


    </div>

  </div>

</template>

<script>

import DicListSelect from '@/components/DicListSelect/index.vue'
export default {
  name: 'SurveyAnswerRank',
  components: { DicListSelect },
  props: {
    value: Object
  },
  data() {
    return {
      current: 0,
      postForm: {
        answerList: []
      }
    }
  },

  watch: {
    value: {
      handler() {
        this.initValue()
      }
    }
  },

  created() {
    // 查找详情
    this.initValue()
  },

  methods: {

    // NPS用白色遮盖未选中的渐变
    bgStyle(score) {
      return score > this.current && this.postForm.rankStyle === 'num2'
    },

    // 确定只高亮选中的或前面的也高亮
    checkStyle(score) {
      // 圈圈只选中当前，不选择前面的
      if (this.postForm.rankStyle === 'circle' || this.postForm.rankStyle === 'num1') {
        return score === this.current
      }
      // 其他的，前面的也选中
      return score <= this.current && this.postForm.rankStyle !== 'num2'
    },

    // 选定
    checkValue(item) {
      this.postForm.answerList.forEach((v) => {
        v.selected = v.id === item.id
      })
      this.current = item.score
      this.$emit('change', this.postForm)
    },

    // 构建默认数据
    initValue() {
      this.postForm = this.value

      // 赋值
      this.postForm.answerList.forEach((v) => {
        if (v.selected) {
          this.current = v.score
        }
      })
    }

  }
}
</script>

<style scoped>

.answer-list{
  margin-top: 10px;
}

.rank-text{
  display: flex;
  justify-content: space-between;
  margin: 30px 0 0 10px;
  font-weight: 700;
  color: #555;
  padding-bottom: 10px;
}

.style-box{
  margin-bottom: 20px;
  height: 47px;
  position: relative;
  background: #f5f5f5;
  border: #f1f1f1 1px solid;
}

.rank-mask{
  position: absolute;
  top: 0;
  left: 0;
  height:100%;
  background: linear-gradient(to right, #fff, #fcaf1a);
  width: 100%;
}

.circle-num-box{
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  position: relative;
}

.circle-num-box > div{
  width: 24px;
  height: 24px;
  position: absolute;
  top: 0;
  left: 0
}

.circle-num-box > .num{
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}

.rank-num-box{
  position: absolute;
  top: 0;
  left: 0;
  height:100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center
}

.rank-num-item{
  flex-grow:1;
  text-align:center;
  cursor:pointer;
  font-size: 18px;
  color: #555;
  font-weight: 700;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-color{
  color: #aaa;
  font-size: 24px;
}

.rank-unchecked-bg{
  background: #fff;
}

.rank-checked-color{
  color: #e99b05;
  animation: bubble-up 0.6s ease-in-out;
  //animation: bubble-pop 0.5s forwards;
}

@keyframes bubble-pop {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  50% {
    transform: scale(2);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes bubble-up {
  50% {
    transform: translateY(-10px);
    opacity: 1;
  }
}

.q-content{
  display: flex;
}

.q-content > .num{
  padding-right: 10px;
  color: #0A84FF;
  font-weight: 700;
}

.q-content > .req{
  color: #ff3333;
  font-size: 22px;
  line-height: 20px;
  font-weight: 700
}

::v-deep .q-content p{
  margin-block-start: 0;
  margin-block-end: 0;
}
</style>
