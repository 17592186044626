<template>

  <el-select
    v-model="currentValue"
    :disabled="disabled"
    :placeholder="title"
    :remote-method="fetchData"
    size="small"
    filterable
    remote
    reserve-keyword
    clearable
    automatic-dropdown
    class="filter-item"
    @change="handlerChange"
    @clear="fetchData('')"
  >
    <el-option
      v-for="item in dataList"
      :key="item.value"
      :label="item.title"
      :value="item.value"
    />
  </el-select>

</template>

<script>

import { fetchTree } from '@/api/sys/dict/value'

export default {
  name: 'DicListSelect',
  props: {
    dicCode: String,
    title: String,
    value: {
      type: String | Number
    },
    disabled: Boolean,
    excludes: Array
  },
  data() {
    return {
      // 下拉选项值
      dataList: [],
      currentValue: []
    }
  },

  watch: {
    // 检测查询变化
    value: {
      handler() {
        this.fillValue()
      }
    },
    dicCode: {
      handler() {
        this.fetchData()
      }
    }
  },
  created() {
    this.fillValue()
    this.fetchData()
  },
  methods: {

    // 填充数据
    fillValue() {
      if (this.value) {
        this.currentValue = this.value.toString()
      } else {
        this.currentValue = ''
      }
    },

    fetchData() {
      fetchTree({ dicCode: this.dicCode, excludes: this.excludes }).then(res => {
        this.dataList = res.data
      })
    },
    handlerChange(e) {
      this.$emit('change', e)
      this.$emit('input', e)
    }
  }
}
</script>

<style scoped>

::v-deep .el-input--suffix {
  display: flex !important;
  cursor: pointer;
  overflow: hidden;
  align-items: center;
}


</style>
