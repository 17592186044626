var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("data-grid", {
    ref: "dataGrid",
    attrs: { options: _vm.options, "list-query": _vm.listQuery },
    scopedSlots: _vm._u([
      {
        key: "filter-content",
        fn: function () {
          return [
            _c("dic-catalog-tree", {
              staticClass: "filter-item",
              attrs: { "dic-code": "repo_catalog", width: "200" },
              model: {
                value: _vm.listQuery.params.catId,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery.params, "catId", $$v)
                },
                expression: "listQuery.params.catId",
              },
            }),
            _c("el-input", {
              staticClass: "filter-item",
              staticStyle: { width: "200px" },
              attrs: {
                size: "small",
                placeholder: "搜索题库名称",
                "prefix-icon": "el-icon-search",
              },
              model: {
                value: _vm.listQuery.params.title,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery.params, "title", $$v)
                },
                expression: "listQuery.params.title",
              },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "data-item",
        fn: function (ref) {
          var data = ref.data
          return [
            _c("div", { staticClass: "flex-bt" }, [
              _c(
                "div",
                { staticStyle: { "flex-grow": "1" } },
                [
                  _c("detail-link", {
                    attrs: {
                      params: { repoId: data.id },
                      title: data.title,
                      to: "MyRepoView",
                    },
                  }),
                  _c(
                    "span",
                    { staticClass: "tag-box" },
                    [
                      data.price > 0
                        ? _c(
                            "el-tag",
                            { attrs: { type: "danger", size: "mini" } },
                            [_vm._v("￥" + _vm._s(data.price))]
                          )
                        : _c(
                            "el-tag",
                            { attrs: { type: "success", size: "mini" } },
                            [_vm._v("免费")]
                          ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "info-item" }, [
                    _c("span", { staticClass: "title" }, [
                      _vm._v("题库分类："),
                    ]),
                    _c("span", [_vm._v(_vm._s(data.catId_dictText))]),
                  ]),
                  _c("div", { staticClass: "info-item" }, [
                    _c("span", { staticClass: "title" }, [
                      _vm._v("试题数量： "),
                    ]),
                    _c("span", [_vm._v(_vm._s(data.quCount) + "题")]),
                  ]),
                  _c("div", { staticClass: "info-item" }, [
                    _c("span", { staticClass: "title" }, [
                      _vm._v("训练覆盖： "),
                    ]),
                    _c("span", [
                      _vm._v(
                        _vm._s(data.coverCount) +
                          "题（" +
                          _vm._s(data.coverRate) +
                          "%）"
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c("div", [
                _c(
                  "div",
                  [
                    _c(
                      "el-link",
                      {
                        attrs: {
                          type: "danger",
                          icon: "el-icon-document-delete",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.toBook(data.id)
                          },
                        },
                      },
                      [_vm._v("错题记录（" + _vm._s(data.wrongCount) + "）")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "el-link",
                      {
                        attrs: { type: "primary", icon: "el-icon-tickets" },
                        on: {
                          click: function ($event) {
                            return _vm.toTrainRecord(data.id)
                          },
                        },
                      },
                      [_vm._v("训练记录（" + _vm._s(data.trainCount) + "）")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            data.latestTrain
              ? _c(
                  "div",
                  {
                    staticClass: "record-box",
                    staticStyle: { "margin-top": "10px" },
                  },
                  [
                    _c("div", [
                      _c("span", { staticClass: "title" }, [
                        _vm._v("最近训练："),
                      ]),
                      _c("span", { staticClass: "info" }, [
                        _vm._v(
                          " " +
                            _vm._s(data.latestTrain.trainMode_dictText) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("div", [
                      _c("span", { staticClass: "title" }, [
                        _vm._v("正确率："),
                      ]),
                      _c("span", { staticClass: "info" }, [
                        _vm._v(" " + _vm._s(data.latestTrain.percent) + "% "),
                      ]),
                    ]),
                    _c("div", [
                      _c("span", { staticClass: "title" }, [
                        _vm._v("训练时间："),
                      ]),
                      _c("span", { staticClass: "info" }, [
                        _vm._v(" " + _vm._s(data.latestTrain.updateTime) + " "),
                      ]),
                    ]),
                  ]
                )
              : _vm._e(),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }