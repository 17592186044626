<template>

  <div>
    <div v-if="!noDevice" class="cam-box">

      <div class="cam-3x2">
        <video
          id="video"
          ref="video"
          :playsinline="true"
          :autoplay="true"
          style="background: #262626; max-width: 100%; height: auto" />

        <face-tracking
          v-if="tracking && videoLoad"
          ref="tracking"
          :capture="trackCapture"
          video-id="video"
          @face-out="handleFaceOut"
          @face-in="handleFaceIn"
        />

      </div>

      <div style="padding-top: 10px">

        <el-select v-model="deviceId" size="mini" style="width: 100%;">
          <el-option
            v-for="item in cameras"
            :key="item.deviceId"
            :value="item.deviceId"
            :label="item.label"/>
        </el-select>

        <div v-if="tracking && showTrackTips && trackingMsg" class="tips">
          {{ trackingMsg }}
        </div>

      </div>
    </div>
    <el-empty v-else description="摄像头载入失败，请确认电脑上有摄像头且已允许网页访问！"/>
  </div>
</template>

<script>
import FaceTracking from '@/components/FaceTracking/index.vue'

export default {
  name: 'TrackingCam',
  components: { FaceTracking },
  props: {
    captureFormat: {
      type: String,
      default: 'image/jpeg'
    },
    // 是否开启面部追踪
    tracking: {
      type: Boolean,
      default: false
    },
    // 是否在追踪到人脸后截图
    trackCapture: {
      type: Boolean,
      default: true
    },
    // 是否展示追踪提示
    showTrackTips: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      source: null,
      camerasListEmitted: false,
      cameras: [],
      noDevice: false,
      // 摄像头ID
      deviceId: null,
      // 内容偏移
      paddingTop: 0,
      // 人脸识别相关
      videoLoad: true,
      trackingMsg: '',
      captureLock: false
    }
  },

  // 切换摄像头
  watch: {
    deviceId: function(val) {
      if (val) {
        this.changeCamera(val)
      }
    }
  },
  mounted() {
    this.setupMedia()
  },
  beforeDestroy() {
    this.stop()
  },
  methods: {

    // 获取媒体设备
    legacyGetUserMediaSupport() {
      return constraints => {
        const getUserMedia =
          navigator.getUserMedia ||
          navigator.webkitGetUserMedia ||
          navigator.mozGetUserMedia ||
          navigator.msGetUserMedia ||
          navigator.oGetUserMedia
        if (!getUserMedia) {
          return Promise.reject(
            new Error('您的浏览器不支持媒体访问，请升级浏览器！')
          )
        }
        // 旧版浏览器
        return new Promise(function(resolve, reject) {
          getUserMedia.call(navigator, constraints, resolve, reject)
        })
      }
    },

    // 初始化媒体设备
    setupMedia() {
      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {}
      }
      if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = this.legacyGetUserMediaSupport()
      }

      // 测试并获得授权
      this.testMediaAccess()
    },

    // 读取可用的媒体设备
    loadCameras() {
      navigator.mediaDevices
        .enumerateDevices()
        .then(deviceInfos => {
          for (let i = 0; i !== deviceInfos.length; ++i) {
            const deviceInfo = deviceInfos[i]

            // OBS虚拟摄像头排除
            if (deviceInfo.kind === 'videoinput' && deviceInfo.label !== 'OBS Virtual Camera') {
              this.cameras.push(deviceInfo)
            }
          }

          if (this.cameras.length === 0) {
            this.noDevice = true
          }
        })
        .then(() => {
          if (!this.camerasListEmitted) {
            if (this.cameras.length > 0) {
              this.deviceId = this.cameras[0].deviceId
            } else {
              this.noDevice = true
            }
            this.$emit('cameras', this.cameras)
            this.camerasListEmitted = true
          }
        })
        .catch(error => this.$emit('notsupported', error))
    },

    // 更换不同的摄像头, 比如前置摄像头、后置摄像头等
    changeCamera(deviceId) {
      this.stop()
      this.$emit('camera-change', deviceId)
      this.loadCamera(deviceId)
    },

    // 加载视频流
    loadSrcStream(stream) {
      if ('srcObject' in this.$refs.video) {
        this.$refs.video.srcObject = stream
      } else {
        this.source = window.HTMLMediaElement.srcObject(stream)
      }

      // 视频播放
      this.$emit('started', stream)

      // 开始播放视频
      this.$refs.video.onloadedmetadata = () => {
        this.$emit('video-live', stream)

        // 视频下方内容位置
        this.paddingTop = document.querySelector('#video').clientHeight + 5

        // 加载人脸追踪
        this.videoLoad = true
      }
    },

    // 停止流读取
    stopStreamedVideo(videoElem) {
      const stream = videoElem.srcObject
      const tracks = stream.getTracks()
      tracks.forEach(track => {
        track.stop()
        this.$emit('stopped', stream)
        this.$refs.video.srcObject = null
        this.source = null
      })
    },
    // 停止播放
    stop() {
      if (this.$refs.video !== null && this.$refs.video.srcObject) {
        this.stopStreamedVideo(this.$refs.video)
      }
    },
    // 开始播放
    start() {
      if (this.deviceId) {
        this.loadCamera(this.deviceId)
      }
    },
    // 暂停播放
    pause() {
      if (this.$refs.video !== null && this.$refs.video.srcObject) {
        this.$refs.video.pause()
      }
    },
    // 继续视频
    resume() {
      if (this.$refs.video !== null && this.$refs.video.srcObject) {
        this.$refs.video.play()
      }
    },
    /**
     * test access
     */
    testMediaAccess() {
      const constraints = { video: true }
      if (this.resolution) {
        constraints.video = {}
        constraints.video.height = this.resolution.height
        constraints.video.width = this.resolution.width
      }
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(stream => {
          const tracks = stream.getTracks()
          tracks.forEach(track => {
            track.stop()
          })

          this.loadCameras()
        })
        .catch(error => {
          this.noDevice = true
          this.$emit('error', error)
        })
    },

    // 指定媒体设备进行
    loadCamera(device) {
      // 清除识别框
      if (this.context && this.canvas) {
        this.context.clearRect(0, 0, this.canvas.width, this.canvas.height)
      }

      const constraints = { video: { deviceId: { exact: device }, facingMode: 'user' }}
      if (this.resolution) {
        constraints.video.height = this.resolution.height
        constraints.video.width = this.resolution.width
      }
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(stream => this.loadSrcStream(stream))
        .catch(error => this.$emit('error', error))
    },

    // 截屏生成base64图片
    captureAsBase64() {
      const video = this.$refs.video
      // 直接截图
      const canvas = document.createElement('canvas')
      canvas.height = video.videoHeight
      canvas.width = video.videoWidth
      const ctx = canvas.getContext('2d')
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height)
      const image = canvas.toDataURL(this.captureFormat)
      return image.replace(/^data:image\/\w+;base64,/, '')
    },

    // 静默截屏，截屏时候无感知
    capture() {
      // 转换成base64去除头
      this.$emit('capture', this.captureAsBase64())
    },

    // 人脸识别到，如果要拍照则拍照
    handleFaceIn() {
      // 延时截屏
      if (!this.captureLock && this.trackCapture) {
        this.captureLock = true
        this.trackingMsg = '很好，请保持这个姿势！'
        // 停留片刻截屏
        setTimeout(() => {
          // 暂停视频
          this.pause()
          // 按画布大小进行截图覆盖
          const base64 = this.captureAsBase64()
          this.trackingMsg = '正在比对，请稍候..'
          // 回调人脸截图
          this.$emit('tracked', base64)
        }, 1000)
      }
    },

    // 人脸不在视频区域
    handleFaceOut() {
      this.$emit('face-out')
    },

    // 重新追踪人脸并截图
    reTrack() {
      this.start()
      this.captureLock = false
    }
  }
}
</script>

<style scoped>

.cam-box{
  position: relative;
  width: 100%;
  height: auto;
}

video, canvas, img {
  position: absolute;
  left: 0;
  top: 0;
}

.tips{
  margin-top: 10px;
  font-size: 12px;
  font-weight: 700;
}

.cam-3x2{
  width: 100%;
  position: relative;
  height: 0;
  padding-bottom: 70%;
  cursor: pointer;
  video,canvas {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 2px;
  }
}

</style>
