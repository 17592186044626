<template>

  <div>

    <data-grid
      ref="dataGrid"
      :options="options"
      :list-query="listQuery">
      <template slot="filter-content">
        <dic-catalog-tree v-model="listQuery.params.catId" dic-code="course_catalog" placeholder="课程分类" width="200" class="filter-item" />
        <el-input
          v-model="listQuery.params.title"
          class="filter-item"
          placeholder="搜索课程名称"
          prefix-icon="el-icon-search"
          size="small"
          style="width: 200px; margin-left: 10px"
        />
      </template>
      <template v-slot:data-item="{ data }">

        <div @click="handelView(data.id)">
          <image-box :src="data.cover" />
        </div>

        <div>
          <detail-link :id="data.id" :title="data.title" @click="handelView" />
          <el-tag v-if="data.liveCount > 0" style="margin-left: 5px" type="danger" size="mini">直播</el-tag>
        </div>

        <div style="color: #666; font-size: 12px">
          {{ data.contentText }}
        </div>

        <div style="display: flex; align-items: center">
          <div style="flex-grow: 1">
            <div style="color: #666; font-size: 12px">
              课程分类：{{ data.catId_dictText }}
            </div>
            <div style="color: #666; font-size: 12px">
              课程售价：  <el-tag v-if="data.price > 0" type="danger" size="mini">￥{{ data.price }}</el-tag>
              <el-tag v-else type="success" size="mini">免费</el-tag>
            </div>
          </div>
          <div>
            <div :class="{'fav-btn-off': !data.hasJoin}" class="fav-btn" @click="handleJoin(data)">
              <i class="el-icon-star-on"/>
            </div>
          </div>
        </div>


      </template>

    </data-grid>

  </div>

</template>

<script>

import { mapGetters } from 'vuex'
import { joinCourse } from '@/api/client/course/course'
import ImageBox from '@/components/ImageBox/index.vue'

export default {
  name: 'OpenCoursePage',
  components: { ImageBox },
  props: {
    onlyLearn: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {

      defaultCover: require('@/assets/web/images/course_default.jpg'),

      listQuery: {
        current: 1,
        size: 12,
        params: {

        }
      },

      options: {
        // 列表请求URL
        listUrl: '/api/client/course/open-paging',
        lineCount: 4
      }
    }
  },

  computed: {
    ...mapGetters([
      'siteData'
    ])
  },

  methods: {

    // 加入我的课程
    handleJoin(data) {
      joinCourse(data.id).then(() => {
        this.$emit('join')
        data.hasJoin = !data.hasJoin
      })
    },

    // 课程详情
    handelView(courseId) {
      this.$router.push({ name: 'CourseView', params: { courseId: courseId }})
    }

  }
}
</script>

<style lang="scss" scoped>
.image-3x2{
  width: 100%;
  position: relative;
  height: 0;
  padding-bottom: 56%;
  cursor: pointer;
  .el-image{
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 2px;
  }
}
</style>

