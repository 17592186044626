<template>

  <el-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :append-to-body="true"
    top="5vh"
    title="设备调试"
    width="800px"
    @close="handleClose"
  >

    <div v-if="dialogVisible">

      <div v-if="step==='camera'">

        <div id="video_camera" class="v-box"/>
        <div class="tips-lg">能看到视频图像则表示摄像头正常</div>
        <div style="padding-top: 10px">
          <el-select v-model="deviceId" size="mini" style="width: 100%;" placeholder="选择摄像头">
            <el-option
              v-for="item in cameras"
              :key="item.deviceId"
              :value="item.deviceId"
              :label="item.label"/>
          </el-select>
        </div>

        <div class="tips-sm">
          <div>1、网页必须基于https下才能调用摄像头和麦克风设备。</div>
          <div>2、推荐使用Chrome谷歌浏览器、Firefox火狐浏览器。</div>
          <div>3、地址栏若弹出询问是否允许摄像头/麦克风时，必须允许。</div>
        </div>
      </div>

      <div v-if="step==='mobile'">

        <div class="m-box">
          <div style="flex-grow: 1">
            <div id="video_mobile" class="v-box" />
            <div class="tips-lg">能看到画面则说明手机摄像头正常</div>
            <div class="tips-sm">
              <div>1、调试成功后请退出手机端、正式考试需再次进行扫码。</div>
              <div>2、扫码成功后请将摄像头放置于电脑斜45度，确保能看到完整的视角。</div>
              <div>3、请保证摄像头开启直至考试结束、注意手机不要息屏。</div>
            </div>
          </div>
          <div class="m-right">
            <div class="mr-tt">副摄像头二维码</div>
            <div>
              <vue-qr :text="camUrl" :size="180" />
            </div>
            <div class="mr-tips">
              请使用手机扫描上面二维码
            </div>
          </div>
        </div>
      </div>

      <div v-if="step==='screen'">
        <div id="video_screen" class="v-box"/>
        <div class="tips-lg">能看到视频图像则表示录屏功能正常</div>
        <div class="tips-sm">
          <div>1、点击【整个屏幕】选项卡 > 选中屏幕图像 > 点击【分享】按钮</div>
          <div>2、Mac电脑需要给浏览器授权才能进行屏幕录制，请在【隐私与安全】为浏览器开启录制权限。</div>
        </div>
      </div>

      <div style="display: flex; justify-content: space-between; margin-top: 10px">
        <el-button :disabled="step==='camera'" style="flex-grow: 1" type="primary" icon="el-icon-back" @click="handlePrev">上一步</el-button>
        <el-button :disabled="step==='screen'" style="flex-grow: 1" type="primary" icon="el-icon-right" @click="handleNext">下一步</el-button>
        <el-button v-if="step==='screen'" style="flex-grow: 1" type="danger" icon="el-icon-close" @click="handleClose">关闭</el-button>
      </div>

    </div>

  </el-dialog>

</template>

<script>
import vueQr from 'vue-qr'
import { roomToken } from '@/api/ability/rtc/qnrtc'
import QNRTC, { QNLogLevel } from 'qnweb-rtc'
// 关闭日志
QNRTC.setLogLevel(QNLogLevel.NONE)
// client实例
const client = QNRTC.createClient()


export default {
  name: 'RtcDeviceTest',
  components: { vueQr },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogVisible: false,
      step: 'camera',
      camUrl: '',
      paperId: '',
      examId: '',
      userId: '',
      screenTrack: null,
      videoTrack: null,
      // 摄像头ID
      cameras: [],
      deviceId: null
    }
  },

  watch: {

    // 检测查询变化
    visible: {
      handler(val) {
        this.dialogVisible = val
        if (val) {
          this.initClient()
        } else {
          this.leaveRoom()
        }
      }
    },
    deviceId: function(val) {
      if (val) {
        // 重新开始推送
        this.testCamera()
      }
    }
  },

  created() {
    // 构建测试数据
    this.buildData()

    // 获得摄像头列表
    QNRTC.getCameras().then(res => {
      for (let i = 0; i<res.length; i++) {
        const deviceInfo = res[i]

        // OBS虚拟摄像头排除
        if (deviceInfo.kind === 'videoinput' && deviceInfo.label !== 'OBS Virtual Camera') {
          this.cameras.push(deviceInfo)
          if (!this.deviceId) {
            this.deviceId = deviceInfo.deviceId
          }
        }
      }
    })
  },

  // 取消全部流
  beforeDestroy() {
    this.leaveRoom()
  },

  methods: {

    // 构建临时数据
    buildData() {
      this.examId = new Date().getTime()
      this.paperId = new Date().getTime()
      this.userId = new Date().getTime()
      this.camUrl = document.location.protocol + '//' + window.location.host + '/pages/cam?examId=' + this.examId + '&paperId=' + this.paperId + '&userId=' + this.userId
      console.log('调试连接', this.camUrl)
    },

    // 初始化客户端
    async initClient() {
      const userId = this.userId + '_' + this.paperId + '_camera'
      const params = { userId: userId, roomName: this.examId }
      const that = this
      roomToken(params).then(async res => {
        await client.join(res.data.token)
        await that.testCamera()
      })
    },

    // 测试摄像头
    async testCamera() {
      // 取消发布
      await this.cancelAll()
      this.step = 'camera'
      // 采集视频
      this.videoTrack = await QNRTC.createCameraVideoTrack({
        encoderConfig: '720p',
        cameraId: this.deviceId
      })
      // 发布流
      await client.publish(this.videoTrack)
      // 获取页面上的一个元素作为播放画面的父元素
      const videoElement = document.getElementById('video_camera')
      await this.videoTrack.play(videoElement, { mirror: false })
    },

    // 断开流
    async cancelAll() {
      if (this.videoTrack) {
        this.videoTrack.destroy()
      }
      if (this.screenTrack) {
        this.screenTrack.destroy()
      }
    },

    // 录屏测试
    async testScreen() {
      // 取消发布
      await this.cancelAll()
      this.step = 'screen'
      // 采集屏录制
      this.screenTrack = await QNRTC.createScreenVideoTrack({
        encoderConfig: '720p'
      })
      // 发布流
      await client.publish(this.screenTrack)
      // 获取页面上的一个元素作为播放画面的父元素
      const videoElement = document.getElementById('video_screen')
      await this.screenTrack.play(videoElement, { mirror: false })
    },

    // 调试移动端
    async testMobile() {
      await this.cancelAll()
      this.step = 'mobile'
      this.autoSubscribe(client)
    },

    // 离开房间
    leaveRoom() {
      this.cancelAll()
      client.leave()
    },

    handleClose() {
      this.$emit('update:visible', false)
    },

    handlePrev() {
      if (this.step === 'screen') {
        this.testMobile()
      }

      if (this.step === 'mobile') {
        this.testCamera()
      }
    },

    handleNext() {
      if (this.step === 'camera') {
        this.testMobile()
      }

      if (this.step === 'mobile') {
        this.testScreen()
      }
    },

    /**
     * 订阅远端用户发布的音视频轨道
     */
    async subscribe(userId, client, tracks) {
      const remoteTracks = await client.subscribe(tracks)
      // 遍历返回的远端 Track，调用 play 方法完成在页面上的播放
      for (const remoteTrack of [...remoteTracks.videoTracks]) {
        console.log('++++++subscribe', userId)
        if (userId.endsWith('_mobile') && remoteTrack.isVideo()) {
          // 选择页面上的一个元素作为父元素，播放远端的音视频轨
          const remoteElement = document.getElementById('video_mobile')
          remoteElement.innerHTML = ''
          remoteTrack.play(remoteElement)
        }
      }
    },
    // 这里的参数 client 是指刚刚初始化的 QNRTCClient 对象, 同上
    autoSubscribe(client) {
      const thatId = this.userId + '_' + this.paperId + '_mobile'
      console.log('++++++thatId', thatId)
      // 添加事件监听，当房间中出现新的 Track 时就会触发，参数是 trackInfo 列表
      client.on('user-published', (userId, tracks) => {
        console.log('++++++userId', userId)
        // 只订阅自己的
        if (userId === thatId) {
          this.subscribe(userId, client, tracks)
            .then(() => console.log('subscribe success!'))
            .catch(e => console.error('subscribe error', e))
        }
      })
    }
  }
}
</script>

<style scoped>

.tips-lg{
  background-color:#262626;
  color:#fff;
  text-align: center;
  font-weight: 700;
  padding: 10px 0;
  font-size: 16px;
  cursor: pointer;
}

.tips-sm{
  line-height: 22px;
  margin-top: 10px;
  color: #888;
  font-size: 12px;
}

.m-box{
  display: flex;
  width: 100%
}

.m-right{
  width: 200px;
  text-align: center
}

.mr-tt{
  color: #4377FB;
  font-weight: 700
}

.mr-tips{
  color: #FF6A00;
  font-size: 12px;
}

.v-box{
  height: 400px;
  background: #262626
}

</style>
