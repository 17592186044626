var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("data-table", {
        ref: "pagingTable",
        attrs: { options: _vm.options, "list-query": _vm.listQuery },
        scopedSlots: _vm._u([
          {
            key: "data-columns",
            fn: function () {
              return [
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    label: "答题时间",
                    prop: "createTime",
                  },
                }),
                _c("el-table-column", {
                  attrs: { align: "center", label: "答题分数", prop: "score" },
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    label: "答对题数",
                    prop: "trueCount",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    label: "答错题数",
                    prop: "falseCount",
                  },
                }),
                _c("el-table-column", {
                  attrs: { align: "center", label: "操作", fixed: "right" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-link",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleDetail(scope.row.id)
                                },
                              },
                            },
                            [_vm._v("答题详情")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }