var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "destroy-on-close": true,
        "show-close": true,
        title: "导入问题",
        width: "30%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.handleClose,
      },
    },
    [
      _c("import-excel", {
        attrs: {
          data: { surveyId: _vm.surveyId },
          title: "导入问题",
          action: "/api/survey/qu/import",
          template: "/api/survey/qu/import/template",
        },
        on: { success: _vm.importSuccess },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }