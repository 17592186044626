// 知识竞赛模块
import WebLayout from '@/layout/WebLayout'

const activityRouter = {
  path: '/activity',
  component: WebLayout,
  redirect: '/pages/activity',
  hidden: true,
  children: [

    {
      path: '/pages/activity',
      component: () => import('@/views/web/activity/index'),
      name: 'WebActivityList',
      meta: { title: '活动报名', noCache: true }
    },

    {
      path: '/pages/activity/detail/:id',
      component: () => import('@/views/web/activity/detail'),
      name: 'WebActivityDetail',
      meta: { title: '活动详情', noCache: true }
    }

  ]
}
export default activityRouter
