<template>

  <div>
    <el-tabs v-model="activeTab" type="card">
      <el-tab-pane label="积分兑换" name="my">
        <mall-goods-page ref="my" />
      </el-tab-pane>
      <el-tab-pane label="我的订单" name="order">
        <mall-order-page ref="order"/>
      </el-tab-pane>
      <el-tab-pane label="地址管理" name="addr">
        <mall-address
          :show-radio="false"
          :show-delete="true" />
      </el-tab-pane>
    </el-tabs>
  </div>

</template>

<script>

import MallGoodsPage from '@/views/web/mall/components/MallGoodsPage/index.vue'
import MallAddress from '@/views/web/mall/components/MallAddress/index.vue'
import MallOrderPage from '@/views/web/mall/components/MallOrderPage/index.vue'

export default {
  components: { MallOrderPage, MallAddress, MallGoodsPage },
  data() {
    return {
      activeTab: 'my'
    }
  },

  created() {
    const to = this.$route.query.to
    if (to) {
      this.activeTab = to
    }
  },
  methods: {

    reloadMy() {
      this.$refs.my.reload()
    }
  }
}
</script>
