<template>
  <div :class="theme" class="sv-page" >

    <div :class="{'view-mask':viewMode}" class="sv-body">
      <div class="sv-title">
        {{ paper.title }}
      </div>
      <div
        v-if="paper.content"
        class="sv-content"
        v-html="$options.filters.formatRichText(paper.content)">
        问卷说明
      </div>

      <div
        v-for="(item,index) in paper.quList"
        :key="item.id"
        :id="`qu-${item.id}`"
        :class="{'error-box': errorId === item.id}"
        class="q-box">
        <survey-answer-select
          v-if="item.quType==='1' || item.quType==='2' || item.quType==='27' || item.quType==='28'"
          :value="item"
          @change="saveAnswer($event, index)"/>

        <survey-answer-rank
          v-if="item.quType==='26'"
          :value="item"
          @change="saveAnswer($event, index)"/>

        <survey-answer-ask
          v-if="item.quType==='4'"
          :value="item"
          @change="saveAnswer($event, index)"/>

        <div v-if="errorId === item.id" class="error-text">!{{ errorText }}</div>
      </div>


      <div v-if="!viewMode" style="text-align: center; padding: 20px 0">
        <el-button type="danger" style="width: 50%" icon="el-icon-s-promotion" @click="submitPaper">提交问卷</el-button>
      </div>
    </div>
  </div>

</template>

<script>
import { detailForAnswer, fillAnswer, handPaper } from '@/api/survey/paper'
import SurveyAnswerSelect from '@/views/web/survey/components/SurveyAnswerSelect/index.vue'
import SurveyAnswerRank from '@/views/web/survey/components/SurveyAnswerRank/index.vue'
import { scrollToDom } from '@/utils/scroll-to'
import SurveyAnswerAsk from '@/views/web/survey/components/SurveyAnswerAsk/index.vue'


export default {
  components: { SurveyAnswerAsk, SurveyAnswerRank, SurveyAnswerSelect },
  data() {
    return {
      paperId: '',
      paper: {},
      errorId: '',
      errorText: '',
      viewMode: false
    }
  },
  computed: {
    theme() {
      if (!this.paper.id) {
        return ''
      }
      if (!this.paper.theme) {
        return 'sv-theme-default'
      }
      return 'sv-theme-' + this.paper.theme
    }
  },

  created() {
    // 答卷ID
    this.paperId = this.$route.query.paperId
    this.viewMode = this.$route.query.flag && this.$route.query.flag === 'view'
    this.fetchDetail()
  },
  methods: {

    // 获取详情
    fetchDetail() {
      detailForAnswer(this.paperId).then(res => {
        this.paper = res.data
      })
    },

    // 保存答案
    saveAnswer(val, i) {
      console.log('+++++++val', val)
      fillAnswer(val).then(() => {
        console.log('答案已保存..')
        this.paper.quList[i] = val
      })
    },

    // 数据校验
    allCheck() {
      return new Promise((resolve) => {
        for (let i = 0; i<this.paper.quList.length; i++) {
          const qu = this.paper.quList[i]
          const quType = qu.quType

          // 必答
          if (qu.required) {
            if (quType === '1' || quType === '2' || quType === '26' || quType === '27' || quType === '28') {
              const count = qu.answerList.filter(item => item.selected === true).length

              // 单选多选量表通用判断
              if (count === 0) {
                this.errorText = '此题为必答，请作答后再提交！'
                this.focusError(qu.id)
                return
              }

              // 选择数量判断
              if ((quType === '2' || quType === '28') && (qu.selectMin > 0 && qu.selectMax>0)) {
                if (count > qu.selectMax || count < qu.selectMin) {
                  this.errorText = '请最少选' + qu.selectMin + '项，最多选' + qu.selectMax + '项'
                  this.focusError(qu.id)
                  return
                }
              }
            }

            // 问答题
            if (quType === '4' && !qu.answer) {
              this.errorText = '此题为必答，请作答后再提交！'
              this.focusError(qu.id)
              return
            }
          }
        }

        resolve()
      })
    },

    // 指定到子题目
    focusError(quId) {
      this.errorId = quId

      const dom = document.querySelector('.sv-page')
      const anchor = document.getElementById(`qu-${quId}`)
      const to = anchor.offsetTop - 50
      // 滑动到指定位置
      scrollToDom(dom, to, 600, function() {
      })

      setTimeout(() => {
        this.errorId = ''
      }, 3500)
    },

    // 提交问卷
    submitPaper() {
      this.allCheck().then(() => {
        handPaper({ id: this.paperId }).then(() => {
          this.$router.push({ name: 'SurveyThanks' })
        })
      })
    }
  }
}
</script>

<style scoped>

.sv-bg{
  background: url("~@/assets/web/bg-work.jpg") no-repeat center;
  background-size: cover;
  height: 100vh;
  overflow-y: auto;
  padding: 120px 0 40px 0;
}

.q-box{
  border: #eee 1px solid;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.error-box{
  border-style: dashed;
  border-width: 2px;
  animation: blink 0.5s infinite;
}

.error-text{
  color: #ff3333;
  font-weight: 700;
  font-size: 14px;
  padding-top: 10px;
}

.app-container{
  padding: 0 !important;
}

@keyframes blink {
  0% {
    border-color: transparent;
  }
  50% {
    border-color: red;
  }
  100% {
    border-color: transparent;
  }
}

/*只读遮罩*/
.view-mask{
  pointer-events: none;
  touch-action: none;
  overflow: scroll;
}
</style>
