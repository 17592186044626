<template>
  <train-record-page :repo-id="repoId" />
</template>

<script>
import TrainRecordPage from '@/views/web/repo/components/TrainRecordPage/index.vue'

export default {
  components: { TrainRecordPage },
  data() {
    return {
      repoId: null
    }
  },
  created() {
    this.repoId = this.$route.query.repoId
  }
}
</script>
