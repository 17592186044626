<template>

  <el-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    title="问答详情"
    width="60%"
    top="5vh"
    @close="handleClose"
  >

    <div class="chat-box">

      <div>
        <div class="flex-bt">
          <div>
            <yf-avatar :src="detail.avatar" :size="50"/>
          </div>
          <div class="tp-nbox">
            <div class="tp-nick">{{ detail.realName }}</div>
            <div class="tp-time">{{ detail.createTime }}</div>
          </div>
        </div>

        <div class="bubble">
          <div v-html="$options.filters.formatRichText(detail.content)" />
        </div>
      </div>


      <div v-if="replyList && replyList.length > 0" style="margin-top: 50px">

        <div v-for="reply in replyList" :key="reply.id" >
          <div>
            <div style="display: flex; justify-content: flex-end">
              <div class="tp-nbox" style="text-align: right">
                <div class="tp-nick" style="color: #FF3649">{{ reply.realName }}</div>
                <div class="tp-time">{{ reply.createTime }}</div>
              </div>
              <div>
                <yf-avatar :src="reply.avatar" :size="50"/>
              </div>
            </div>
          </div>

          <div class="bubble bubble-a">
            <div v-html="$options.filters.formatRichText(reply.content)" />
          </div>
        </div>

      </div>

      <div v-else>

        <div>
          <div style="display: flex; justify-content: flex-end">
            <div class="tp-nbox" style="text-align: right">
              <div class="tp-nick" style="color: #FF3649">云帆考试</div>
              <div class="tp-time">{{ detail.createTime }}</div>
            </div>
            <div>
              <yf-avatar :size="50"/>
            </div>
          </div>
        </div>

        <div class="bubble bubble-a">
          <div>此问答还没有回复奥~</div>
        </div>

      </div>


    </div>

  </el-dialog>

</template>

<script>

import { qaDetail, qaPaging } from '@/api/course/qa'
import YfAvatar from '@/components/YfAvatar/index.vue'
export default {
  name: 'CourseQaDetailDialog',
  components: { YfAvatar },
  props: {
    qaId: String,
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogVisible: false,
      detail: {},
      replyList: []
    }
  },

  watch: {

    // 检测查询变化
    visible: {
      handler() {
        this.dialogVisible = this.visible
      }
    },
    // 检测查询变化
    qaId: {
      handler() {
        this.showDetail()
      }
    }
  },

  created() {
    this.dialogVisible = this.visible
    this.showDetail()
  },

  methods: {


    // 关闭回调
    handleClose() {
      this.$emit('update:visible', false)
    },

    showDetail() {
      if (!this.qaId) {
        return
      }
      qaDetail(this.qaId).then(res => {
        this.detail = res.data
        this.listReply()
      })
    },

    listReply() {
      qaPaging({ current: 1, size: 100, params: { qaId: this.qaId }}).then(res => {
        this.replyList = res.data.records
      })
    }

  }
}
</script>

<style scoped>

.chat-box{
  margin-bottom: 5px;
  border: #ededed 1px solid;
  padding: 20px
}


.tp-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: #f2f2f2 1px solid;
}

.tp-nbox {
  padding-left: 10px;
  padding-right: 10px;
  flex-grow: 1;
}

.tp-nick {
  font-size: 16px;
  color: #333;
  font-weight: 700;
}

.tp-time {
  color: #999;
  font-size: 14px;
}

.bubble{
  background: #e9e9e9;
  border-radius: 5px;
  padding: 10px;
  margin-right: 100px;
  position: relative;
  display: inline-block;
  font-size: 16px;
  width: 80%;
  margin-top: 12px;
  color: #333;
}

::v-deep .bubble p{
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
  line-height: 28px;
}

.bubble:before {
  content: "";
  position: absolute;
  top: -24px;
  left: 80px;
  margin-left: -15px;
  border: 12px solid transparent;
  border-bottom: 12px solid #e9e9e9;
}

.bubble-a{
  background: #9dbcfb !important;
  margin-left: 100px;
  width: calc(100% - 100px) !important;
}

.bubble-a:before {
  left: calc(100% - 100px) !important;
  border-bottom: 12px solid #9dbcfb;
}
</style>
