import { post } from '@/utils/request'

/**
 * 删除错题
 * @param ids
 */
export function deleteQu(ids) {
  return post('/api/client/book/delete', { ids: ids })
}

/**
 * 答对自动移除
 * @param refId
 * @param quId
 * @returns {Promise}
 */
export function autoRemove(refId, quId) {
  return post('/api/client/book/auto-remove', { refId, quId })
}
