var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "result-box" }, [
    _c("div", { staticClass: "score" }, [
      _vm._v(" " + _vm._s(_vm.paperData.score)),
      _c("span", [_vm._v("分")]),
    ]),
    _c("div", { staticStyle: { display: "flex" } }, [
      _c(
        "div",
        {
          staticStyle: {
            width: "50%",
            display: "flex",
            "align-items": "center",
          },
        },
        [
          _c(
            "i",
            {
              staticClass: "el-icon-success",
              staticStyle: {
                color: "#1CA035",
                "font-size": "22px",
                width: "100px",
              },
            },
            [_vm._v(_vm._s(_vm.paperData.trueCount) + "题")]
          ),
        ]
      ),
      _c(
        "div",
        {
          staticStyle: {
            width: "50%",
            display: "flex",
            "align-items": "center",
          },
        },
        [
          _c(
            "i",
            {
              staticClass: "el-icon-error",
              staticStyle: {
                color: "#ff0000",
                "font-size": "22px",
                width: "100px",
              },
            },
            [_vm._v(_vm._s(_vm.paperData.falseCount) + "题")]
          ),
        ]
      ),
    ]),
    _c("div", [_vm._v(" 答题结束，感谢您的参与！ ")]),
    _c("div", { staticStyle: { color: "#c26a3e", "line-height": "20px" } }, [
      _vm._v("今日剩余" + _vm._s(_vm.paperData.chance) + "次答题机会"),
    ]),
    _c(
      "div",
      [
        _c(
          "el-button",
          { attrs: { type: "danger" }, on: { click: _vm.showPaper } },
          [_vm._v("答题详情")]
        ),
        _c(
          "el-button",
          { attrs: { type: "primary" }, on: { click: _vm.backToBattle } },
          [_vm._v("返回竞赛")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }