// 课程模块
import WebLayout from '@/layout/WebLayout'

const surveyRouter = {
  path: '/survey',
  component: WebLayout,
  redirect: '/pages/survey',
  hidden: true,
  children: [
    {
      path: '/pages/survey',
      component: () => import('@/views/web/survey/index.vue'),
      name: 'Survey',
      meta: { title: '问卷调查', noCache: true, activeMenu: '/pages/survey' }
    },
    {
      path: '/pages/survey/thanks',
      component: () => import('@/views/web/survey/thanks.vue'),
      name: 'SurveyThanks',
      meta: { title: '感谢提交', noCache: true, activeMenu: '/pages/survey' }
    }
  ]
}
export default surveyRouter
