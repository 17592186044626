<template>

  <el-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    :show-close="false"
    title="人脸登录"
    width="540px"
  >
    <div class="face-box">

      <tracking-cam
        v-if="dialogVisible"
        ref="cam"
        :tracking="true"
        @tracked="handleTracked"/>

    </div>

    <div slot="footer" class="dialog-footer">
      <el-button type="danger" @click="handleClose">取 消</el-button>
    </div>

  </el-dialog>

</template>

<script>

export default {
  name: 'FaceLoginDialog',
  components: { },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    params: {
      type: Object,
      default: function() {
        return {}
      }
    },
    url: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dialogVisible: false,
      faceInfo: {
        id: '',
        realName: '',
        idCard: ''
      }
    }
  },

  watch: {

    // 检测查询变化
    visible: {
      handler() {
        this.dialogVisible = this.visible
      },
      deep: true
    }
  },

  created() {
    this.dialogVisible = this.visible
  },

  methods: {

    // 关闭窗口
    handleClose() {
      this.$message.info('窗口已关闭，若要重新识别请刷新页面！')
      this.$emit('update:visible', false)
    },

    // 面部追踪成功
    handleTracked(base64) {
      this.$store.dispatch('user/faceLogin', { base64: base64 }).catch(this.failBack)
    },

    // 识别失败
    failBack() {
      // 重新截屏
      this.$refs.cam.reTrack()
    }
  }
}
</script>

<style scoped>

.face-box{
  position: relative;
  width: 100%;
  height: auto;
}

</style>
