var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "page-out" },
    [
      _c("el-header", { staticClass: "page-header" }, [_c("web-header")], 1),
      _c(
        "el-main",
        { staticClass: "page-main container" },
        [_vm.$slots.main ? _vm._t("main") : _c("app-main")],
        2
      ),
      _vm.siteData.copyRight
        ? _c("el-footer", { staticClass: "page-footer" }, [
            _c("div", {
              domProps: { innerHTML: _vm._s(_vm.siteData.copyRight) },
            }),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }