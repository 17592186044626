<template>

  <span class="state-flag">
    <span v-if="flag==='NOT_START'" class="warning">【未开始】</span>
    <span v-if="flag==='WAIT_REVIEW'" class="warning">【待阅卷】</span>
    <span v-if="flag==='IN_REVIEW'" class="warning">【阅卷中】</span>
    <span v-if="flag==='FINISHED'" class="danger">【已出分】</span>
    <span v-if="flag==='PROCESSING'" class="success">【考试中】</span>
    <span v-if="flag==='OVERDUE'" class="info">【已过期】</span>
  </span>

</template>

<script>

export default {
  name: 'ExamStateFlag',
  props: {
    flag: String
  }
}
</script>

<style scoped>
.state-flag{
  font-weight: 700;
}
</style>

