var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "flex", "justify-content": "space-between" } },
    [
      _c(
        "div",
        { ref: "content", staticClass: "left-content" },
        [
          _vm.quList.length === 0
            ? _c(
                "div",
                [
                  _c("el-empty", {
                    attrs: {
                      description:
                        "该问卷还没有添加题目，请点击右侧的题型开始添加吧！",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._l(_vm.quList, function (item, index) {
            return _c("div", { key: index }, [
              _c(
                "div",
                { staticClass: "survey-qu-box", attrs: { id: "qu-" + index } },
                [
                  _c("div", { staticClass: "survey-qu-title" }, [
                    _vm._v(
                      "第" +
                        _vm._s(index + 1) +
                        "题：" +
                        _vm._s(_vm._f("quTypeFilter")(item.quType))
                    ),
                  ]),
                  item.quType === "1" ||
                  item.quType === "2" ||
                  item.quType === "27" ||
                  item.quType === "28"
                    ? _c("survey-qu-type-select", {
                        attrs: { "survey-id": _vm.surveyId, value: item },
                        on: {
                          "update:value": function ($event) {
                            item = $event
                          },
                        },
                      })
                    : _vm._e(),
                  item.quType === "4"
                    ? _c("survey-qu-type-ask", {
                        attrs: { "survey-id": _vm.surveyId, value: item },
                        on: {
                          "update:value": function ($event) {
                            item = $event
                          },
                        },
                      })
                    : _vm._e(),
                  item.quType === "26"
                    ? _c("survey-qu-type-rank", {
                        attrs: { "survey-id": _vm.surveyId, value: item },
                        on: {
                          "update:value": function ($event) {
                            item = $event
                          },
                        },
                      })
                    : _vm._e(),
                  !item.visible
                    ? _c("div", { staticClass: "hide-mask" })
                    : _vm._e(),
                ],
                1
              ),
            ])
          }),
        ],
        2
      ),
      _c("div", { staticClass: "right-preview" }, [
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "justify-content": "space-between",
            },
          },
          [
            _c(
              "el-dropdown",
              { on: { command: _vm.addCommand } },
              [
                _c(
                  "el-button",
                  {
                    staticStyle: { width: "150px" },
                    attrs: { type: "primary", size: "small" },
                  },
                  [
                    _vm._v(" 添加新题目"),
                    _c("i", {
                      staticClass: "el-icon-arrow-down el-icon--right",
                    }),
                  ]
                ),
                _c(
                  "el-dropdown-menu",
                  [
                    _c(
                      "el-dropdown-item",
                      { attrs: { command: "radio" } },
                      [
                        _c("svg-icon", { attrs: { "icon-class": "qu-radio" } }),
                        _vm._v(" 单选题 "),
                      ],
                      1
                    ),
                    _c(
                      "el-dropdown-item",
                      { attrs: { command: "multi" } },
                      [
                        _c("svg-icon", { attrs: { "icon-class": "qu-multi" } }),
                        _vm._v(" 多选题 "),
                      ],
                      1
                    ),
                    _c(
                      "el-dropdown-item",
                      { attrs: { command: "ask" } },
                      [
                        _c("svg-icon", { attrs: { "icon-class": "qu-ask" } }),
                        _vm._v(" 简答题 "),
                      ],
                      1
                    ),
                    _c(
                      "el-dropdown-item",
                      { attrs: { command: "rank" } },
                      [
                        _c("svg-icon", { attrs: { "icon-class": "qu-rank" } }),
                        _vm._v(" 量表题 "),
                      ],
                      1
                    ),
                    _c(
                      "el-dropdown-item",
                      { attrs: { command: "radio-score" } },
                      [
                        _c("svg-icon", {
                          attrs: { "icon-class": "qu-radio-score" },
                        }),
                        _vm._v(" 单选评分 "),
                      ],
                      1
                    ),
                    _c(
                      "el-dropdown-item",
                      { attrs: { command: "multi-score" } },
                      [
                        _c("svg-icon", {
                          attrs: { "icon-class": "qu-multi-score" },
                        }),
                        _vm._v(" 多选评分 "),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-button",
              {
                staticStyle: { width: "150px" },
                attrs: {
                  type: "danger",
                  icon: "el-icon-upload2",
                  size: "small",
                },
                on: { click: _vm.showImport },
              },
              [_vm._v("批量导入")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "overview-list" },
          _vm._l(_vm.quList, function (item, index) {
            return _c("div", { key: "qu-" + index, staticClass: "over-item" }, [
              _c(
                "div",
                { staticStyle: { display: "flex", "align-items": "center" } },
                [
                  _c(
                    "div",
                    { staticStyle: { "flex-grow": "1" } },
                    [
                      _c(
                        "el-link",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.goAnchor(index)
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(index + 1) +
                              " - " +
                              _vm._s(_vm._f("quTypeFilter")(item.quType))
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", circle: "" },
                          on: {
                            click: function ($event) {
                              return _vm.markAsHidden(index)
                            },
                          },
                        },
                        [
                          _c("svg-icon", {
                            attrs: {
                              "icon-class": !item.visible
                                ? "icon-hide"
                                : "icon-show",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("el-button", {
                        attrs: {
                          size: "mini",
                          type: "danger",
                          icon: "el-icon-delete",
                          circle: "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.removeQu(index)
                          },
                        },
                      }),
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            effect: "dark",
                            content: "按住鼠标调整到合适的位置后释放",
                            placement: "top-start",
                          },
                        },
                        [
                          _c("el-button", {
                            staticClass: "sort-btn",
                            attrs: {
                              size: "mini",
                              icon: "el-icon-rank",
                              circle: "",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c("div", {
                staticClass: "over-content",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$options.filters.clearHtml(item.content)
                  ),
                },
              }),
            ])
          }),
          0
        ),
      ]),
      _c("survey-qu-import-dialog", {
        attrs: { "survey-id": _vm.surveyId, visible: _vm.importVisible },
        on: {
          "update:visible": function ($event) {
            _vm.importVisible = $event
          },
          success: _vm.fetchQuList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }