var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "destroy-on-close": true,
        "show-close": true,
        title: "创建问卷",
        width: "50%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.handleClose,
      },
    },
    [
      _c("survey-update-base", {
        attrs: { next: "design" },
        on: { success: _vm.handleSuccess, cancel: _vm.handleClose },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }