var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.tagList, function (tag, index) {
        return _c(
          "el-tag",
          {
            key: index,
            attrs: {
              "disable-transitions": false,
              size: "medium",
              closable: "",
            },
            on: {
              close: function ($event) {
                return _vm.removeTag(tag)
              },
            },
          },
          [_vm._v(" " + _vm._s(tag) + " ")]
        )
      }),
      _vm.inputVisible
        ? _c("el-input", {
            staticClass: "input-new-tag",
            class: { "fix-left": _vm.tagList.length === 0 },
            attrs: { placeholder: "请输入选项值", size: "mini" },
            on: { blur: _vm.tagInputConfirm },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.tagInputConfirm($event)
              },
            },
            model: {
              value: _vm.inputValue,
              callback: function ($$v) {
                _vm.inputValue = $$v
              },
              expression: "inputValue",
            },
          })
        : _c(
            "el-button",
            {
              staticClass: "button-new-tag",
              class: { "fix-left": _vm.tagList.length === 0 },
              attrs: { size: "mini" },
              on: { click: _vm.showTagInput },
            },
            [_vm._v("+添加近似值")]
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }