<template>

  <div class="checkout">
    <div class="checkout-address">
      <div style="font-size: 18px; font-weight: 700; margin-bottom: 20px">
        <el-button class="float-right" icon="el-icon-plus" type="default" size="mini" plain @click="showAddAddr">添加地址</el-button>
        <span style="font-size: 18px">收货地址</span>
      </div>

      <div class="checkout-address-container">
        <div v-if="!addrList || addrList.length===0" class="checkout-no-data">
          <h5 class="checkout-secondary text-center">请点击上方按钮添加一个地址！</h5>
        </div>

        <div v-for="item in addrList" :key="item.id" class="checkout-address-item">
          <p>

            <el-button icon="el-icon-edit" size="mini" type="primary" class="float-right" style="font-size: 14px" circle @click="preUpdate(item.id)"/>
            <el-button v-if="showDelete" icon="el-icon-delete" size="mini" type="danger" class="float-right" style="font-size: 14px; margin-right: 10px" circle @click="handleDelete(item.id)"/>

            <label class="addr-line">
              <input v-if="showRadio" :checked="item.id===addrId" class="checkout-option" type="radio" name="address-shipping" @click="addrId=item.id">
              <span v-if="item.isDefault">默认</span>
              {{ item.contact }}
              <b>{{ item.mobile }}</b>
            </label>
          </p>
          <p class="addr-line">
            {{ item.province }}{{ item.city }}{{ item.area }} {{ item.address }}
          </p>
        </div>

      </div>

    </div>

    <el-dialog
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      title="添加地址"
      width="40%"
    >

      <el-form ref="postForm" :model="postForm" :rules="rules" label-position="left" label-width="100px">

        <el-form-item label="姓名" prop="contact">
          <el-input v-model="postForm.contact"/>
        </el-form-item>

        <el-form-item label="手机号" prop="mobile">
          <el-input v-model="postForm.mobile"/>
        </el-form-item>

        <el-form-item label="所在区域" prop="area">
          <v-distpicker :province="postForm.province" :city="postForm.city" :area="postForm.area" @selected="distSelected"/>
        </el-form-item>

        <el-form-item label="详细地址" prop="address">
          <el-input v-model="postForm.address" type="textarea"/>
        </el-form-item>

        <el-form-item label="">
          <el-checkbox v-model="postForm.isDefault" label="设置为默认地址"/>
        </el-form-item>

      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button :loading="loading" type="primary" @click="handleSave">确 定</el-button>
      </span>
    </el-dialog>

  </div>

</template>

<script>
import { fetchAddrList, saveAddr, fetchDetail, deleteAddr } from '@/api/mall/addr'
import VDistpicker from 'v-distpicker'

export default {
  name: 'MallAddress',
  components: { VDistpicker },
  props: {
    value: String,
    showDelete: {
      type: Boolean,
      default: false
    },
    showRadio: {
      type: Boolean,
      default: true
    }
  },
  data() {
    const validateArea = (rule, value, callback) => {
      if (!this.postForm.area) {
        callback(new Error('区域必须选择！'))
      } else {
        callback()
      }
    }

    return {
      addrList: [],
      dialogVisible: false,
      postForm: {},
      loading: false,
      rules: {
        contact: [
          { required: true, message: '姓名不能为空！' }
        ],
        mobile: [
          { required: true, message: '手机号码不能为空！' }
        ],
        area: [
          { required: true, validator: validateArea }
        ],
        address: [
          { required: true, message: '详细地址不能为空！' }
        ]

      },
      addrId: ''
    }
  },

  watch: {
    value: {
      handler(val) {
        this.addrId = val
      }
    },
    addrId: {
      handler(val) {
        this.$emit('input', val)
      }
    }
  },

  created() {
    this.addrId = this.value
    this.refreshAddr()
  },
  methods: {

    // 获取地址列表
    refreshAddr() {
      // 获取地址列表
      fetchAddrList().then(res => {
        this.addrList = res.data.records
        // 优先选定默认地址，无则选择第一个
        if (!this.addrId) {
          for (let i = 0; i<this.addrList.length; i++) {
            this.addrId = this.addrList[i].id
            if (this.addrList[i].isDefault) {
              return
            }
          }
        }
      })
    },

    // 添加地址
    showAddAddr() {
      this.postForm = {}
      this.dialogVisible = true
    },

    // 区域选定
    distSelected(val) {
      this.postForm.province = val.province.value
      this.postForm.city = val.city.value
      this.postForm.area = val.area.value
    },

    // 保存地址
    handleSave() {
      // 表单验证
      this.$refs.postForm.validate().then((valid) => {
        if (!valid) {
          return
        }

        this.loading = true

        saveAddr(this.postForm).then(res => {
          this.$message.success('地址保存成功！')
          this.dialogVisible = false
          // 默认选定最新添加数据
          this.addrId = res.data.id
          // 刷新地址
          this.refreshAddr()
          // 加载
          this.loading = false
        })
      })
    },

    // 修改地址
    preUpdate(id) {
      fetchDetail(id).then(res => {
        this.postForm = res.data
        this.dialogVisible = true
      })
    },

    // 删除地址
    handleDelete(id) {
      this.$confirm('确实要删除地址吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteAddr(id).then(() => {
          this.$message.success('删除成功！')
          this.refreshAddr()
        })
      })
    }
  }
}
</script>

<style scoped>
.checkout .checkout-gray {
  color: #8B8185
}

.checkout .checkout-container .checkout-goods {
  display: block;
  background-color: #F9F9F9;
  padding: .9375rem
}

.checkout .checkout-container .checkout-goods .checkout-goods-items a h5 {
  color: #302E2F
}

.checkout .checkout-container .checkout-goods .checkout-goods-items a:hover {
  text-decoration: none
}

.checkout .checkout-container .checkout-goods .checkout-goods-items .media {
  overflow: hidden
}

.checkout .checkout-container .checkout-goods .checkout-goods-items .media img {
  width: 3.75rem
}

.checkout .checkout-container .checkout-goods .checkout-goods-items .media p {
  margin: 0;
  overflow: hidden;
  font-size: .875rem
}

.checkout .checkout-container .checkout-goods .checkout-goods-items .media p label {
  margin: 0 0 0 .625rem
}

.checkout .checkout-container .checkout-goods .checkout-goods-items .media p .checkout-perch {
  margin-right: 0.5rem;
  display: inline-block;
  color: #8B8185
}

.checkout .checkout-container .checkout-goods .checkout-goods-items .media:not(:last-child) {
  margin-bottom: .625rem
}

@media (min-width: 768px) {
  .checkout .checkout-container .checkout-goods .checkout-goods-items {
    max-height:282px;
    overflow-x: hidden;
    overflow-y: auto
  }
}

.checkout h5 {
  line-height: 2.375rem
}

.checkout h5 .btn {
  color: #C3232D;
  background: none
}

.checkout .checkout-secondary {
  color: #8B8185
}

.checkout .checkout-more {
  padding-bottom: 1rem;
  padding-top: 1rem;
  cursor: pointer;
  text-align: center
}

.checkout .checkout-more.active {
  transform: rotate(180deg)
}

.checkout .checkout-address .checkout-address-container {
  border: 1px solid #EDE9EA;
  border-radius: .375rem
}

.checkout .checkout-address .checkout-address-container .checkout-address-item {
  border-bottom: 1px solid #EDE9EA;
  padding-bottom: 1rem;
  margin: 1rem 1rem -1px 2.5rem;
  position: relative
}

.checkout .checkout-address .checkout-address-container .checkout-address-item label {
  display: block;
  margin: 0
}

.checkout .checkout-address .checkout-address-container .checkout-address-item .checkout-option {
  position: absolute;
  left: -1.5625rem;
  top: 0.3rem
}

.checkout .checkout-address .checkout-address-container .checkout-address-item p {
  margin: 0
}

.checkout .checkout-address .checkout-address-container .checkout-address-item p:not(:last-child) {
  margin-bottom: 0.5rem
}

.checkout .checkout-address .checkout-address-container .checkout-address-item p span {
  display: inline-block;
  border-radius: .25rem;
  border: 1px solid #C3232D;
  color: #C3232D;
  padding-left: 0.4rem;
  padding-right: 0.4rem
}

.checkout .checkout-address .checkout-address-container .checkout-address-item p b {
  font-weight: normal;
  margin-left: 1rem
}

.checkout .checkout-address .checkout-address-container .checkout-address-item p .btn {
  color: #8B8185;
  background: none;
  margin-top: -4px
}

.checkout .checkout-address .checkout-address-container .checkout-address-item .btn-add {
  background: none;
  color: #C3232D !important
}

.checkout .checkout-summary {
  background-color: #f7f7f7;
  border: 1px solid #EDE9EA;
  padding: .625rem;
  cursor: pointer;
  color: #C3232D
}

.checkout .checkout-summary b {
  color: #302E2F
}

.checkout .checkout-summary p {
  margin: 0
}

.checkout .checkout-summary p span {
  transform: rotate(180deg);
  color: blue
}

.checkout .checkout-back a,.checkout .checkout-back .btn {
  color: #302E2F;
  background: none
}

.checkout .checkout-address-shipping {
  border: 1px solid #EDE9EA;
  padding: 1rem;
  border-radius: .375rem
}

.checkout .checkout-address-shipping li {
  cursor: pointer
}

.checkout .checkout-address-shipping li label {
  color: #8B8185;
  width: 5rem
}

.checkout .checkout-address-shipping li:not(:last-child) {
  margin-bottom: 0.75rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid #EDE9EA
}

.checkout .checkout-discount .discount-badge {
  display: none
}

.checkout .checkout-discount .discount-badge .form-control {
  border-left: 0
}

.checkout .checkout-discount .discount-badge .btn {
  background-color: #e9ecef;
  border: 1px solid #ced4da
}

.checkout .checkout-discount.is-applied .input-group {
  display: none
}

.checkout .checkout-discount.is-applied .checkout-discount-msg {
  display: none
}

.checkout .checkout-discount.is-applied .discount-badge {
  display: flex
}

.checkout .checkout-total dt {
  font-weight: bold
}

.checkout .checkout-total dd del {
  display: inline-block;
  margin-right: 5px;
  text-align: right;
  width: 120px
}

.checkout .checkout-total dd span {
  display: inline-block;
  text-align: right;
  width: 120px
}

.checkout .checkout-no-data {
  padding: 2.5rem 1.5rem;
  overflow: hidden;
  border: 0;
  background-color: #F7F6F6
}

.checkout .checkout-no-data p {
  margin: 0
}

@media (max-width: 768px) {
  .checkout .checkout-back {
    margin-top:1rem;
    text-align: center
  }

  .checkout .checkout-goods {
    display: none
  }
}

.checkout .promotion-notes {
  padding: 0.75rem 1rem;
  background-color: #FFF7E6;
  margin-bottom: 1rem;
  margin-top: 1rem
}

.checkout .promotion-notes p {
  font-size: 12px
}

.checkout .checkout-goods-promotion-header {
  margin-bottom: 0.5rem;
  padding: 3px 8px;
  max-height: 24px;
  font-size: 12px;
  display: flex;
  width: fit-content
}

.checkout .checkout-goods-promotion-header .title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-wrap: break-word
}

.checkout .checkout-goods-promotion-header .title::after {
  content: ' ';
  white-space: pre
}

.checkout .checkout-goods-promotion-header .value {
  white-space: nowrap
}

.checkout-form {
  margin: 1rem 0.5rem
}

.checkout-form .checkout-close {
  position: absolute;
  display: inline-block;
  padding: 0.5rem 1.25rem;
  right: 0;
  top: 0;
  cursor: pointer
}

.checkout-form .btn {
  width: 30%;
  margin: 0 .3125rem
}

.float-right {
  float: right !important;
}

.addr-line{
  word-break: break-all;
  line-break: anywhere;
}

</style>
