var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("data-grid", {
    ref: "dataGrid",
    attrs: { options: _vm.options, "list-query": _vm.listQuery },
    scopedSlots: _vm._u([
      {
        key: "filter-content",
        fn: function () {
          return [
            _c("dic-catalog-tree", {
              staticClass: "filter-item",
              attrs: { "dic-code": "repo_catalog", width: "200" },
              model: {
                value: _vm.listQuery.params.catId,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery.params, "catId", $$v)
                },
                expression: "listQuery.params.catId",
              },
            }),
            _c("el-input", {
              staticClass: "filter-item",
              staticStyle: { width: "200px" },
              attrs: {
                size: "small",
                placeholder: "搜索题库名称",
                "prefix-icon": "el-icon-search",
              },
              model: {
                value: _vm.listQuery.params.title,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery.params, "title", $$v)
                },
                expression: "listQuery.params.title",
              },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "data-item",
        fn: function (ref) {
          var data = ref.data
          return [
            _c("detail-link", {
              attrs: {
                params: { repoId: data.id },
                title: data.title,
                to: "MyRepoView",
              },
            }),
            _c(
              "span",
              { staticClass: "tag-box" },
              [
                data.price > 0
                  ? _c("el-tag", { attrs: { type: "danger", size: "mini" } }, [
                      _vm._v("￥" + _vm._s(data.price)),
                    ])
                  : _c("el-tag", { attrs: { type: "success", size: "mini" } }, [
                      _vm._v("免费"),
                    ]),
              ],
              1
            ),
            _c("div", { staticClass: "flex-bt" }, [
              _c("div", [
                _c("div", { staticClass: "info-item" }, [
                  _c("span", { staticClass: "title" }, [_vm._v("题库分类：")]),
                  _c("span", [_vm._v(_vm._s(data.catId_dictText))]),
                ]),
                _c("div", { staticClass: "info-item" }, [
                  _c("span", { staticClass: "title" }, [_vm._v("试题数量： ")]),
                  _c("span", [_vm._v(_vm._s(data.quCount))]),
                ]),
              ]),
              _c("div", [
                _c(
                  "div",
                  {
                    staticClass: "fav-btn",
                    class: { "fav-btn-off": !data.hasJoin },
                    on: {
                      click: function ($event) {
                        return _vm.handleJoin(data)
                      },
                    },
                  },
                  [_c("i", { staticClass: "el-icon-star-on" })]
                ),
              ]),
            ]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }