<template>

  <div>

    <div style="background: #f5f5f5">

      <el-image v-if="activity.banner" :src="activity.banner" fit="fill" style="width: 100%" />
      <div style="display: flex; flex-direction: row; padding: 10px; align-items: center">
        <h2 style="flex-grow: 1">{{ activity.title }} </h2>

        <div v-if="check.my && check.my.id">
          <div v-if="check.my.state===0">

            <el-button v-if="activity.activityType==='1' && activity.refCourse" type="primary" @click="toCourse(activity.id, activity.refCourse)">去学习</el-button>
            <el-button v-else-if="activity.activityType==='2' && activity.refExam" type="primary" @click="toExam(activity.id, activity.refExam)">去考试</el-button>
            <el-button v-else type="success" @click="handleView">我已报名成功</el-button>
          </div>
          <div v-else-if="check.my.state===1">
            <el-button type="danger" @click="handlePay">立即支付</el-button>
          </div>
          <div v-else-if="check.my.state===2">
            <el-button type="warn" @click="handleView">等待管理员审核</el-button>
          </div>
          <div v-else-if="check.my.state===3">
            <el-button type="danger" @click="handleUpdate">修改报名信息</el-button>
          </div>
        </div>
        <div v-else>

          <el-tag v-if="check.disabled" type="info">报名已禁用</el-tag>
          <el-tag v-else-if="check.notStart" type="warning">报名尚未开始</el-tag>
          <el-tag v-else-if="check.ended" type="warning">报名已结束</el-tag>
          <el-tag v-else-if="check.overJoin" type="danger">报名人数已满</el-tag>
          <div v-else>
            <el-button type="primary" @click="handleJoin">立即报名</el-button>
          </div>
        </div>

      </div>

    </div>

    <el-tabs v-model="activeTab" class="uc-tab">
      <el-tab-pane label="报名信息" name="first">

        <div style="margin-top: -40px">
          <h3>报名详情</h3>
          <div>
            <p>报名时间：{{ activity.startTime }}至{{ activity.endTime }}</p>
            <p v-if="activity.maxJoin>0">限定人数：{{ activity.maxJoin }}人</p>
            <p v-else>限定人数：不限制</p>
            <p v-if="activity.price>0" class="module-pay">报名价格：{{ activity.price }}</p>
            <p v-else class="module-pay">报名价格：免费</p>
            <p>人工审核：{{ activity.audit?'是':'否' }}</p>
          </div>
        </div>

        <el-divider />

        <h3>报名须知</h3>
        <p class="intro" v-html="$options.filters.formatRichText(activity.content)" />
      </el-tab-pane>

    </el-tabs>

    <activity-join-dialog :activity="activity" :password="password" :visible.sync="dialogVisible"/>

    <activity-buy v-if="check.my" :join-id="check.my.id" :visible.sync="payVisible"/>

    <join-view-dialog v-if="check.my" :join-id="check.my.id" :visible.sync="viewVisible" />

    <join-update-dialog v-if="check.my" :join-id="check.my.id" :visible.sync="updateVisible" msg="报名修改成功，请等待审核！" />

  </div>

</template>

<script>

import { fetchDetail, checkJoin, checkPass } from '@/api/activity/activity'
import JoinViewDialog from '@/views/admin/activity/components/JoinViewDialog.vue'
import JoinUpdateDialog from '@/views/admin/activity/components/JoinUpdateDialog.vue'

export default {
  components: { JoinUpdateDialog, JoinViewDialog },
  data() {
    return {
      activeTab: 'first',
      dialogVisible: false,
      activityId: '',
      activity: {},
      check: {
      },
      payVisible: false,
      viewVisible: false,
      updateVisible: false,
      password: ''
    }
  },

  created() {
    this.activityId = this.$route.params.id
    fetchDetail(this.activityId).then(res => {
      this.activity = res.data
    })

    checkJoin(this.activityId).then(res => {
      this.check = res.data
    })
  },
  methods: {

    // 参与
    handleJoin() {
      if (this.activity.openType === 9) {
        this.$prompt('请输入报名密码', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }).then(({ value }) => {
          this.handlerPass(value)
        })
      } else {
        this.dialogVisible = true
      }
    },

    // 校验密码
    handlerPass(pass) {
      const params = {
        activityId: this.activityId,
        password: pass
      }
      checkPass(params).then(() => {
        this.password = pass
        this.dialogVisible = true
      })
    },

    // 发起支付
    handlePay() {
      this.payVisible = true
    },

    // 查看
    handleView() {
      this.viewVisible = true
    },

    // 查看
    handleUpdate() {
      this.updateVisible = true
    },

    // 去学习
    toCourse(activityId, courseId) {
      this.$router.push({ name: 'CourseView', params: { courseId: courseId }, query: { activityId: activityId }})
    },
    // 去考试
    toExam(activityId, examId) {
      this.$router.push({ name: 'CheckExam', params: { id: examId }, query: { activityId: activityId }})
    }
  }
}
</script>

<style scoped>

/deep/
.el-tabs__item{
  font-size: 16px;
  height: 60px;
  line-height: 60px;
  font-weight: 700;
}

/deep/
.el-tab-pane{
  padding-top: 20px;
}

</style>
