// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/web/images/ubg2.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.user-infocon[data-v-79d5de29] {\n  height: 145px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat top center;\n  background-size: 100% 100%;\n}\n.user-infocon[data-v-79d5de29] {\n  padding: 0 85px\n}\n.user-infocon .bg[data-v-79d5de29] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  padding-top: 40px;\n}\n.user-infocon .user-img[data-v-79d5de29] {\n  border: 2px solid #eee;\n  width: 130px;\n  height: 130px;\n  overflow: hidden;\n  position: relative;\n  border-radius: 5px;\n}\n.user-infocon .user-img img[data-v-79d5de29] {\n  width: 130px;\n  height: 130px;\n  overflow: hidden;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  -webkit-transform: translate(-50%, -50%);\n  transform: translate(-50%, -50%)\n}\n.user-infocon .infor[data-v-79d5de29] {\n  margin: 10px 0 0 35px;\n}\n.user-infocon .infor .name[data-v-79d5de29] {\n  font-size: 14px;\n  font-weight: 700;\n  color: #fff;\n  margin-top: 0;\n}\n.user-infocon .infor .class[data-v-79d5de29] {\n  font-size: 14px;\n  color: #fff;\n}\n.user-infocon .infor .class span[data-v-79d5de29]:first-child:after {\n  content: \"|\";\n  font-size: 14px;\n  color: #fff;\n  position: absolute;\n  right: -15px;\n  top: 0\n}\n.edit-con[data-v-79d5de29]{\n  background:#fff;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align:center;\n      -ms-flex-align:center;\n          align-items:center;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  text-align:center;\n  color:#888;\n  right:0;\n  z-index: 888;\n  width: 20px;\n  height: 20px;\n  position: absolute;\n  font-size: 16px;\n}\n", ""]);
// Exports
module.exports = exports;
