<template>

  <div>
    <el-tag
      v-for="(tag,index) in tagList"
      :key="index"
      :disable-transitions="false"
      size="medium"
      closable
      @close="removeTag(tag)">
      {{ tag }}
    </el-tag>
    <el-input
      v-if="inputVisible"
      v-model="inputValue"
      :class="{'fix-left': tagList.length===0}"
      placeholder="请输入选项值"
      class="input-new-tag"
      size="mini"
      @keyup.enter.native="tagInputConfirm"
      @blur="tagInputConfirm"
    />
    <el-button v-else :class="{'fix-left': tagList.length===0}" class="button-new-tag" size="mini" @click="showTagInput">+添加近似值</el-button>
  </div>
</template>

<script>

export default {
  name: 'FillItemEditor',
  props: {
    value: String
  },
  data() {
    return {
      inputVisible: true,
      inputValue: '',
      tagList: []
    }
  },

  watch: {
    // 检测查询变化
    value: {
      handler() {
        this.initTags()
      }
    }
  },
  created() {
    this.initTags()
  },
  methods: {

    initTags() {
      if (this.value) {
        const str = (this.value || '').toString()
        this.tagList = str.split('||')
      } else {
        this.tagList = []
      }

      this.inputVisible = this.tagList.length === 0
    },

    tagsChange() {
      let all = ''
      if (this.tagList && this.tagList.length>0) {
        all = this.tagList.join('||')
      }
      this.$emit('input', all)
    },

    // 移除近似值
    removeTag(tag) {
      this.tagList.splice(this.tagList.indexOf(tag), 1)
      this.tagsChange()
    },

    // 确认输入
    tagInputConfirm() {
      const inputValue = this.inputValue
      if (inputValue) {
        this.tagList.push(inputValue)
      }
      this.tagsChange()
      this.inputVisible = false
      this.inputValue = ''
      this.$forceUpdate()
    },

    // 显示输入
    showTagInput() {
      this.inputVisible = true
      this.$forceUpdate()
    }
  }
}
</script>

<style scoped>
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
}
.input-new-tag {
  width: 200px;
  margin-left: 10px;
  vertical-align: bottom;
}

.fix-left{
  margin-left: 0;
}
</style>
