import { post } from '@/utils/request'

/**
 * 课程详情
 * @param id
 * @returns {*}
 */
export function fetchDetail(id) {
  return post('/api/course/course/detail', { id: id })
}

/**
 * 课程详情
 * @param id
 * @returns {*}
 */
export function fetchDetailForUpdate(id) {
  return post('/api/course/course/admin/detail', { id: id })
}

/**
 * 简略详情
 * @param id
 * @returns {*}
 */
export function simpleDetail(id) {
  return post('/api/course/course/simple-detail', { id: id })
}

/**
 * 课程分页
 * @param data
 * @returns {*}
 */
export function fetchPaging(data) {
  return post('/api/course/course/paging', data)
}

/**
 * 课程分页
 * @param data
 * @returns {*}
 */
export function fetchUserPaging(data) {
  return post('/api/course/course/user-paging', data)
}

/**
 * 保存课程
 * @param data
 */
export function saveData(data) {
  return post('/api/course/course/save', data)
}

/**
 * 创建预付费订单
 * @param id
 * @returns {Promise}
 */
export function buy(courseId) {
  return post('/api/course/buy/init', { courseId: courseId })
}

/**
 * 发送学习安排
 * @param data
 * @returns {Promise}
 */
export function prepareNotify(data) {
  return post('/api/course/course/prepare-notify', data)
}
