var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { attrs: { gutter: 30 } },
        [
          _c(
            "el-col",
            { attrs: { span: 18 } },
            [
              _vm.current.state === 1 && _vm.current.liveType == 1
                ? _c("live-player", {
                    model: {
                      value: _vm.current.flvUrl,
                      callback: function ($$v) {
                        _vm.$set(_vm.current, "flvUrl", $$v)
                      },
                      expression: "current.flvUrl",
                    },
                  })
                : _vm._e(),
              _vm.current.state === 1 && _vm.current.liveType == 2
                ? _c("iframe", {
                    staticStyle: {
                      width: "100%",
                      height: "680px",
                      border: "none",
                    },
                    attrs: { src: _vm.current.outUrl },
                  })
                : _vm._e(),
              _vm.current.state === 0
                ? _c("el-empty", {
                    attrs: { description: "直播尚未开始，请开播再来奥~" },
                  })
                : _vm._e(),
              _vm.current.state === 2
                ? _c("el-empty", { attrs: { description: "直播已经结束喽~" } })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 6 } },
            _vm._l(_vm.value, function (live) {
              return _c(
                "el-card",
                {
                  key: live.id,
                  staticClass: "live-item",
                  class: { current: live.id === _vm.current.id },
                },
                [
                  _c(
                    "div",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.changeLive(live)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "16px",
                            "font-weight": "700",
                          },
                        },
                        [_vm._v(_vm._s(live.title))]
                      ),
                      _c(
                        "div",
                        { staticStyle: { "font-size": "12px", color: "#666" } },
                        [_vm._v(_vm._s(live.intro))]
                      ),
                      _c("div", [
                        _vm._v("开播时间：" + _vm._s(live.startTime)),
                      ]),
                      _c("div", [
                        _vm._v("直播时长：" + _vm._s(live.duration) + "分钟"),
                      ]),
                    ]
                  ),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }