// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/web/bg-work.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.sv-bg[data-v-dbba8c24]{\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center;\n  background-size: cover;\n  height: 100vh;\n  overflow-y: auto;\n  padding: 120px 0 40px 0;\n}\n.q-box[data-v-dbba8c24]{\n  border: #eee 1px solid;\n  padding: 10px;\n  border-radius: 5px;\n  margin-bottom: 20px;\n}\n.error-box[data-v-dbba8c24]{\n  border-style: dashed;\n  border-width: 2px;\n  -webkit-animation: blink-data-v-dbba8c24 0.5s infinite;\n          animation: blink-data-v-dbba8c24 0.5s infinite;\n}\n.error-text[data-v-dbba8c24]{\n  color: #ff3333;\n  font-weight: 700;\n  font-size: 14px;\n  padding-top: 10px;\n}\n.app-container[data-v-dbba8c24]{\n  padding: 0 !important;\n}\n@-webkit-keyframes blink-data-v-dbba8c24 {\n0% {\n    border-color: transparent;\n}\n50% {\n    border-color: red;\n}\n100% {\n    border-color: transparent;\n}\n}\n@keyframes blink-data-v-dbba8c24 {\n0% {\n    border-color: transparent;\n}\n50% {\n    border-color: red;\n}\n100% {\n    border-color: transparent;\n}\n}\n\n/*只读遮罩*/\n.view-mask[data-v-dbba8c24]{\n  pointer-events: none;\n  -ms-touch-action: none;\n      touch-action: none;\n  overflow: scroll;\n}\n", ""]);
// Exports
module.exports = exports;
