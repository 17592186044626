<template>
  <div>
    屏幕录制开启中...
  </div>
</template>

<script>
import QNRTC, { QNLogLevel } from 'qnweb-rtc'
import { mapGetters } from 'vuex'
import { roomToken, roomRecord } from '@/api/ability/rtc/qnrtc'
// 关闭日志
QNRTC.setLogLevel(QNLogLevel.NONE)

export default {
  name: 'QnrtcScreen',
  props: {
    paperId: {
      type: String,
      default: ''
    },
    examId: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      client: null,
      // 录屏流
      screenTrack: null,
      checkTimer: null
    }
  },
  computed: {
    ...mapGetters([
      'userId'
    ])
  },
  beforeDestroy() {
    // 离开房间
    this.leaveRoom()
  },
  methods: {

    // 初始化调用
    startScreen() {
      this.client = QNRTC.createClient()
      // 开启流检测
      this.startStreamCheck()
      // 初始化
      this.initClient()
    },

    // 监测流
    startStreamCheck() {
      if (this.checkTimer) {
        clearInterval(this.checkTimer)
      }

      // 首次延时开启
      setTimeout(() => {
        this.checkTimer = setInterval(() => {
          this.streamCheck()
        }, 3000)
      }, 10000)
    },

    // 流测试
    streamCheck() {
      if (!this.screenTrack) {
        this.$emit('break')
      } else {
        this.$emit('ready')
      }
    },

    // 推送视频流
    async initClient() {
      const userId = this.userId + '_' + this.paperId + '_screen'
      const params = { userId: userId, roomName: this.examId }
      roomToken(params).then(async res => {
        // 进入房间
        await this.client.join(res.data.token)
        // 录屏推送
        await this.pushScreen(res.data.preset)
        // 开始录制
        setTimeout(() => {
          this.startRecord(params)
        }, 3000)
      })
    },
    // 开始录屏
    async pushScreen(preset) {
      const that = this

      // 做上下取近：只支持480p|720p|1080p
      if (preset === '360p') {
        preset = '480p'
      }
      if (preset === '4k' || preset === '1440p') {
        preset = '1080p'
      }

      console.log('+++++++录屏质量：' + preset)
      // 采集屏录制
      this.screenTrack = await QNRTC.createScreenVideoTrack({
        // 最高1080p
        encoderConfig: preset
      })

      this.screenTrack.on('ended', function() {
        that.screenTrack = null
        that.$emit('break')
      })

      // 推送录屏
      await this.client.publish(this.screenTrack)
      this.$emit('ready')
    },

    // 同步进行录制
    startRecord(params) {
      // 发送录制视频和录屏
      roomRecord(params).then(res => {
        console.log('++++++++++屏幕录制已开始', res)
      })
    },

    // 销毁流
    async leaveRoom() {
      if (this.screenTrack) {
        this.screenTrack.destroy()
      }
      // 离开房间
      await this.client.leave()
    }
  }
}
</script>
