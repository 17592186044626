<template>

  <div>

    <div v-if="visible">
      <video-player
        ref="player"
        v-model="file.viewUrl"
        :drag="course.videoDrag"
        @play="onPlay"
        @pause="onPause"
        @ended="onEnded"
        @loaded="onLoaded"
        @timeupdate="onTimeUpdate"
      />
    </div>

    <!-- SOCKET记录时间 -->
    <read-socket
      v-if="file.fileId && visible && playing"
      :file-id="file.fileId"
      :course-id="course.id"
      @change="timeChange"
      @finish="handleFinish"
      @break="checkBreak"
    />

    <!-- 行为校验 -->
    <read-check
      v-if="file.fileId && visible && playing && course.checkOn"
      :time-min="course.checkSec"
      @break="checkBreak"
    />

  </div>

</template>

<script>
import { fixProcess, loadProcess, saveProcess } from '@/api/course/file'

export default {
  name: 'FilePlayer',
  props: {
    course: Object,
    file: Object
  },
  data() {
    return {
      playing: false,
      visible: true,
      lastFileId: '',
      learnSec: 0
    }
  },
  watch: {
    file: {
      handler(newVal, oldVal) {
        if (newVal.fileId !== oldVal.fileId) {
          this.handleProcess(newVal.fileId)
        }
      }
    }
  },
  created() {
    // 监听页面状态
    document.addEventListener('visibilitychange', this.visibleChange)
    // 记录首次的ID
    if (this.file && this.file.fileId) {
      this.lastFileId = this.file.fileId
    }
  },

  beforeDestroy() {
    this.handleProcess()
  },
  methods: {

    // 监听面可见状态
    visibleChange() {
      if (document.visibilityState === 'hidden') {
        this.visible = false
        this.$emit('pause')
      } else {
        this.visible = true
      }
    },

    // 阅读进度变化
    timeChange(data) {
      this.$emit('change', data)
    },

    // 完成课件阅读
    handleFinish(json) {
      this.$emit('finish', json)
    },

    onPlay() {
      this.playing = true
      this.$emit('play')
    },

    onPause() {
      this.playing = false
      this.$emit('pause')
      this.handleProcess()
    },

    // 时间更新
    onTimeUpdate(sec) {
      this.learnSec = sec
    },

    // 暂停
    pause() {
      this.$refs.player.pause()
    },

    // 播完进行补偿
    onEnded() {
      const params = { courseId: this.course.id, fileId: this.file.fileId }
      fixProcess(params).then(res => {
        this.handleFinish(res.data)
      })
    },

    // 记忆播放进度
    onLoaded() {
      const params = { courseId: this.course.id, fileId: this.file.fileId }
      loadProcess(params).then(res => {
        this.$refs.player.startAt(res.data)
      })
    },

    // 保存进度
    handleProcess(newFileId) {
      const params = { courseId: this.course.id, fileId: this.lastFileId, learnSec: parseInt(this.learnSec) }
      saveProcess(params).then(() => {
        console.log('进度已保存', params)
      })

      // 替换为当前文件ID
      if (newFileId) {
        this.lastFileId = newFileId
      }
    },

    // 校验超时
    checkBreak() {
      // 跳转到
      this.$router.push('/pages/course?break')
    }
  }
}
</script>

<style scoped>

  .file-item{

    border: #ddd 1px solid;
    text-align: left;
    padding: 10px;
    margin-bottom: 15px;
    cursor: pointer;
  }

  .file-item-active {

    border: #1890ff 1px solid;
    box-sizing: border-box;
    color: #1890ff;
    font-weight: 700;
  }

   ::v-deep
   .content{
     text-align: left;
   }

   ::v-deep
   .content img{
     text-align: left;
     max-width: 100%;
   }

</style>
