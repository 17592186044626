// 积分商城模块
import WebLayout from '@/layout/WebLayout'

const battleRouter = {
  path: '/mall',
  component: WebLayout,
  redirect: '/pages/mall',
  hidden: true,
  children: [

    {
      path: '/pages/mall',
      component: () => import('@/views/web/mall/index'),
      name: 'MallIndex',
      meta: { title: '积分商城', noCache: true }
    },

    {
      path: '/pages/mall/detail/:id',
      component: () => import('@/views/web/mall/detail'),
      name: 'GoodsDetail',
      meta: { title: '商品明细', noCache: true }
    },

    {
      path: '/pages/mall/checkout/:sku',
      component: () => import('@/views/web/mall/checkout'),
      name: 'MallCheckout',
      meta: { title: '订单结算', noCache: true }
    }

  ]
}
export default battleRouter
