<template>

  <el-select
    v-model="roleIds"
    :disabled="disabled"
    :remote-method="fetchData"
    size="small"
    style="width: 100%"
    filterable
    remote
    multiple
    reserve-keyword
    clearable
    class="filter-item"
    automatic-dropdown
    placeholder="请选择角色"
    @change="handlerChange"
    @clear="fetchData('')"
  >
    <el-option
      v-for="item in list"
      :key="item.id"
      :label="`${item.roleName}(${item.roleLevel})`"
      :value="item.id"
    />
  </el-select>

</template>

<script>

import { fetchList } from '@/api/sys/role/role'

export default {
  name: 'RoleSelect',
  props: {
    value: Array,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // 下拉选项值
      list: [],
      roleIds: []
    }
  },

  watch: {
    // 检测查询变化
    value: {
      handler() {
        this.roleIds = this.value
      },
      deep: true
    }
  },
  created() {
    this.roleIds = this.value
    this.fetchData()
  },
  methods: {

    fetchData() {
      fetchList().then(response => {
        this.list = response.data
      })
    },
    handlerChange(e) {
      this.$emit('change', e)
      this.$emit('input', e)
    }
  }
}
</script>

<style scoped>

::v-deep .el-input.el-input--suffix {
  display: flex !important;
  cursor: pointer;
  overflow: hidden;
}

</style>
