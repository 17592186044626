<template>

  <div>

    <el-row>

      <el-col :lg="16" :md="10" class="left hidden-sm-and-down">
        <img src="@/assets/web/images/login2.png" style="height: 45vh">
      </el-col>

      <el-col :lg="8" :md="14" class="right">

        <div class="box">

          <el-tabs v-model="activeName">
            <el-tab-pane label="重设密码" name="account">

              <el-form ref="postForm" :model="postForm" :rules="rules">

                <el-form-item prop="mobile">
                  <el-input
                    v-model="postForm.mobile"
                    style="width: 100%"
                    placeholder="手机号码"
                    prefix-icon="el-icon-mobile"
                  />
                </el-form-item>

                <el-form-item prop="smsCode">
                  <sms-input v-model="postForm" :type="2" />
                </el-form-item>

                <el-form-item prop="newPassword">
                  <el-input
                    v-model="postForm.newPassword"
                    show-password
                    style="width: 100%"
                    placeholder="新密码"
                    type="password"
                    prefix-icon="el-icon-lock"
                  />
                </el-form-item>

                <el-form-item>
                  <el-button :loading="loading" type="primary" style="width: 100%" @click.native.prevent="handleReset">重设密码</el-button>
                </el-form-item>

              </el-form>

            </el-tab-pane>

          </el-tabs>

          <div style="text-align: right; line-height: 10px">
            <el-link @click="toReg">立即注册</el-link>
            <el-link style="margin-left: 10px" @click="toLogin">已有账号？</el-link>
          </div>

        </div>

      </el-col>

    </el-row>

  </div>

</template>

<script>
import { resetPass } from '@/api/sys/user/user'
import { mapGetters } from 'vuex'
import { isMobile } from '@/utils/validate'

export default {
  components: { },
  data() {
    return {
      activeName: 'account',
      postForm: {
        mobile: '',
        smsCode: '',
        newPassword: '',
        captchaKey: '',
        captchaValue: ''
      },
      rules: {
        mobile: [{ required: true, validator: isMobile }],
        smsCode: [{ required: true, message: '手机验证码不能为空！' }],
        newPassword: [{ required: true, message: '新密码不能为空！' }]
      },
      loading: false
    }
  },
  computed: {
    ...mapGetters([
      'siteData'
    ])
  },

  methods: {

    handleReset() {
      this.$refs.postForm.validate((valid) => {
        if (valid) {
          this.loading = true

          resetPass(this.postForm).then(() => {
            this.$message.success('密码重置成功！')
            this.loading = false

            setTimeout(() => {
              this.$router.push({ name: 'Login' })
            }, 1500)
          })
            .catch(() => {
              this.loading = false
            })
        }
      })
    },

    toReg() {
      this.$router.push({ name: 'Register' })
    },

    toLogin() {
      this.$router.push({ name: 'Login' })
    }

  }
}
</script>
