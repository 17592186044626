var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "append-to-body": true,
        top: "5vh",
        title: "设备调试",
        width: "800px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.handleClose,
      },
    },
    [
      _vm.dialogVisible
        ? _c("div", [
            _vm.step === "camera"
              ? _c("div", [
                  _c("div", {
                    staticClass: "v-box",
                    attrs: { id: "video_camera" },
                  }),
                  _c("div", { staticClass: "tips-lg" }, [
                    _vm._v("能看到视频图像则表示摄像头正常"),
                  ]),
                  _c(
                    "div",
                    { staticStyle: { "padding-top": "10px" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { size: "mini", placeholder: "选择摄像头" },
                          model: {
                            value: _vm.deviceId,
                            callback: function ($$v) {
                              _vm.deviceId = $$v
                            },
                            expression: "deviceId",
                          },
                        },
                        _vm._l(_vm.cameras, function (item) {
                          return _c("el-option", {
                            key: item.deviceId,
                            attrs: { value: item.deviceId, label: item.label },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "tips-sm" }, [
                    _c("div", [
                      _vm._v(
                        "1、网页必须基于https下才能调用摄像头和麦克风设备。"
                      ),
                    ]),
                    _c("div", [
                      _vm._v(
                        "2、推荐使用Chrome谷歌浏览器、Firefox火狐浏览器。"
                      ),
                    ]),
                    _c("div", [
                      _vm._v(
                        "3、地址栏若弹出询问是否允许摄像头/麦克风时，必须允许。"
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm.step === "mobile"
              ? _c("div", [
                  _c("div", { staticClass: "m-box" }, [
                    _c("div", { staticStyle: { "flex-grow": "1" } }, [
                      _c("div", {
                        staticClass: "v-box",
                        attrs: { id: "video_mobile" },
                      }),
                      _c("div", { staticClass: "tips-lg" }, [
                        _vm._v("能看到画面则说明手机摄像头正常"),
                      ]),
                      _c("div", { staticClass: "tips-sm" }, [
                        _c("div", [
                          _vm._v(
                            "1、调试成功后请退出手机端、正式考试需再次进行扫码。"
                          ),
                        ]),
                        _c("div", [
                          _vm._v(
                            "2、扫码成功后请将摄像头放置于电脑斜45度，确保能看到完整的视角。"
                          ),
                        ]),
                        _c("div", [
                          _vm._v(
                            "3、请保证摄像头开启直至考试结束、注意手机不要息屏。"
                          ),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "m-right" }, [
                      _c("div", { staticClass: "mr-tt" }, [
                        _vm._v("副摄像头二维码"),
                      ]),
                      _c(
                        "div",
                        [
                          _c("vue-qr", {
                            attrs: { text: _vm.camUrl, size: 180 },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "mr-tips" }, [
                        _vm._v(" 请使用手机扫描上面二维码 "),
                      ]),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm.step === "screen"
              ? _c("div", [
                  _c("div", {
                    staticClass: "v-box",
                    attrs: { id: "video_screen" },
                  }),
                  _c("div", { staticClass: "tips-lg" }, [
                    _vm._v("能看到视频图像则表示录屏功能正常"),
                  ]),
                  _c("div", { staticClass: "tips-sm" }, [
                    _c("div", [
                      _vm._v(
                        "1、点击【整个屏幕】选项卡 > 选中屏幕图像 > 点击【分享】按钮"
                      ),
                    ]),
                    _c("div", [
                      _vm._v(
                        "2、Mac电脑需要给浏览器授权才能进行屏幕录制，请在【隐私与安全】为浏览器开启录制权限。"
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "justify-content": "space-between",
                  "margin-top": "10px",
                },
              },
              [
                _c(
                  "el-button",
                  {
                    staticStyle: { "flex-grow": "1" },
                    attrs: {
                      disabled: _vm.step === "camera",
                      type: "primary",
                      icon: "el-icon-back",
                    },
                    on: { click: _vm.handlePrev },
                  },
                  [_vm._v("上一步")]
                ),
                _c(
                  "el-button",
                  {
                    staticStyle: { "flex-grow": "1" },
                    attrs: {
                      disabled: _vm.step === "screen",
                      type: "primary",
                      icon: "el-icon-right",
                    },
                    on: { click: _vm.handleNext },
                  },
                  [_vm._v("下一步")]
                ),
                _vm.step === "screen"
                  ? _c(
                      "el-button",
                      {
                        staticStyle: { "flex-grow": "1" },
                        attrs: { type: "danger", icon: "el-icon-close" },
                        on: { click: _vm.handleClose },
                      },
                      [_vm._v("关闭")]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }