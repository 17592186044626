<template>

  <div>

    <web-table
      :options="options"
      :list-query="listQuery"
    >

      <template #filter-content/>

      <template #data-columns>

        <el-table-column
          label="报名名称"
          show-overflow-tooltip
        >
          <template v-slot="scope">
            <detail-link :params="{ id: scope.row.activityId }" :title="scope.row.title" to="WebActivityDetail" />
          </template>
        </el-table-column>

        <el-table-column
          align="center"
          width="160px"
          label="报名对象">

          <template v-slot="scope">
            <el-link type="primary" @click="handleView(scope.row.id)">{{ scope.row.joinName }}</el-link>
          </template>

        </el-table-column>

        <el-table-column
          label="报名类型"
          align="center"
          prop="activityType_dictText"
          width="120px"
        />

        <el-table-column
          label="报名时间"
          align="center"
          prop="createTime"
          width="180px"
        />

        <el-table-column
          align="center"
          width="160px"
          label="报名状态">

          <template v-slot="scope">

            <div style="display: flex; align-items: center; justify-content: center">
              <div style="margin-right: 10px">{{ scope.row.state_dictText }}</div>
              <div v-if="scope.row.state===1">
                <el-button type="danger" size="mini" @click="handlePay(scope.row.id)">支付</el-button>
              </div>
              <div v-else-if="scope.row.state===3">
                <el-button type="primary" size="mini" @click="handleUpdate(scope.row.id)">修改</el-button>
              </div>
              <div v-else-if="scope.row.state===0">
                <el-button v-if="scope.row.activityType==='1' && scope.row.refCourse" type="primary" size="mini" @click="toCourse(scope.row.activityId, scope.row.refCourse)">去学习</el-button>
                <el-button v-if="scope.row.activityType==='2' && scope.row.refExam" type="primary" size="mini" @click="toExam(scope.row.activityId, scope.row.refExam)">去考试</el-button>
              </div>
            </div>

          </template>

        </el-table-column>

      </template>

    </web-table>

    <activity-buy :join-id="joinId" :visible.sync="payVisible"/>
    <join-update-dialog :join-id="joinId" :visible.sync="updateVisible" msg="报名修改成功，请等待审核！"/>
    <join-view-dialog :join-id="joinId" :visible.sync="viewVisible"/>
  </div>
</template>

<script>
import JoinUpdateDialog from '@/views/admin/activity/components/JoinUpdateDialog.vue'
import JoinViewDialog from '@/views/admin/activity/components/JoinViewDialog.vue'

export default {
  name: 'ActivityJoinPage',
  components: { JoinViewDialog, JoinUpdateDialog },
  data() {
    return {
      activityId: null,
      payVisible: false,
      joinId: null,
      updateVisible: false,
      viewVisible: false,

      listQuery: {
        current: 1,
        size: 10,
        params: {
        }
      },

      options: {
        // 列表请求URL
        listUrl: '/api/activity/join/my-paging'
      }
    }
  },
  methods: {
    handlePay(joinId) {
      this.joinId = joinId
      this.payVisible = true
    },
    handleUpdate(joinId) {
      this.joinId = joinId
      this.updateVisible = true
    },
    handleView(joinId) {
      this.joinId = joinId
      this.viewVisible = true
    },
    // 去学习
    toCourse(activityId, courseId) {
      this.$router.push({ name: 'CourseView', params: { courseId: courseId }, query: { activityId: activityId }})
    },
    // 去考试
    toExam(activityId, examId) {
      this.$router.push({ name: 'CheckExam', params: { id: examId }, query: { activityId: activityId }})
    }
  }
}
</script>

<style scoped>

::v-deep .search-area{
  display: none;
}
</style>
