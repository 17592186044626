<template>

  <div>
    <el-tabs v-model="activeTab" type="card">
      <el-tab-pane label="课程中心" name="open">
        <open-course-page @join="reloadMy"/>
      </el-tab-pane>
      <el-tab-pane label="我的课程" name="my">
        <my-course-page ref="my" />
      </el-tab-pane>
    </el-tabs>
  </div>

</template>

<script>


import MyCoursePage from '@/views/web/course/components/MyCoursePage/index.vue'
import OpenCoursePage from '@/views/web/course/components/OpenCoursePage/index.vue'

export default {
  components: { OpenCoursePage, MyCoursePage },
  data() {
    return {
      cacheKey: 'course-index',
      activeTab: 'open'
    }
  },

  watch: {
    activeTab: {
      handler(val) {
        localStorage.setItem(this.cacheKey, val)
      }
    }
  },
  created() {
    // 获取缓存
    const cache = localStorage.getItem(this.cacheKey)
    if (cache) {
      this.activeTab = cache
    }
  },
  methods: {

    reloadMy() {
      this.$refs.my.reload()
    }
  }
}
</script>
