var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticStyle: { background: "#f5f5f5" } },
        [
          _vm.activity.banner
            ? _c("el-image", {
                staticStyle: { width: "100%" },
                attrs: { src: _vm.activity.banner, fit: "fill" },
              })
            : _vm._e(),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "flex-direction": "row",
                padding: "10px",
                "align-items": "center",
              },
            },
            [
              _c("h2", { staticStyle: { "flex-grow": "1" } }, [
                _vm._v(_vm._s(_vm.activity.title) + " "),
              ]),
              _vm.check.my && _vm.check.my.id
                ? _c("div", [
                    _vm.check.my.state === 0
                      ? _c(
                          "div",
                          [
                            _vm.activity.activityType === "1" &&
                            _vm.activity.refCourse
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.toCourse(
                                          _vm.activity.id,
                                          _vm.activity.refCourse
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("去学习")]
                                )
                              : _vm.activity.activityType === "2" &&
                                _vm.activity.refExam
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.toExam(
                                          _vm.activity.id,
                                          _vm.activity.refExam
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("去考试")]
                                )
                              : _c(
                                  "el-button",
                                  {
                                    attrs: { type: "success" },
                                    on: { click: _vm.handleView },
                                  },
                                  [_vm._v("我已报名成功")]
                                ),
                          ],
                          1
                        )
                      : _vm.check.my.state === 1
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "danger" },
                                on: { click: _vm.handlePay },
                              },
                              [_vm._v("立即支付")]
                            ),
                          ],
                          1
                        )
                      : _vm.check.my.state === 2
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "warn" },
                                on: { click: _vm.handleView },
                              },
                              [_vm._v("等待管理员审核")]
                            ),
                          ],
                          1
                        )
                      : _vm.check.my.state === 3
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "danger" },
                                on: { click: _vm.handleUpdate },
                              },
                              [_vm._v("修改报名信息")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ])
                : _c(
                    "div",
                    [
                      _vm.check.disabled
                        ? _c("el-tag", { attrs: { type: "info" } }, [
                            _vm._v("报名已禁用"),
                          ])
                        : _vm.check.notStart
                        ? _c("el-tag", { attrs: { type: "warning" } }, [
                            _vm._v("报名尚未开始"),
                          ])
                        : _vm.check.ended
                        ? _c("el-tag", { attrs: { type: "warning" } }, [
                            _vm._v("报名已结束"),
                          ])
                        : _vm.check.overJoin
                        ? _c("el-tag", { attrs: { type: "danger" } }, [
                            _vm._v("报名人数已满"),
                          ])
                        : _c(
                            "div",
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: { click: _vm.handleJoin },
                                },
                                [_vm._v("立即报名")]
                              ),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
            ]
          ),
        ],
        1
      ),
      _c(
        "el-tabs",
        {
          staticClass: "uc-tab",
          model: {
            value: _vm.activeTab,
            callback: function ($$v) {
              _vm.activeTab = $$v
            },
            expression: "activeTab",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "报名信息", name: "first" } },
            [
              _c("div", { staticStyle: { "margin-top": "-40px" } }, [
                _c("h3", [_vm._v("报名详情")]),
                _c("div", [
                  _c("p", [
                    _vm._v(
                      "报名时间：" +
                        _vm._s(_vm.activity.startTime) +
                        "至" +
                        _vm._s(_vm.activity.endTime)
                    ),
                  ]),
                  _vm.activity.maxJoin > 0
                    ? _c("p", [
                        _vm._v(
                          "限定人数：" + _vm._s(_vm.activity.maxJoin) + "人"
                        ),
                      ])
                    : _c("p", [_vm._v("限定人数：不限制")]),
                  _vm.activity.price > 0
                    ? _c("p", { staticClass: "module-pay" }, [
                        _vm._v("报名价格：" + _vm._s(_vm.activity.price)),
                      ])
                    : _c("p", { staticClass: "module-pay" }, [
                        _vm._v("报名价格：免费"),
                      ]),
                  _c("p", [
                    _vm._v(
                      "人工审核：" + _vm._s(_vm.activity.audit ? "是" : "否")
                    ),
                  ]),
                ]),
              ]),
              _c("el-divider"),
              _c("h3", [_vm._v("报名须知")]),
              _c("p", {
                staticClass: "intro",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$options.filters.formatRichText(_vm.activity.content)
                  ),
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("activity-join-dialog", {
        attrs: {
          activity: _vm.activity,
          password: _vm.password,
          visible: _vm.dialogVisible,
        },
        on: {
          "update:visible": function ($event) {
            _vm.dialogVisible = $event
          },
        },
      }),
      _vm.check.my
        ? _c("activity-buy", {
            attrs: { "join-id": _vm.check.my.id, visible: _vm.payVisible },
            on: {
              "update:visible": function ($event) {
                _vm.payVisible = $event
              },
            },
          })
        : _vm._e(),
      _vm.check.my
        ? _c("join-view-dialog", {
            attrs: { "join-id": _vm.check.my.id, visible: _vm.viewVisible },
            on: {
              "update:visible": function ($event) {
                _vm.viewVisible = $event
              },
            },
          })
        : _vm._e(),
      _vm.check.my
        ? _c("join-update-dialog", {
            attrs: {
              "join-id": _vm.check.my.id,
              visible: _vm.updateVisible,
              msg: "报名修改成功，请等待审核！",
            },
            on: {
              "update:visible": function ($event) {
                _vm.updateVisible = $event
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }