<template>

  <div>
    <Tinymce ref="editor" v-model="postForm.content" :height="200" />

    <div class="answer-list">
      <div style="display: flex; align-items: center; justify-content: flex-end">
        <el-checkbox v-model="postForm.required" class="opt-item">必答</el-checkbox>
      </div>
    </div>

  </div>

</template>

<script>

export default {
  name: 'SurveyQuTypeAsk',
  props: {
    surveyId: String,
    value: Object
  },
  data() {
    return {
      postForm: {
        required: false
      }
    }
  },

  watch: {
    surveyId: {
      handler() {
        // 查找详情
      }
    },
    value: {
      handler(val) {
        this.postForm = val
      }
    }
  },

  created() {
    // 查找详情
    this.postForm = this.value
  },


  methods: {

    // 添加说明
    addRemark(index) {
      const item = this.postForm.answerList[index]
      const remark = item.remark || ''
      this.$prompt('输入选项说明', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: remark
      }).then(({ value }) => {
        item.remark = value
        this.$forceUpdate()
      })
    }
  }
}
</script>

<style scoped>

.answer-list{
  margin-top: 10px;
}
.opt-item + .opt-item {
  margin-left: 10px;
}


</style>
